import React, { memo } from 'react';

const IconLogoWhite: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36 9H21.5V35H7V22H21.5H36" stroke="#F8F8F8" strokeWidth="2" />
    </svg>
  );
};

export default memo(IconLogoWhite);
