import React, { useCallback } from 'react';

import { AssignmentItem } from 'shared/types/entities';
import { useSearchParams } from 'react-router-dom';
import { cx } from 'shared/utils';
import { getIconByUrl } from 'shared/utils/getIcon';
import { Tooltip } from 'antd';
import { noop } from 'lodash';
import { useAppSelector } from 'shared/store/hooks';

import './styles.scss';

type Props = {
  isCollapsed?: boolean;
  item: AssignmentItem;
};

const AssignmentCard: React.FC<Props> = ({ isCollapsed, item }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectGenerate = useAppSelector((state) => state.project.generation);

  const version = searchParams.get('version');
  const structureItem = searchParams.get('str');
  const isSelected = structureItem === item.id;

  const onClickCard = useCallback(() => {
    if (version) {
      setSearchParams({ str: item.id, version: version });
    } else {
      setSearchParams({ str: item.id });
    }
  }, [item.id, version]);

  const classNames = cx('assignment-card', {
    'assignment-card--selected': isSelected,
    'tour--assignments-quiz': item.type?.name === 'quiz',
    'assignment-card--disabled': projectGenerate,
  });

  const content = (
    <>
      <div className="assignment-card__index-wrap">
        <span className="assignment-card__index">{item.index}</span>
      </div>
      <div className="assignment-card__icon">{getIconByUrl(item.type?.icon)}</div>
    </>
  );

  return (
    <div role="button" className={classNames} onClick={projectGenerate ? noop : onClickCard}>
      {isCollapsed ? (
        <Tooltip className="assignment-card-tooltip" title={item.title} trigger="hover">
          {content}
        </Tooltip>
      ) : (
        content
      )}
      {!isCollapsed ? (
        <div className="assignment-card__content">
          <span className="assignment-card__title">{item.title}</span>
          <span className="assignment-card__description">{item.description}</span>
        </div>
      ) : null}
    </div>
  );
};

export default AssignmentCard;
