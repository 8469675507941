import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { cx } from 'shared/utils';
import { useBoolean } from 'usehooks-ts';
import { MenuInfo, QuizAnswer, QuizContentItem } from 'shared/types/entities';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import {
  createQuizAction,
  generateQuizAnswersAction,
  removeAssignmentQuizAction,
  setAddQuiz,
  updateAssignmentQuizAction,
  useCurrentAssignment,
} from 'entities/assignment/model';

import { useOwner } from '../../shared/hooks/useProjectOwner';

import QuizCardHead from './ui/quiz-card-head';
import QuizCardItem from './ui/quiz-card-item';

import './styles.scss';

type Props = {
  canShowTour?: boolean;
  isPrint?: boolean;
  isNew?: boolean;
  item: QuizContentItem;
};

const QuizCard: React.FC<Props> = ({ canShowTour, isPrint, isNew, item }) => {
  const { value: open, toggle } = useBoolean(!isNew);
  const { value: isShowEmpty, setTrue, setFalse } = useBoolean(false);
  const [state, setState] = useState<QuizContentItem>(item);
  const dispatch = useAppDispatch();
  const { assignment, loading } = useCurrentAssignment();
  const isGeneration = useAppSelector((s) => s.project.generation);
  const selectedVersion = useAppSelector((s) => s.project.selectedVersion);
  const { isOwner } = useOwner();

  useEffect(() => {
    if (item) {
      setState(item);
    }
  }, [item]);

  const handleChangeHead = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleChangeAnswer = useCallback((id: string, text: string) => {
    setState((prev) => ({
      ...prev,
      children: prev?.children?.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            content: text,
          };
        } else {
          return el;
        }
      }),
    }));
  }, []);

  const handleChangeCheckbox = useCallback(
    (id: string, value: boolean) => {
      setState((prev) => {
        const temp = {
          ...prev,
          children: prev?.children?.map((el) => {
            if (el.id === id) {
              return {
                ...el,
                is_answer: value,
              };
            } else {
              return el;
            }
          }),
        };
        void dispatch(updateAssignmentQuizAction(item.id, temp));
        return temp;
      });
    },
    [dispatch, item.id]
  );

  const onMoreActions = useCallback(
    (info: MenuInfo) => {
      if (info.key === 'del') {
        void dispatch(removeAssignmentQuizAction(item.id));
      }
      if (info.key === 'gen') {
        void dispatch(generateQuizAnswersAction(item.id));
      }
    },
    [dispatch, item.id]
  );

  const onClickAdd = useCallback(() => {
    setTrue();
    setState((prev) => {
      const newItem: QuizAnswer = {
        id: '100500100',
        content: '',
        is_answer: false,
        order: (prev.children?.length || 0) + 1,
      };
      const newChildren = prev.children ? [...prev.children, newItem] : [newItem];
      return {
        ...prev,
        children: newChildren,
      };
    });
  }, [setTrue]);

  const onBlur = () => {
    if (isNew) {
      if (state.content) {
        const payload = {
          content: state.content,
          children: [],
        };
        if (assignment) {
          void dispatch(createQuizAction(assignment.id, payload));
        }
      } else {
        dispatch(setAddQuiz(false));
      }
    } else {
      const payload = {
        ...state,
        children: state.children
          ?.map((el) => {
            if (el.id === '100500100') {
              return {
                content: el.content,
                is_answer: el.is_answer,
                order: el.order,
              };
            } else {
              return el;
            }
          })
          .filter((el) => el.content),
      };
      void dispatch(updateAssignmentQuizAction(item.id, payload)).finally(() => {
        setState((prev) => ({
          ...prev,
          children: prev.children?.filter((el) => el.id !== '100500100'),
        }));
      });
      setFalse();
    }
  };

  const classNamesContent = cx('quiz-card-content', {
    'quiz-card-content--open': open,
    'quiz-card-content--empty': isEmpty(state?.children),
  });

  const disable = !isOwner || loading || isGeneration || !!isPrint || !!selectedVersion;

  return (
    <div className="quiz-card">
      <QuizCardHead
        canShowTour={canShowTour}
        isPrint={isPrint}
        isShowEmpty={isShowEmpty}
        isNew={isNew}
        open={open}
        onToggle={toggle}
        item={state}
        onMoreActions={onMoreActions}
        onChangeText={handleChangeHead}
        onBlur={onBlur}
        onClickAdd={onClickAdd}
      />
      <div className={classNamesContent}>
        <div
          className={cx('quiz-card-content__inner', {
            'quiz-card-content__inner--print': isPrint,
          })}
        >
          {state?.children?.map((answer) => {
            return (
              <QuizCardItem
                isPrint={isPrint}
                disabled={disable}
                isShowEmpty={isShowEmpty}
                key={answer.id}
                item={answer}
                onChangeText={handleChangeAnswer}
                onChangeCheckbox={handleChangeCheckbox}
                onBlur={onBlur}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QuizCard;
