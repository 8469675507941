import { gql } from '@apollo/client';

import { ACTIVE_MODULE_OUTCOMES, ACTIVE_PROJECT_VERSION, ACTIVE_SUB_MODULE_OUTCOMES } from './fragments';

export const PROJECT_VERSION = gql`
  query ProjectVersion($projectVerId: Int!) {
    outline_builder_project_version(id: $projectVerId) {
      ...ActiveProjectVersion
      active_modules_outcomes {
        ...ActiveModuleOutcomes
        active_sub_modules_outcomes {
          ...ActiveSubModuleOutcomes
        }
      }
    }
  }
  ${ACTIVE_PROJECT_VERSION}
  ${ACTIVE_MODULE_OUTCOMES}
  ${ACTIVE_SUB_MODULE_OUTCOMES}
`;
