import { VideoAvatarPosition } from 'entities/studio/model/types';

import avatarLeft from './icons/avatar-left-w-slide.svg';
import avatarRight from './icons/avatar-right-w-slide.svg';
import avatarCenter from './icons/avatar-center-w-slide.svg';
import avatarOnly from './icons/avatar-only.svg';
import slideOnly from './icons/slide-only.svg';

export const buttonsMap = [
  {
    key: VideoAvatarPosition.AVATAR_LEFT_W_SLIDE,
    icon: avatarLeft,
  },
  {
    key: VideoAvatarPosition.AVATAR_RIGHT_W_SLIDE,
    icon: avatarRight,
  },
  {
    key: VideoAvatarPosition.AVATAR_CENTER_W_SLIDE,
    icon: avatarCenter,
  },
  {
    key: VideoAvatarPosition.AVATAR_ONLY,
    icon: avatarOnly,
  },
  {
    key: VideoAvatarPosition.SLIDE_ONLY,
    icon: slideOnly,
  },
];
