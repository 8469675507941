import { useQuery } from '@apollo/client';
import { SUBSCRIPTIONS } from 'shared/graphql/schemas/subscriptions';

import { BillingHistory } from '../../../shared/graphql/schemas/billingHistory';
import { AFFILIATE_HISTORY } from '../../../shared/graphql/schemas/affiliateHistory';

import { AffiliateHistoryData, BillingHistoryData, SubscriptionItemData } from './types';

export const useSubscriptions = () => {
  const { data } = useQuery<SubscriptionItemData>(SUBSCRIPTIONS, {
    fetchPolicy: 'network-only',
  });

  return {
    subscriptions: data?.outline_builder_subscriptions,
    free: data?.outline_builder_subscriptions.find((item) => item.type === 'free'),
    pro: data?.outline_builder_subscriptions.find((item) => item.type === 'pro'),
  };
};

type BillingHistoryProps = {
  projectId?: string;
};

export const useBillingHistory = ({ projectId }: BillingHistoryProps) => {
  const { data, loading } = useQuery<BillingHistoryData>(BillingHistory, {
    fetchPolicy: 'network-only',
    variables: {
      project_id: projectId,
    },
  });

  return {
    billingHistory: data?.outline_builder_billing_history.edges.map((item) => item.node),
    loading,
  };
};

export const useAffiliateHistory = () => {
  const { data, loading } = useQuery<AffiliateHistoryData>(AFFILIATE_HISTORY, {
    fetchPolicy: 'network-only',
  });

  console.log(data);

  return {
    affiliateHistory: data?.outline_builder_affiliation_history.edges.map((item, index) => {
      return {
        ...item.node,
        id: index + 1,
      };
    }),
    loading,
  };
};
