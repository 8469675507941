import { useQuery } from '@apollo/client';
import { BaseListOption } from 'shared/types/entities';

import { INDUSTRIES } from '../schemas/industries';
import { localStorageService } from '../../services/localStorageService';
import { baseListToSelect } from '../../utils';

type Node = {
  node: BaseListOption;
};

type Data = {
  industries: {
    edges: Node[];
  };
};

const useIndustries = () => {
  const language = localStorageService.getLangData();
  const { data } = useQuery<Data>(INDUSTRIES, {
    variables: {
      language,
    },
    fetchPolicy: 'network-only',
  });

  const industries = data?.industries.edges.map((item) => item.node);
  const sorted = industries?.sort((a, b) => a?.order - b?.order);

  return {
    industries,
    industriesForSelect: baseListToSelect(sorted),
  };
};

export default useIndustries;
