import React, { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { getProjectAction } from 'modules/project/actions';
import { getAssignmentsFullAction } from 'widgets/assignments-list/store/actions';
import { setLayout } from 'app/store';
import LearningOutcomesStructureViewItem from 'modules/learning-outcomes-structure-view/ui/learning-outcomes-structure-view-item/LearningOutcomesStructureViewItem';
import QuizCard from 'modules/quiz-card';
import BlockTitle from 'shared/ui/block-title/BlockTitle';

import { getIconByUrl } from 'shared/utils/getIcon';

import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import 'modules/assignments-head/styles.scss';
import './styles.scss';

const QuizzesPrint: React.FC = () => {
  const { id, lang } = useParams();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.project);
  const assignments = useAppSelector((state) => state.assignments.assignments);

  useEffect(() => {
    dispatch(setLayout('print'));
    return () => {
      dispatch(setLayout(null));
    };
  }, [dispatch]);

  const initPage = useCallback(() => {
    if (id && lang) {
      void dispatch(getProjectAction(id, false, undefined, lang));
      void dispatch(getAssignmentsFullAction(id, lang)).then(() => {
        setTimeout(() => window.print(), 1000);
      });
    }
  }, [dispatch, id, lang]);

  useEffect(() => {
    initPage();
  }, [initPage]);

  const filtered = assignments?.filter((a) => a?.type?.name === 'quiz' && a.assignment_filled);

  return (
    <div className="course-print">
      <h4 className="course-print__title">{project?.active_project_version?.generated_title}</h4>
      <BlockTitle text="Course Description" />
      <span className="lo-description-view__text mb-8">{project?.active_project_version?.generated_description}</span>
      {filtered?.map((item) => {
        return (
          <div key={item.id} className="course-print-quizzes-block">
            <div className="assignment-select-head">
              <div className="assignment-select-head__top">
                <span className="assignment-select-head__index">{item?.index}</span>
                <div className="assignment-select-head__icon">{getIconByUrl(item?.type?.icon)}</div>
                <div className="assignment-select-head__controls assignment-select-head__title">{item.title}</div>
              </div>
              <div className="assignment-select-head__controls assignment-select-head__description">
                {item.description}
              </div>
              <div className="assignment-select-head__lo">
                {item?.sub_module_outcomes?.map((el) => {
                  return (
                    <LearningOutcomesStructureViewItem
                      key={el.id}
                      tag={el.letter_index}
                      text={el.name}
                      color={el.outline_builder_project_module_outcome.color}
                    />
                  );
                })}
              </div>
            </div>
            <div className="course-print-quizzes-content">
              {item?.assignment?.content_items?.map((c) => {
                return <QuizCard isPrint key={item.id} item={c} />;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default QuizzesPrint;
