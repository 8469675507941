import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { StructureItem } from 'shared/types/entities';
import useObjectTypes from 'shared/graphql/queries/useObjectTypes';
import { useDebounce } from 'usehooks-ts';
import CourseStructureElementView from 'shared/components/course-structure-element-view/CourseStructureElementView';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { useOwner } from 'shared/hooks/useProjectOwner';

import { createStructureItemAction } from '../store/actions';
import { setAddElement, setAddElementType } from '../store';

import './styles.scss';

type Props = {
  item: StructureItem;
};

const AddStructureElement: React.FC<Props> = ({ item }) => {
  const [state, setState] = useState({ title: '', description: '' });
  const [focus, setFocus] = useState({ title: true, description: false });

  const { objectTypes } = useObjectTypes();
  const projectVerId = useAppSelector((s) => s.project.project?.active_project_version?.id);
  const dispatch = useAppDispatch();
  const store = useAppSelector((s) => s.structureItem);
  const { isOwner } = useOwner();
  const debouncedFocus = useDebounce<any>(focus, 1000);

  const onFinish = useCallback(() => {
    dispatch(setAddElement(null));
    dispatch(setAddElementType(null));
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    if (projectVerId) {
      const payload = {
        ...state,
        course_structure_item_parent_id: Number(store.showAddElement),
        type_id: Number(store.addElementType),
      };
      dispatch(createStructureItemAction(payload)).finally(() => {
        onFinish();
      });
    }
  }, [dispatch, onFinish, state, store.addElementType, store.showAddElement, projectVerId]);

  useEffect(() => {
    if (!debouncedFocus.title && !debouncedFocus.description) {
      if (state.title || state.description) {
        void onSubmit();
      } else {
        onFinish();
      }
    }
  }, [debouncedFocus, onFinish, onSubmit, state.description, state.title, store.addElementType, store.showAddElement]);

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const onBlur = (event: any) => {
    const { name } = event.target;
    setFocus((prevState) => ({
      ...prevState,
      [name]: false,
    }));
  };

  const onFocus = (event: any) => {
    const { name } = event.target;
    setFocus((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const selectedType = objectTypes?.find((t) => t.id === store.addElementType);

  return (
    <CourseStructureElementView
      className="add-structure-elem"
      autoFocus={true}
      index={`${item.index}.${item?.children?.length + 1}`}
      icon={selectedType?.icon}
      title={state.title}
      description={state.description}
      disabled={store.loading || !isOwner}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={handleChange}
    />
  );
};

export default AddStructureElement;
