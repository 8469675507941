import { AppDispatch, store } from 'shared/store/store';
import {
  postOutlineBuilderProjectStructureItemVideoSettingsRequest,
  VideoSettingsPayload,
} from 'shared/api/requests/outline-builder-project-structure';
import { messageSave } from 'shared/utils';
import { finishLoading, startLoading } from 'modules/course-structure-collapse/store';
import { message } from 'antd';
import { getAssignmentItemAction } from 'entities/assignment/model';

export const updateVideoSettings = (structureItemId: string, payload: VideoSettingsPayload) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment.assignment;

    messageSave();
    dispatch(startLoading());
    await postOutlineBuilderProjectStructureItemVideoSettingsRequest(structureItemId, payload)
      .then(() => {
        if (assign?.id) {
          dispatch(getAssignmentItemAction(assign.id, true)).then(() => {
            message.destroy();
            dispatch(finishLoading());
          });
        }
      })
      .catch((error) => {
        console.log(error);
        message.destroy();
        dispatch(finishLoading());
      });
  };
};
