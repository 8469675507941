import { useLayoutEffect } from 'react';

function useLockBodyScroll(initialState = false) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (initialState) {
      document.body.style.overflow = 'hidden';
    }

    return () => (document.body.style.overflow = originalStyle);
  }, [initialState]);
}

export default useLockBodyScroll;
