import React, { memo } from 'react';

import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { cx } from 'shared/utils';
const ATextArea = Input.TextArea;

import './styles.scss';

const TextArea: React.FC<TextAreaProps> = (props) => {
  const classNames = cx('cb-text-area', props.className);
  return <ATextArea {...props} className={classNames} />;
};

export default memo(TextArea);
