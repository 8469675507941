import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { PROJECT_VERSIONS } from '../schemas/project-versions';
import { dateFormat } from '../../utils';

export type ProjectVersionShort = {
  id: string;
  pk: string;
  created_at: string;
  is_active: boolean;
};

type ProjectVersionShortData = {
  outline_builder_project_versions: {
    edges: {
      node: ProjectVersionShort;
    }[];
  };
};

type Props = {
  id?: string;
  lang?: string;
  skip?: boolean;
};

const useProjectVersions = ({ id, lang, skip }: Props) => {
  const { data, loading, refetch } = useQuery<ProjectVersionShortData>(PROJECT_VERSIONS, {
    variables: {
      projectId: Number(id),
      language: lang,
    },
    notifyOnNetworkStatusChange: true,
    skip: skip,
    nextFetchPolicy: 'network-only',
  });

  const convertedData = data?.outline_builder_project_versions.edges.map((v) => v.node);

  return {
    loading,
    data: convertedData,
    refetch,
  };
};

export default useProjectVersions;
