import { generatePath } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';

import { localStorageService } from '../../shared/services/localStorageService';

export const courseNavigation = (
  courseId = 'new',
  lang = localStorageService.getLangData(),
  disableAbout?: boolean,
  disableLO?: boolean,
  disableStructure?: boolean,
  allDisable?: boolean
) => {
  return [
    {
      path: generatePath(PATH_NAME.courseManualWithEntity, {
        id: courseId,
        lang,
        entity: PATH_NAME.courseEntity.settings,
      }),
      translateKey: 'courseNav.settings',
      disabled: allDisable,
      entity: PATH_NAME.courseEntity.settings,
    },
    {
      path: generatePath(PATH_NAME.courseManualWithEntity, {
        id: courseId,
        lang,
        entity: PATH_NAME.courseEntity.about,
      }),
      translateKey: 'courseNav.about',
      disabled: disableAbout || allDisable,
      entity: PATH_NAME.courseEntity.about,
    },
    {
      path: generatePath(PATH_NAME.courseManualWithEntity, {
        id: courseId,
        lang,
        entity: PATH_NAME.courseEntity.learning,
      }),
      translateKey: 'courseNav.learningOutcomes',
      disabled: disableLO || allDisable,
      entity: PATH_NAME.courseEntity.learning,
    },
    {
      path: generatePath(PATH_NAME.courseManualWithEntity, {
        id: courseId,
        lang,
        entity: PATH_NAME.courseEntity.structure,
      }),
      translateKey: 'courseNav.structure',
      disabled: disableStructure || allDisable,
      entity: PATH_NAME.courseEntity.structure,
    },
    {
      path: generatePath(PATH_NAME.courseManualWithEntity, {
        id: courseId,
        lang,
        entity: PATH_NAME.courseEntity.content,
      }),
      translateKey: 'courseNav.content',
      disabled: disableStructure || allDisable,
      entity: PATH_NAME.courseEntity.content,
    },
    {
      path: generatePath(PATH_NAME.courseManualWithEntity, {
        id: courseId,
        lang,
        entity: PATH_NAME.courseEntity.assignments,
      }),
      translateKey: 'courseNav.assignments',
      disabled: disableStructure || allDisable,
      entity: PATH_NAME.courseEntity.assignments,
    },
  ];
};
