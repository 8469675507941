import React, { memo } from 'react';

import { PromoMessageData } from 'shared/types/entities';
import Button from 'shared/ui/button';
import mixpanel from 'mixpanel-browser';
import { CloseOutlined } from '@ant-design/icons';
import { closePromoAction } from 'app/store/actions';

import { useAppDispatch } from 'shared/store/hooks';

import './styles.scss';

type Props = {
  promoId: string;
  item: PromoMessageData;
};

const PromoAlertCard: React.FC<Props> = ({ promoId, item }) => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    mixpanel.track('Promo banner click button', { BannerId: promoId, BannerType: 'alert' });
    window.open(item.button_href, '_blank');
  };

  const onClosePromo = () => {
    mixpanel.track('Promo banner click button close', { BannerId: promoId, BannerType: 'alert' });
    void dispatch(closePromoAction(promoId));
  };

  return (
    <div className="promo-alert-card">
      <div className="promo-alert-card__info">
        <span className="promo-alert-card__title">{item.title}</span>
        <span className="promo-alert-card__description">{item.description}</span>
      </div>
      <Button size="middle" type="primary" onClick={onClick}>
        {item.button_name}
      </Button>
      <Button className="ml-2" type="text" icon={<CloseOutlined />} onClick={onClosePromo} />
    </div>
  );
};

export default memo(PromoAlertCard);
