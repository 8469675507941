import React, { useCallback } from 'react';

import Button from 'shared/ui/button';
import { Dropdown, MenuProps } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import IconMagic from 'shared/ui/icons/magic';
import { cx } from 'shared/utils';
import { useTranslation } from 'react-i18next';
import { LevelType, ObjectType } from 'shared/types/entities';
import useObjectTypes from 'shared/graphql/queries/useObjectTypes';
import { getIconByUrl } from 'shared/utils/getIcon';
import { useAppDispatch } from 'shared/store/hooks';

import { setAddElement, setAddElementType, setTypeMenuOpen } from '../store';

import './styles.scss';

type DropdownLabelProps = {
  text: string;
  type: 'gen' | 'add' | 'del' | 'up' | 'down';
  hidden?: boolean;
  action?: () => void;
};

const getIcon = (type: 'gen' | 'add' | 'del' | 'up' | 'down') => {
  switch (type) {
    case 'gen':
      return <IconMagic size={12} />;
    case 'add':
      return <PlusOutlined style={{ fontSize: 12 }} />;
    case 'del':
      return <DeleteOutlined style={{ fontSize: 12 }} />;
    case 'up':
      return <ArrowUpOutlined style={{ fontSize: 12 }} />;
    case 'down':
      return <ArrowDownOutlined style={{ fontSize: 12 }} />;
    default:
      return <DeleteOutlined style={{ fontSize: 12 }} />;
  }
};

export const DropdownLabel = ({ text, type, hidden, action }: DropdownLabelProps) => {
  if (hidden) {
    return null;
  }
  const classNames = cx('more-actions-label', {
    [`more-actions-label--${type}`]: type,
  });
  return (
    <div className={classNames} onClick={action}>
      <div className="more-actions-label__icon">{getIcon(type)}</div>
      <span className="more-actions-label__text">{text}</span>
    </div>
  );
};

type DropdownLabelChildrenProps = {
  item: ObjectType;
  itemId?: string;
  cb?: () => void;
};

const DropdownLabelChildren = ({ item, itemId, cb }: DropdownLabelChildrenProps) => {
  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    dispatch(setAddElementType(item.id));
    dispatch(setAddElement(itemId || ''));
    dispatch(setTypeMenuOpen(false));
    cb?.();
  }, [cb, dispatch, item.id, itemId]);

  return (
    <div className="more-actions-label" onClick={onClick}>
      <div className="more-actions-label__icon">{getIconByUrl(item.icon)}</div>
      <span className="more-actions-label__text">{item.title}</span>
    </div>
  );
};

type Props = {
  className?: string;
  disabled?: boolean;
  itemId?: string;
  levelType: LevelType;
  onAdd?: () => void;
  onRemove?: () => void;
  onDropCallback?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
};

const MoreActions: React.FC<Props> = ({
  className,
  disabled,
  itemId,
  onMoveUp,
  onMoveDown,
  onRemove,
  onDropCallback,
}) => {
  const { t } = useTranslation();
  const { objectTypes } = useObjectTypes();

  const items: MenuProps['items'] = objectTypes?.map((el) => {
    return {
      key: el.id,
      label: <DropdownLabelChildren key={el.id} itemId={itemId} item={el} cb={onDropCallback} />,
    };
  });

  const itemsForModule: MenuProps['items'] = [
    // {
    //   key: 'generation',
    //   label: <DropdownLabel text={t<string>('structure.moreActions.regenerate')} type="gen" action={onGenerate} />,
    // },
    {
      key: 'moveUp',
      label: <DropdownLabel text={t<string>('structure.moreActions.moveUp')} type="up" action={onMoveUp} />,
    },
    {
      key: 'moveDown',
      label: <DropdownLabel text={t<string>('structure.moreActions.moveDown')} type="down" action={onMoveDown} />,
    },
    // {
    //   key: 'add',
    //   label: (
    //     <DropdownLabel
    //       hidden={levelType === 'production_item'}
    //       text={
    //         levelType === 'lesson'
    //           ? t<string>('structure.moreActions.addItem')
    //           : t<string>('structure.moreActions.addLesson')
    //       }
    //       type="add"
    //       action={levelType === 'lesson' ? noop : onAdd}
    //     />
    //   ),
    //   children: levelType === 'lesson' ? items : undefined,
    // },
    {
      key: 'del',
      label: <DropdownLabel text={t<string>('structure.moreActions.delete')} type="del" action={onRemove} />,
    },
  ];

  return (
    <Dropdown
      disabled={disabled}
      overlayClassName="more-action-drop"
      placement="bottomRight"
      trigger={['click']}
      menu={{ items: itemsForModule, triggerSubMenuAction: 'click', expandIcon: () => null }}
    >
      <Button
        className={cx(className)}
        disabled={disabled}
        size="small"
        shape="circle"
        type="text"
        icon={<EllipsisOutlined style={{ fontSize: 16 }} />}
      />
    </Dropdown>
  );
};

export default MoreActions;
