import { RootState } from 'shared/store/store';

export const documentFileSections = (state: RootState) => state.studio.file?.related_files[0].sections;
export const selectScriptId = (state: RootState) => state.studio.scriptId;
export const documentFileId = (state: RootState) => state.studio.file?.id;
export const selectSlideLoading = (state: RootState) => state.studio.slideLoading;
export const selectPresentationFile = (state: RootState) => state.studio.presentationFile;
export const selectPresentationFileId = (state: RootState) => state.studio.presentationFile?.id;
export const selectPresentationStatus = (state: RootState) => state.studio.presentationFile?.state;
export const selectLinkedSlide = (state: RootState) => state.studio.linkedSlides;
