import React, { memo, ReactNode } from 'react';

import './styles.scss';

type Props = {
  children: ReactNode;
};

const TourText: React.FC<Props> = ({ children }) => {
  return <span className="tour__text">{children}</span>;
};

export default memo(TourText);
