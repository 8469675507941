import { gql } from '@apollo/client';

import { STRUCTURE_ITEM_VIDEO_SETTINGS_FRAGMENT } from './fragments';

export const STRUCTURE_ITEM_VIDEO_SETTINGS = gql`
  query StructureItemVideoSettings($id: Int) {
    outline_builder_course_structure_item(id: $id) {
      ...StructureItemVideoSettings
    }
  }
  ${STRUCTURE_ITEM_VIDEO_SETTINGS_FRAGMENT}
`;
