import { message } from 'antd';
import i18n from 'i18next';

import modalService from '../services/modalService';

export const messageDurability = 8;

message.config({
  maxCount: 1,
});

export const messageSave = () => {
  void message.open({
    type: 'loading',
    content: i18n.t<string>('messages.saving'),
    duration: 0,
  });
};

export const messageGeneration = () => {
  void message.open({
    type: 'loading',
    content: i18n.t<string>('messages.generating'),
    duration: 0,
  });
};

export const messageRejectedGeneration = () => {
  void message.error({
    type: 'loading',
    content: i18n.t<string>('messages.rejected'),
    duration: messageDurability,
  });
};

export type CustomDataError = {
  error: string | null;
  error_code: string | null;
};

export const messageError = (err?: any) => {
  const dataError = err?.data as CustomDataError;
  const text = dataError?.error_code ? i18n.t(`errorCode.${dataError?.error_code}`) : dataError.error;

  if (dataError?.error_code === 'cob_insufficient_funds') {
    modalService.openTokensModal({});
  } else {
    void message.error({
      type: 'loading',
      content: text,
      duration: messageDurability,
    });
  }
};

export const messageErrorStr = (errStr?: string) => {
  const text = errStr ? errStr : i18n.t<string>('messages.error');

  void message.error({
    type: 'loading',
    content: text,
    duration: messageDurability,
  });
};
