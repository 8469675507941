import { PATH_NAME } from 'pages/constants';

export const profileNav = [
  {
    path: PATH_NAME.profile,
    translateKey: 'profileNav.profile',
  },
  {
    path: PATH_NAME.billing,
    translateKey: 'profileNav.billing',
  },
  {
    path: PATH_NAME.affiliate,
    translateKey: 'profileNav.affiliate',
  },
];
