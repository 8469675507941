import { gql } from '@apollo/client';

import { ACTIVE_PROJECT_VERSION, OUTLINE_PROJECT_NODE, PAGE_INFO } from './fragments';

export const OUTLINE_BUILDER_PROJECTS = gql`
  query OutlineBuilderProjects($after: String, $offset: Int, $first: Int) {
    outline_builder_projects(after: $after, offset: $offset, first: $first) {
      edges {
        node {
          ...OutlineProjectNode
          active_project_version {
            ...ActiveProjectVersion
            active_modules_outcomes {
              id
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PAGE_INFO}
  ${OUTLINE_PROJECT_NODE}
  ${ACTIVE_PROJECT_VERSION}
`;
