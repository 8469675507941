import React, { memo, useCallback } from 'react';

import { ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button';
import { onSetAllCollapseItemAction } from 'widgets/course-structure/store/actions';

import { useAppDispatch } from 'shared/store/hooks';

import './styles.scss';

type Props = {
  callback?: () => void;
};

const StructureCollapseButtons: React.FC<Props> = ({ callback }) => {
  const dispatch = useAppDispatch();

  const onExpandAll = useCallback(() => {
    void dispatch(onSetAllCollapseItemAction(true));
  }, [dispatch]);

  const onCollapseAll = useCallback(() => {
    void dispatch(onSetAllCollapseItemAction(false));
    callback?.();
  }, [callback, dispatch]);

  return (
    <div className="structure-collapse-buttons">
      <Button
        shape="circle"
        type="default"
        icon={<ExpandAltOutlined style={{ fontSize: 14 }} />}
        onClick={onExpandAll}
      />
      <Button
        shape="circle"
        type="default"
        icon={<ShrinkOutlined style={{ fontSize: 14 }} />}
        onClick={onCollapseAll}
      />
    </div>
  );
};

export default memo(StructureCollapseButtons);
