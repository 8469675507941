import React from 'react';

import ModalContainer from 'shared/components/modal/container';
import ModalHeader from 'shared/components/modal/header/ModalHeader';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import FormItem from 'shared/ui/form-item/FormItem';
import Select from 'shared/ui/select';
import useIndustries from 'shared/graphql/queries/useIndustries';
import usePositions from 'shared/graphql/queries/usePositions';
import Button from 'shared/ui/button';
import { useBoolean } from 'usehooks-ts';
import { putUpdateUserProfile } from 'shared/api/requests/app';
import { useAppSelector } from 'shared/store/hooks';
import { messageSave } from 'shared/utils';

import './styles.scss';

type Props = {
  onCancel: () => void;
};

const UserDetailModal = ({ onCancel }: Props) => {
  const { value: loading, setValue: setLoading } = useBoolean(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { industriesForSelect } = useIndustries();
  const { positionsForSelect } = usePositions();
  const me = useAppSelector((state) => state.app.me);

  const onSubmit = async () => {
    if (me) {
      messageSave();
      setLoading(true);
      const values = form.getFieldsValue();
      const formData = new FormData();

      formData.append('name', me?.name);
      formData.append('position', values.position);
      formData.append('industry', values.industry);

      await putUpdateUserProfile(formData)
        .then(() => {
          setLoading(false);
          onCancel();
          message.destroy();
        })
        .catch(() => {
          setLoading(false);
          message.destroy();
        });
    }
  };

  return (
    <ModalContainer>
      <ModalHeader type="h4" title={t<string>('signup.fillRequired')} />
      <Form
        name="user-form"
        form={form}
        requiredMark={false}
        className="user-form-modal"
        layout="vertical"
        onFinish={onSubmit}
      >
        <FormItem
          name="position"
          label={t<string>('loginForm.position.label')}
          rules={[{ required: true, type: 'string', message: t<string>('loginForm.error.required') }]}
        >
          <Select
            options={positionsForSelect}
            size="large"
            exSize="xl"
            placeholder={t<string>('loginForm.position.placeholder')}
            disabled={loading}
          />
        </FormItem>
        <FormItem
          name="industry"
          label={t<string>('loginForm.industry.label')}
          rules={[{ required: true, type: 'string', message: t<string>('loginForm.error.required') }]}
        >
          <Select
            options={industriesForSelect}
            size="large"
            exSize="xl"
            placeholder={t<string>('loginForm.industry.placeholder')}
            disabled={loading}
          />
        </FormItem>
        <div className="mt-4">
          <Button size="large" exSize="xl" htmlType="submit" type="primary" loading={loading} disabled={loading}>
            {t<string>('common.save')}
          </Button>
        </div>
      </Form>
    </ModalContainer>
  );
};

export default UserDetailModal;
