import React, { useCallback } from 'react';

import { Form } from 'antd';
import FormItem from 'shared/ui/form-item';
import Input from 'shared/ui/input';
import Button from 'shared/ui/button';
import { useBoolean } from 'usehooks-ts';
import { postOutlineBuilderProposal, ProposalPayload, SourceType } from 'shared/api/requests/proposal';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import ModalContainer from 'shared/components/modal/container';
import ModalHeader from 'shared/components/modal/header';
import ModalContent from 'shared/components/modal/content';
import FinallyModalContent from 'shared/components/modal-finally-content';
import { useAppSelector } from 'shared/store/hooks';

import './styles.scss';
type Props = {
  sourceType: SourceType;
  title: string;
  onCancel: () => void;
};

const WeContactModal: React.FC<Props> = ({ sourceType, title, onCancel }) => {
  const { value: sent, setValue } = useBoolean(false);
  const [form] = Form.useForm();
  const project = useAppSelector((state) => state.project.project);
  const { t } = useTranslation();

  const onSubmit = useCallback(() => {
    if (sourceType === 'buy_tokens_button') {
      mixpanel.track('BuyCreditPopupButtonClicked');
    }
    form
      .validateFields()
      .then((res) => {
        const payload: ProposalPayload = {
          email: res.email,
          source_type: sourceType,
        };
        if (sourceType !== 'buy_tokens_button' && project?.id) {
          payload.source_project_id = Number(project?.id);
        }

        postOutlineBuilderProposal(payload).then(() => {
          setValue(true);
        });
      })
      .catch(() => {});
  }, [form, project?.id, setValue, sourceType]);

  return (
    <ModalContainer>
      {!sent ? <ModalHeader type="h4" title={title} onClose={onCancel} /> : null}

      <ModalContent className="we-contact">
        {sent ? (
          <FinallyModalContent onClick={onCancel} />
        ) : (
          <>
            <Form form={form} requiredMark={false} layout="vertical" className="mb-8">
              <FormItem
                name="email"
                label="Email"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid Email!',
                  },
                  {
                    required: true,
                    message: 'Please input your Email!',
                  },
                ]}
              >
                <Input size="large" placeholder={t<string>('modal.joinToEdit.placeholder')} />
              </FormItem>
            </Form>
            <div className="form-action">
              <Button size="large" exSize="xxl" type="primary" onClick={onSubmit}>
                {t<string>('modal.joinToEdit.button')}
              </Button>
            </div>
          </>
        )}
      </ModalContent>
    </ModalContainer>
  );
};

export default WeContactModal;
