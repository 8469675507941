import React, { ChangeEvent, useCallback, useState } from 'react';

import { StructureItem } from 'shared/types/entities';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import TextArea from 'shared/ui/textArea/TextArea';
import Badge from 'shared/ui/badge';
import { cx } from 'shared/utils';
import RegeneratePopup from 'modules/regenerate-popup';
import CourseStructureIndexWithState from 'shared/components/course-structure-index-with-state/CourseStructureIndexWithState';
import ButtonSparking from 'shared/components/button-sparking/ButtonSparking';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { updateStructureItem } from '../store/actions';
import useStructureItem from '../useStructureItem';
import MoreActions from '../more-actions';
import ItemTypeSelection from '../item-type-selection';
import AddOutcomes from '../add-outcomes';
import QuizButton from '../quiz-button';

import './styles.scss';
import { useOwner } from '../../../shared/hooks/useProjectOwner';

type CourseStructureElementViewProps = {
  item: StructureItem;
};

const CourseStructureElement: React.FC<CourseStructureElementViewProps> = ({ item }) => {
  const [state, setState] = useState({ title: item.title, description: item.description });
  const projectVerId = useAppSelector((s) => s.project?.project?.active_project_version?.id);
  const { onRemoveItem, onGenerate, onMoveUp, onMoveDown } = useStructureItem(item);
  const dispatch = useAppDispatch();
  const store = useAppSelector((s) => s.structureItem);
  const layout = useAppSelector((s) => s.app.layout);
  const projectGen = useAppSelector((s) => s.project.generation);
  const selectedVersion = useAppSelector((s) => s.project.selectedVersion);
  const { t } = useTranslation();
  const { isOwner } = useOwner();

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const onBlur = () => {
    if (item.title !== state.title || item.description !== state.description) {
      if (projectVerId) {
        const payload = {
          title: state.title,
          description: state.description,
          course_structure_item_parent_id: Number(item?.course_structure_item_parent?.id),
        };
        void dispatch(updateStructureItem(payload, item.id));
      }
    }
  };

  const hideActions = layout === 'print' || layout === 'public';
  const disabled = !isOwner || hideActions || store.loading || projectGen;
  const selectedOutcomes = item.sub_module_outcomes.map((el) => el.id);

  const classNamesMain = cx('cs-element-main-view', {
    'cs-element-main-view--disabled': disabled,
    'cs-element-main-view--public': hideActions,
  });

  const classNamesActions = cx('cs-element-main-view__actions', {
    'cs-element-main-view__actions--hidden': layout === 'print' || layout === 'public',
  });

  return (
    <div className={classNamesMain}>
      <div className="cs-element-main-view__inner">
        <div className="cs-element-main-view__info">
          <CourseStructureIndexWithState isEdited={hideActions ? false : item.is_edited} index={item.index} />
        </div>
        <div className="cs-element-main-view__icon">
          <ItemTypeSelection disabled={disabled || !!selectedVersion} itemId={item.id} itemType={item?.type} />
        </div>
        <div className="cs-element-main-view__content">
          <TextArea
            name="title"
            bordered={false}
            placeholder={t<string>('structure.addTitle')}
            className="cs-element-main-view__title mb-2"
            autoSize={{ minRows: 1, maxRows: 20 }}
            value={state.title}
            disabled={disabled || !!selectedVersion}
            onBlur={onBlur}
            onChange={handleChange}
          />
          <TextArea
            name="description"
            bordered={false}
            placeholder={t<string>('structure.addDescription')}
            className="cs-element-main-view__description"
            autoSize={{ minRows: 1, maxRows: 20 }}
            value={state.description}
            disabled={disabled || !!selectedVersion}
            onBlur={onBlur}
            onChange={handleChange}
          />
          {item.type?.name === 'quiz' && !hideActions ? (
            <QuizButton
              disabled={disabled || !!selectedVersion}
              structureItemId={item.id}
              assignment_filled={item.assignment_filled}
              assignment={item.assignment}
            />
          ) : null}
        </div>

        {!isEmpty(item.sub_module_outcomes) ? (
          <div className="cs-element-main-view__goals">
            {item.sub_module_outcomes?.map((s, idx) => (
              <Badge
                key={idx}
                shape="rectangle"
                text={s.letter_index}
                color={s.outline_builder_project_module_outcome.color}
              />
            ))}
          </div>
        ) : null}

        <div className={classNamesActions}>
          {!hideActions ? (
            <>
              <RegeneratePopup disabled={disabled || !!selectedVersion} onSubmit={onGenerate}>
                <ButtonSparking disabled={disabled || !!selectedVersion} size="small" />
              </RegeneratePopup>
              <AddOutcomes disabled={disabled || !!selectedVersion} itemId={item.id} selectedItems={selectedOutcomes} />
              <MoreActions
                disabled={disabled || !!selectedVersion}
                levelType={item.learning_structure.level_type}
                onRemove={onRemoveItem}
                onMoveUp={onMoveUp}
                onMoveDown={onMoveDown}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CourseStructureElement;
