import api, { ApiModules } from 'shared/api/index';
import { ENDPOINTS } from 'shared/api/constants';

import { AssignmentKeyPoint } from '../../../types/entities';

interface GenerateResponse {
  request_id: number;
}

export type ProjectVersionStructureItemsPayload = {
  regeneration_text?: string;
};

export const postOutlineBuilderProjectVersionStructureItemsRequest = (verId: string): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_STRUCTURE_ITEMS(verId));

export interface StructureItemPayload {
  course_structure_item_parent_id?: number;
  type_id?: number;
  title?: string;
  description?: string;
}

interface CreateStructureResponse {
  id: number;
  course_structure_item_parent_id: number | null;
  type_id: number | null;
  title: string;
  description: string;
}

export interface UpdateItemTypePayload {
  type_id: number;
}

export interface UpdateItemOutcomePayload {
  sub_module_outcomes_ids: number[] | [];
}

export interface UpdateItemPositionPayload {
  course_structure_item_parent_id?: number | null;
  order: number;
}

export const postOutlineBuilderProjectVersionStructureItemRequest = (
  verId: string,
  payload: StructureItemPayload
): Promise<CreateStructureResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_STRUCTURE_ITEM(verId), payload);

export const putOutlineBuilderCourseStructureItemRequest = (id: string, payload: StructureItemPayload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM(id), payload);

export const deleteOutlineBuilderCourseStructureItemRequest = (id: string) =>
  api(ApiModules.OUTLINE_BUILDER).delete(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM(id));

export type OutlineBuilderProjectStructureItemGeneratePayload = {
  for_regeneration_structure_module?: string;
  for_regeneration_structure_lesson?: string;
  for_regeneration_structure_item?: string;
};

export const postOutlineBuilderProjectStructureItemGenerateRequest = (
  id: string,
  payload: OutlineBuilderProjectStructureItemGeneratePayload
): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_REGENERATE(id), payload);

export const putOutlineBuilderProjectStructureItemTypeRequest = (id: string, payload: UpdateItemTypePayload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_TYPE(id), payload);

export const putOutlineBuilderProjectStructureItemOutcomesRequest = (id: string, payload: UpdateItemOutcomePayload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_OUTCOMES(id), payload);

export const putOutlineBuilderProjectStructureItemPositionRequest = (id: string, payload: UpdateItemPositionPayload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_POSITION(id), payload);

export type GenerateQuizPayload = {
  quiz_number_of_questions: number;
  quiz_type_of_answers: string;
};

export const postOutlineBuilderProjectStructureItemGenQuizRequest = (
  id: string,
  payload: GenerateQuizPayload
): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_GENERATE_QUIZ(id), payload);

export type QuizPayload = {
  content: string;
};

export const postOutlineBuilderProjectStructureItemQuizRequest = (id: string, payload: QuizPayload) =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_QUIZ(id), payload);

export type VideoSettingsPayload = {
  video_length_s: number;
};

export const postOutlineBuilderProjectStructureItemVideoSettingsRequest = (id: string, payload: VideoSettingsPayload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_VIDEO_SETTINGS(id), payload);

export const postStructureItemGenerateVideoPointsRequest = (
  id: string,
  payload: VideoSettingsPayload
): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(
    ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_GENERATE_VIDEO_POINTS(id),
    payload
  );

export type AssignmentVideoPointPayload = {
  name: string;
};

export const postAssignmentPointRequest = (
  strId: string,
  type: AssignmentKeyPoint,
  payload: AssignmentVideoPointPayload
) => api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.STRUCTURE_ITEM_VIDEO_POINT(strId, type), payload);

// NEW
export const putAssignmentPointRequest = (id: string, type: AssignmentKeyPoint, payload: AssignmentVideoPointPayload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.ASSIGNMENT_KEY_POINT(id, type), payload);

export const deleteAssignmentPointRequest = (id: string, type: AssignmentKeyPoint) =>
  api(ApiModules.OUTLINE_BUILDER).delete(ENDPOINTS.ASSIGNMENT_KEY_POINT(id, type));

export type RegenerateVideoPointPayload = {
  for_regeneration_video_point?: string;
  for_regeneration_reading_point?: string;
};

export const postAssignmentPointRegenerateRequest = (
  id: string,
  type: AssignmentKeyPoint,
  payload: RegenerateVideoPointPayload
): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.ASSIGNMENT_POINT_REGENERATE(id, type), payload);

export const postStructureItemGenerateVideoScriptRequest = (id: string): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_GENERATE_VIDEO_SCRIPT(id));

export type RegenerateVideoScriptPayload = {
  regeneration_text: string;
};

export const postStructureItemRegenerateVideoScriptRequest = (
  id: string,
  payload: RegenerateVideoScriptPayload
): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(
    ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_REGENERATE_VIDEO_SCRIPT(id),
    payload
  );

export const postStructureItemCreateVideo = (id: string): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_CREATE_VIDEO(id));

type ProjectFile = {
  file_id: number;
};

export const postStructureItemUploadFile = (id: string, payload: ProjectFile) =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_FILE(id), payload);

export type GenReadingKeyPointsPayload = {
  user_target_content_length: number;
  regeneration_text?: string;
};

export const postStructureItemGenerateReadingKeyPoints = (
  strItemId: string,
  payload: GenReadingKeyPointsPayload
): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(
    ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_GENERATE_READING_KEY_POINTS(strItemId),
    payload
  );

export type GenReadingPayload = {
  user_target_content_length: number;
};

export const postStructureItemGenerateReading = (strItemId: string): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_GENERATE_READING(strItemId));

export type VideoExternalUrlPayload = {
  external_video_href: string;
};

export const postStructureItemVideoExternalUrl = (strItemId: string, payload: VideoExternalUrlPayload) =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.COURSE_STRUCTURE_ITEM_VIDEO_EXTERNAL_URL(strItemId), payload);
