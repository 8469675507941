import React from 'react';

import './styles.scss';

type Props = {
  show?: boolean;
  hasEmpty?: boolean;
};

const LinearIndeterminateProgress = ({ show, hasEmpty }: Props) => {
  if (!show && hasEmpty) {
    return <div className="liner-progress--empty"></div>;
  }

  if (!show && !hasEmpty) {
    return null;
  }

  return (
    <div className="liner-progress">
      <div className="liner-progress__bar" />
    </div>
  );
};

export default LinearIndeterminateProgress;
