import React from 'react';

import { Popover } from 'antd';
import Button from 'shared/ui/button';
import { ShareAltOutlined } from '@ant-design/icons';
import { useBoolean } from 'usehooks-ts';

import { useAppSelector } from '../../shared/store/hooks';
import { useOwner } from '../../shared/hooks/useProjectOwner';

import LinkButtonContent from './content';

const LinkButton: React.FC = () => {
  const { value: open, toggle, setFalse } = useBoolean();
  const structureStore = useAppSelector((s) => s.structure);
  const project = useAppSelector((state) => state.project?.project);
  const projectGen = useAppSelector((state) => state.project.generation);
  const { isOwner } = useOwner();

  const disabled = !isOwner || structureStore.loading || !project?.has_submodules || projectGen;

  return (
    <Popover placement="bottomRight" open={open} trigger={['click']} content={<LinkButtonContent onClose={setFalse} />}>
      <Button
        className="tour--share"
        disabled={disabled}
        shape="circle"
        type="default"
        icon={<ShareAltOutlined style={{ fontSize: 16 }} />}
        onClick={toggle}
      />
    </Popover>
  );
};

export default LinkButton;
