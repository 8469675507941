import { FC, useCallback, useMemo } from 'react';

import useVideoProviderAvatarLang from 'shared/graphql/queries/useVideoProviderAvatarLang';
import { useTranslation } from 'react-i18next';
import Select from 'shared/ui/select';
import useStructureItemVideoSettings from 'shared/graphql/queries/useStructureItemVideoSettings';
import { useSearchParams } from 'react-router-dom';
import useVideoProviderAvatarVoices from 'shared/graphql/queries/useVideoProviderAvatarVoices';
import { useAppSelector } from 'shared/store/hooks';
import { selectProjectDisabled } from 'modules/project/project.selectors';
import { useOwner } from 'shared/hooks/useProjectOwner';

import { putVideoAvatarSettingsRequest, VideoAvatarSettingsPayload } from '../../api';

import './styles.scss';

export const AvatarSettings: FC = () => {
  const [searchParams] = useSearchParams();
  const strItemId = searchParams.get('str');

  const { t } = useTranslation();

  const projectDisabled = useAppSelector(selectProjectDisabled);
  const { isOwner } = useOwner();

  const disabled = !isOwner || projectDisabled;

  const { data: settings, loading: loadingSettings, refetch } = useStructureItemVideoSettings(strItemId);
  const { data: languages, loading: languagesLoading } = useVideoProviderAvatarLang();
  const { data: voices, loading: loadingVoices } = useVideoProviderAvatarVoices({
    langId: settings?.avatar_language?.id,
  });

  const languagesOptions = useMemo(() => {
    return languages?.map((el) => {
      return {
        label: el?.name,
        value: el?.id,
      };
    });
  }, [languages]);

  const voicesOptions = useMemo(() => {
    return voices?.map((el) => {
      return {
        label: el?.name,
        value: el?.id,
      };
    });
  }, [voices]);

  const updateSettings = useCallback(
    async (payload: VideoAvatarSettingsPayload) => {
      if (strItemId) {
        await putVideoAvatarSettingsRequest(strItemId, payload);
        await refetch();
      }
    },
    [refetch, strItemId]
  );

  const onChangeLang = (value: string) => {
    if (strItemId) {
      const payload = {
        avatar_language_id: Number(value),
      };
      void updateSettings(payload);
    }
  };

  const onChangeVoice = (value: string) => {
    if (strItemId) {
      const payload = {
        avatar_voice_id: Number(value),
      };
      void updateSettings(payload);
    }
  };

  return (
    <div className="avatar-settings">
      <span className="avatar-settings__title">{t('assignments.video.studio.settingsTitle')}</span>
      <div className="avatar-settings__controls">
        <Select
          disabled={disabled}
          value={settings?.avatar_language?.id}
          options={languagesOptions}
          loading={languagesLoading || loadingSettings}
          placeholder={t('assignments.video.studio.settingsPlaceholder.lang')}
          onChange={onChangeLang}
        />
        <Select
          disabled={!settings?.avatar_language?.id || disabled}
          value={settings?.avatar_voice?.id}
          options={voicesOptions}
          loading={loadingVoices || loadingSettings}
          placeholder={t('assignments.video.studio.settingsPlaceholder.voice')}
          onChange={onChangeVoice}
        />
      </div>
    </div>
  );
};
