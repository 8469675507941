import React from 'react';

import FormItem from 'shared/ui/form-item/FormItem';
import Slider from 'shared/ui/slider/Slider';
import { useTranslation } from 'react-i18next';
import Select from 'shared/ui/select';
import { useCurrentAssignment } from 'entities/assignment/model';

import './styles.scss';
import { useAppSelector } from '../../../../shared/store/hooks';
import { useOwner } from '../../../../shared/hooks/useProjectOwner';

const questionsMarks: { [index: string]: string } = {
  2: '2',
  8: '8',
  14: '14',
  20: '20',
};

const AssignmentSettingsQuiz = () => {
  const { t } = useTranslation();
  const { loading } = useCurrentAssignment();
  const isGeneration = useAppSelector((state) => state.project.generation);
  const selectedVersion = useAppSelector((s) => s.project.selectedVersion);
  const { isOwner } = useOwner();
  const options = [
    {
      label: t<string>('assignments.quiz.options.single'),
      value: 'single',
    },
    {
      label: t<string>('assignments.quiz.options.multiple'),
      value: 'multiple',
    },
  ];

  const disable = !isOwner || loading || isGeneration || !!selectedVersion;

  return (
    <div className="assignments-setting-quiz">
      <FormItem
        name="questions"
        className="assignment-settings-quiz-questions tour--assignments-quiz-number"
        label={t<string>('assignments.quiz.questionsLabel')}
      >
        <Slider disabled={disable} marks={questionsMarks} min={2} max={20} />
      </FormItem>
      <FormItem
        name="question_type"
        className="assignment-settings-quiz-questions-type tour--assignments-quiz-type"
        label={t<string>('assignments.quiz.questionType.label')}
      >
        <Select
          disabled={disable}
          placeholder={t<string>('assignments.quiz.questionType.placeholder')}
          options={options}
        />
      </FormItem>
    </div>
  );
};

export default AssignmentSettingsQuiz;
