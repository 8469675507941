import { message } from 'antd';
import { AppDispatch, store } from 'shared/store/store';
import { checkAsyncState, messageError, messageGeneration, messageRejectedGeneration, messageSave } from 'shared/utils';
import { setAssignmentsLoading } from 'widgets/assignments-list/store';
import {
  AssignmentVideoPointPayload,
  deleteAssignmentPointRequest,
  postAssignmentPointRegenerateRequest,
  postAssignmentPointRequest,
  putAssignmentPointRequest,
  RegenerateVideoPointPayload,
} from 'shared/api/requests/outline-builder-project-structure';
import { setProjectGeneration } from 'modules/project';
import { getAssignmentItemAction } from 'entities/assignment/model';
import { AssignmentKeyPoint } from 'shared/types/entities';

export const addAssignmentPointAction = (type: AssignmentKeyPoint, payload: AssignmentVideoPointPayload) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment.assignment;

    messageSave();
    dispatch(setAssignmentsLoading(true));

    if (assign) {
      await postAssignmentPointRequest(assign.id, type, payload)
        .then(() => {
          if (assign?.id) {
            dispatch(getAssignmentItemAction(assign.id, true));
            dispatch(setAssignmentsLoading(false));
            message.destroy();
          }
        })
        .catch(() => {
          dispatch(setAssignmentsLoading(false));
          message.destroy();
        });
    }
  };
};

export const updateAssignmentPointAction = (
  id: string,
  type: AssignmentKeyPoint,
  payload: AssignmentVideoPointPayload
) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment.assignment;

    messageSave();
    dispatch(setAssignmentsLoading(true));

    await putAssignmentPointRequest(id, type, payload)
      .then(() => {
        if (assign?.id) {
          dispatch(getAssignmentItemAction(assign.id, true));
          dispatch(setAssignmentsLoading(false));
          message.destroy();
        }
      })
      .catch(() => {
        dispatch(setAssignmentsLoading(false));
        message.destroy();
      });
  };
};

export const deleteAssignmentPointAction = (id: string, type: AssignmentKeyPoint) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment.assignment;
    messageSave();
    dispatch(setAssignmentsLoading(true));

    await deleteAssignmentPointRequest(id, type)
      .then(() => {
        if (assign?.id) {
          dispatch(getAssignmentItemAction(assign.id, true));
          dispatch(setAssignmentsLoading(false));
          message.destroy();
        }
      })
      .catch(() => {
        dispatch(setAssignmentsLoading(false));
        message.destroy();
      });
  };
};

export const regenerateAssignmentPointAction = (
  id: string,
  type: AssignmentKeyPoint,
  payload: RegenerateVideoPointPayload
) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment.assignment;
    messageGeneration();
    dispatch(setProjectGeneration(true));

    const intermediateRequest = () => {
      if (assign?.id) {
        dispatch(getAssignmentItemAction(assign.id, true));
      }
    };

    const finishRequest = () => {
      if (assign?.id) {
        dispatch(getAssignmentItemAction(assign.id, true));
      }
      dispatch(setProjectGeneration(false));
      message.destroy();
    };
    const rejectedRequest = () => {
      dispatch(setProjectGeneration(false));
      message.destroy();
      messageRejectedGeneration();
    };

    await postAssignmentPointRegenerateRequest(id, type, payload)
      .then((response) => {
        checkAsyncState(response.request_id, finishRequest, rejectedRequest, intermediateRequest);
      })
      .catch((err) => {
        dispatch(setProjectGeneration(false));
        message.destroy();
        messageError(err);
      });
  };
};
