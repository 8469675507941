import React, { useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import CourseHeader from 'widgets/course-header-manual';
import { setLayout } from 'app/store';
import { useAppDispatch } from 'shared/store/hooks';

import './styles.scss';

const CourseManualLayout: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLayout('manual'));
  }, [dispatch]);

  return (
    <div className="course-layout">
      <CourseHeader />
      <Outlet />
    </div>
  );
};

export default CourseManualLayout;
