import React, { memo } from 'react';
import type { FC } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import './styles.scss';

type Props = {
  variant?: string;
};

const Spinner: FC<Props> = ({ variant }) => {
  if (variant === 'mask') {
    return (
      <div className="cb-spinner__mask">
        <div className="cb-spinner">
          <LoadingOutlined style={{ fontSize: 64, color: '#1E1E1E' }} spin />
        </div>
      </div>
    );
  }

  return (
    <div className="cb-spinner">
      <LoadingOutlined style={{ fontSize: 64, color: '#1E1E1E' }} spin />
    </div>
  );
};

export default memo(Spinner);
