import React from 'react';

import LearningOutcomesStructureView from 'modules/learning-outcomes-structure-view';
import CourseStructure from 'widgets/course-structure';
import Feedback from 'modules/feedback';

import './styles.scss';

const CourseStructurePage: React.FC = () => {
  return (
    <div className="structure-page">
      <LearningOutcomesStructureView />
      <CourseStructure />
      <Feedback />
    </div>
  );
};

export default CourseStructurePage;
