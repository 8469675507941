import React, { memo } from 'react';

import { Switch } from 'antd';
import BlockTitle from 'shared/ui/block-title/BlockTitle';
import Slider from 'shared/ui/slider/Slider';
import { cx } from 'shared/utils';

import './styles.scss';

export const weeksMarks: { [index: string]: string } = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
};

type Props = {
  massive_course?: boolean;
  duration?: number;
  className?: string;
  onChangeDuration?: (value: number) => void;
  onChangeMassive?: (checked: boolean) => void;
};

const CourseSettingView: React.FC<Props> = ({
  massive_course,
  duration,
  className,
  onChangeMassive,
  onChangeDuration,
}) => {
  const classNames = cx('create-course-settings', className);
  return (
    <div className={classNames}>
      <BlockTitle text="Settings" type="h5" />
      <div className="create-course-settings__wrap">
        <div className="create-course-settings__block create-course-settings__block-mmoc">
          <div className="create-course-settings__row">
            <Switch disabled size="small" className="mr-2" checked={massive_course} onChange={onChangeMassive} />
            <span className="create-course-settings__MMOC">MOOC</span>
          </div>
          <span className="create-course-settings__text">Optimize for use on massive learning platforms</span>
        </div>
        <div className="create-course-settings__block create-course-settings__block-duration">
          <div className="create-course-settings__row">
            <span className="create-course-settings__label">Duration, weeks</span>
          </div>
          <Slider
            marks={weeksMarks}
            min={1}
            max={6}
            tooltip={{ formatter: null }}
            value={duration}
            onChange={onChangeDuration}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(CourseSettingView);
