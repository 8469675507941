import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CreateCourseUpload {
  uploading: boolean;
  fileSize: number | null;
  progress: number | null;
}

const initialState: CreateCourseUpload = {
  uploading: false,
  fileSize: null,
  progress: null,
};

export const createCourseUpload = createSlice({
  name: 'createCourse',
  initialState,
  reducers: {
    setUploading: (state, action: PayloadAction<boolean>) => {
      state.uploading = action.payload;
    },
    setFileSize: (state, action: PayloadAction<number | null>) => {
      state.fileSize = action.payload;
    },
    setProgress: (state, action: PayloadAction<number | null>) => {
      state.progress = action.payload;
    },
  },
});

export const { setUploading, setFileSize, setProgress } = createCourseUpload.actions;
export default createCourseUpload.reducer;
