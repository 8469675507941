import React from 'react';

import { Subscription } from 'features/subscription';
import { useAppSelector } from 'shared/store/hooks';
import { DowngradeAlert, UserBilling } from 'entities/price';

import './styles.scss';
import Button from '../../shared/ui/button';

const BillingWidget = () => {
  const me = useAppSelector((state) => state.app?.me);
  const data = useAppSelector((state) => state.app?.me?.account_data);

  if (!me) {
    return null;
  }

  return (
    <div className="billing-widget">
      {data?.subscription_cancel_dt ? <DowngradeAlert /> : null}
      {data?.subscription.type === 'pro' && !data?.subscription_cancel_dt ? <UserBilling data={data} /> : null}
      {data?.subscription.type !== 'free' ? <Subscription.PortalButton /> : null}
    </div>
  );
};

export default BillingWidget;
