import React, { useEffect } from 'react';

import PriceListWidget from 'widgets/price-list';
import BillingWidget from 'widgets/user-billing';
import { TokensUsageTable } from 'entities/price';

import { localStorageService } from '../../shared/services/localStorageService';

import './styles.scss';

const BillingPage = () => {
  const navPath = localStorageService.getNavigate();

  useEffect(() => {
    if (navPath === 'billing') {
      localStorageService.removeNavigate();
    }
  }, [navPath]);

  return (
    <div className="billing-page">
      <div className="billing-page__wrapper">
        <BillingWidget />
        <PriceListWidget />
        <TokensUsageTable />
      </div>
    </div>
  );
};

export default BillingPage;
