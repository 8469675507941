import type { AppDispatch } from 'shared/store/store';
import { store } from 'shared/store/store';
import { messageError } from 'shared/utils';
import { onDeleteSlideRequest } from 'features/slides/api';

import {
  getScriptSlidesLinksQuery,
  getStructureItemStudioFileQuery,
  getStructureItemStudioPresentationFileQuery,
} from '../api';

import {
  setStudioScript,
  setStudioLoading,
  setStudioPresentationFile,
  setStudioSlideLoading,
  setLinkedSlides,
} from './studio.slice';

export const getStudioFileAction = (structureItemId: string, isSilent?: boolean) => {
  return async (dispatch: AppDispatch) => {
    if (!isSilent) {
      dispatch(setStudioLoading(true));
    }
    await getStructureItemStudioFileQuery(structureItemId)
      .then((response) => {
        if (response.data && response.data.outline_builder_course_structure_item.assignment.video.script) {
          dispatch(setStudioScript(response.data.outline_builder_course_structure_item.assignment.video.script));
        } else {
          dispatch(setStudioScript(null));
        }
      })
      .catch((err) => messageError(err))
      .finally(() => {
        if (!isSilent) {
          dispatch(setStudioLoading(false));
        }
      });
  };
};

export const getStudioPresentationFileAction = (structureItemId: string, isSilent?: boolean) => {
  return async (dispatch: AppDispatch) => {
    if (!isSilent) {
      dispatch(setStudioSlideLoading(true));
    }
    await getStructureItemStudioPresentationFileQuery(structureItemId)
      .then((response) => {
        if (
          response.data &&
          response.data.outline_builder_course_structure_item.assignment.video.script.presentation_file
        ) {
          dispatch(
            setStudioPresentationFile(
              response.data.outline_builder_course_structure_item.assignment.video.script.presentation_file
            )
          );
        } else {
          dispatch(setStudioScript(null));
        }
      })
      .catch((err) => messageError(err))
      .finally(() => {
        if (!isSilent) {
          dispatch(setStudioSlideLoading(false));
        }
      });
  };
};

export const getStudioLinkedSlidesAction = () => {
  return async (dispatch: AppDispatch) => {
    const scriptId = store.getState().studio.scriptId;

    if (scriptId) {
      const response = await getScriptSlidesLinksQuery(scriptId);
      if (response.data) {
        dispatch(setLinkedSlides(response.data.cob_script_presentation_slides));
      }
    }
  };
};

export const deleteStudioSlideAction = (fileId: string, slideId: string) => {
  return async () => {
    await onDeleteSlideRequest(fileId, slideId).catch((err) => messageError(err));
  };
};

export const deleteStudioAllSlideAction = (fileId: string) => {
  return async (dispatch: AppDispatch) => {
    const slideList = store.getState().studio.presentationFile?.related_files;
    const slideIds = slideList?.map((el) => el.id);

    if (slideIds) {
      dispatch(setStudioSlideLoading(true));
      let iterator = 1;
      let loaded = 0;

      for await (const slideId of slideIds) {
        await onDeleteSlideRequest(fileId, slideId)
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          .then(() => {
            loaded = loaded + 1;
            iterator = iterator + 1;
          })
          .catch((err) => messageError(err));

        if (loaded === slideIds.length) {
          console.log('Process is complete, counter');
          dispatch(setStudioSlideLoading(false));
        }
      }
    }
  };
};
