import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssignmentItem, ContextFile, QuizAssignment, VideoGenMessage } from 'shared/types/entities';

export interface AssignmentState {
  loading: boolean;
  assignment: AssignmentItem | null;
  questionsQuiz: QuizAssignment | null;
  isAddQuiz: boolean;
  isExternalVideoUrl: boolean;
  systemMessages: VideoGenMessage[];
}

const initialState: AssignmentState = {
  loading: false,
  assignment: null,
  questionsQuiz: null,
  isAddQuiz: false,
  isExternalVideoUrl: false,
  systemMessages: [],
};

export const assignmentModel = createSlice({
  name: 'assignment',
  initialState,
  reducers: {
    setAssignmentLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAssignment: (state, action: PayloadAction<AssignmentItem | null>) => {
      state.assignment = action.payload;
    },
    setAddQuiz: (state, action: PayloadAction<boolean>) => {
      state.isAddQuiz = action.payload;
    },
    setExternalVideoUrl: (state, action: PayloadAction<boolean>) => {
      state.isExternalVideoUrl = action.payload;
    },
    setContextFiles: (state, action: PayloadAction<ContextFile[]>) => {
      const newAssign = {
        ...state.assignment,
        context_files: action.payload,
      } as AssignmentItem;

      state.assignment = newAssign;
    },
    cleanScript: (state) => {
      const newAssign = {
        ...state.assignment,
        assignment: {
          ...state.assignment?.assignment,
          video: {
            ...state.assignment?.assignment?.video,
            script: null,
          },
        },
      } as AssignmentItem;

      state.assignment = newAssign;
    },
    setSystemMessages: (state, action: PayloadAction<VideoGenMessage>) => {
      const hasItem = !!state.systemMessages.find((el) => el.id === action.payload.id);

      if (hasItem) {
        state.systemMessages = state.systemMessages.map((el) => {
          if (el.id === action.payload.id) {
            return {
              ...action.payload,
            };
          }
          return {
            ...el,
          };
        });
      } else {
        state.systemMessages = [...state.systemMessages, action.payload];
      }
    },
  },
});

export const {
  setAssignmentLoading,
  setAssignment,
  setAddQuiz,
  setContextFiles,
  setExternalVideoUrl,
  setSystemMessages,
  cleanScript,
} = assignmentModel.actions;
export default assignmentModel.reducer;
