import { gql } from '@apollo/client';

export const HELP_ROLES = gql`
  query HelpRoles($language: String) {
    outline_builder_help_roles(language: $language) {
      edges {
        node {
          id
          pk
          name
          order
        }
      }
    }
  }
`;
