import React, { useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import CourseHeaderPublic from 'widgets/course-header-public';
import { setLayout } from 'app/store';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

const CoursePublicLayout: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLayout('public'));
  }, [dispatch]);

  return (
    <div className="course-layout course-layout-public">
      <CourseHeaderPublic />
      <Outlet />
    </div>
  );
};

export default CoursePublicLayout;
