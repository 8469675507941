import React from 'react';

import { Account } from 'features/account';

const UserDetailsWidget = () => {
  return (
    <>
      <Account.UserInfo.Form />
    </>
  );
};

export default UserDetailsWidget;
