import { client } from '../index';
import { OUTLINE_BUILDER_PROJECTS } from '../schemas/outlineBuilderProjects';

import { OutlineProjectsData } from './useOutlineProjects';

export const getProjectsRequest = () => {
  return client.query<OutlineProjectsData>({
    query: OUTLINE_BUILDER_PROJECTS,
    fetchPolicy: 'network-only',
  });
};
