import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { DEV_AMAZON_API_URL, DEV_API_URL, DEV_TEST } from 'shared/config/urls';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { localStorageService } from 'shared/services/localStorageService';
import { LoginResponse, postRefreshToken } from 'shared/api/requests/auth';

const getBaseUrl = () => {
  return process.env.NODE_ENV === 'development'
    ? `${DEV_AMAZON_API_URL}/graphql/pm/graphql/`
    : `${window.location.origin}/graphql/pm/graphql/`;
};

const httpLink = createHttpLink({
  uri: getBaseUrl(),
});

//Add JWT header
const authLink = setContext(async (_, { headers }) => {
  const authData = localStorageService.getAuthData();
  return {
    headers: {
      ...headers,
      Authorization: authData?.access ? `JWT ${authData?.access}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }: any) => {
  const authData = localStorageService.getAuthData();
  if (graphQLErrors)
    graphQLErrors?.forEach(async ({ message, locations, path, context }: any) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

      if (message === 'Signature has expired') {
        await postRefreshToken({ refresh: authData?.refresh || '' }).then((res: LoginResponse | any) => {
          localStorageService.setAuthData(res);
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              Authorization: res.access ? `JWT ${authData?.access}` : '',
            },
          });
        });
        return forward(operation);
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const cache = new InMemoryCache({});

export const client = new ApolloClient({
  link: link,
  connectToDevTools: true,
  cache,
});
