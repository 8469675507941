import api, { ApiModules } from 'shared/api/index';
import { ENDPOINTS } from 'shared/api/constants';
import { AsyncState, ExportState } from 'shared/types/entities';

export interface AsyncStateResponse {
  id: number;
  state: AsyncState;
  next_item?: number;
}

export interface CompanyAsyncTaskStateResponse {
  id: number;
  state: AsyncState;
  data?: any;
}

export const getAsyncState = (id?: number): Promise<AsyncStateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).get(ENDPOINTS.ASYNC_REQUEST_STATE(id));

export const getCompanyAsyncTaskState = (id: number): Promise<CompanyAsyncTaskStateResponse> =>
  api(ApiModules.PM).get(ENDPOINTS.COMPANY_ASYNC_TASK_REQUEST_STATE(id));

export type ExportStateResponse = {
  id: number;
  state: ExportState;
  file: string;
};

export const getExportState = (id: number): Promise<ExportStateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).get(ENDPOINTS.EXPORT_STATE(id));
