import React, { useCallback, useEffect } from 'react';

import { Form, message } from 'antd';
import FormItem from 'shared/ui/form-item';
import Input from 'shared/ui/input';
import { useTranslation } from 'react-i18next';
import InputPassword from 'shared/ui/input/InputPassword';
import { useForm } from 'antd/es/form/Form';
import Button from 'shared/ui/button';
import { LoginResponse, postLogin, postSignupRequest } from 'shared/api/requests/auth';
import { messageError, messageErrorStr } from 'shared/utils';
import { localStorageService } from 'shared/services/localStorageService';
import { setAuth } from 'app/store';
import { getMeAction } from 'app/store/actions';
import { PATH_NAME } from 'pages/constants';
import { useBoolean } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import useIndustries from 'shared/graphql/queries/useIndustries';
import usePositions from 'shared/graphql/queries/usePositions';
import Select from 'shared/ui/select';
import { useAppDispatch } from 'shared/store/hooks';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';
import { useCookies } from 'react-cookie';

import './styles.scss';
import { isEmpty } from 'lodash';

type FormType = {
  email: string;
  name: string;
  password: string;
  position: string;
  industry: string;
};

const SignupForm: React.FC = () => {
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [searchParams] = useSearchParams();
  const [form] = useForm<FormType>();
  const { navigate } = useCustomNavigator();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { industriesForSelect } = useIndustries();
  const { positionsForSelect } = usePositions();

  const code = searchParams.get('promo');
  const codeCookies = cookies.cf_an;

  useEffect(() => {
    if (code) {
      if (!codeCookies) {
        setCookie('cf_an', code);
      }
    }
  }, [code, codeCookies, setCookie]);

  const onSubmit = useCallback(
    async (values: FormType) => {
      setTrue();
      const formData = new FormData();
      if (values.email) {
        formData.append('email', values.email);
      }
      if (values.name) {
        formData.append('name', values.name);
      }
      if (values.password) {
        formData.append('password', values.password);
      }
      if (code) {
        formData.append('code', code);
      }

      if (values.position) {
        formData.append('position', values.position);
      }

      if (values.industry) {
        formData.append('industry', values.industry);
      }

      const loginPayload = {
        email: values.email,
        password: values.password,
      };

      await postSignupRequest(formData)
        .then(() => {
          postLogin(loginPayload)
            .then((res: LoginResponse) => {
              if (res?.access) {
                localStorageService.setAuthData(res);
                dispatch(setAuth());
                dispatch(getMeAction()).then(() => {
                  setFalse();
                  navigate(PATH_NAME.main);
                });
                removeCookie('cf_an');
              }
              if (res.data?.detail) {
                message.error(t<string>('loginForm.error.wrongCredentials'), 5);
                setFalse();
              }
            })
            .catch((err) => {
              console.log(err);
              setFalse();
            });
        })
        .catch((error) => {
          if (!isEmpty(error.data)) {
            Object.values(error.data).forEach((text) => {
              const str = text as string[];
              messageErrorStr(str[0]);
            });
          }

          setFalse();
        });
    },
    [code, dispatch, navigate, removeCookie, setFalse, setTrue, t]
  );

  return (
    <Form
      name="signup"
      className="signup-form"
      layout="vertical"
      requiredMark={false}
      form={form}
      autoComplete="off"
      onFinish={onSubmit}
      disabled={isLoading}
    >
      <FormItem
        name="email"
        label={t<string>('loginForm.email.label')}
        rules={[{ required: true, type: 'email', message: t<string>('loginForm.error.plsEmail') }]}
      >
        <Input size="large" exSize="xl" placeholder={t<string>('loginForm.email.placeholder')} />
      </FormItem>
      <FormItem
        name="name"
        label={t<string>('loginForm.name.label')}
        rules={[{ required: true, type: 'string', message: t<string>('loginForm.error.plsName') }]}
      >
        <Input size="large" exSize="xl" placeholder={t<string>('loginForm.name.placeholder')} />
      </FormItem>
      <FormItem
        name="password"
        label={t<string>('loginForm.password.label')}
        rules={[{ required: true, message: t<string>('loginForm.error.plsPassword') }]}
      >
        <InputPassword size="large" exSize="xl" placeholder={t<string>('loginForm.password.placeholder')} />
      </FormItem>
      <FormItem
        name="position"
        label={t<string>('loginForm.position.label')}
        rules={[{ required: true, type: 'string', message: t<string>('loginForm.error.required') }]}
      >
        <Select
          options={positionsForSelect}
          size="large"
          exSize="xl"
          placeholder={t<string>('loginForm.position.placeholder')}
        />
      </FormItem>
      <FormItem
        name="industry"
        label={t<string>('loginForm.industry.label')}
        rules={[{ required: true, type: 'string', message: t<string>('loginForm.error.required') }]}
      >
        <Select
          options={industriesForSelect}
          size="large"
          exSize="xl"
          placeholder={t<string>('loginForm.industry.placeholder')}
        />
      </FormItem>
      <Button htmlType="submit" size="large" exSize="xl" loading={isLoading} className="mt-4">
        {t('signup.button')}
      </Button>
    </Form>
  );
};

export default SignupForm;
