import { postOutlineBuilderProjectVersionRequest } from 'shared/api/requests/outline-builder-project';
import { message } from 'antd';
import { messageError, messageGeneration, messageSave } from 'shared/utils';
import {
  OutlineBuilderProjectVersionRegeneratePayload,
  postOutlineBuilderProjectVersionRegenerateRequest,
  putOutlineBuilderProjectVersionRequest,
} from 'shared/api/requests/outline-builder-project-version';
import { AppDispatch, store } from 'shared/store/store';

import { setProjectGeneration } from '../../project';

export const createProjectVersionAction = (projectId: string, language: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setProjectGeneration(true));
    messageGeneration();

    const payload = {
      language: language,
    };

    postOutlineBuilderProjectVersionRequest(projectId, payload).catch((err) => {
      dispatch(setProjectGeneration(false));
      message.destroy();
      messageError(err);
    });
  };
};

interface AboutPayload {
  generated_title: string;
  generated_description: string;
}

export const updateProjectAboutContentAction = (verId: string, payload: AboutPayload) => {
  return async () => {
    messageSave();

    await putOutlineBuilderProjectVersionRequest(verId, payload).catch((err) => {
      message.destroy();
      messageError(err);
    });
  };
};

export const regenerateProjectAboutContentAction = (
  entity: 'title' | 'description',
  payload: OutlineBuilderProjectVersionRegeneratePayload
) => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;

    if (project?.active_project_version?.id) {
      dispatch(setProjectGeneration(true));

      postOutlineBuilderProjectVersionRegenerateRequest(project?.active_project_version?.id, entity, payload).catch(
        (err) => {
          dispatch(setProjectGeneration(false));
          message.destroy();
          messageError(err);
        }
      );
    }
  };
};
