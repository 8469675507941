import React, { memo, useCallback } from 'react';

import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import { PATH_NAME } from 'pages/constants';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';

import './styles.scss';

const GoLoginFormModule: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useCustomNavigator();

  const onClick = useCallback(() => navigate(PATH_NAME.login), []);

  return (
    <div className="signup-to-login">
      <span className="signup-to-login__title">{t('signup.alreadyRegistered')}</span>
      <Button size="large" exSize="xl" onClick={onClick}>
        {t<string>('loginForm.login')}
      </Button>
    </div>
  );
};

export default memo(GoLoginFormModule);
