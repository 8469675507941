import React, { memo, useCallback } from 'react';

import mixpanel from 'mixpanel-browser';
import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import { OutlineProject } from 'shared/types/entities';
import { last } from 'lodash';
import { postOutlineBuilderProjectVersionTranslateRequest } from 'shared/api/requests/outline-builder-project-version';
import { getProjectsAction } from 'widgets/course-list/store/actions';
import { useAppDispatch } from 'shared/store/hooks';

type Props = {
  project: OutlineProject;
};

const ReTranslateButton: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClick = useCallback(async () => {
    mixpanel.track('Project Re-translate', { ProjectId: project.pk });

    if (project?.active_project_version) {
      const lastLang = last(project?.languages);
      const payload = {
        language: lastLang?.code || project?.active_project_version.language.code,
      };
      await postOutlineBuilderProjectVersionTranslateRequest(project?.active_project_version?.id, payload);
      await dispatch(getProjectsAction(true));
    }
  }, [dispatch, project?.active_project_version, project?.languages, project.pk]);

  return (
    <Button className="card-regen-button" type="primary" onClick={onClick}>
      {t('common.regenerate')}
    </Button>
  );
};

export default memo(ReTranslateButton);
