import React, { memo, ReactNode } from 'react';

import { Upload } from 'antd';
import { cx } from 'shared/utils';
import type { UploadProps } from 'antd';
import { PlusCircleFilled, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

type Props = {
  variant?: string;
  text?: string;
  hint?: string;
};

const FilesUpload: React.FC<Props & UploadProps> = ({ text, hint, variant, className, ...props }) => {
  const { t } = useTranslation();
  const classNames = cx('files-upload', className);

  const uploadContentView = (
    <>
      <p className="ant-upload-drag-icon">
        <PlusCircleFilled style={{ fontSize: 32 }} />
      </p>
      <p className="ant-upload-text">{text || t('uploadView.text1')}</p>
      <p className="ant-upload-hint">{hint || t('uploadView.text2')}</p>
    </>
  );

  const smallContentView = (
    <>
      <PlusOutlined style={{ fontSize: 32 }} />
      <p className="ant-upload-hint">{hint || t('uploadView.text2')}</p>
    </>
  );

  return (
    <div className={classNames}>
      <Dragger {...props}>{variant === 'small' ? smallContentView : uploadContentView}</Dragger>
    </div>
  );
};

export default memo(FilesUpload);
