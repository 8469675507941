import React, { memo } from 'react';

import { Form } from 'antd';
import { FormItemProps } from 'antd/es/form/FormItem';
import { cx } from 'shared/utils';

import './styles.scss';

const FormItem: React.FC<FormItemProps> = (props) => {
  const classNames = cx('cb-form-item', props.className);
  return <Form.Item {...props} className={classNames} />;
};

export default memo(FormItem);
