import { AssignmentItem } from './outlineProject';

export type QuizAnswer = {
  id?: string;
  content: string;
  is_answer: boolean;
  order: number;
};

export type QuizContentItem = {
  id: string;
  content: string;
  description?: string;
  content_type: string;
  order: number;
  children?: QuizAnswer[];
};

export type QuizAssignment = {
  id: string;
  content_items: QuizContentItem[];
};

export interface CourseStructureItemData {
  outline_builder_course_structure_item: AssignmentItem;
}

export enum AssignmentKeyPoint {
  video = 'video-point',
  reading = 'reading-point',
}
