import { useQuery } from '@apollo/client';

import { VIDEO_PROVIDERS } from '../schemas/videoProviders';
import { VideoProviderType } from '../../types/entities';

type VideoProvidersListData = {
  user_video_provider_usages: {
    id: string;
    pk: string;
    video_provider: VideoProviderType;
  }[];
};

const useVideoProvidersList = () => {
  const { data } = useQuery<VideoProvidersListData>(VIDEO_PROVIDERS, {
    fetchPolicy: 'cache-first',
  });

  const providersForSelect = data?.user_video_provider_usages.map((item) => {
    return {
      label: item.video_provider.name,
      value: item.pk || item.id,
    };
  });

  return {
    providers: data?.user_video_provider_usages,
    providersForSelect,
  };
};

export default useVideoProvidersList;
