import React from 'react';

import { Routes, Route } from 'react-router-dom';
import MainLayout from 'layout/main';
import CourseManualLayout from 'layout/courseManual';

import ProtectedLayout from '../layout/protected';
import CourseAutoLayout from '../layout/courseAuto';
import CoursePublicLayout from '../layout/coursePublic';
import ProfileLayout from '../layout/profile';

import CoursesPage from './courses-page';
import { PATH_NAME } from './constants';
import CourseSettingsPage from './course-settings-page';
import CourseAboutPage from './course-about-page';
import CourseLearningOutcomesPage from './course-learning-outcomes-page';
import CourseStructurePage from './course-structure-page';
import LoginPage from './login-page';
import CoursePrint from './print/project';
import ErrorPage from './error';
import CourseAssignmentPage from './course-assignments-page';
import QuizzesPrint from './print/quizzes';
import SignupPage from './signup-page';
import ProfilePage from './profile';
import CourseContentPage from './course-content-page/CourseContentPage';
import BillingPage from './billing';
import ChangePasswordPage from './change-password';
import AffiliatePage from './affiliate';
import ExportPage from './export';

const Routing = () => {
  return (
    <Routes>
      {/*<Route path='*' element={<NotFound />} />*/}

      <Route path={PATH_NAME.login} element={<LoginPage />} />
      <Route path={PATH_NAME.signup} element={<SignupPage />} />
      <Route path={PATH_NAME.coursePublic} element={<CoursePublicLayout />}>
        <Route path={PATH_NAME.coursePublic} element={<CourseStructurePage />} />
      </Route>
      <Route element={<ProtectedLayout />}>
        <Route element={<MainLayout />}>
          <Route path={PATH_NAME.main} element={<CoursesPage />} />
          {/*<Route path={PATH_NAME.pricing} element={<PricingPage />} />*/}
        </Route>
        <Route element={<ProfileLayout />}>
          <Route path={PATH_NAME.profile} element={<ProfilePage />} />
          <Route path={PATH_NAME.changePass} element={<ChangePasswordPage />} />
          <Route path={PATH_NAME.billing} element={<BillingPage />} />
          <Route path={PATH_NAME.affiliate} element={<AffiliatePage />} />
        </Route>
        <Route path={PATH_NAME.courseAuto} element={<CourseAutoLayout />}>
          <Route path={PATH_NAME.courseEntity.structure} element={<CourseStructurePage />} />
        </Route>
        <Route path={PATH_NAME.courseStructurePrint} element={<CoursePrint />} />

        <Route path={PATH_NAME.courseQuizzesPrint} element={<QuizzesPrint />} />

        <Route path={PATH_NAME.courseManual} element={<CourseManualLayout />}>
          <Route path={PATH_NAME.courseEntity.settings} element={<CourseSettingsPage />} />
          <Route path={PATH_NAME.courseEntity.about} element={<CourseAboutPage />} />
          <Route path={PATH_NAME.courseEntity.learning} element={<CourseLearningOutcomesPage />} />
          <Route path={PATH_NAME.courseEntity.structure} element={<CourseStructurePage />} />
          <Route path={PATH_NAME.courseEntity.assignments} element={<CourseAssignmentPage />} />
          <Route path={PATH_NAME.courseEntity.content} element={<CourseContentPage />} />
          <Route path={PATH_NAME.courseEntity.export} element={<ExportPage />} />
        </Route>
      </Route>
      <Route path={PATH_NAME.error.main} element={<ErrorPage />} />
    </Routes>
  );
};

export default Routing;
