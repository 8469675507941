import { ActiveSubModulesOutcome } from '../types/entities';

export const searchFilterOutcomes = (search: string, outcomes: ActiveSubModulesOutcome[]) => {
  return outcomes.filter((outcome) => {
    const s = search.toLowerCase();
    const text = outcome.name.toLowerCase();
    const letterIndex = outcome.letter_index.toLowerCase();

    return text.includes(s) || letterIndex.includes(s);
  });
};

export const compareSelectedOutcomes = (array1: string[], array2: string[]) => {
  if (array1.length != array2.length) {
    return false;
  }

  array1 = array1.slice();
  array1.sort();
  array2 = array2.slice();
  array2.sort();

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] != array2[i]) {
      return false;
    }
  }

  return true;
};
