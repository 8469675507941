import { getProjectsRequest } from 'shared/graphql/queries/getProjects';
import { isEmpty } from 'lodash';
import { AppDispatch } from 'shared/store/store';

import { setTourId } from '../../../modules/tutorial/store';
import { sortProjectsByTour } from '../../../shared/utils';

import { setProjects, setProjectsLoading } from './index';

export const getProjectsAction = (isSilent?: boolean) => {
  return async (dispatch: AppDispatch) => {
    if (!isSilent) {
      dispatch(setProjectsLoading(true));
    }

    await getProjectsRequest().then((response) => {
      const projects = response.data.outline_builder_projects.edges.map((p) => p.node);

      const projectTour = projects.find((project) => project.is_tutorial);

      if (!!projectTour) {
        dispatch(setTourId(Number(projectTour.pk)));
      }

      dispatch(setProjects(sortProjectsByTour(projects)));
      if (!isSilent) {
        dispatch(setProjectsLoading(false));
      }
    });
  };
};
