import { LanguagesData } from 'shared/types/entities/app';

import { client } from '../index';
import { LANGUAGES } from '../schemas/languages';

export const getLanguagesRequest = () => {
  return client.query<LanguagesData>({
    query: LANGUAGES,
  });
};
