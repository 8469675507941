import { gql } from '@apollo/client';

export const POSITIONS = gql`
  query Positions($language: String) {
    positions(language: $language) {
      edges {
        node {
          id
          pk
          name
          order
        }
      }
    }
  }
`;
