import React, { useEffect, useMemo, useState } from 'react';

import { Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import {
  getStructureItemContextFilesAction,
  setExternalVideoUrl,
  useCurrentAssignment,
} from 'entities/assignment/model';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { selectedVideoProvider } from 'app/store/app.selectors';
import Button from 'shared/ui/button';
import modalService from 'shared/services/modalService';
import { useOwner } from 'shared/hooks/useProjectOwner';
import { isSelectProjectGenerating } from 'modules/project/project.selectors';
import { GenerateVideoButton } from 'features/assignment-video-gen-buttons';
import { AssignmentKeyPoint } from 'shared/types/entities';

import AssignmentVideoSettings from './content/settings';
import AssignmentVideoPoints from './content/points';
import AssignmentVideoScript from './content/script';
import { AssignmentVideo } from './content/video';
import ExternalVideoUrl from './content/externelVideoUrl';
import { AssignmentVideoStudio } from './content/studio/AssignmentVideoStudio';

import './styles.scss';

const AssignmentVideoWidget: React.FC = () => {
  const [tabKey, setTabKey] = useState<string>('settings');
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { assignment } = useCurrentAssignment();
  const { t } = useTranslation();
  const assignmentId = searchParams.get('str');
  const videoUrl = useAppSelector((state) => state.assignment?.assignment?.assignment?.video?.external_video_href);
  const isExternalVideoUrl = useAppSelector((state) => state.assignment.isExternalVideoUrl);
  const videoProvider = useAppSelector(selectedVideoProvider);
  const isConstructor = videoProvider === 'constructor';

  const isProjectGenerating = useAppSelector(isSelectProjectGenerating);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const { isOwner } = useOwner();
  const disabled = !isOwner || isProjectGenerating || !!selectedVersion;

  useEffect(() => {
    if (assignment?.id) {
      void dispatch(getStructureItemContextFilesAction(assignment?.id));
    }
  }, [assignment?.id, dispatch]);

  useEffect(() => {
    if (assignmentId !== assignment?.id) {
      setTabKey('settings');
    }
  }, [assignment?.id, assignmentId]);

  useEffect(() => {
    if (Boolean(videoUrl)) {
      dispatch(setExternalVideoUrl(true));
    } else {
      dispatch(setExternalVideoUrl(false));
    }
  }, [dispatch, videoUrl]);

  const onChangeTab = (activeKey: string) => {
    setTabKey(activeKey);
  };

  const items: TabsProps['items'] = useMemo(() => {
    return [
      {
        key: 'settings',
        label: t('assignments.video.tabs.settings'),
        children: <AssignmentVideoSettings onChangeTab={onChangeTab} />,
      },
      {
        key: 'points',
        label: t('assignments.video.tabs.points'),
        children: <AssignmentVideoPoints type={AssignmentKeyPoint.video} onChangeTab={onChangeTab} />,
        disabled: isEmpty(assignment?.assignment?.video?.points),
      },
      {
        key: 'studio',
        label: t('assignments.video.tabs.studio'),
        children: <AssignmentVideoStudio onChangeTab={onChangeTab} />,
        disabled: isEmpty(assignment?.assignment?.video?.script),
      },
      {
        key: 'script',
        label: t('assignments.video.tabs.script'),
        children: <AssignmentVideoScript onChangeTab={onChangeTab} />,
        disabled: isEmpty(assignment?.assignment?.video?.script),
      },
      {
        key: 'video',
        label: t('assignments.video.tabs.video'),
        children: isExternalVideoUrl ? <ExternalVideoUrl /> : <AssignmentVideo />,
        disabled: isExternalVideoUrl || Boolean(videoUrl) ? false : isEmpty(assignment?.assignment?.video?.script),
      },
    ];
  }, [assignment?.assignment?.video?.points, assignment?.assignment?.video?.script, isExternalVideoUrl, t, videoUrl]);

  const filteredItems = useMemo(() => {
    if (isConstructor) {
      return items.filter((item) => item.key !== 'script' && item.key !== 'video');
    }
    return items.filter((item) => item.key !== 'studio');
  }, [isConstructor, items]);

  const onOpenSlides = () => modalService.openStudioSlides({});

  const extraContent = (
    <div className="assignment-video--extra">
      <GenerateVideoButton
        disable={disabled}
        structureItemId={assignmentId}
        buttonProps={{ size: 'middle', type: 'default' }}
      />
      <Button disabled={disabled} onClick={onOpenSlides}>
        {t<string>('assignments.video.studio.uploadSlides')}
      </Button>
    </div>
  );

  const isShowExtraContent = isConstructor && tabKey === 'studio';

  return (
    <Tabs
      destroyInactiveTabPane
      tabBarExtraContent={{ right: isShowExtraContent ? extraContent : null }}
      activeKey={tabKey}
      items={filteredItems}
      onChange={onChangeTab}
    />
  );
};

export default AssignmentVideoWidget;
