import React from 'react';

import { cx } from 'shared/utils';
import { StructureItem } from 'shared/types/entities';
import { useAppSelector } from 'shared/store/hooks';

import { useOwner } from '../../shared/hooks/useProjectOwner';

import CourseStructureCollapseHead from './course-structure-collapse-head';
import useStructureItem from './useStructureItem';
import AddStructureLesson from './add-structure-lesson';
import CourseStructureElement from './course-structure-element';
import AddStructureElement from './add-structure-element';

import './styles.scss';

type CourseStructureCollapseProps = {
  item: StructureItem;
  level: number;
  canShowTour?: boolean;
};

const CourseStructureCollapse: React.FC<CourseStructureCollapseProps> = ({ canShowTour, level, item }) => {
  const store = useAppSelector((s) => s.structureItem);
  const projectGen = useAppSelector((s) => s.project.generation);
  const layout = useAppSelector((s) => s.app.layout);
  const selectedVersion = useAppSelector((s) => s.project.selectedVersion);

  const {
    open,
    onToggle,
    text,
    itemState,
    handleChange,
    onBlur,
    onRemoveItem,
    onClickAdd,
    onGenerate,
    onMoveUp,
    onMoveDown,
  } = useStructureItem(item);

  const classNames = cx('cs-collapse', {
    [`cs-collapse--lvl-${level}`]: level,
    [`cs-collapse--disabled`]: layout === 'print' || layout === 'public',
  });

  const { isOwner } = useOwner();

  return (
    <div className={classNames}>
      <CourseStructureCollapseHead
        canShowTour={canShowTour}
        item={item}
        disabled={!isOwner || itemState.loading || projectGen || !!selectedVersion}
        title={text}
        open={open}
        onToggle={onToggle}
        onChange={handleChange}
        onBlur={onBlur}
        onRemove={onRemoveItem}
        onAdd={onClickAdd}
        onGenerate={onGenerate}
        onMoveUp={onMoveUp}
        onMoveDown={onMoveDown}
      />
      <div className={cx('cs-collapse-content', { 'cs-collapse-content--open': open })}>
        <div className="cs-collapse-content__wrap">
          {item.children.map((s) => {
            if (s.learning_structure.level_type === 'production_item') {
              return <CourseStructureElement key={s.id} item={s} />;
            }

            return <CourseStructureCollapse key={s.id} level={level + 1} item={s} />;
          })}
          {item.learning_structure.level_type === 'lesson' &&
          item.id === store.showAddElement &&
          store.addElementType ? (
            <AddStructureElement item={item} />
          ) : null}
        </div>
        <AddStructureLesson item={item} />
      </div>
    </div>
  );
};

export default CourseStructureCollapse;
