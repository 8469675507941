import React, { memo } from 'react';

import { Button as AntButton, ButtonProps } from 'antd';
import { cx } from 'shared/utils';

import './styles.scss';

export interface ExButtonProps extends ButtonProps {
  exSize?: 'xl' | 'xxl';
}

const Button: React.FC<ExButtonProps> = ({ exSize, ...props }: ExButtonProps) => {
  const classNames = cx('cb-button', props.className, {
    [`cb-button--${exSize}`]: exSize,
  });

  return (
    <AntButton {...props} className={classNames}>
      {props.children}
    </AntButton>
  );
};

export default memo(Button);
