import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isExpiredToken } from 'shared/utils';
import { localStorageService } from 'shared/services/localStorageService';
import jwtDecode from 'jwt-decode';
import { LanguageType, PageLayout } from 'shared/types/entities/app';
import { Me } from 'shared/types/entities';

let isAuth = false;
const authData = localStorageService.getAuthData();

if (authData && authData.access) {
  const decode = jwtDecode<any>(authData?.access);
  isAuth = decode?.exp && isExpiredToken(decode?.exp);
} else {
  localStorageService.removeAuthData();
}

interface AppState {
  loading: boolean;
  auth: boolean | null;
  layout: PageLayout;
  me: Me | null;
  languages: LanguageType[] | null;
}

const initialState: AppState = {
  loading: false,
  auth: isAuth,
  layout: null,
  me: null,
  languages: null,
};

export const appSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    finishLoading: (state) => {
      state.loading = false;
    },
    setLayout: (state, action: PayloadAction<PageLayout>) => {
      state.layout = action.payload;
    },
    setMe: (state, action: PayloadAction<Me | null>) => {
      state.me = action.payload;
    },
    setLang: (state, action: PayloadAction<LanguageType[] | null>) => {
      state.languages = action.payload;
    },
    setAuth: (state) => {
      state.auth = true;
    },
    logout: () => initialState,
  },
});

export const { startLoading, finishLoading, setAuth, logout, setLayout, setMe, setLang } = appSlice.actions;
export default appSlice.reducer;
