import React, { memo } from 'react';

import Button from 'shared/ui/button/Button';
import IconMagic from 'shared/ui/icons/magic/IconMagic';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { cx } from 'shared/utils';

import './styles.scss';
import { Popover } from 'antd';

type FormFooterProps = {
  disabled?: boolean;
  classNameSubmit?: string;
  labelSubmit?: string;
  onSubmit?: () => void;
  labelBack?: string;
  onBack?: () => void;
  disabledSubmit?: boolean;
  loading?: boolean;
  hasPopup?: boolean;
  popupTitle?: string;
  popupText?: string;
};

const FormFooter: React.FC<FormFooterProps> = ({
  disabled,
  classNameSubmit,
  labelSubmit,
  labelBack,
  disabledSubmit,
  onSubmit,
  onBack,
  loading,
  hasPopup,
  popupTitle,
  popupText,
}: FormFooterProps) => {
  const classNames = cx('form-footer', {
    'justify-space-between': labelBack && onBack,
    'justify-end': !(labelBack && onBack),
  });

  return (
    <div className={classNames}>
      {labelBack && onBack ? (
        <Button className="form-footer__back" size="large" exSize="xl" onClick={onBack}>
          <ArrowLeftOutlined style={{ fontSize: 16 }} />
          {labelBack}
        </Button>
      ) : null}

      {hasPopup ? (
        <Popover overlayClassName="settings-form-popover" trigger="click" title={popupTitle} content={popupText}>
          <Button
            className={cx('form-footer__submit', classNameSubmit)}
            size="large"
            exSize="xl"
            type="primary"
            disabled={disabledSubmit}
            loading={loading}
          >
            {labelSubmit}
            <IconMagic size={16} />
          </Button>
        </Popover>
      ) : (
        <Button
          className={cx('form-footer__submit', classNameSubmit)}
          size="large"
          exSize="xl"
          type="primary"
          disabled={disabledSubmit}
          loading={loading}
          onClick={onSubmit}
        >
          {labelSubmit}
          <IconMagic size={16} />
        </Button>
      )}
    </div>
  );
};

export default memo(FormFooter);
