import { AssignmentsItemData } from 'shared/types/entities';

import { client } from '../index';
import { OUTLINE_BUILDER_PROJECT_ASSIGNMENTS_FULL } from '../schemas/outlineBuilderAssignmentsFull';

export const getProjectAssignmentsFullRequest = (projectId: string, language: string) => {
  return client.query<AssignmentsItemData>({
    query: OUTLINE_BUILDER_PROJECT_ASSIGNMENTS_FULL,
    fetchPolicy: 'network-only',
    variables: {
      id: projectId,
      language,
    },
  });
};
