import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import { cx } from 'shared/utils';
import { useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import LearningOutcomeItemView from 'shared/components/learning-outcome-item-view';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import LearningOutcomeGroup from '../../learning-outcome-group';
import { toggleView } from '../store';
import { loadOutcomeNextItemDataAction, saveNewOutcomeAction } from '../store/actions';

type Props = {
  disabled?: boolean;
  isParent?: boolean;
};

const AddLearningOutcome: React.FC<Props> = ({ disabled, isParent }) => {
  const [text, setText] = useState('');
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectGen = useAppSelector((state) => state.project.generation);
  const addState = useAppSelector((state) => state.addOutcome);

  const projectVerId = useAppSelector((state) => state.project?.project?.active_project_version?.id);

  // Load next color and letter index
  useEffect(() => {
    if (projectVerId && !addState.letterIndex) {
      void dispatch(loadOutcomeNextItemDataAction(projectVerId));
    }
  }, [addState.letterIndex, dispatch, projectVerId]);

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  }, []);

  const onBlur = () => {
    if (!text) {
      dispatch(toggleView());
    } else {
      if (projectVerId) {
        dispatch(saveNewOutcomeAction(projectVerId, text)).finally(() => {
          setText('');
        });
      }
    }
  };

  const onView = useCallback(() => {
    mixpanel.track('Add First Level Learning Outcome (Button)', { ProjectId: id, Page: 'LearningOutcome' });
    dispatch(toggleView());
  }, [dispatch, id]);

  const classNames = cx('add-lo-item', {
    'lo-item-parent': isParent,
  });

  return (
    <div className={classNames}>
      {addState.isView ? (
        <LearningOutcomeGroup color={addState.color}>
          <LearningOutcomeItemView
            autoFocus
            isParent
            color={addState.color}
            letterIndex={addState.letterIndex}
            text={text}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </LearningOutcomeGroup>
      ) : null}

      <Button
        className="module-lo__add tour--learning-outcomes-add"
        size="large"
        exSize="xl"
        type="default"
        disabled={projectGen || disabled}
        onClick={onView}
      >
        <PlusOutlined style={{ fontSize: 16 }} />
        {t<string>('learningOutcomes.addLearning')}
      </Button>
    </div>
  );
};

export default AddLearningOutcome;
