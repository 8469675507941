import React from 'react';

import { useTranslation } from 'react-i18next';
import { dateFormat } from 'shared/utils';
import Table from 'shared/ui/table';
import { isEmpty } from 'lodash';

import { AffiliateHistory, useAffiliateHistory } from '../../model';

import './styles.scss';

const AffiliateTokensTable = () => {
  const { t, i18n } = useTranslation();
  const { affiliateHistory, loading } = useAffiliateHistory();

  const columns = [
    {
      title: t('affiliate.table.date'),
      key: 'date',
      width: 150,
      render: (record: AffiliateHistory) => record?.date,
    },
    {
      title: t('affiliate.table.user'),
      key: 'user',
      render: (record: AffiliateHistory) => record.num_users,
    },
    {
      title: t('affiliate.table.count'),
      key: 'count',
      render: (record: AffiliateHistory) => record.sum_tokens,
    },
  ];

  return (
    <div className="affiliate-tokens">
      <h5 className="tokens-usage__title">{t('affiliate.title')}</h5>
      <Table columns={columns} dataSource={affiliateHistory} loading={loading} />
    </div>
  );
};

export default AffiliateTokensTable;
