import { RootState } from 'shared/store/store';
import { createSelector } from '@reduxjs/toolkit';
import { selectedVideoProvider } from 'app/store/app.selectors';

export const selectGeneratedVideos = (state: RootState) =>
  state.assignment?.assignment?.assignment?.video?.script?.generated_videos;

export const selectVideosByProvider = createSelector(
  selectGeneratedVideos,
  selectedVideoProvider,
  (videos, provider) => {
    return videos?.filter((el) => el.system === provider);
  }
);

export const selectAssignmentAvatarPosition = (state: RootState) =>
  state?.assignment?.assignment?.assignment?.video?.avatar_position_type;
