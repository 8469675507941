import React, { useCallback } from 'react';

import { App, Dropdown, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button';
import { useTranslation } from 'react-i18next';
import { MenuInfo } from 'shared/types/entities';
import modalService from 'shared/services/modalService';
import { generatePath, useLocation } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';
import { postOutlineBuilderProjectVersionTranslateRequest } from 'shared/api/requests/outline-builder-project-version';
import { useAppSelector } from 'shared/store/hooks';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';
import { CustomDataError, messageError } from 'shared/utils';

import './styles.scss';
import { useOwner } from '../../shared/hooks/useProjectOwner';

type Props = {
  name: string;
  hasAvailable: boolean;
};

const ContentLanguageLabel: React.FC<Props> = ({ name, hasAvailable }) => {
  return (
    <div className="switch-content-lang-label">
      <span className="switch-content-lang-label__text">{name}</span>
      {hasAvailable ? <div className="switch-content-lang-label__dot"></div> : null}
    </div>
  );
};

const ContentLanguageButton: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { modal } = App.useApp();
  const { navigate } = useCustomNavigator();
  const layout = useAppSelector((s) => s.app.layout);
  const projectGen = useAppSelector((state) => state.project.generation);
  const project = useAppSelector((state) => state.project.project);
  const languages = useAppSelector((state) => state.app.languages);
  const structureStore = useAppSelector((s) => s.structure);
  const hasSubscription = useAppSelector((state) => state.app?.me?.has_active_subscription);
  const { isOwner } = useOwner();

  const disabled = !isOwner || !hasSubscription || structureStore.loading || !project?.has_submodules || projectGen;
  const isPublicPage = layout === 'public';

  const languagesByPage = isPublicPage ? project?.languages : languages;

  const items = languagesByPage?.map((l) => {
    return {
      key: l.code,
      label: (
        <ContentLanguageLabel name={l.name} hasAvailable={!!project?.languages.some((el) => el.code === l.code)} />
      ),
    };
  });

  const onClickItemPublicPage: MenuProps['onClick'] = useCallback(
    (info: MenuInfo) => {
      const path = generatePath(PATH_NAME.coursePublic, {
        id: project?.public_slug || '',
        lang: info.key,
      });
      navigate(path);
    },
    [navigate, project?.public_slug]
  );

  const onClickItem: MenuProps['onClick'] = useCallback(
    (info: MenuInfo) => {
      const hasLang = !!project?.languages.some((el) => el.code === info.key);

      const onTranslation = async () => {
        const payload = {
          language: info.key,
        };
        if (project?.active_project_version) {
          await postOutlineBuilderProjectVersionTranslateRequest(project?.active_project_version.id, payload)
            .then(() => navigate(PATH_NAME.main))
            .catch((err) => {
              const dataError = err?.data as CustomDataError;
              if (dataError?.error_code === 'cob_insufficient_funds') {
                modal.confirm({
                  title: t('contentLangButton.errorModal.title'),
                  okText: t('contentLangButton.errorModal.ok'),
                  cancelText: t('common.close'),
                  onCancel() {},
                  onOk: () => navigate(PATH_NAME.billing),
                  content: (
                    <div>
                      <p>{t('contentLangButton.errorModal.text1')}</p>
                      <p>{t('contentLangButton.errorModal.text2')}</p>
                      <p>{t('contentLangButton.errorModal.text3')}</p>
                    </div>
                  ),
                });
              } else {
                messageError(err);
              }
            });
        }
      };

      if (!hasLang) {
        modalService.openCourseTranslation({
          lang: languages?.find((l) => l.code === info.key),
          onSubmit: onTranslation,
        });
      } else {
        if (project) {
          const entity = location.pathname.split('/').pop();
          const path = generatePath(PATH_NAME.courseManualWithEntity, {
            id: project.id,
            lang: info.key,
            entity,
          });
          navigate(path);
        }
      }
    },
    [languages, location.pathname, modal, navigate, project, t]
  );

  return (
    <Dropdown
      disabled={disabled}
      trigger={['click']}
      placement="bottomRight"
      overlayClassName="switch-content-lang"
      menu={{ items, onClick: isPublicPage ? onClickItemPublicPage : onClickItem }}
    >
      <Button className="tour--content-lang" type="primary" disabled={disabled}>
        <>
          <span className="mr-1">{t<string>('contentLangButton.text')}</span>
          <DownOutlined />
        </>
      </Button>
    </Dropdown>
  );
};

export default ContentLanguageButton;
