import { isEmpty } from 'lodash';

import { StructureItem } from '../types/entities';

export const extendStructure = (arr: StructureItem[]) => {
  return arr?.map((item) => {
    const childrenEx: StructureItem[] = isEmpty(item.children) ? [] : extendStructure(item.children);
    if (item.learning_structure.level_type !== 'production_item') {
      return {
        ...item,
        children: [...childrenEx],
        open: true,
      };
    } else {
      return {
        ...item,
      };
    }
  });
};

export const toggleCollapseStructureItem = (id: string, value: boolean, arr: StructureItem[]) => {
  return arr.map((item) => {
    const updated: StructureItem[] = isEmpty(item.children)
      ? []
      : toggleCollapseStructureItem(id, value, item.children);

    if (item.id === id) {
      return {
        ...item,
        children: [...updated],
        open: value,
      };
    } else {
      return {
        ...item,
        children: [...updated],
      };
    }
  });
};

export const setCollapseStructureItem = (value: boolean, arr: StructureItem[]) => {
  return arr.map((item) => {
    const updated: StructureItem[] = isEmpty(item.children) ? [] : setCollapseStructureItem(value, item.children);

    if (item.learning_structure.level_type !== 'production_item') {
      return {
        ...item,
        children: [...updated],
        open: value,
      };
    } else {
      return {
        ...item,
      };
    }
  });
};
