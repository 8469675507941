import React, { memo } from 'react';

import Button from 'shared/ui/button/Button';
import { priceModel } from 'entities/price';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { useTranslation } from 'react-i18next';
import { Popconfirm } from 'antd';

import { downgradeSubscriptionAction, tokenSubscriptionAction } from '../actions/actions';

import './styles.scss';

type Props = {
  id: string;
  title?: string;
  type: priceModel.SubscriptionTypes;
  url?: string;
};

const SubscriptionButton: React.FC<Props> = ({ id, type, title, url }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state?.app?.me?.account_data);
  const hasSubscription = useAppSelector((state) => state.app?.me?.has_active_subscription);
  const isCurrent = id === account?.subscription.id;

  const getLabel = () => {
    if (title) {
      return title;
    }
    if (type === 'corp') {
      return t('subscribe.contactUs');
    }
    if (type === 'pro' && !isCurrent) {
      return t('subscribe.upgrade');
    }
    if (type === 'free' && hasSubscription) {
      return t('subscribe.downgrade');
    }
  };

  if (isCurrent && ((type === 'free' && !hasSubscription) || (type === 'pro' && hasSubscription))) {
    return <div className="subscription-active">{t('subscribe.active')}</div>;
  }

  const getAction = async () => {
    if (type === 'corp' && url) {
      window.open(url, '_blank');
    } else if (type === 'free' && hasSubscription) {
      void dispatch(downgradeSubscriptionAction());
    } else {
      void dispatch(tokenSubscriptionAction(id));
    }
  };

  const disabled = !!account?.subscription_cancel_dt;

  return (
    <>
      {type === 'free' && hasSubscription ? (
        <Popconfirm
          title={t('subscribe.confirm.title')}
          description={t('subscribe.confirm.description')}
          onConfirm={getAction}
          okText={t('subscribe.confirm.ok')}
          cancelText={t('subscribe.confirm.cancel')}
          icon={null}
        >
          <Button size="large" exSize="xl" type="primary" disabled={disabled}>
            {getLabel()}
          </Button>
        </Popconfirm>
      ) : (
        <Button size="large" exSize="xl" type="primary" onClick={getAction}>
          {getLabel()}
        </Button>
      )}
    </>
  );
};

export default memo(SubscriptionButton);
