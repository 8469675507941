import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useHover, useDebounce } from 'usehooks-ts';
import TextArea from 'shared/ui/textArea/TextArea';
import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button';
import type { VideoScriptParagraphType, VideoScriptSection } from 'shared/types/entities';
import { cx } from 'shared/utils';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { documentFileId } from 'entities/studio/model/studio.selectors';
import { useOwner } from 'shared/hooks/useProjectOwner';
import { isSelectProjectGenerating } from 'modules/project/project.selectors';

import { changeParagraphAction, changeParagraphOrderAction, deleteParagraphAction } from '../../model/actions';

import './styles.scss';

type Props = {
  isLast: boolean;
  item: VideoScriptSection;
};

export const Paragraph: FC<Props> = ({ isLast, item }) => {
  const content = useMemo(() => JSON.parse(item.section_content) as VideoScriptParagraphType, [item.section_content]);
  const [text, setText] = useState(content.text);
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const fileId = useAppSelector(documentFileId);
  const isProjectGenerating = useAppSelector(isSelectProjectGenerating);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const { isOwner } = useOwner();
  const disabled = !isOwner || isProjectGenerating || !!selectedVersion;

  const dispatch = useAppDispatch();

  const debouncedText = useDebounce<string>(text, 500);

  useEffect(() => {
    setText(content.text);
  }, [content.text]);

  const onSave = useCallback(() => {
    const payload = {
      text: debouncedText,
      presentation_slide_id: null,
    };
    if (fileId) {
      void dispatch(changeParagraphAction(fileId, item.id, payload));
    }
  }, [debouncedText, dispatch, fileId, item.id]);

  useEffect(() => {
    if (debouncedText !== content.text) {
      onSave();
    }
  }, [content.text, debouncedText, onSave]);

  const onHandleChangeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const onPrevPosition = () => {
    const payload = {
      order: item.order - 1,
    };
    if (fileId) {
      void dispatch(changeParagraphOrderAction(fileId, item.id, payload));
    }
  };

  const onNextPosition = () => {
    const payload = {
      order: item.order + 1,
    };
    if (fileId) {
      void dispatch(changeParagraphOrderAction(fileId, item.id, payload));
    }
  };

  const onRemove = () => {
    if (fileId) {
      void dispatch(deleteParagraphAction(fileId, item.id));
    }
  };

  const classNames = cx('paragraph', {
    'paragraph--hovered': isHover,
  });

  const disablePrev = item.order === 1;

  return (
    <div ref={hoverRef} className={classNames}>
      {isHover && !disabled && (
        <div className="paragraph__actions">
          <Button
            size="small"
            shape="circle"
            type="text"
            disabled={disablePrev}
            icon={<UpOutlined style={{ fontSize: 12 }} />}
            onClick={onPrevPosition}
          />
          <Button
            size="small"
            shape="circle"
            type="text"
            disabled={isLast}
            icon={<DownOutlined style={{ fontSize: 12 }} />}
            onClick={onNextPosition}
          />
          <Button
            size="small"
            shape="circle"
            type="text"
            icon={<CloseOutlined style={{ fontSize: 12 }} />}
            onClick={onRemove}
          />
        </div>
      )}

      <TextArea disabled={disabled} value={text} autoSize={{ minRows: 4, maxRows: 10 }} onChange={onHandleChangeText} />
    </div>
  );
};
