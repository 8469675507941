import React, { useCallback, useEffect, useMemo } from 'react';

import Button from 'shared/ui/button';
import IconMagic from 'shared/ui/icons/magic/IconMagic';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { generateQuizAction, useCurrentAssignment } from 'entities/assignment/model';

import { useOwner } from '../../shared/hooks/useProjectOwner';

import AssignmentSettingsQuiz from './content/quiz';

import './styles.scss';

const AssignmentsSettings = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { assignment, loading } = useCurrentAssignment();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isGeneration = useAppSelector((state) => state.project.generation);
  const selectedVersion = useAppSelector((s) => s.project.selectedVersion);
  const { isOwner } = useOwner();
  const isAutoSubmit = searchParams.get('auto');

  const defaultValues = useMemo(() => {
    return {
      questions: assignment?.assignment?.quiz?.generate_param_number_of_questions || 5,
      question_type: assignment?.assignment?.quiz?.generate_param_type_of_answer || 'multiple',
    };
  }, [assignment]);

  //Init form values
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [defaultValues, form]);

  const onSubmit = useCallback(() => {
    if (assignment) {
      const values = form.getFieldsValue();
      const payload = {
        quiz_number_of_questions: values.questions,
        quiz_type_of_answers: values.question_type,
      };

      void dispatch(generateQuizAction(assignment.id, payload)).then(() => {
        if (isAutoSubmit) {
          searchParams.delete('auto');
          setSearchParams(searchParams);
        }
      });
    }
  }, [assignment, dispatch, form, isAutoSubmit, searchParams, setSearchParams]);

  useEffect(() => {
    if (isAutoSubmit) {
      onSubmit();
    }
  }, [isAutoSubmit, onSubmit, searchParams, setSearchParams]);

  return (
    <div className="assignments-setting">
      <Form form={form} name="assignments-setting-form" className="assignments-setting-form" layout="vertical">
        <AssignmentSettingsQuiz />
      </Form>
      <div className="assignments-setting__actions">
        <Button
          className="form-footer__submit"
          size="large"
          exSize="xl"
          type="primary"
          disabled={!isOwner || loading || isGeneration || !!selectedVersion}
          onClick={onSubmit}
        >
          {`${t('common.generate')} ${t(`objectTypes.${assignment?.type?.name}`)}`}
          <IconMagic size={16} />
        </Button>
      </div>
    </div>
  );
};

export default AssignmentsSettings;
