import { useAppSelector } from 'shared/store/hooks';

import { BusinessRoleEnum } from '../../shared/types/entities';

export const useUserDetails = () => {
  const me = useAppSelector((state) => state.app.me);

  const company = me?.companies?.[0];

  return {
    companyType: company?.company.company_type,
    businessRoles: company?.business_roles.map((item) => item.role),
    isExecutiveManager: !!company?.business_roles.find((item) => item.role === BusinessRoleEnum.executiveManager),
  };
};
