import React, { useCallback, useEffect, useMemo } from 'react';

import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AssignmentCard from 'modules/assignment-card';
import BlockTitle from 'shared/ui/block-title/BlockTitle';
import Button from 'shared/ui/button';
import IconMagic from 'shared/ui/icons/magic/IconMagic';
import { isEmpty } from 'lodash';
import { runTutor, setStepIndex } from 'modules/tutorial/store';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { generateAllQuizzesAction } from 'entities/assignment/model/actions';
import { useOwner } from 'shared/hooks/useProjectOwner';
import Sider from 'antd/es/layout/Sider';
import { useBoolean } from 'usehooks-ts';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { selectedVideoProvider } from 'app/store/app.selectors';

import { getAssignmentsAction } from './store/actions';
import { filteredContentItems } from './lib';

import './styles.scss';

type Props = {
  isContent?: boolean;
};

const StructureItemsListWidget: React.FC<Props> = ({ isContent }) => {
  const location = useLocation();
  const isContentPage = location.pathname.indexOf('content') !== -1;
  const videoProvider = useAppSelector(selectedVideoProvider);
  const isConstructor = videoProvider === 'constructor';

  const { value: collapsed, setValue: setCollapsed } = useBoolean(isContentPage && isConstructor);
  const { id, lang } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.assignments.loading);
  const structureItems = useAppSelector((state) => state.assignments.assignments);
  const isTourActive = useAppSelector((state) => state.tutorial.tourActive);
  const isGeneration = useAppSelector((state) => state.project.generation);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const version = searchParams.get('version') || undefined;
  const str = searchParams.get('str') || undefined;
  const hasQuizzes = !!structureItems?.find((a) => a?.type?.name === 'quiz');
  const { isOwner } = useOwner();
  const strItems = useMemo(() => {
    return filteredContentItems(structureItems, isContent);
  }, [isContent, structureItems]);

  useEffect(() => {
    if (strItems?.length && !str) {
      if (version) {
        setSearchParams({ str: strItems[0]?.id, version: version });
      } else {
        setSearchParams({ str: strItems[0]?.id });
      }
    }
  }, [setSearchParams, str, strItems, version]);

  useEffect(() => {
    if (id && lang) {
      if (version) {
        void dispatch(getAssignmentsAction(id, lang, false, version));
      } else {
        void dispatch(getAssignmentsAction(id, lang));
      }
    }
  }, [dispatch, id, lang, version]);

  //Run Tour
  useEffect(() => {
    if (!loading && !isEmpty(structureItems) && isTourActive) {
      dispatch(setStepIndex(15));
      dispatch(runTutor());
    }
  }, [structureItems, dispatch, isTourActive, loading]);

  const onGenerateAll = useCallback(() => {
    void dispatch(generateAllQuizzesAction());
  }, [dispatch]);

  const onToggle = () => setCollapsed(!collapsed);

  return (
    <Sider width={440} collapsedWidth={100} trigger={null} collapsible collapsed={collapsed}>
      <div className="assignment-list__top">
        {isContentPage && isConstructor && (
          <Button className="assignment-list__toggle" onClick={onToggle}>
            {collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          </Button>
        )}

        <div className="assignment-list__top-wrap">
          <BlockTitle text={t<string>(isContent ? 'assignments.titleC' : 'assignments.titleA')} />
          {!isContent && !collapsed ? (
            <Button
              disabled={!isOwner || !hasQuizzes || loading || isGeneration || !!selectedVersion}
              className="assignment-list__button"
              type="primary"
              size="middle"
              onClick={onGenerateAll}
            >
              {t('assignments.genAllQuizzes')}
              <IconMagic size={16} />
            </Button>
          ) : null}
        </div>
      </div>
      <div className="assignment-list">
        <div className="assignment-list__content">
          {strItems?.map((item) => {
            return <AssignmentCard isCollapsed={collapsed} key={item.id} item={item} />;
          })}
        </div>
      </div>
    </Sider>
  );
};

export default StructureItemsListWidget;
