import React, { memo, useCallback } from 'react';

import { Dropdown, MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button/Button';
import useObjectTypes from 'shared/graphql/queries/useObjectTypes';
import { ObjectType } from 'shared/types/entities';
import { getIconByUrl } from 'shared/utils/getIcon';
import { useAppDispatch } from 'shared/store/hooks';

import { setAddElement, setAddElementType, setTypeMenuOpen } from '../store';

import './styles.scss';

type Props = {
  disabled: boolean;
  itemId: string;
  cb?: () => void;
};

type DropProps = {
  parentItemId: string;
  item: ObjectType;
  cb?: () => void;
};

const DropdownLabel = ({ parentItemId, item, cb }: DropProps) => {
  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    dispatch(setAddElementType(item.id));
    dispatch(setAddElement(parentItemId));
    dispatch(setTypeMenuOpen(false));
    cb?.();
  }, [cb, dispatch, item.id, parentItemId]);

  return (
    <div className="add-element-drop__label" role="button" onClick={onClick}>
      {getIconByUrl(item.icon)}
      <span className="add-element-drop__label-text">{item.title}</span>
    </div>
  );
};

const AddElementDropdown: React.FC<Props> = ({ disabled, itemId, cb }) => {
  const { objectTypes } = useObjectTypes();
  const dispatch = useAppDispatch();

  const onOpenChange = useCallback(
    (open: boolean) => {
      dispatch(setTypeMenuOpen(open));
    },
    [dispatch]
  );

  const items: MenuProps['items'] = objectTypes?.map((el) => {
    return {
      key: el.id,
      label: <DropdownLabel key={el.id} item={el} parentItemId={itemId} cb={cb} />,
    };
  });

  return (
    <Dropdown
      disabled={disabled}
      overlayClassName="add-element-drop"
      trigger={['click']}
      menu={{ items }}
      placement="bottom"
      onOpenChange={onOpenChange}
    >
      <Button
        disabled={disabled}
        shape="circle"
        size="small"
        type="text"
        icon={<PlusOutlined style={{ fontSize: 14 }} />}
      />
    </Dropdown>
  );
};

export default memo(AddElementDropdown);
