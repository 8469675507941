import React, { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { useHover } from 'usehooks-ts';
import TextArea from 'shared/ui/textArea/TextArea';

import './styles.scss';

type Props = {
  autoFocus?: boolean;
  disabled?: boolean;
  color?: string;
  itemId?: string;
  val?: string;
  actions?: ReactNode;
  onSubmit?: (text?: string, itemId?: string) => void;
};

const EditableCard = ({ autoFocus, disabled, color, itemId, val, actions, onSubmit }: Props) => {
  const [text, setText] = useState<string>('');
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  useEffect(() => {
    if (val) {
      setText(val);
    }
  }, [val]);

  const onBlur = () => {
    if (val) {
      if (val !== text && text) {
        onSubmit?.(text, itemId);
      }
    } else {
      onSubmit?.(text);
    }
  };

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  }, []);

  return (
    <div ref={hoverRef} className="edit-card" style={{ borderColor: color || '' }}>
      <div className="edit-card__inner">
        <TextArea
          autoFocus={autoFocus}
          className="lo-item__name"
          size="small"
          bordered={false}
          autoSize={{ minRows: 1, maxRows: 50 }}
          value={text}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
      <div className="edit-card__actions">{isHover ? actions : null}</div>
    </div>
  );
};

export default EditableCard;
