import { selectedVideoProvider } from 'app/store/app.selectors';
import { useQuery } from '@apollo/client';

import { useAppSelector } from '../../store/hooks';
import { VIDEO_PROVIDER_AVATAR_VOICES } from '../schemas/videoProvider';

type VideoProviderAvatarVoicesData = {
  cob_video_provider_avatar_voices: {
    id: string;
    name: string;
  }[];
};

type Props = {
  langId?: string;
};

const useVideoProviderAvatarVoices = ({ langId }: Props) => {
  const provider = useAppSelector(selectedVideoProvider);
  const { data, loading } = useQuery<VideoProviderAvatarVoicesData>(VIDEO_PROVIDER_AVATAR_VOICES, {
    variables: {
      videoProvider: provider,
      avatarLanguageId: Number(langId),
    },
    fetchPolicy: 'network-only',
    skip: !langId,
  });

  return {
    data: data?.cob_video_provider_avatar_voices,
    loading,
  };
};

export default useVideoProviderAvatarVoices;
