import React, { memo } from 'react';

import { CBIconProps } from '../types';

const IconSearch: React.FC<CBIconProps> = ({ size }: CBIconProps) => {
  const styles = {
    width: size || 24,
    height: size || 24,
  };
  return (
    <svg style={styles} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4392 16.0442L14.1176 12.7227C15.1379 11.4037 15.6898 9.79109 15.6898 8.09489C15.6898 6.06453 14.8974 4.16074 13.4645 2.7253C12.0316 1.28987 10.1227 0.5 8.09489 0.5C6.06706 0.5 4.15821 1.2924 2.7253 2.7253C1.28987 4.15821 0.5 6.06453 0.5 8.09489C0.5 10.1227 1.2924 12.0316 2.7253 13.4645C4.15821 14.8999 6.06453 15.6898 8.09489 15.6898C9.79109 15.6898 11.4012 15.1379 12.7202 14.1202L16.0417 17.4392C16.061 17.4584 16.0839 17.4737 16.1091 17.4842C16.1342 17.4946 16.1613 17.5 16.1885 17.5C16.2158 17.5 16.2428 17.4946 16.268 17.4842C16.2932 17.4737 16.3161 17.4584 16.3354 17.4392L17.4392 16.3379C17.4584 16.3186 17.4737 16.2957 17.4842 16.2705C17.4946 16.2453 17.5 16.2183 17.5 16.1911C17.5 16.1638 17.4946 16.1368 17.4842 16.1116C17.4737 16.0864 17.4584 16.0635 17.4392 16.0442ZM12.105 12.105C11.0316 13.1759 9.60881 13.7657 8.09489 13.7657C6.58098 13.7657 5.1582 13.1759 4.08479 12.105C3.01391 11.0316 2.42404 9.60881 2.42404 8.09489C2.42404 6.58098 3.01391 5.15567 4.08479 4.08479C5.1582 3.01391 6.58098 2.42404 8.09489 2.42404C9.60881 2.42404 11.0341 3.01138 12.105 4.08479C13.1759 5.1582 13.7657 6.58098 13.7657 8.09489C13.7657 9.60881 13.1759 11.0341 12.105 12.105Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(IconSearch);
