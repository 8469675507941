import React from 'react';

import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import GoogleAuthButton from 'modules/google-auth-button';
import { Account } from 'features/account';

import './styles.scss';

const SignupWidget: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="signup__container">
      <div className="signup__inner">
        <span className="signup__title">{t('signup.title')}</span>
        <Account.UserInfo.SignupForm />
        <Divider>{t('signup.or')}</Divider>
        <GoogleAuthButton />
      </div>
    </div>
  );
};

export default SignupWidget;
