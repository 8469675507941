import React, { useEffect } from 'react';

import StructureItemsListWidget from 'widgets/assignments-list';
import AssignmentSelectWidget from 'widgets/assignment-select';
import { useAppDispatch } from 'shared/store/hooks';
import { setAssignment } from 'entities/assignment/model';

import './styles.scss';

const CourseContentPage: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(setAssignment(null));
    };
    // Don't update deps
  }, []);
  return (
    <div className="course-content-page">
      <StructureItemsListWidget isContent />
      <AssignmentSelectWidget />
    </div>
  );
};

export default CourseContentPage;
