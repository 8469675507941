import React, { useCallback, useState } from 'react';

import { ProjectContextFile } from 'shared/types/entities';
import { isEmpty } from 'lodash';
import Button from 'shared/ui/button';
import { DeleteOutlined } from '@ant-design/icons';
import { cx } from 'shared/utils';
import { useParams } from 'react-router-dom';
import { Progress } from 'antd';

import './styles.scss';

type Props = {
  isLoading: boolean;
  className?: string;
  percent: number;
  files?: ProjectContextFile[];
  onRemoveFile?: (contextFileId: string) => Promise<any>;
  disabled?: boolean;
};

type ItemProps = {
  disabled?: boolean;
  name: string;
  contextFileId: string;
  onRemove?: (contextFileId: string) => Promise<any>;
};

const FileUploadItem = React.memo<ItemProps>(({ disabled, name, contextFileId, onRemove }) => {
  const [loading, setLoading] = useState(false);
  const onRemoveFile = useCallback(() => {
    setLoading(true);
    onRemove?.(contextFileId).finally(() => setLoading(false));
  }, [contextFileId, onRemove]);

  return (
    <div className="files-upload-list-item">
      <span className="files-upload-list-item__name">{name}</span>
      <Button
        disabled={loading || disabled}
        loading={loading}
        type="text"
        icon={<DeleteOutlined style={{ fontSize: 12, color: '#D73C1D' }} onClick={onRemoveFile} />}
      />
    </div>
  );
});

const FilesUploadList: React.FC<Props> = ({ isLoading, disabled, percent, className, files, onRemoveFile }) => {
  const { id } = useParams();

  if (isEmpty(files) && !isLoading) {
    return null;
  }

  const classNames = cx('files-upload-list', className);

  return (
    <div className={classNames}>
      {isLoading ? (
        <div className="files-upload-list__progress">
          <Progress percent={percent} showInfo={false} strokeColor="#1E1E1E" trailColor="#E9E9E9" />
        </div>
      ) : null}

      {files?.map((file) => {
        return (
          <FileUploadItem
            key={file.context_file.file.id}
            disabled={disabled}
            contextFileId={id === 'new' ? file.context_file.file.id : file.context_file.id}
            name={file.context_file.file.name}
            onRemove={onRemoveFile}
          />
        );
      })}
    </div>
  );
};

export default FilesUploadList;
