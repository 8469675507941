import { gql } from '@apollo/client';

export const DC_PROJECT_VERSIONS = gql`
  query DSProjectVersions($cob_project_id: Int) {
    dc_project_versions(cob_project_id: $cob_project_id) {
      edges {
        node {
          id
          pk
          state
          note
          created_at
          cob_project_version {
            id
            is_active
            created_at
            language {
              name
              code
            }
          }
          final_path
          dc_project {
            ccm_project_id
            connector {
              connector_name
            }
          }
        }
      }
      totalCount
    }
  }
`;
