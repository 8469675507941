import { gql } from '@apollo/client';

export const COURSE_TYPES = gql`
  query CourseTypes($language: String) {
    outline_builder_course_types(language: $language) {
      id
      pk
      code
      name
      is_active
    }
  }
`;
