import { client } from '../index';
import { OUTLINE_BUILDER_PROJECT } from '../schemas/outlineBuilderProject';

import { OutlineProjectData } from './useOutlineProject';

export const getProjectRequest = (projectId: string, publicSlug?: string, language?: string) => {
  return client.query<OutlineProjectData>({
    query: OUTLINE_BUILDER_PROJECT,
    fetchPolicy: 'network-only',
    variables: {
      id: publicSlug ? undefined : projectId,
      public_slug: publicSlug,
      language,
    },
  });
};
