import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import TextArea from 'shared/ui/textArea/TextArea';
import { useTranslation } from 'react-i18next';
import { getAssignmentsAction } from 'widgets/assignments-list/store/actions';
import { getIconByUrl } from 'shared/utils/getIcon';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { useCurrentAssignment } from 'entities/assignment/model';

import { updateStructureItem } from '../course-structure-collapse/store/actions';
import LearningOutcomesStructureViewItem from '../learning-outcomes-structure-view/ui/learning-outcomes-structure-view-item';

import './styles.scss';
import { useOwner } from '../../shared/hooks/useProjectOwner';

type StateType = {
  title?: string;
  description?: string;
};

const AssignmentsHead: React.FC = () => {
  const [state, setState] = useState<StateType>({
    title: '',
    description: '',
  });
  const dispatch = useAppDispatch();
  const project = useAppSelector((s) => s.project?.project);
  const lang = project?.active_project_version?.language.code;
  const structureItemStore = useAppSelector((s) => s.structureItem);
  const isProjectGenerate = useAppSelector((s) => s.project.generation);
  const selectedVersion = useAppSelector((s) => s.project.selectedVersion);
  const { t } = useTranslation();
  const { loading, assignment } = useCurrentAssignment();
  const { isOwner } = useOwner();
  useEffect(() => {
    if (assignment) {
      setState({
        title: assignment.title,
        description: assignment.description,
      });
    }
  }, [assignment]);

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const onBlur = async () => {
    if (assignment?.title !== state.title || assignment?.description !== state.description) {
      if (project && lang && assignment?.id) {
        const payload = {
          title: state.title,
          description: state.description,
          course_structure_item_parent_id: Number(assignment?.course_structure_item_parent?.id),
        };
        await dispatch(updateStructureItem(payload, assignment?.id));
        await dispatch(getAssignmentsAction(project?.id, lang));
      }
    }
  };

  const disabled = !isOwner || isProjectGenerate || structureItemStore.loading || loading;

  return (
    <div className="assignment-select-head">
      <div className="assignment-select-head__top">
        <span className="assignment-select-head__index">{assignment?.index}</span>
        <div className="assignment-select-head__icon">{getIconByUrl(assignment?.type?.icon)}</div>
        <TextArea
          name="title"
          bordered={false}
          placeholder={t<string>('structure.addTitle')}
          className="assignment-select-head__controls assignment-select-head__title"
          autoSize={{ minRows: 1, maxRows: 20 }}
          value={state.title}
          disabled={disabled || !!selectedVersion}
          onBlur={onBlur}
          onChange={handleChange}
        />
      </div>

      <TextArea
        name="description"
        bordered={false}
        placeholder={t<string>('structure.addDescription')}
        className="assignment-select-head__controls assignment-select-head__description"
        autoSize={{ minRows: 1, maxRows: 20 }}
        value={state.description}
        disabled={disabled || !!selectedVersion}
        onBlur={onBlur}
        onChange={handleChange}
      />
      <div className="assignment-select-head__lo">
        {assignment?.sub_module_outcomes?.map((el) => {
          return (
            <LearningOutcomesStructureViewItem
              key={el.id}
              tag={el.letter_index}
              text={el.name}
              color={el.outline_builder_project_module_outcome.color}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AssignmentsHead;
