import React, { memo, useCallback, useEffect } from 'react';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { localStorageService } from 'shared/services/localStorageService';
import { getLanguagesAction } from 'app/store/actions';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import './styles.scss';

const SwitchLanguage: React.FC = () => {
  const dispatch = useAppDispatch();
  const languages = useAppSelector((state) => state.app.languages);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!languages) {
      void dispatch(getLanguagesAction());
    }
  }, [dispatch, languages]);

  const onChangeLanguage = useCallback(
    (val: string) => {
      i18n.changeLanguage(val).then(() => localStorageService.setLangData(val));
    },
    [i18n]
  );

  const options = languages
    ?.filter((el) => el.interface_language)
    ?.map((el) => {
      return {
        value: el.code,
        label: el.name,
      };
    });

  return (
    <Select className="switch-lang" size="small" options={options} value={i18n.language} onChange={onChangeLanguage} />
  );
};

export default memo(SwitchLanguage);
