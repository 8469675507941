import React, { ChangeEvent, useCallback, useState } from 'react';

import LearningOutcomeItemView from 'shared/components/learning-outcome-item-view/LearningOutcomeItemView';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { toggleSubView } from '../store';
import { saveNewSubOutcomeAction } from '../store/actions';

type Props = {
  parentId: string;
  letterIndex: string;
  order?: number;
  color: string;
};

const AddLearningSubOutcome: React.FC<Props> = ({ parentId, letterIndex, order = 0, color }) => {
  const [text, setText] = useState('');
  const dispatch = useAppDispatch();
  const addState = useAppSelector((state) => state.addOutcome);

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  }, []);

  const onBlur = () => {
    if (!text) {
      dispatch(toggleSubView(null));
    } else {
      dispatch(saveNewSubOutcomeAction(parentId, text)).then(() => setText(''));
    }
  };

  if (addState.subView !== parentId) {
    return null;
  }

  return (
    <LearningOutcomeItemView
      autoFocus
      color={color}
      letterIndex={`${letterIndex}${order + 1}`}
      text={text}
      onChange={handleChange}
      onBlur={onBlur}
    />
  );
};

export default AddLearningSubOutcome;
