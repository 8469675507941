import { useQuery } from '@apollo/client';
import { ExportState, PageInfo } from 'shared/types/entities';

import { DC_PROJECT_VERSIONS } from '../schemas/dcProjectVersions';
import { ExportType } from '../../../entities/export';

export type DCProjectVersion = {
  id: string;
  pk: string;
  state: ExportState;
  note: string;
  created_at: string;
  cob_project_version: {
    id: string;
    is_active: boolean;
    created_at: string;
    language: {
      name: string;
      code: string;
    };
  };
  final_path: string;
  dc_project: {
    ccm_project_id: string;
    connector: {
      connector_name: ExportType;
    };
  };
};

type DCProjectVersionData = {
  dc_project_versions: {
    edges: {
      node: DCProjectVersion;
    }[];
    totalCount: number;
  };
};

type Props = {
  projectId?: string;
};

const useDCProjectVersions = ({ projectId }: Props) => {
  const { data, previousData, loading, refetch } = useQuery<DCProjectVersionData>(DC_PROJECT_VERSIONS, {
    variables: {
      cob_project_id: Number(projectId),
    },
    nextFetchPolicy: 'network-only',
    skip: !projectId,
  });

  const convertData = (data || previousData)?.dc_project_versions.edges.map((el) => el.node);

  return {
    loading,
    data: convertData,
    totalCount: data?.dc_project_versions.totalCount || previousData?.dc_project_versions.totalCount,
    refetch,
  };
};

export default useDCProjectVersions;
