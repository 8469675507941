import React from 'react';

import { isEmpty } from 'lodash';

import { useAppSelector } from 'shared/store/hooks';

import PromoCardItem from './ui/card/PromoCardItem';

const PromoCard: React.FC = () => {
  const messages = useAppSelector((state) => state?.app?.me?.promo_messages);
  const filtered = messages?.filter((m) => m.is_active && m.promo_message.type === 'card');

  if (isEmpty(filtered)) {
    return null;
  }

  return (
    <>
      {filtered?.map((item) => {
        return <PromoCardItem key={item.id} promoId={item.id} item={item.promo_message} />;
      })}
    </>
  );
};

export default PromoCard;
