import React, { ChangeEvent, memo } from 'react';

import Input from 'shared/ui/input';
import { SearchOutlined } from '@ant-design/icons';
import { ActiveSubModulesOutcome } from 'shared/types/entities';

import AddOutcomeMenuItem from './AddOutcomeMenuItem';

import './styles.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  items: ActiveSubModulesOutcome[] | undefined;
  selectedItems: string[];
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  onToggleSelected: (outcomeId: string) => void;
};

const AddOutcomeMenu: React.FC<Props> = ({ items, selectedItems, onSearch, onToggleSelected }) => {
  const { t } = useTranslation();

  return (
    <div className="course-structure-add-outcomes-menu">
      <div className="course-structure-add-outcomes-menu__search">
        <Input
          size="middle"
          prefix={<SearchOutlined style={{ fontSize: 14 }} />}
          placeholder={t<string>('addOutcomesPopup.placeholder')}
          onChange={onSearch}
        />
      </div>
      <div className="course-structure-add-outcomes-menu__list">
        {items?.map((item) => {
          return (
            <AddOutcomeMenuItem
              key={item.id}
              item={item}
              isSelected={selectedItems.includes(item.id)}
              onClick={onToggleSelected}
            />
          );
        })}
      </div>
    </div>
  );
};

export default memo(AddOutcomeMenu);
