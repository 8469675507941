import React from 'react';

import { Dropdown } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button/Button';
import { MenuInfo } from 'shared/types/entities';

import './styles.scss';
import { useTranslation } from 'react-i18next';

import IconSparking from '../../../../shared/ui/icons/sparking';

type Props = {
  disabled: boolean;
  onClick: (info: MenuInfo) => void;
};

const QuizCardMore: React.FC<Props> = ({ disabled, onClick }) => {
  const { t } = useTranslation();
  const menu = [
    // {
    //   key: 'up',
    //   label: 'Move up',
    //   icon: <ArrowUpOutlined style={{ fontSize: 12 }} />,
    // },
    {
      key: 'gen',
      label: t<string>('learningOutcomes.moreActions.generateAnswers'),
      icon: (
        <div style={{ height: 12 }}>
          <IconSparking size={12} />
        </div>
      ),
    },
    {
      key: 'del',
      label: t<string>('learningOutcomes.moreActions.delete'),
      danger: true,
      icon: <DeleteOutlined style={{ fontSize: 12, color: '#D73C1D' }} />,
    },
  ];

  return (
    <Dropdown
      disabled={disabled}
      overlayClassName="quiz-more-action-drop"
      placement="bottomRight"
      trigger={['click']}
      menu={{ items: menu, onClick }}
    >
      <Button
        disabled={disabled}
        size="small"
        shape="circle"
        type="text"
        icon={<EllipsisOutlined style={{ fontSize: 16 }} />}
      />
    </Dropdown>
  );
};

export default QuizCardMore;
