import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import AssignmentsHead from 'modules/assignments-head';
import { useTranslation } from 'react-i18next';
import BlockTitle from 'shared/ui/block-title';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { updateVideoSettings } from 'features/assignment-video-duration/model/action';
import Input from 'shared/ui/input';
import { isEmpty } from 'lodash';
import { GeneratePointsButton } from 'features/assignment-video-gen-buttons';
import FilesUpload from 'shared/components/files-upload';
import { RcFile } from 'antd/es/upload';
import { checkMimeType } from 'shared/utils';
import { Alert, message, UploadProps } from 'antd';
import { deleteOutlineBuilderAssignmentContextFile, postCreateFileRequest } from 'shared/api/requests/file';
import { onUploadFileInPartRequest } from 'shared/api/fetches/fileUpload';
import { postStructureItemUploadFile } from 'shared/api/requests/outline-builder-project-structure';
import FilesUploadList from 'shared/components/files-upload-list';
import { finishUpload, setFileSize, setProgress, startUpload } from 'modules/project';
import { getAssignmentItemAction, getStructureItemContextFilesAction } from 'entities/assignment/model/actions';
import { useContextFileState, useCurrentAssignment } from 'entities/assignment/model/hooks';

import '../styles.scss';
import { useOwner } from '../../../../shared/hooks/useProjectOwner';

type Props = {
  onChangeTab: (key: string) => void;
};

const AssignmentVideoSettings: React.FC<Props> = ({ onChangeTab }) => {
  const [duration, setDuration] = useState<number | string>(5);
  const dispatch = useAppDispatch();
  const { loading, assignment } = useCurrentAssignment();
  const projectGenerate = useAppSelector((state) => state.project.generation);
  const { t } = useTranslation();
  const { contextState } = useContextFileState();
  const selectedVersion = useAppSelector((s) => s.project.selectedVersion);
  const { isOwner } = useOwner();
  const totalSize = useAppSelector((state) => state.project.totalFilesSize);
  const progress = useAppSelector((state) => state.project.progress);
  const activeUploads = useAppSelector((state) => state.project.activeUploads);

  useEffect(() => {
    if (assignment?.assignment?.video?.video_length_s) {
      setDuration(assignment?.assignment?.video?.video_length_s);
    }
  }, [assignment?.assignment?.video?.video_length_s]);

  const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/, '');
    const validated = Number(value) > 10 ? 10 : value;
    setDuration(validated);
  };

  const onBlur = async () => {
    if (Number(duration) !== 0 && assignment?.id && !isEmpty(assignment?.assignment?.video?.points)) {
      const payload = {
        video_length_s: Number(duration),
      };
      void dispatch(updateVideoSettings(assignment?.id, payload));
    }
  };

  const disabledRemove = contextState === 'new' || contextState === 'parsing' || contextState === 'ready';
  const disabled = projectGenerate || loading || disabledRemove || !isOwner;

  const onUploadProgress = useCallback(
    (p: number) => {
      dispatch(setProgress(p));
    },
    [dispatch]
  );

  const onSetFileSize = useCallback(
    (fileSize: number) => {
      dispatch(setFileSize(fileSize));
    },
    [dispatch]
  );

  const onStartUpload = useCallback(() => dispatch(startUpload()), [dispatch]);
  const onFinishUpload = useCallback(() => dispatch(finishUpload()), [dispatch]);

  const onBeforeUpload = useCallback(
    async (file: RcFile) => {
      const { isPdf } = checkMimeType(file);

      if (!isPdf) {
        return void message.error('Allowed file format: PDF', 5);
      }

      onStartUpload();
      onSetFileSize(file.size);

      const payload = {
        file_name: file.name || '',
      };
      const response = await postCreateFileRequest('cob_file', payload);

      if (response?.id && assignment) {
        const onAfterUpload = () => {
          const payloadFile = {
            file_id: response.id,
          };
          postStructureItemUploadFile(assignment?.id, payloadFile).then(() => {
            dispatch(getStructureItemContextFilesAction(assignment?.id));
          });
        };

        await onUploadFileInPartRequest({
          file,
          fileId: response.id,
          callbackComplete: onAfterUpload,
          updateUpload: onUploadProgress,
          callback: onFinishUpload,
        });
      }
    },
    [assignment, dispatch, onFinishUpload, onSetFileSize, onStartUpload, onUploadProgress]
  );

  const onRemoveFile = useCallback(
    async (fileId: string) => {
      await deleteOutlineBuilderAssignmentContextFile(fileId);
      if (assignment?.id) {
        void dispatch(getAssignmentItemAction(assignment?.id, true));
      }
    },
    [assignment?.id, dispatch]
  );

  const customRequest = () => null;

  const uploadProps: UploadProps = {
    customRequest,
    showUploadList: false,
    beforeUpload: onBeforeUpload,
    disabled: !isEmpty(assignment?.context_files) || !!selectedVersion,
  };

  return (
    <div className="assign-video-content__wrap">
      <div className="assignment-select__block">
        <AssignmentsHead />
      </div>
      <div className="assignment-select__block w-auto">
        <FilesUpload className="mb-2" {...uploadProps} />
        {contextState === 'ready' || contextState === 'parsing' || contextState === 'new' ? (
          <Alert type="info" message="The file has been loaded and is currently being parsed. Please wait." />
        ) : null}
        {contextState === 'error' ? <Alert type="error" message="File upload failed. Please try again later." /> : null}
        {contextState === 'error_file_parsing' ? (
          <Alert
            type="error"
            message="Failed to parse the file. Please ensure that the file format is valid and try again. If the issue persists, please reach out to our support team for further assistance."
          />
        ) : null}
        <FilesUploadList
          disabled={disabledRemove || !!selectedVersion}
          isLoading={Boolean(activeUploads)}
          percent={(progress / totalSize) * 100}
          files={assignment?.context_files}
          onRemoveFile={onRemoveFile}
        />
      </div>
      <div className="assignment-select__block w-auto">
        <BlockTitle className="mb-0" text={t('assignments.video.videoDuration')} />
        <div>
          <Input
            disabled={disabled || !!selectedVersion}
            style={{ width: 100 }}
            size="large"
            value={duration}
            onBlur={onBlur}
            onChange={onHandleChange}
          />
          <BlockTitle className="ml-2" text={t('assignments.video.minutes')} />
        </div>
      </div>
      <div className="assign-video-settings__footer">
        <GeneratePointsButton
          disable={disabled || Number(duration) < 1 || !!selectedVersion}
          duration={Number(duration)}
          structureItemId={assignment?.id}
          onChangeTab={onChangeTab}
        />
      </div>
    </div>
  );
};

export default AssignmentVideoSettings;
