import { ENDPOINTS } from 'shared/api/constants';
import api, { ApiModules } from 'shared/api';

export type ChangeDocumentSectionOrderPayload = {
  order: number;
};

export const changeDocumentSectionOrderRequest = (
  fileId: string,
  sectionId: string,
  payload: ChangeDocumentSectionOrderPayload
) => api(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.DOCUMENT_SECTION_ORDER(fileId, sectionId), payload);

export const deleteDocumentSectionRequest = (fileId: string, sectionId: string) =>
  api(ApiModules.ARTEFACT_MANAGEMENT).delete(ENDPOINTS.DOCUMENT_SECTION_UPDATE(fileId, sectionId));

export type DocumentSectionPayload = {
  text: string;
  presentation_slide_id: number | null;
};

export const changeDocumentSectionRequest = (fileId: string, sectionId: string, payload: DocumentSectionPayload) =>
  api(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.DOCUMENT_SECTION_UPDATE(fileId, sectionId), payload);

export const addSectionRequest = (fileId: string, payload: DocumentSectionPayload) =>
  api(ApiModules.ARTEFACT_MANAGEMENT).post(ENDPOINTS.DOCUMENT_SECTION(fileId), payload);
