import React from 'react';

import ModalContainer from 'shared/components/modal/container';
import ModalHeader from 'shared/components/modal/header/ModalHeader';
import { useLocation } from 'react-router-dom';
import { Form, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import ModalContent from 'shared/components/modal/content';
import FormItem from 'shared/ui/form-item';
import Select from 'shared/ui/select';
import Button from 'shared/ui/button/Button';
import useHelpRoles from 'shared/graphql/queries/useHelpRoles';
import useHelpWorkType from 'shared/graphql/queries/useHelpWorkTypes';
import { postHelpRequest } from 'shared/api/requests/app';
import { useAppSelector } from 'shared/store/hooks';

import './styles.scss';
import { useBoolean } from 'usehooks-ts';

type Props = {
  onCancel: () => void;
};

type DataForm = {
  expertise: string;
  type: string;
};

const ExpertFormModal = ({ onCancel }: Props) => {
  const { value: fetching, setValue: onFetching } = useBoolean(false);
  const { value: hasResult, setTrue } = useBoolean(false);
  const { t } = useTranslation();
  const location = useLocation();
  const userEmail = useAppSelector((s) => s.app.me?.email);

  const { helpRolesForSelect, loading } = useHelpRoles();
  const { helpWorkTypesForSelect, loading: loadingWork } = useHelpWorkType();

  const onSubmit = async (values: DataForm) => {
    onFetching(true);
    const payload = {
      email: userEmail || '',
      page_link: window.location.href,
      help_role_id: Number(values.expertise),
      help_work_type_id: Number(values.type),
    };
    console.log(values);
    await postHelpRequest(payload)
      .then(() => {
        onFetching(false);
        setTrue();
      })
      .catch(() => onFetching(false));
  };

  return (
    <ModalContainer>
      <ModalHeader type="h4" title={t<string>('expert.title')} />
      <ModalContent className="modal-expert">
        {!hasResult ? <span className="modal-expert__sub-title">{t<string>('expert.subTitle')}</span> : null}

        {hasResult ? (
          <div className="modal-expert__result">
            <span className="modal-expert__result-text">{t<string>('expert.result')}</span>
            <Button type="primary" size="large" onClick={onCancel}>
              {t('common.close')}
            </Button>
          </div>
        ) : (
          <Form requiredMark={false} layout="vertical" onFinish={onSubmit}>
            <FormItem
              name="expertise"
              label={t<string>('expert.expertise.label')}
              rules={[{ required: true, type: 'string', message: t<string>('loginForm.error.required') }]}
            >
              <Select
                placeholder={t<string>('expert.expertise.placeholder')}
                size="large"
                loading={loading}
                options={helpRolesForSelect}
              />
            </FormItem>
            <FormItem
              name="type"
              label={t<string>('expert.type.label')}
              rules={[{ required: true, type: 'string', message: t<string>('loginForm.error.required') }]}
            >
              <Select
                placeholder={t<string>('expert.type.placeholder')}
                size="large"
                loading={loadingWork}
                options={helpWorkTypesForSelect}
              />
            </FormItem>
            <div className="modal-expert__footer">
              <Button htmlType="submit" size="large" type="primary" loading={fetching}>
                {t<string>('expert.submit')}
              </Button>
            </div>
          </Form>
        )}
      </ModalContent>
    </ModalContainer>
  );
};

export default ExpertFormModal;
