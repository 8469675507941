import React, { memo } from 'react';

import ButtonSparking from 'shared/components/button-sparking/ButtonSparking';
import RegeneratePopup from 'modules/regenerate-popup';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { AssignmentKeyPoint } from 'shared/types/entities';

import { regenerateAssignmentPointAction } from '../model/actions';

type Props = {
  type: AssignmentKeyPoint;
  itemId: string;
};

const RegenKeyPointButton = ({ type, itemId }: Props) => {
  const loading = useAppSelector((state) => state.assignments.loading);
  const projectGenerate = useAppSelector((state) => state.project.generation);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);

  const dispatch = useAppDispatch();

  const payloadKey =
    type === AssignmentKeyPoint.video ? 'for_regeneration_video_point' : 'for_regeneration_reading_point';

  const onClick = (content: string) => {
    const payload = {
      [payloadKey]: content,
    };
    void dispatch(regenerateAssignmentPointAction(itemId, type, payload));
  };

  const disabled = projectGenerate || loading || !!selectedVersion;

  return (
    <RegeneratePopup disabled={disabled} onSubmit={onClick}>
      <ButtonSparking disabled={disabled} size="small" />
    </RegeneratePopup>
  );
};

export default memo(RegenKeyPointButton);
