import api, { ApiModules } from 'shared/api/index';

import { ENDPOINTS } from '../../constants';

export type SourceType = 'public_view' | 'edit_mode' | 'buy_tokens_button';

export type ProposalPayload = {
  email: string;
  source_type: SourceType;
  source_project_id?: number | undefined;
};

export const postOutlineBuilderProposal = (payload: ProposalPayload) =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.PROPOSAL, payload);
