import api, { ApiModules } from 'shared/api/index';

import { ENDPOINTS } from '../../constants';

export type StripeCheckoutPayload = {
  success_url: string;
  cancel_url: string;
  subscription_id: number;
};

export type StripeCheckoutResponse = {
  payment_url: string | null;
};

export const postStripeCheckoutRequest = (payload: StripeCheckoutPayload): Promise<StripeCheckoutResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.STRIPE_CHECKOUT, payload);

export type StripePortalSessionPayload = {
  return_url: string;
};

export type StripePortalSessionResponse = {
  return_url: string;
  url: string;
};

export const postStripePortalSession = (payload: StripePortalSessionPayload): Promise<StripePortalSessionResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.STRIPE_PORTAL_SESSION, payload);

export const postStripeDowngradeRequest = () => api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.STRIPE_DOWNGRADE);
