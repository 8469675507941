import { gql } from '@apollo/client';

import { LEARNING_STRUCTURE, OBJECT_TYPE, STRUCTURE_ITEM } from './fragments';

export const OUTLINE_BUILDER_PROJECT_STRUCTURE = gql`
  query OutlineBuilderProjectStructure($id: Int, $language: String!, $projectVerId: Int) {
    outline_builder_course_structure(project_id: $id, language: $language, project_version_id: $projectVerId) {
      ...StructureItem
      learning_structure {
        ...LearningStructure
      }
      type {
        ...ObjectType
      }
      children {
        ...StructureItem
        learning_structure {
          ...LearningStructure
        }
        type {
          ...ObjectType
        }
        children {
          ...StructureItem
          learning_structure {
            ...LearningStructure
          }
          type {
            ...ObjectType
          }
          children {
            ...StructureItem
            learning_structure {
              ...LearningStructure
            }
            type {
              ...ObjectType
            }
          }
        }
      }
    }
  }
  ${STRUCTURE_ITEM}
  ${LEARNING_STRUCTURE}
  ${OBJECT_TYPE}
`;
