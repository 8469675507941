import { gql } from '@apollo/client';

import { PAGE_INFO } from './fragments';

export const BillingHistory = gql`
  query BillingHistory($after: String, $first: Int, $offset: Int, $project_id: Int) {
    outline_builder_billing_history(after: $after, first: $first, offset: $offset, project_id: $project_id) {
      edges {
        node {
          id
          created_at
          tokens
          state
          type
          project {
            active_project_version {
              generated_title
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PAGE_INFO}
`;
