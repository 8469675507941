import React, { useCallback, useState } from 'react';

import { Subscription } from 'features/subscription';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { PriceCard, priceLib, priceModel } from 'entities/price';

import './styles.scss';

const PriceListWidget = () => {
  const [period, setPeriod] = useState<string | number>('annual');
  const { subscriptions } = priceModel.useSubscriptions();
  const { t } = useTranslation();

  const options = [
    {
      label: t('common.monthly'),
      value: 'monthly',
    },
    {
      label: t('common.annual'),
      value: 'annual',
    },
  ];

  const onChange = useCallback((val: string | number) => setPeriod(val), []);

  return (
    <div className="price-list">
      <div className="price-list__toggle">
        <Segmented options={options} value={period} onChange={onChange} />
      </div>
      <div className="price-list__inner">
        {priceLib
          .filterByPeriod(period, subscriptions)
          ?.sort((a, b) => a.order - b.order)
          .map((item) => {
            return (
              <PriceCard
                key={item.id}
                data={item}
                action={
                  <Subscription.SubscriptionButton
                    id={item.id}
                    type={item.type}
                    title={item.button_name}
                    url={item.url}
                  />
                }
              />
            );
          })}
      </div>
    </div>
  );
};

export default PriceListWidget;
