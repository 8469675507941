import api, { ApiModules } from 'shared/api/index';
import { ENDPOINTS } from 'shared/api/constants';

import { GenerateResponse } from '../../types';
import { ExportType } from '../../../../entities/export';

interface Payload {
  generated_title: string;
  generated_description: string;
}

export interface OutcomeNextResponse {
  color: string;
  letter_index: string;
  order: number;
}

interface PayloadNewOutcome {
  name: string;
  color: string;
}

export const putOutlineBuilderProjectVersionRequest = (verId: string, payload: Payload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_BY_ID(verId), payload);

export type ProjectVersionOutcomesPayload = {
  regeneration_text?: string;
};

export const postOutlineBuilderProjectVersionOutcomesRequest = (verId: string): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_BY_ID_MODULE_OUTCOMES(verId));

export const postOutlineBuilderProjectVersionSubOutcomesRequest = (verId: string): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_BY_ID_SUB_MODULE_OUTCOMES(verId));

export const getOutlineBuilderProjectVersionOutcomeNextRequest = (verId: string): Promise<OutcomeNextResponse> =>
  api(ApiModules.OUTLINE_BUILDER).get(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_MODULE_OUTCOME_NEXT(verId));

export const postOutlineBuilderProjectVersionOutcomeRequest = (verId: string, payload: PayloadNewOutcome) =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_BY_ID_MODULE_OUTCOME(verId), payload);

export type OutlineBuilderProjectVersionRegeneratePayload = {
  for_regeneration_title?: string;
  for_regeneration_description?: string;
};

export const postOutlineBuilderProjectVersionRegenerateRequest = (
  verId: string,
  entity: 'title' | 'description',
  payload: OutlineBuilderProjectVersionRegeneratePayload
): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_REGENERATE(verId, entity), payload);

type FeedbackPayload = {
  feedback_rate: number;
};

export const postOutlineBuilderProjectVersionFeedbackRequest = (verId: string, payload: FeedbackPayload) =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_FEEDBACK(verId), payload);

export const getOutlineBuilderProjectVersionCSVRequest = (verId: string) =>
  api(ApiModules.OUTLINE_BUILDER).get(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_CSV(verId));

export const postOutlineBuilderProjectVersionGenerateQuizzesRequest = (verId: string): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_GENERATE_QUIZZES(verId));

type TranslatePayload = {
  language: string;
};

export const postOutlineBuilderProjectVersionTranslateRequest = (verId: string, payload: TranslatePayload) =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_TRANSLATE(verId), payload);

type CCMImportPayload = {
  timezone: string;
};

type CCMImportResponse = {
  async_task_id: number;
};

export const postCcmImportRequest = (verId: string, payload: CCMImportPayload): Promise<CCMImportResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_CCM_IMPORT(verId), payload);

export const postProjectModuleOutcomesRegen = (
  verId: string,
  payload: ProjectVersionOutcomesPayload
): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(
    ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_PROJECT_MODULE_OUTCOMES_REGEN(verId),
    payload
  );

export const postStructureItemsRegen = (
  verId: string,
  payload: ProjectVersionOutcomesPayload
): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_STRUCTURE_ITEMS_REGEN(verId), payload);

type ExportResponse = {
  request_id: number;
};

export const getScormExportRequest = (verId: string): Promise<ExportResponse> =>
  api(ApiModules.OUTLINE_BUILDER).get(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_SCORM(verId));

export const getCommonCartridgeRequest = (verId: string): Promise<ExportResponse> =>
  api(ApiModules.OUTLINE_BUILDER).get(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_COMMON_CARTRIDGE(verId));

export type VersionExportPayload = {
  type: string;
  project_version: number;
  note?: string;
  timezone: string;
};

export const postVersionExport = (payload: VersionExportPayload) =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION_EXPORT, payload);
