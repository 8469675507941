import { useQuery } from '@apollo/client';
import { BaseListOption } from 'shared/types/entities';
import { baseListToSelect } from 'shared/utils';

import { localStorageService } from '../../services/localStorageService';
import { HELP_ROLES } from '../schemas/helpRoles';

type Node = {
  node: BaseListOption;
};

type Data = {
  outline_builder_help_roles: {
    edges: Node[];
  };
};

const useHelpRoles = () => {
  const language = localStorageService.getLangData();
  const { data, loading } = useQuery<Data>(HELP_ROLES, {
    variables: {
      language,
    },
  });

  const arr = data?.outline_builder_help_roles.edges.map((el) => el.node);
  const sorted = arr?.sort((a, b) => a?.order - b?.order);

  return {
    loading,
    helpRoles: arr,
    helpRolesForSelect: baseListToSelect(sorted),
  };
};

export default useHelpRoles;
