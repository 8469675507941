import { AsyncStateResponse, getAsyncState } from 'shared/api/requests/async';

import {
  getOutlineBuilderProjectCurriculumRequest,
  ProjectCurriculumResponse,
} from '../api/requests/outline-builder-project';
import { getProjectGenerationStatusRequest } from '../graphql/queries/getProjectGenerationStatus';

export const checkAsyncState = async (
  id?: number,
  callback?: () => void,
  rejectCallback?: () => void,
  callbackIteration?: () => void
) => {
  const response: AsyncStateResponse = await getAsyncState(id);

  if (response.state === 'in_progress' || response.state === 'new') {
    callbackIteration?.();
    setTimeout(() => checkAsyncState(id, callback, rejectCallback, callbackIteration), 2000);
  }

  if (response.state === 'done' && response.next_item) {
    callbackIteration?.();
    setTimeout(() => checkAsyncState(response.next_item, callback, rejectCallback, callbackIteration), 2000);
  }

  if (response.state === 'done' && !response.next_item) {
    callback?.();
  }

  if (response.state === 'rejected') {
    rejectCallback?.();
  }
};

export const checkParsingFile = async (id: number, callback?: () => void, rejectCallback?: () => void) => {
  const response: ProjectCurriculumResponse = await getOutlineBuilderProjectCurriculumRequest(id.toString());

  if (response.state === 'new' || response.state === 'parsing') {
    setTimeout(() => checkParsingFile(id, callback, rejectCallback), 1000);
  }

  if (response.state === 'ready') {
    callback?.();
  }

  if (response.state === 'error') {
    rejectCallback?.();
  }
};

export const checkProjectProcessGenerating = async (
  projectId: string,
  callbackIteration?: () => void,
  callbackFinish?: () => void
) => {
  const response = await getProjectGenerationStatusRequest(projectId);

  if (response.data.outline_builder_project.generation_in_progress) {
    callbackIteration?.();
    setTimeout(() => checkProjectProcessGenerating(projectId, callbackIteration, callbackFinish), 3000);
  } else {
    callbackFinish?.();
  }
};
