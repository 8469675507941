import React from 'react';

import { cx } from 'shared/utils';
import { ExportState } from 'shared/types/entities';

import './styles.scss';

type Props<T> = {
  status: T;
  text: string;
};

const StatusLabel = ({ status, text }: Props<ExportState>) => {
  const classNames = cx('status-label', {
    [`status-label--${status}`]: status,
  });
  return <div className={classNames}>{text}</div>;
};

export default StatusLabel;
