import { gql } from '@apollo/client';

export const HELP_WORK_TYPES = gql`
  query HelpWorkTypes($language: String) {
    outline_builder_help_work_types(language: $language) {
      edges {
        node {
          id
          pk
          name
          order
        }
      }
    }
  }
`;
