import api, { ApiModules } from 'shared/api';
import { ENDPOINTS } from 'shared/api/constants';
import { VideoAvatarPosition } from 'entities/studio/model/types';

type VideoAvatarPositionPayload = {
  avatar_position_type: VideoAvatarPosition;
};

export const putVideoAvatarPositionRequest = (strItemId: string, payload: VideoAvatarPositionPayload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.STRUCTURE_ITEM_VIDEO_AVATAR_POSITION(strItemId), payload);

export type VideoAvatarSettingsPayload = {
  avatar_id?: number;
  avatar_voice_id?: number;
  avatar_language_id?: number;
};

export const putVideoAvatarSettingsRequest = (strItemId: string, payload: VideoAvatarSettingsPayload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.STRUCTURE_ITEM_VIDEO_AVATAR_SETTINGS(strItemId), payload);
