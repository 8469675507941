import React from 'react';

import SettingsPageForm from 'modules/settings-page-form';

const CourseSettingsPage: React.FC = () => {
  return (
    <div className="container-center container-small">
      <SettingsPageForm />
    </div>
  );
};

export default CourseSettingsPage;
