import React from 'react';

import Table from 'shared/ui/table';
import useDCProjectVersions, { DCProjectVersion } from 'shared/graphql/queries/useDCProjectVersions';
import { Link, useParams } from 'react-router-dom';
import { dateFormat } from 'shared/utils';
import { ExportState, ExportStateNameMap } from 'shared/types/entities';
import { useSubscribe } from 'shared/hooks/useSubscribe';
import { ColumnsType } from 'antd/es/table';
import { platformNameByTypeMap } from 'features/export/utils';
import { localStorageService } from 'shared/services/localStorageService';
import StatusLabel from 'shared/components/status-label';
import { FileZipOutlined, QuestionOutlined } from '@ant-design/icons';
import IconCourseFactory from 'shared/ui/icons/cf/IconCourseFactory';
import IconMoodle from 'shared/ui/icons/moodle/IconMoodle';
import IconCanvas from 'shared/ui/icons/canvas/IconCanvas';
import { useBoolean } from 'usehooks-ts';
import LinearIndeterminateProgress from 'shared/ui/linear-indeterminate-progress';
import IconIrAcademy from 'shared/ui/icons/irAcademy/IconIRacademy';

import { ExportType } from '../../types';

const workplaceUrl = process.env.REACT_APP_WORKPLACE_URL;

const getSystemIcon = (type: ExportType) => {
  switch (type) {
    case ExportType.ccm:
      return <IconCourseFactory size={14} />;
    case ExportType.moodle:
      return <IconMoodle size={14} />;
    case ExportType.canvas:
      return <IconCanvas size={14} />;
    case ExportType.scorm:
      return <FileZipOutlined style={{ fontSize: 14 }} />;
    case ExportType.iracademy:
      return <IconIrAcademy size={14} />;
    default:
      return <QuestionOutlined style={{ fontSize: 14 }} />;
  }
};

export const HistoryTable = () => {
  const { id, lang } = useParams();
  const { value, setValue } = useBoolean(false);
  const { loading, data, totalCount, refetch } = useDCProjectVersions({ projectId: id });

  const access = localStorageService.getAuthData()?.access;
  const refresh = localStorageService.getAuthData()?.refresh;

  const checkStatus = async () => {
    setValue(true);
    await refetch().then((response) => {
      const isProgress = response.data.dc_project_versions.edges?.some(
        (el) => el.node.state === ExportState.new || el.node.state === ExportState.inProgress
      );

      if (isProgress) {
        setTimeout(() => checkStatus(), 1000);
      } else {
        setValue(false);
      }
    });
  };

  useSubscribe('export-table', () => {
    void checkStatus();
  });

  const columns: ColumnsType<DCProjectVersion> = [
    {
      key: 'format',
      title: 'Format',
      width: '20%',
      render: (record: DCProjectVersion) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {getSystemIcon(record.dc_project.connector.connector_name)}
            {platformNameByTypeMap[record.dc_project.connector.connector_name]}
          </div>
        );
      },
    },
    {
      key: 'note',
      title: 'Export Note',
      width: '20%',
      render: (record: DCProjectVersion) => record.note,
    },
    {
      key: 'version',
      title: 'Course Version',
      width: '15%',
      render: (record: DCProjectVersion) => dateFormat(record.cob_project_version.created_at, 'YYYY-MM-DD HH:mm', lang),
    },
    {
      key: 'lang',
      title: 'Language',
      width: '10%',
      render: (record: DCProjectVersion) => record.cob_project_version.language.name,
    },
    {
      key: 'link',
      title: 'Link',
      width: '15%',
      render: (record: DCProjectVersion) => {
        if (record.state !== ExportState.done) {
          return null;
        }

        if (record.dc_project.connector.connector_name === ExportType.ccm) {
          const baseUrl = `https://${workplaceUrl}/`;
          const path = `${baseUrl}project/${record.dc_project.ccm_project_id}/content?a=${access}&r=${refresh}`;
          return (
            <Link style={{ textDecoration: 'underline' }} to={path} target="_blank">
              Open in workplace
            </Link>
          );
        }
        return (
          <Link style={{ textDecoration: 'underline' }} to={record.final_path} download>
            Download
          </Link>
        );
      },
    },
    {
      key: 'state',
      title: 'State',
      align: 'center',
      width: '10%',
      render: (record: DCProjectVersion) => (
        <StatusLabel status={record.state} text={ExportStateNameMap[record.state]} />
      ),
    },
    {
      key: 'date',
      title: 'Export Date',
      align: 'right',
      width: '10%',
      render: (record: DCProjectVersion) => dateFormat(record.created_at, 'YYYY-MM-DD HH:mm', lang),
    },
  ];

  return (
    <>
      <LinearIndeterminateProgress hasEmpty show={value} />
      <Table columns={columns} loading={loading} dataSource={data} pagination={{ total: totalCount, pageSize: 10 }} />
    </>
  );
};

export default HistoryTable;
