import React, { ChangeEvent, memo } from 'react';

import TextArea from 'shared/ui/textArea';
import Badge from 'shared/ui/badge';

import './styles.scss';

type Props = {
  isParent?: boolean;
  autoFocus?: boolean;
  letterIndex?: string;
  color?: string;
  text: string;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
};

const LearningOutcomeItemView: React.FC<Props> = ({
  isParent,
  autoFocus,
  letterIndex,
  color,
  text,
  disabled,
  onChange,
  onBlur,
}) => {
  return (
    <div className="lo-item__inner">
      {isParent ? (
        <span style={{ color: color }} className="lo-item__tag">
          {letterIndex}
        </span>
      ) : (
        <Badge color={color} text={letterIndex} />
      )}

      <TextArea
        autoFocus={autoFocus}
        className="lo-item__name"
        size="small"
        bordered={false}
        autoSize={{ minRows: 1, maxRows: 50 }}
        value={text}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  );
};

export default memo(LearningOutcomeItemView);
