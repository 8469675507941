import React from 'react';

import { createReactEditorJS } from 'react-editor-js';
import { API, OutputData } from '@editorjs/editorjs';
import { EditorCore } from '@react-editor-js/core/src/editor-core';
import { BlockMutationEvent } from '@editorjs/editorjs/types/events/block';

import './styles.scss';

const ReactEditorJS = createReactEditorJS();

type Props = {
  disabled?: boolean;
  data?: OutputData;
  onInitialize?: (core: EditorCore) => void;
  onChange?(api: API, event: BlockMutationEvent | BlockMutationEvent[]): void;
};

const Editor: React.FC<Props> = ({ disabled, data, onInitialize, onChange }) => {
  return (
    <div className="ccm-editor">
      <ReactEditorJS readOnly={disabled} defaultValue={data} onInitialize={onInitialize} onChange={onChange} />
    </div>
  );
};

export default Editor;
