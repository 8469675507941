export enum AsyncState {
  new = 'new',
  inProgress = 'in_progress',
  done = 'done',
  rejected = 'rejected',
  failed = 'failed',
}

export enum ExportState {
  new = 'new',
  inProgress = 'in_progress',
  done = 'done',
  cancelled = 'cancelled',
  failed = 'failed',
}

export const ExportStateNameMap = {
  new: 'New',
  in_progress: 'In progress',
  done: 'Done',
  cancelled: 'Cancelled',
  failed: 'Failed',
};

export enum ProjectGenerateState {
  new = 'NEW',
  inProgress = 'IN_PROGRESS',
  done = 'DONE',
  rejected = 'REJECTED',
}

export enum VideoGenerateState {
  new = 'new',
  inProgress = 'in_progress',
  videoGenerated = 'video_generated',
  generationError = 'generation_error',
  succeeded = 'succeeded',
  failed = 'failed',
}

export enum UploadStateEnum {
  NEW = 'new',
  CANCELLED = 'cancelled',
  UPLOADED = 'uploaded',
  UPLOADING = 'uploading',
}
