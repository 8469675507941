import React, { ChangeEvent, useCallback, useState } from 'react';

import Input from 'shared/ui/input';
import { StructureItem } from 'shared/types/entities';
import CourseStructureIndexWithState from 'shared/components/course-structure-index-with-state/CourseStructureIndexWithState';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { setAddLesson } from '../store';
import { createStructureItemAction } from '../store/actions';

import './styles.scss';

type Props = {
  item: StructureItem;
};

const AddStructureLesson: React.FC<Props> = ({ item }) => {
  const [text, setText] = useState('');

  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state.structureItem);
  const projectVerId = useAppSelector((state) => state.project.project?.active_project_version?.id);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  }, []);

  const onBlur = () => {
    if (!text) {
      dispatch(setAddLesson(null));
    } else {
      if (projectVerId) {
        const payload = {
          title: text,
          course_structure_item_parent_id: Number(item.id),
        };
        dispatch(createStructureItemAction(payload)).finally(() => {
          dispatch(setAddLesson(null));
          setText('');
        });
      }
    }
  };

  if (!store.addLesson || item.id !== store.addLesson) {
    return null;
  }
  return (
    <div className="cs-collapse-add-lesson">
      <div className="cs-collapse-head__info">
        <CourseStructureIndexWithState isEdited={false} index={`${item.order}.${item.children.length + 1}`} />
      </div>
      <Input autoFocus bordered={false} disabled={store.loading} value={text} onBlur={onBlur} onChange={handleChange} />
    </div>
  );
};

export default AddStructureLesson;
