import { gql } from '@apollo/client';

export const VIDEO_PROVIDERS = gql`
  query VideoProviders {
    user_video_provider_usages {
      id
      pk
      video_provider {
        id
        pk
        is_default
        is_public
        name
        system_key
      }
    }
  }
`;
