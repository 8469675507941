import React, { memo } from 'react';

import { Trans } from 'react-i18next';

import { cx } from '../../utils';

import './styles.scss';

type WordCounterViewProps = {
  className?: string;
  words?: number | string;
  charsWithSpace?: number | string;
  charsWithoutSpace?: number | string;
};

const WordCounterView = ({ className, words = 0, charsWithSpace = 0, charsWithoutSpace = 0 }: WordCounterViewProps) => {
  const classNames = cx('word-counter-tag', className);
  return (
    <div className={classNames}>
      <span className="word-counter-tag__text">
        <Trans
          i18nKey="wordCount"
          default={`Слов: ${words}. Символов: ${charsWithSpace} символов с пробелами, ${charsWithoutSpace} символов без пробелов.`}
          values={{ words, charsWithSpace, charsWithoutSpace }}
        />
      </span>
    </div>
  );
};

export default memo(WordCounterView);
