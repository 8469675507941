import { gql } from '@apollo/client';

export const INDUSTRIES = gql`
  query Industries($language: String) {
    industries(language: $language) {
      edges {
        node {
          id
          pk
          name
          order
        }
      }
    }
  }
`;
