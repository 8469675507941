import type { AppDispatch } from 'shared/store/store';
import { store } from 'shared/store/store';
import { getStudioFileAction } from 'entities/studio';
import { message } from 'antd';
import { messageError, messageSave } from 'shared/utils';

import {
  ChangeDocumentSectionOrderPayload,
  changeDocumentSectionOrderRequest,
  DocumentSectionPayload,
  changeDocumentSectionRequest,
  deleteDocumentSectionRequest,
  addSectionRequest,
} from '../api';

export const changeParagraphOrderAction = (
  fileId: string,
  sectionId: string,
  payload: ChangeDocumentSectionOrderPayload
) => {
  return async (dispatch: AppDispatch) => {
    const structureItemId = store.getState().assignment.assignment?.id;
    messageSave();

    await changeDocumentSectionOrderRequest(fileId, sectionId, payload)
      .catch((err) => messageError(err))
      .finally(() => {
        if (structureItemId) {
          void dispatch(getStudioFileAction(structureItemId));
          message.destroy();
        }
      });
  };
};

export const deleteParagraphAction = (fileId: string, sectionId: string) => {
  return async (dispatch: AppDispatch) => {
    const structureItemId = store.getState().assignment.assignment?.id;
    messageSave();

    await deleteDocumentSectionRequest(fileId, sectionId)
      .catch((err) => messageError(err))
      .finally(() => {
        if (structureItemId) {
          void dispatch(getStudioFileAction(structureItemId));
          message.destroy();
        }
      });
  };
};

export const changeParagraphAction = (fileId: string, sectionId: string, payload: DocumentSectionPayload) => {
  return async (dispatch: AppDispatch) => {
    const structureItemId = store.getState().assignment.assignment?.id;
    messageSave();

    await changeDocumentSectionRequest(fileId, sectionId, payload)
      .catch((err) => messageError(err))
      .finally(() => {
        if (structureItemId) {
          void dispatch(getStudioFileAction(structureItemId));
          message.destroy();
        }
      });
  };
};

export const addParagraphAction = (fileId: string, payload: DocumentSectionPayload) => {
  return async (dispatch: AppDispatch) => {
    const structureItemId = store.getState().assignment.assignment?.id;
    messageSave();

    await addSectionRequest(fileId, payload)
      .catch((err) => messageError(err))
      .finally(() => {
        if (structureItemId) {
          void dispatch(getStudioFileAction(structureItemId));
          message.destroy();
        }
      });
  };
};
