import {
  postStructureItemCreateVideo,
  postStructureItemGenerateVideoPointsRequest,
  postStructureItemGenerateVideoScriptRequest,
  postStructureItemRegenerateVideoScriptRequest,
  VideoSettingsPayload,
} from 'shared/api/requests/outline-builder-project-structure';
import { AppDispatch, store } from 'shared/store/store';
import { messageError } from 'shared/utils';
import { setProjectGeneration } from 'modules/project';
import { message } from 'antd';
import { getStructureItem } from 'shared/graphql/queries/getStructureItem';
import { getAssignmentItemAction, setAssignment } from 'entities/assignment/model';

export const generateVideoPointsAction = (structureItemId: string, payload: VideoSettingsPayload) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setProjectGeneration(true));

    await postStructureItemGenerateVideoPointsRequest(structureItemId, payload).catch((err) => {
      dispatch(setProjectGeneration(false));
      message.destroy();
      messageError(err);
    });
  };
};

export const generateVideoScriptAction = (structureItemId: string, text?: string, isRen?: boolean) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setProjectGeneration(true));

    await postStructureItemGenerateVideoScriptRequest(structureItemId).catch((err) => {
      dispatch(setProjectGeneration(false));
      message.destroy();
      messageError(err);
    });
  };
};

export const checkStatusGenVideo = (structureItemId: string) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment.assignment;
    const response = await getStructureItem(structureItemId);
    const status =
      response?.data.outline_builder_course_structure_item.assignment?.video?.script?.generated_videos?.[0]?.state;

    if (status === 'in_progress' && assign?.id === structureItemId) {
      setTimeout(() => dispatch(checkStatusGenVideo(structureItemId)), 15000);
    }

    if (status === 'succeeded' && assign?.id === structureItemId) {
      dispatch(setAssignment(response.data.outline_builder_course_structure_item));
    }
  };
};

export const createVideoAction = (structureItemId: string) => {
  return async (dispatch: AppDispatch) => {
    await postStructureItemCreateVideo(structureItemId)
      .then((response) => {
        void dispatch(getAssignmentItemAction(structureItemId));
      })
      .catch((err) => {
        message.destroy();
        messageError(err);
      });
  };
};
