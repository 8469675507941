import React, { memo } from 'react';

import BlockTitle from 'shared/ui/block-title';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import RegenerateEntityBlock from 'entities/regenerate-entity/ui/regenerate-block';
import { regenerationStructureItemsAction } from 'widgets/course-structure/store/actions';
import { useParams } from 'react-router-dom';

import LearningOutcomesStructureViewItem from './ui/learning-outcomes-structure-view-item';

import './styles.scss';
import { useOwner } from '../../shared/hooks/useProjectOwner';

const LearningOutcomesStructureView: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.project);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const generation = useAppSelector((state) => state.project.generation);
  const projectVerId = useAppSelector((state) => state.project?.project?.active_project_version?.id);
  const { t } = useTranslation();
  const layout = useAppSelector((s) => s.app.layout);
  const hide = layout === 'print' || !layout;

  const { isOwner } = useOwner();

  const onRegenAllStructure = (text: string) => {
    if (id && projectVerId) {
      void dispatch(regenerationStructureItemsAction(text));
    }
  };

  const modules = selectedVersion
    ? selectedVersion.active_modules_outcomes
    : project?.active_project_version?.active_modules_outcomes;

  if (hide) {
    return null;
  }

  return (
    <div className="lo-structure-view">
      <RegenerateEntityBlock
        title={t<string>('regenEntityBlock.structure.title')}
        buttonText={t<string>('regenEntityBlock.structure.button')}
        disabled={!isOwner || generation || !!selectedVersion}
        action={onRegenAllStructure}
      />
      <BlockTitle className="lo-structure-view__title" text={t('structure.courseLO')} />
      <div className="lo-structure-view__content">
        {modules?.map((module) => {
          return (
            <div key={module.id} style={{ borderColor: module.color }} className="lo-structure-view__group">
              <LearningOutcomesStructureViewItem
                isParent
                tag={module.letter_index}
                text={module.name}
                color={module.color}
              />
              {module?.active_sub_modules_outcomes?.map((sub) => {
                return (
                  <LearningOutcomesStructureViewItem
                    key={sub.id}
                    tag={sub.letter_index}
                    text={sub.name}
                    color={module.color}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(LearningOutcomesStructureView);
