import React, { ChangeEvent, memo, ReactNode } from 'react';

import { isEmpty } from 'lodash';
import { StructureItemSubModule } from 'shared/types/entities';
import { useTranslation } from 'react-i18next';
import Badge from 'shared/ui/badge';
import TextArea from 'shared/ui/textArea';
import { cx } from 'shared/utils';

import { getIconByUrl } from 'shared/utils/getIcon';

import CourseStructureIndexWithState from '../course-structure-index-with-state/CourseStructureIndexWithState';

import './styles.scss';

type Props = {
  hideActions?: boolean;
  isEdited?: boolean;
  autoFocus?: boolean;
  className?: string;
  index: string;
  icon?: string;
  title: string;
  description: string;
  disabled: boolean;
  subModules?: StructureItemSubModule[];
  actions?: ReactNode;
  onBlur: (event: any) => void;
  onFocus?: (event: any) => void;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};

const CourseStructureElementView: React.FC<Props> = ({
  className,
  hideActions,
  isEdited,
  autoFocus,
  index,
  icon,
  title,
  description,
  disabled,
  subModules,
  actions,
  onBlur,
  onFocus,
  onChange,
}) => {
  const { t } = useTranslation();
  const classNames = cx('cs-element-view', className);
  return (
    <div className={classNames}>
      <div className="cs-element-view__inner">
        <div className="cs-element-view__info">
          <CourseStructureIndexWithState isEdited={hideActions ? false : isEdited} index={index} />
        </div>
        <div className="cs-element-view__icon">{getIconByUrl(icon)}</div>
        <div className="cs-element-view__content">
          <TextArea
            autoFocus={autoFocus}
            name="title"
            bordered={false}
            placeholder={t<string>('structure.addTitle')}
            className="cs-element-view__title mb-2"
            autoSize={{ minRows: 1, maxRows: 20 }}
            value={title}
            disabled={disabled}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
          />
          <TextArea
            name="description"
            bordered={false}
            placeholder={t<string>('structure.addDescription')}
            className="cs-element-view__description"
            autoSize={{ minRows: 1, maxRows: 20 }}
            value={description}
            disabled={disabled}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
          />
        </div>

        {!isEmpty(subModules) ? (
          <div className="cs-element-view__goals">
            {subModules?.map((s, idx) => (
              <Badge key={idx} text={s.letter_index} color={s.outline_builder_project_module_outcome.color} />
            ))}
          </div>
        ) : null}

        {actions && !disabled ? <div className="cs-element-view__actions">{actions}</div> : null}
      </div>
    </div>
  );
};

export default memo(CourseStructureElementView);
