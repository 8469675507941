import React, { useCallback } from 'react';

import { DownOutlined, FilePdfOutlined, FileZipOutlined, FormOutlined, QuestionOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from 'shared/ui/button/Button';
import IconCourseFactory from 'shared/ui/icons/cf/IconCourseFactory';
import { CompanyTypeEnum, MenuInfo } from 'shared/types/entities';
import IconCanvas from 'shared/ui/icons/canvas/IconCanvas';
import IconMoodle from 'shared/ui/icons/moodle/IconMoodle';
import IconEdx from 'shared/ui/icons/edx/IconEdx';
import { useAppSelector } from 'shared/store/hooks';
import { generatePath } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { PATH_NAME } from 'pages/constants';
import { useUserDetails } from 'app/hooks/useUserDetails';
import modalService from 'shared/services/modalService';
import { ExportType } from 'entities/export';

import { platformNameByTypeMap } from '../../utils';

import './styles.scss';

const SelectButton = () => {
  const { t } = useTranslation();
  const { isExecutiveManager, companyType } = useUserDetails();
  const projectGen = useAppSelector((s) => s.project.generation);
  const project = useAppSelector((s) => s.project?.project);
  const structureStore = useAppSelector((s) => s.structure);

  const items: MenuProps['items'] = [
    {
      key: 'pdf',
      label: 'Pdf',
      icon: <FilePdfOutlined style={{ fontSize: 14 }} />,
    },
    {
      key: 'csv',
      label: 'CourseFactory Workplace',
      icon: (
        <span>
          <IconCourseFactory size={14} />
        </span>
      ),
      disabled: !isExecutiveManager && companyType !== CompanyTypeEnum.opm,
    },
    {
      key: 'scorm',
      label: 'SCORM',
      icon: <FileZipOutlined style={{ fontSize: 14 }} />,
    },
    {
      key: 'quizzes',
      label: t('exportButton.quizzes'),
      icon: <QuestionOutlined style={{ fontSize: 14 }} />,
    },
    {
      key: 'lms',
      type: 'group',
      label: 'LMS',
      children: [
        {
          key: 'canvas',
          label: 'Canvas',
          icon: (
            <span>
              <IconCanvas size={14} />
            </span>
          ),
        },
        {
          key: 'moodle',
          label: 'Moodle',
          icon: (
            <span>
              <IconMoodle size={14} />
            </span>
          ),
        },
        {
          key: 'edx',
          label: 'Open EDX',
          disabled: true,
          icon: (
            <span>
              <IconEdx size={14} />
            </span>
          ),
        },
        {
          key: 'other',
          label: 'Add Your LMS',
          icon: <FormOutlined style={{ fontSize: 14 }} />,
        },
      ],
    },
  ];

  const onClickItem: MenuProps['onClick'] = useCallback(
    (info: MenuInfo) => {
      if (info.key === 'pdf' && project?.id) {
        const path = generatePath(PATH_NAME.courseStructurePrint, {
          id: project?.id,
          lang: project.active_project_version?.language.code,
        });
        const finalPath = `${window.location.origin}/${path}`;
        mixpanel.track('ExportPdf', { ProjectId: `${project.id}` });
        window.open(finalPath, '_blank');
      }

      if (info.key === 'csv') {
        modalService.openExportModal({
          type: ExportType.ccm,
          platform: platformNameByTypeMap[ExportType.ccm],
        });
      }

      if (info.key === 'scorm') {
        modalService.openExportModal({
          type: ExportType.scorm,
          platform: platformNameByTypeMap[ExportType.scorm],
        });
      }

      if (info.key === 'quizzes' && project?.id) {
        const path = generatePath(PATH_NAME.courseQuizzesPrint, {
          id: project?.id,
          lang: project?.active_project_version?.language.code,
        });
        const finalPath = `${window.location.origin}/${path}`;
        mixpanel.track('ExportQuizzes', { ProjectId: `${project.id}` });
        window.open(finalPath, '_blank');
      }

      if (info.key === 'canvas') {
        modalService.openExportModal({
          type: ExportType.canvas,
          platform: platformNameByTypeMap[ExportType.canvas],
        });
      }
      //
      if (info.key === 'moodle') {
        modalService.openExportModal({
          type: ExportType.moodle,
          platform: platformNameByTypeMap[ExportType.moodle],
        });
      }

      if (info.key === 'other') {
        window.open('https://855ii67p1l2.typeform.com/to/pS2RK7lZ', '_blank');
      }
    },
    [project?.active_project_version?.language.code, project?.id]
  );

  const disabled = structureStore.loading || !project?.has_submodules || projectGen;

  return (
    <Dropdown
      disabled={disabled}
      overlayClassName="export-menu"
      trigger={['click']}
      placement="bottomRight"
      menu={{ items, onClick: onClickItem }}
    >
      <Button className="tour--export" disabled={disabled} type="primary">
        <>
          <span className="mr-1">{t('exportButton.text')}</span>
          <DownOutlined />
        </>
      </Button>
    </Dropdown>
  );
};

export default SelectButton;

// const downloadCSV = useCallback(async () => {
//   if (project?.active_project_version?.id) {
//     await getOutlineBuilderProjectVersionCSVRequest(project?.active_project_version?.id)
//       .then((response: any) => {
//         const data = new Blob([response]);
//         const a = document.createElement('a');
//         a.setAttribute('style', 'display: none');
//         a.setAttribute('download', `${project.active_project_version?.generated_title}.csv`);
//         a.setAttribute('target', '_blank');
//         a.href = URL.createObjectURL(data);
//         document.body.appendChild(a);
//         a.click();
//       })
//       .catch((error) => console.log(error));
//   }
// }, [project?.active_project_version?.generated_title, project?.active_project_version?.id]);
