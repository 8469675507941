import React, { memo } from 'react';

import Button from 'shared/ui/button';
import { useTranslation } from 'react-i18next';

import './styles.scss';

type Props = {
  onClick: () => void;
};

const FinallyModalContent: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="cb-modal-finally-content">
      <span className="cb-modal-finally-content__title">{t('modal.finallyContent.title')}</span>
      <span className="cb-modal-finally-content__text">{t('modal.finallyContent.text')}</span>
      <Button size="large" className="cb-modal-finally-content__close" type="primary" onClick={onClick}>
        OK
      </Button>
    </div>
  );
};

export default memo(FinallyModalContent);
