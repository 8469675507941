import { gql } from '@apollo/client';

export const VIDEO_PROVIDER_AVATAR_LANGUAGES = gql`
  query VideoProviderAvatarLanguages($videoProvider: String) {
    cob_video_provider_avatar_languages(video_provider: $videoProvider) {
      id
      name
    }
  }
`;

export const VIDEO_PROVIDER_AVATAR_VOICES = gql`
  query VideoProviderAvatarVoices($videoProvider: String, $avatarLanguageId: Int) {
    cob_video_provider_avatar_voices(video_provider: $videoProvider, avatar_language_id: $avatarLanguageId) {
      id
      name
    }
  }
`;

export const VIDEO_PROVIDER_AVATARS = gql`
  query VideoProviderAvatars($videoProvider: String) {
    cob_video_provider_avatars(video_provider: $videoProvider) {
      id
      name
    }
  }
`;
