import React, { useCallback } from 'react';

import CreateCourseUpload from 'modules/create-course-upload';
import { Divider, message } from 'antd';
import CreateCourseSettings from 'modules/create-course-settings';
import Button from 'shared/ui/button';
import IconSparking from 'shared/ui/icons/sparking';
import CurriculumForm from 'modules/curriculum-form';
import { setProject } from 'modules/project';
import BlockTitle from 'shared/ui/block-title/BlockTitle';
import { ProjectGenerateState } from 'shared/types/entities';
import mixpanel from 'mixpanel-browser';
import ModalContainer from 'shared/components/modal/container';
import ModalHeader from 'shared/components/modal/header';
import ModalContent from 'shared/components/modal/content';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { continueAutogenAction } from './store/actions';
import { CreateCourseError, resetCreateCourse, setError } from './store';
import ErrorBlock from './error';

import './styles.scss';

type Props = {
  onCancel: () => void;
};

const CreateCourse: React.FC<Props> = ({ onCancel }) => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.project);

  const onClose = useCallback(async () => {
    dispatch(setProject(null));
    dispatch(resetCreateCourse());
    onCancel();
  }, [dispatch, onCancel]);

  const onContinueGen = useCallback(() => {
    if (
      project?.generation_status?.current_step === 1 &&
      project?.generation_status?.state === ProjectGenerateState.done &&
      project?.id
    ) {
      mixpanel.track('ProjectCreateCourse', { ProjectId: `${project?.id}` });
      void dispatch(continueAutogenAction(project?.id));
      void onClose();
      void message.info('When the course is ready, we will email you the link', 5);
    }
    if (!project?.active_project_version?.curriculum?.file.id) {
      dispatch(setError(CreateCourseError.file));
    }
    if (
      project?.generation_status?.current_step === 1 &&
      project?.generation_status?.state === ProjectGenerateState.rejected
    ) {
      const target = document.getElementById('generation');
      if (target) {
        target.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }, [dispatch, onClose, project]);

  return (
    <ModalContainer>
      <ModalHeader
        title={project?.active_project_version?.generated_title || 'Create online course outline'}
        onClose={onClose}
      />
      <ModalContent>
        <BlockTitle text="Syllabus" type="h5" />
        <ErrorBlock />
        <CreateCourseUpload />
        <CurriculumForm />
        <Divider className="create-course-divider" />
        <CreateCourseSettings />
        <div className="create-course-action">
          <Button size="large" exSize="xxl" type="primary" onClick={onContinueGen}>
            <span className="mr-2">generate course</span>
            <IconSparking />
          </Button>
        </div>
      </ModalContent>
    </ModalContainer>
  );
};

export default CreateCourse;
