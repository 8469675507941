import React, { useEffect, useMemo } from 'react';

import ModalContainer from 'shared/components/modal/container';
import ModalHeader from 'shared/components/modal/header/ModalHeader';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import ModalContent from 'shared/components/modal/content';
import FormItem from 'shared/ui/form-item';
import Select from 'shared/ui/select';
import TextArea from 'shared/ui/textArea/TextArea';
import Button from 'shared/ui/button';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'shared/store/hooks';
import useProjectVersions from 'shared/graphql/queries/useProjectVersions';
import { dateFormat } from 'shared/utils';
import { postVersionExport } from 'shared/api/requests/outline-builder-project-version';
import { usePublish } from 'shared/hooks/useSubscribe';
import { ExportType } from 'entities/export';

import './styles.scss';

export type ParamsModalProps = {
  type: ExportType;
  platform: string;
  onCancel?: () => void;
};

const ParamsModal = ({ type, platform, onCancel }: ParamsModalProps) => {
  const { lang } = useParams();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const projectId = useAppSelector((s) => s.project.project?.id);
  const languages = useAppSelector((s) => s.project.project?.languages);
  const currentLang = useAppSelector((s) => s.project.project?.active_project_version?.language);
  const projectActiveVerId = useAppSelector((s) => s.project.project?.active_project_version?.id);

  const publish = usePublish();

  const language = Form.useWatch('language', form);
  const version = Form.useWatch('version', form);

  const {
    data: verList,
    loading,
    refetch,
  } = useProjectVersions({ id: projectId, lang: language || currentLang?.code });

  useEffect(() => {
    void refetch();
  }, []);

  const languagesOptions = useMemo(() => {
    return languages?.map((l) => {
      return {
        id: l.code,
        label: l.name,
        value: l.code,
      };
    });
  }, [languages]);

  const versionOptions = useMemo(() => {
    return verList
      ?.sort((a, b) => dayjs(b.created_at).unix() - dayjs(a.created_at).unix())
      .map((v) => {
        const current = `${dateFormat(v.created_at, 'YYYY-MM-DD HH:mm', lang)} ${v.pk === version ? '(current)' : ''}`;
        const active = `${dateFormat(v.created_at, 'YYYY-MM-DD HH:mm', lang)} ${
          v.pk === projectActiveVerId ? '(active)' : ''
        }`;

        return {
          id: v.pk,
          label: v.pk === projectActiveVerId ? active : current,
          value: v.pk,
        };
      });
  }, [lang, projectActiveVerId, verList, version]);

  const defaultValues = useMemo(() => {
    return {
      language: currentLang?.code,
      version: null,
      note: '',
    };
  }, [currentLang?.code]);

  // Init form values
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [defaultValues, form]);

  const handleFormChange = (changedFields: any) => {
    if (changedFields[0].name[0] === 'language') {
      form.setFieldValue('version', null);
    }
  };

  const onSubmit = async () => {
    const values = form.getFieldsValue();

    const payload = {
      type,
      project_version: Number(values.version),
      note: values.note,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    await postVersionExport(payload).finally(() => {
      publish('export-table');
      onCancel?.();
    });
  };

  return (
    <ModalContainer>
      <ModalHeader type="h4" title={t<string>('exportModal.title', { platform: platform })} onClose={onCancel} />
      <ModalContent className="modal-export">
        <span className="modal-export__sub-title">{t('exportModal.subTitle')}</span>
        <Form form={form} layout="vertical" onFieldsChange={handleFormChange}>
          <FormItem name="language" label={t<string>('exportModal.lang.label')}>
            <Select placeholder={t<string>('exportModal.lang.placeholder')} size="large" options={languagesOptions} />
          </FormItem>
          <FormItem name="version" label={t<string>('exportModal.version.label')}>
            <Select
              size="large"
              placeholder={t<string>('exportModal.version.placeholder')}
              loading={loading}
              options={versionOptions}
            />
          </FormItem>
          <FormItem name="note" className="mb-12" label={t<string>('exportModal.note.label')}>
            <TextArea
              placeholder={t<string>('exportModal.note.placeholder')}
              size="large"
              autoSize={{ minRows: 2, maxRows: 10 }}
            />
          </FormItem>
          <div className="modal-export__footer">
            <Button size="large" type="primary" disabled={!version} onClick={onSubmit}>
              {t<string>('exportModal.submit')}
            </Button>
          </div>
        </Form>
      </ModalContent>
    </ModalContainer>
  );
};

export default ParamsModal;
