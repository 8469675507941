import React, { useCallback } from 'react';

import Button from 'shared/ui/button';
import IconGoogleLogo from 'shared/ui/icons/google-logo';
import { DEV_AMAZON_API_URL } from 'shared/config/urls';
import { cx } from 'shared/utils';
import { useSearchParams } from 'react-router-dom';

import './styles.scss';

type Props = {
  className?: string;
  fetching?: boolean;
  onStartFetching?: () => void;
};

const getBaseUrl = () => {
  return process.env.NODE_ENV === 'development' ? `${DEV_AMAZON_API_URL}` : `${window.location.origin}`;
};

const GoogleAuthButton: React.FC<Props> = ({ className, fetching, onStartFetching }) => {
  const [searchParams] = useSearchParams();

  const code = searchParams.get('promo');

  const onClick = useCallback(() => {
    onStartFetching?.();
    const origin = window.location.origin;
    if (code) {
      window.open(`${getBaseUrl()}/account/login/google-oauth2/?next=${origin}&promo_code=${code}`, '_self');
    } else {
      window.open(`${getBaseUrl()}/account/login/google-oauth2/?next=${origin}`, '_self');
    }
  }, [code]);

  const classNames = cx('google-auth', className);

  return (
    <Button className={classNames} size="large" exSize="xl" loading={fetching} onClick={onClick}>
      <IconGoogleLogo />
      <span>Continue with Google</span>
    </Button>
  );
};

export default GoogleAuthButton;
