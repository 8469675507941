import React, { FC, useRef } from 'react';

import AssignmentsHead from 'modules/assignments-head';
import StudioContent from 'entities/studio/ui/content';
import { useSubscribe } from 'shared/hooks/useSubscribe';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/store/hooks';
import ViewPendingGenerate from 'shared/ui/viewPendingGeneration/viewPendingGenerate';
import { StudioPlayer } from 'entities/studio/ui/palyer/StudioPlayer';

type Props = {
  onChangeTab: (key: string) => void;
};

export const AssignmentVideoStudio: FC<Props> = () => {
  const container = useRef<HTMLDivElement>(null);
  const projectGenerate = useAppSelector((state) => state.project.generation);
  const { t } = useTranslation();

  const scrollToBottom = () => {
    container.current?.scroll({ top: container.current.scrollHeight, behavior: 'smooth' });
  };

  useSubscribe('studio-scroll--to-bottom', () => scrollToBottom());

  return (
    <div ref={container} className="assign-video-content__wrap assign-video-studio__wrap">
      <div className="assign-video-studio">
        <div className="assignment-select__block assign-video-studio--header">
          <AssignmentsHead />
        </div>
        <div className="assignment-select__block assign-video-studio--content">
          {projectGenerate ? (
            <ViewPendingGenerate
              title={t<string>('assignments.video.scriptPending.text1')}
              text={t<string>('assignments.video.scriptPending.text2')}
            />
          ) : (
            <div className="assign-video-studio__content">
              <StudioContent />
            </div>
          )}
        </div>
        <div className="assignment-select__block assign-video-studio--player">
          <StudioPlayer />
        </div>
      </div>
    </div>
  );
};
