import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import Backend from 'i18next-locize-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { localStorageService } from '../shared/services/localStorageService';

const locizeOptions = {
  //projectId: '50980078-cb48-4113-8b6a-d6eef121d455',
  apiKey: 'd2d978c8-33c4-4bf3-9618-85cbdef20b7a',
  refLng: 'en',
  version: 'latest',
};

const currentLanguage = localStorageService.getLangData();
const options = {
  // fallbackLng: currentLanguage,
  // debug: true,
  lng: currentLanguage, // default language
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // react: {
  //   wait: true,
  //   useSuspense: true,
  // },
  initImmediate: false,
  backend: {
    backends: [Backend, resourcesToBackend((lng: any, ns: any) => import(`../../public/locales/${lng}/${ns}.json`))],
    backendOptions: [locizeOptions],
  },
  // backend: {
  //   requestOptions: {
  //     cache: 'no-store' as RequestCache,
  //   },
  // },
};
i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(ChainedBackend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(options);

export default i18n;
