import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateFileResponse } from 'shared/api/requests/file';

export enum CreateCourseError {
  file = 'file',
  recognise = 'recognise',
  generation = 'generation',
}

interface CreateCourse {
  generating: boolean;
  file: CreateFileResponse | null;
  massive_course: boolean;
  duration: number;
  error: CreateCourseError | null;
}

const initialState: CreateCourse = {
  generating: false,
  file: null,
  massive_course: true,
  duration: 6,
  error: null,
};

export const createCourse = createSlice({
  name: 'createCourse',
  initialState,
  reducers: {
    setGenerating: (state, action: PayloadAction<boolean>) => {
      state.generating = action.payload;
    },
    setFile: (state, action: PayloadAction<CreateFileResponse | null>) => {
      state.file = action.payload;
    },
    setMassiveCourse: (state, action: PayloadAction<boolean>) => {
      state.massive_course = action.payload;
    },
    setDuration: (state, action: PayloadAction<number>) => {
      state.duration = action.payload;
    },
    setError: (state, action: PayloadAction<CreateCourseError | null>) => {
      state.error = action.payload;
    },
    resetCreateCourse: () => initialState,
  },
});

export const { setGenerating, setFile, setMassiveCourse, setDuration, setError, resetCreateCourse } =
  createCourse.actions;
export default createCourse.reducer;
