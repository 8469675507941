import { BaseListOption } from '../types/entities';

export const baseListToSelect = (arr?: BaseListOption[]) => {
  return (
    arr?.map((item) => {
      return {
        label: item.name,
        value: item.pk || item.id,
      };
    }) || []
  );
};

const getYoutubeId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

const getVimeoId = (url: string) => {
  const match = /vimeo.*\/(\d+)/i.exec(url);

  if (match) {
    return match[1];
  }
};

export const convertedUrl = (str?: string): string | null => {
  if (str?.includes('youtu')) {
    return `https://www.youtube.com/embed/${getYoutubeId(str)}`;
  }

  if (str?.includes('vimeo')) {
    return `https://player.vimeo.com/video/${getVimeoId(str)}`;
  }

  return null;
};
