import React, { memo, SyntheticEvent } from 'react';

import { CopyFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import { cx } from 'shared/utils';

type Props = {
  className?: string;
  text?: string;
  tooltipText?: string;
};

const CopyButton: React.FC<Props> = ({ className, text, tooltipText }) => {
  const { t } = useTranslation();

  const onClipboard = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (text) {
      navigator.clipboard.writeText(text);
    }
  };

  const classNames = cx(className);

  return (
    <Tooltip title={tooltipText || t<string>('common.copy')}>
      <Button className={classNames} type="text" icon={<CopyFilled />} onClick={onClipboard} />
    </Tooltip>
  );
};

export default memo(CopyButton);
