export const PATH_NAME = {
  main: '/',
  login: '/login',
  signup: '/signup',
  profile: '/profile',
  billing: '/billing',
  affiliate: '/affiliate',
  pricing: '/pricing',
  changePass: '/profile/change-password',
  courseManual: '/course/:id/:lang/manual',
  courseAuto: '/course/:id/auto',
  coursePublic: '/course/public/:id/:lang',
  courseAutoPrint: '/course/:id/:lang/auto/:entity/print',
  courseManualWithEntity: '/course/:id/:lang/manual/:entity',
  courseAutoWithEntity: '/course/:id/auto/:entity',
  coursePublicWithEntity: '/course/:id/public/:entity',
  courseAutoWithEntityPrint: '/course/:id/auto/:entity/print',
  courseQuizzesPrint: 'course/:id/:lang/print/quizzes',
  courseStructurePrint: 'course/:id/:lang/print/structure',
  courseEntity: {
    settings: 'settings',
    about: 'about',
    learning: 'learning-outcomes',
    structure: 'structure',
    assignments: 'assignments',
    content: 'content',
    export: 'export',
  },
  error: {
    main: '/error',
  },
};
