import { RootState } from 'shared/store/store';
import { createSelector } from '@reduxjs/toolkit';

export const isSelectProjectGenerating = (state: RootState) => state.project.generation;
export const selectProjectVersion = (state: RootState) => state.project.selectedVersion;

export const selectProjectDisabled = createSelector(
  isSelectProjectGenerating,
  selectProjectVersion,
  (generation, version) => generation && !!version
);
