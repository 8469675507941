const fontSize = {
  fontSize12: 12,
  fontSize13: 13,
  fontSize14: 14,
  fontSize16: 16,
  fontSize20: 20,
  fontSize24: 24,
  fontSize32: 32,
  fontSize40: 40,
  fontSize56: 56,
};

export default fontSize;
