import { gql } from '@apollo/client';

export const SUBSCRIPTIONS = gql`
  query Subscriptions {
    outline_builder_subscriptions {
      id
      price
      description
      is_active
      is_default_free_sub
      billing_cycle
      name
      order
      button_name
      url
      type
    }
  }
`;
