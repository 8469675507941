import React from 'react';

import { Outlet } from 'react-router-dom';
import Header from 'widgets/header';
import HeaderNav from 'shared/components/header-nav';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button/Button';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';
import { PATH_NAME } from 'pages/constants';

import { profileNav } from './lib';

import './styles.scss';

const ProfileLayout = () => {
  const { navigate } = useCustomNavigator();

  const onGoMainPage = () => navigate(PATH_NAME.main);

  const backButton = (
    <Button
      className="profile-layout__back-btn"
      shape="circle"
      type="primary"
      icon={<ArrowLeftOutlined style={{ fontSize: 16 }} />}
      onClick={onGoMainPage}
    />
  );

  return (
    <div className="profile-layout">
      <Header onBack={backButton} />
      <div className="profile-layout__wrap">
        <HeaderNav navList={profileNav} />
      </div>

      <Outlet />
    </div>
  );
};

export default ProfileLayout;
