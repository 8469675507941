import React from 'react';

import { useTranslation } from 'react-i18next';
import { dateFormat } from 'shared/utils';
import Table from 'shared/ui/table';

import { BillingHistory, useBillingHistory } from '../../model';

import './styles.scss';

const TokensUsageTable = () => {
  const { t, i18n } = useTranslation();
  const { billingHistory, loading } = useBillingHistory({});

  const columns = [
    {
      title: t('tokensUsage.table.date'),
      key: 'date',
      width: 150,
      render: (record: BillingHistory) => dateFormat(record?.created_at, 'YYYY MMM DD', i18n.language),
    },
    {
      title: t('tokensUsage.table.project'),
      key: 'name',
      ellipsis: true,
      render: (record: BillingHistory) => record?.project?.active_project_version?.generated_title,
    },
    {
      title: t('tokensUsage.table.action'),
      key: 'reason',
      ellipsis: true,
      render: (record: BillingHistory) => record?.type,
    },
    {
      title: t('tokensUsage.table.count'),
      width: 80,
      key: 'amount',
      render: (record: BillingHistory) => record?.tokens,
    },
  ];

  return (
    <div className="tokens-usage">
      <h5 className="tokens-usage__title">{t('tokensUsage.title')}</h5>
      <Table columns={columns} dataSource={billingHistory} loading={loading} />
    </div>
  );
};

export default TokensUsageTable;
