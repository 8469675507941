import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OutcomeNextResponse } from 'shared/api/requests/outline-builder-project-version';

interface AddOutcomeState {
  isView: boolean;
  subView: string | null;
  loading: boolean;
  color: string;
  letterIndex: string;
}

const initialState: AddOutcomeState = {
  isView: false,
  subView: null,
  loading: false,
  color: '',
  letterIndex: '',
};

export const addOutcomeSlice = createSlice({
  name: 'add-outcome',
  initialState,
  reducers: {
    toggleView: (state) => {
      state.isView = !state.isView;
    },
    toggleSubView: (state, action: PayloadAction<string | null>) => {
      state.subView = action.payload;
    },
    startFetch: (state) => {
      state.loading = true;
    },
    finishFetch: (state) => {
      state.loading = false;
    },
    setNext: (state, action: PayloadAction<OutcomeNextResponse>) => {
      state.color = action.payload.color;
      state.letterIndex = action.payload.letter_index;
    },
  },
});

export const { toggleView, toggleSubView, startFetch, finishFetch, setNext } = addOutcomeSlice.actions;
export default addOutcomeSlice.reducer;
