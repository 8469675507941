import React, { useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import CourseHeaderAuto from 'widgets/course-header-auto';
import { setLayout } from 'app/store';

import { useAppDispatch } from 'shared/store/hooks';

const CourseAutoLayout: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLayout('auto'));
    return () => {
      dispatch(setLayout(null));
    };
  }, [dispatch]);
  return (
    <div className="course-layout">
      <CourseHeaderAuto />
      <Outlet />
    </div>
  );
};

export default CourseAutoLayout;
