import React, { memo } from 'react';

import { cx } from 'shared/utils';

import './styles.scss';

type BlockTitleProps = {
  text: string;
  className?: string;
  type?: 'h5';
};

const BlockTitle: React.FC<BlockTitleProps> = ({ text, className, type }: BlockTitleProps) => {
  return (
    <span
      className={cx('block-title', className, {
        [`block-title--${type}`]: type,
      })}
    >
      {text}
    </span>
  );
};

export default memo(BlockTitle);
