import React, { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';
import Button from 'shared/ui/button';
import LinkButton from 'modules/link-button';
import HeaderSmall from 'shared/components/header-small';
import { useAppSelector } from 'shared/store/hooks';

const CourseHeaderAuto: React.FC = () => {
  const navigation = useNavigate();
  const onGoMainPage = useCallback(() => navigation(PATH_NAME.main), [navigation]);
  const project = useAppSelector((state) => state.project.project);
  const title = project?.active_project_version?.generated_title || 'New course';

  const action = useMemo(() => {
    return (
      <>
        <Button disabled type="default">
          EDIT
        </Button>
        <Button disabled type="default">
          REGENERATE
        </Button>
        <LinkButton />
      </>
    );
  }, []);

  return (
    <div className="course-header">
      <HeaderSmall title={title} goBack={onGoMainPage} actions={action} />
    </div>
  );
};

export default CourseHeaderAuto;
