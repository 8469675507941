import React, { FC } from 'react';

import constrLogo from 'assets/icons/all_constructor_logo-s.svg';
import smartcatLogo from 'assets/icons/smartcat_black.png';
import wileyLogo from 'assets/icons/Wiley_Wordmark_white.png';
import rockstarLogo from 'assets/icons/IR Logo 600x600px.png';

import LogoWhite from '../../ui/icons/logo-white/IconLogoWhite';

export const Logo: FC = () => {
  const appCompany = process.env.REACT_APP_CCM_COMPANY;

  if (appCompany === 'industryrockstarlms') {
    return <img src={rockstarLogo} alt="logo" />;
  }

  if (appCompany === 'constr') {
    return <img src={constrLogo} alt="logo" />;
  }
  if (appCompany === 'smartcat') {
    return <img src={smartcatLogo} alt="logo" />;
  }
  if (appCompany === 'wiley') {
    return <img src={wileyLogo} alt="logo" />;
  }
  return <LogoWhite />;
};
