import React, { useCallback, useEffect } from 'react';

import Spinner from 'shared/ui/spinner';
import CourseCard from 'modules/course-card';
import { generatePath, useNavigate } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';
import mixpanel from 'mixpanel-browser';
import { getMeAction } from 'app/store/actions';
import { setProject } from 'modules/project';
import useObjectTypes from 'shared/graphql/queries/useObjectTypes';
import { sortProjectsByDate } from 'shared/utils';
import { localStorageService } from 'shared/services/localStorageService';
import { runTutor, setStepIndex } from 'modules/tutorial/store';
import { isEmpty } from 'lodash';
import PromoCard from 'modules/promo-card';
import modalService from 'shared/services/modalService';
import CourseCardAction from 'shared/components/course-card-action';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { getProjectsAction } from './store/actions';

import './styles.scss';

const CourseList: React.FC = () => {
  const dispatch = useAppDispatch();

  const account = useAppSelector((state) => state.app?.me?.account_data);
  const projects = useAppSelector((state) => state.projects.projects);
  const loading = useAppSelector((state) => state.projects.loading);
  const tutor = useAppSelector((state) => state.tutorial);
  const navigate = useNavigate();

  useObjectTypes();

  useEffect(() => {
    const intervalId = setInterval(() => {
      void dispatch(getProjectsAction(true));
    }, 5000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    if (account) {
      void dispatch(getProjectsAction(true));
      void dispatch(getMeAction());
    }
  }, [account, dispatch]);

  useEffect(() => {
    if (!tutor.tourActive && !tutor.tourFinish && tutor.tourId) {
      modalService.openWelcomeTutorial({});
    }
  }, [tutor.tourActive, tutor.tourFinish, tutor.tourId]);

  useEffect(() => {
    if (tutor.tourId && tutor.tourActive && !tutor.tourFinish && !tutor.run && !isEmpty(projects) && !loading) {
      dispatch(setStepIndex(0));
      dispatch(runTutor());
    }
  }, [dispatch, loading, projects, tutor.run, tutor.tourActive, tutor.tourFinish, tutor.tourId]);

  const onCreateCourse = useCallback(() => {
    mixpanel.track('CreateProject', { ProjectId: 'new' });
    const path = generatePath(PATH_NAME.courseManualWithEntity, {
      id: 'new',
      lang: localStorageService.getLangData(),
      entity: PATH_NAME.courseEntity.settings,
    });
    dispatch(setProject(null));
    navigate(path);
    window.scrollTo({ top: 0 });
  }, [dispatch, navigate]);

  const count = (account?.tokens_left || 0) + (account?.affiliate_tokens_left || 0);

  return loading ? (
    <Spinner />
  ) : (
    <div className="course-list">
      <PromoCard />
      {account ? (
        count ? (
          <CourseCardAction type="create" onClick={onCreateCourse} />
        ) : (
          <CourseCardAction type="buy" />
        )
      ) : null}
      {sortProjectsByDate(projects)?.map((item) => {
        return <CourseCard key={item?.pk} project={item} />;
      })}
    </div>
  );
};

export default CourseList;
