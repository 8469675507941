import React, { Suspense } from 'react';

import ReactDOM from 'react-dom/client';
import Symbol_observable from 'symbol-observable';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'i18n/i18n';
import { ApolloProvider } from '@apollo/client';
import App from 'app';
import { CookiesProvider } from 'react-cookie';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
//import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from './shared/store/store';
import { client } from './shared/graphql';

// import reportWebVitals from './reportWebVitals';

ReactGA.initialize('G-3LVVBFJ3K9');
mixpanel.init('6b9ded186b4c3d8214316424ae1907ce');
posthog.init('phc_vnnSLZf6FcPxvZQGvch88v7P9hh8V4UX1WtM0UJ5SRR', {
  api_host: 'https://eu.posthog.com',
});
console.log(Symbol_observable);
console.log('¯\\(ツ)/¯');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Suspense fallback={null}>
    <Provider store={store}>
      {/*<PersistGate loading={null} persistor={persistor}>*/}
      <ApolloProvider client={client}>
        <CookiesProvider>
          <BrowserRouter>
            <PostHogProvider client={posthog}>
              <App />
            </PostHogProvider>
          </BrowserRouter>
        </CookiesProvider>
      </ApolloProvider>
      {/*</PersistGate>*/}
    </Provider>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
