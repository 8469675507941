import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OutlineProject } from 'shared/types/entities';

interface ProjectsState {
  projects: OutlineProject[] | null;
  loading: boolean;
  autoUpdate: number | null;
}

const initialState: ProjectsState = {
  loading: false,
  projects: null,
  autoUpdate: null,
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjectsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAutoUpdate: (state, action: PayloadAction<number | null>) => {
      state.autoUpdate = action.payload;
    },
    setProjects: (state, action: PayloadAction<OutlineProject[] | null>) => {
      state.projects = action.payload;
    },
  },
});

export const { setProjectsLoading, setAutoUpdate, setProjects } = projectsSlice.actions;
export default projectsSlice.reducer;
