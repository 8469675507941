import { client } from '../index';
import { ME_INFO } from '../schemas/meInfo';

import { MeData } from './useMeInfo';

export const getMeRequest = () => {
  return client.query<MeData>({
    query: ME_INFO,
    fetchPolicy: 'network-only',
  });
};
