import { gql } from '@apollo/client';

export const ME_INFO = gql`
  query Me {
    me {
      id
      affiliate_username
      name
      first_name
      last_name
      email
      state
      icon_image
      timezone
      companies {
        id
        company {
          id
          name
          company_type
        }
        business_roles {
          id
          name
          role
        }
      }
      cob_tokens_left
      signup_promo_code
      has_active_subscription
      password_is_set
      promo_messages {
        id
        is_active
        promo_message {
          id
          type
          priority
          title
          description
          button_name
          button_href
        }
      }
      user_details {
        industry {
          id
        }
        position {
          id
        }
      }
      account_data {
        api_key
        tone_of_voice
        video_provider_usage {
          id
          pk
          is_active
          licence_type
          video_provider {
            id
            pk
            is_default
            is_public
            name
            system_key
          }
        }
        video_provider
        video_seconds_left
        is_video_enabled
        subscription_cancel_dt
        subscription_expire_at
        tokens_left
        affiliate_tokens_left
        subscription {
          id
          billing_cycle
          price
          type
        }
        dcconnector_accounts {
          dc_connector {
            id
            connector_name
            connector_type
          }
          external_account_id
          id
          pk
          is_active
          params
          state
        }
      }
    }
  }
`;
