import React, { memo } from 'react';

import { Switch } from 'antd';
import { SwitchProps } from 'antd/es/switch';
import { cx } from 'shared/utils';

import './styles.scss';

type Props = {
  disabled?: boolean;
  isBeta?: boolean;
  type?: 'long';
  label: string;
  text: string;
  switchProps?: SwitchProps;
};

const SettingSwitchBlock: React.FC<Props> = ({ disabled, isBeta, type, label, text, switchProps }) => {
  const classNames = cx('setting-switch-block', {
    'setting-switch-block--long': type === 'long',
  });
  return (
    <div className={classNames}>
      <Switch size="small" {...switchProps} disabled={disabled} />
      <div className="setting-switch-block__inner">
        {isBeta ? (
          <div className="setting-switch-block-beta">
            <span className="setting-switch-block-beta__label">Beta</span>
          </div>
        ) : null}
        <span className="setting-switch-block__label">{label}</span>
        <span className="setting-switch-block__text">{text}</span>
      </div>
    </div>
  );
};

export default memo(SettingSwitchBlock);
