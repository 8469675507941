import { useQuery } from '@apollo/client';
import { BaseListOption } from 'shared/types/entities';
import { baseListToSelect } from 'shared/utils';

import { localStorageService } from '../../services/localStorageService';
import { HELP_WORK_TYPES } from '../schemas/helpWorkTypes';

type Node = {
  node: BaseListOption;
};

type Data = {
  outline_builder_help_work_types: {
    edges: Node[];
  };
};

const useHelpWorkType = () => {
  const language = localStorageService.getLangData();
  const { data, loading } = useQuery<Data>(HELP_WORK_TYPES, {
    variables: {
      language,
    },
  });

  const arr = data?.outline_builder_help_work_types.edges.map((el) => el.node);
  const sorted = arr?.sort((a, b) => a?.order - b?.order);

  return {
    loading,
    helpWorkTypes: arr,
    helpWorkTypesForSelect: baseListToSelect(sorted),
  };
};

export default useHelpWorkType;
