import React from 'react';

import { isEmpty } from 'lodash';
import { cx } from 'shared/utils';

import { useAppSelector } from 'shared/store/hooks';

import PromoAlertCard from './ui/card/PromoAlertCard';

import './styles.scss';

type Props = {
  className?: string;
};

const PromoAlert: React.FC<Props> = ({ className }) => {
  const messages = useAppSelector((state) => state?.app?.me?.promo_messages);

  const filtered = messages?.filter((m) => m.is_active && m.promo_message.type === 'alert');

  if (isEmpty(filtered)) {
    return null;
  }

  const classNames = cx('promo-alert', className);

  return (
    <div className={classNames}>
      {filtered?.map((m) => {
        return <PromoAlertCard key={m.id} promoId={m.id} item={m.promo_message} />;
      })}
    </div>
  );
};

export default PromoAlert;
