import React, { memo } from 'react';

import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { cx } from 'shared/utils';
import { useAppSelector } from 'shared/store/hooks';

import './styles.scss';

type Props = {
  className?: string;
};

const CreditsView: React.FC<Props> = ({ className }) => {
  const account = useAppSelector((state) => state.app?.me?.account_data);
  const { t } = useTranslation();
  const classNames = cx('credits-view', className);

  const count = (account?.tokens_left || 0) + (account?.affiliate_tokens_left || 0);

  const videoSecond = account?.video_seconds_left ? account?.video_seconds_left / 60 : 0;

  return (
    <div className={classNames}>
      <div className="credits-view__block">
        <span className="credits-view__text">{t<string>('common.aiCredits')}</span>
        <Badge count={count} showZero color="#ffffff" />
      </div>
      <div className="credits-view__block">
        <span className="credits-view__text">{t<string>('common.aiVideoLimit')}</span>
        <Badge count={Math.floor(videoSecond)} showZero color="#ffffff" />
      </div>
    </div>
  );
};

export default memo(CreditsView);
