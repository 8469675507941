import React, { memo } from 'react';

import { generatePath, Link } from 'react-router-dom';
import { OutlineProject, ProjectGenerateState } from 'shared/types/entities';
import { PATH_NAME } from 'pages/constants';
import { isEmpty } from 'lodash';
import BaseContent from 'shared/components/course-card-view/base';
import StepsContent from 'shared/components/course-card-view/steps';

import 'shared/components/course-card-view/styles.scss';

type CourseCardProps = {
  project: OutlineProject;
};

const getEntity = (project: OutlineProject, isGenSub: boolean) => {
  if (project.has_submodules && !isGenSub) {
    return PATH_NAME.courseEntity.structure;
  }
  if (project.has_submodules && isGenSub) {
    return PATH_NAME.courseEntity.learning;
  }
  if (!isEmpty(project.active_project_version?.active_modules_outcomes)) {
    return PATH_NAME.courseEntity.learning;
  }
  if (project.active_project_version) {
    return PATH_NAME.courseEntity.about;
  }
  return PATH_NAME.courseEntity.settings;
};

const CourseCard: React.FC<CourseCardProps> = ({ project }) => {
  const projectStatus = project.generation_status;
  const lang = project.languages[0].code; // Take the first language

  const path = generatePath(PATH_NAME.courseManualWithEntity, {
    id: project.pk, //Fuck graphql
    lang: lang,
    entity: getEntity(project, project.generation_in_progress),
  });

  const content = () => {
    if (projectStatus === null) {
      return <BaseContent project={project} />;
    } else {
      if (projectStatus.state === ProjectGenerateState.inProgress && !projectStatus.is_ended) {
        return <StepsContent project={project} />;
      }
      if (projectStatus.state === ProjectGenerateState.rejected && projectStatus.is_ended) {
        return <StepsContent project={project} />;
      }
      if (projectStatus.is_ended && projectStatus.state === ProjectGenerateState.done) {
        return <BaseContent project={project} />;
      }
    }
  };

  const isLinkCard =
    projectStatus === null ||
    (projectStatus !== null && projectStatus.state === ProjectGenerateState.done && projectStatus.is_ended);

  return isLinkCard ? (
    <Link to={path} className="course-card tour--card-list">
      {content()}
    </Link>
  ) : (
    <div className="course-card tour--card-list">{content()}</div>
  );
};

export default memo(CourseCard);
