import React, { FC, useRef } from 'react';

import ReactPlayer from 'react-player';
import { cx } from 'shared/utils';

import './styles.scss';

type Props = {
  className?: string;
  url?: string;
};

export const VideoPlayer: FC<Props> = ({ className, url }) => {
  const playerRef = useRef<ReactPlayer>(null);
  const classNames = cx('video-player', className);
  return (
    <div className={classNames}>
      <ReactPlayer ref={playerRef} url={url} controls={true} />
    </div>
  );
};
