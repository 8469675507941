import { useEffect } from 'react';

import { useAppSelector } from 'shared/store/hooks';
import { message } from 'antd';

export const useContextFileState = () => {
  const contextState = useAppSelector((state) => state.assignment.assignment?.context_files?.[0]?.context_file.state);

  // useEffect(() => {
  //   if (contextState === 'error' || contextState === 'error_file_parsing' || contextState === 'done') {
  //     message.destroy();
  //   }
  // }, [contextState]);

  return {
    contextState,
  };
};

export const useCurrentAssignment = () => {
  const loading = useAppSelector((state) => state.assignment.loading);
  const assignment = useAppSelector((state) => state.assignment.assignment);

  return {
    loading,
    assignment,
  };
};
