import React, { useEffect, useMemo, useState } from 'react';

import Select from 'shared/ui/select';
import { FilePdfOutlined, FileZipOutlined, FormOutlined, QuestionOutlined } from '@ant-design/icons';
import IconCourseFactory from 'shared/ui/icons/cf/IconCourseFactory';
import { useTranslation } from 'react-i18next';
import { CompanyTypeEnum } from 'shared/types/entities';
import { useUserDetails } from 'app/hooks/useUserDetails';
import IconCanvas from 'shared/ui/icons/canvas/IconCanvas';
import IconMoodle from 'shared/ui/icons/moodle/IconMoodle';
import IconEdx from 'shared/ui/icons/edx/IconEdx';
import { Form } from 'antd';
import dayjs from 'dayjs';
import { generatePath, useParams } from 'react-router-dom';
import FormItem from 'shared/ui/form-item/FormItem';
import TextArea from 'shared/ui/textArea/TextArea';
import Button from 'shared/ui/button/Button';
import ModalContent from 'shared/components/modal/content/ModalContent';
import { useAppSelector } from 'shared/store/hooks';
import { usePublish } from 'shared/hooks/useSubscribe';
import useProjectVersions from 'shared/graphql/queries/useProjectVersions';
import { dateFormat } from 'shared/utils';
import mixpanel from 'mixpanel-browser';
import { postVersionExport } from 'shared/api/requests/outline-builder-project-version';
import { PATH_NAME } from 'pages/constants';
import IconIrAcademy from 'shared/ui/icons/irAcademy/IconIRacademy';

import './styles.scss';

const ParamsForm = () => {
  const [system, setSystem] = useState<string | null>(null);
  const [form] = Form.useForm();
  const { lang } = useParams();
  const { t } = useTranslation();
  const { isExecutiveManager, companyType } = useUserDetails();

  const onChange = (value: string) => {
    setSystem(value);
  };
  const project = useAppSelector((s) => s.project?.project);
  const projectId = useAppSelector((s) => s.project.project?.id);
  const languages = useAppSelector((s) => s.project.project?.languages);
  const currentLang = useAppSelector((s) => s.project.project?.active_project_version?.language);
  const projectActiveVerId = useAppSelector((s) => s.project.project?.active_project_version?.id);
  const reezonlyData = useAppSelector((state) =>
    state.app?.me?.account_data.dcconnector_accounts.find((el) => el.dc_connector.connector_name === 'reezonly')
  );

  const publish = usePublish();

  const language = Form.useWatch('language', form);
  const version = Form.useWatch('version', form);

  const {
    data: verList,
    loading,
    refetch,
  } = useProjectVersions({ id: projectId, lang: language || currentLang?.code });

  useEffect(() => {
    void refetch();
  }, []);

  const languagesOptions = useMemo(() => {
    return languages?.map((l) => {
      return {
        id: l.code,
        label: l.name,
        value: l.code,
      };
    });
  }, [languages]);

  const versionOptions = useMemo(() => {
    return verList
      ?.sort((a, b) => dayjs(b.created_at).unix() - dayjs(a.created_at).unix())
      .map((v) => {
        const current = `${dateFormat(v.created_at, 'YYYY-MM-DD HH:mm', lang)} ${v.pk === version ? '(current)' : ''}`;
        const active = `${dateFormat(v.created_at, 'YYYY-MM-DD HH:mm', lang)} ${
          v.pk === projectActiveVerId ? '(active)' : ''
        }`;

        return {
          id: v.pk,
          label: v.pk === projectActiveVerId ? active : current,
          value: v.pk,
        };
      });
  }, [lang, projectActiveVerId, verList, version]);

  const defaultValues = useMemo(() => {
    return {
      language: currentLang?.code,
      version: null,
      note: '',
    };
  }, [currentLang?.code]);

  //Actions without selecting a system for export
  useEffect(() => {
    if (system === 'pdf') {
      const path = generatePath(PATH_NAME.courseStructurePrint, {
        id: project?.id,
        lang: project?.active_project_version?.language.code,
      });
      const finalPath = `${window.location.origin}/${path}`;
      mixpanel.track('ExportPdf', { ProjectId: `${project?.id}` });
      window.open(finalPath, '_blank');
      setSystem(null);
    }
    if (system === 'quizzes') {
      const path = generatePath(PATH_NAME.courseQuizzesPrint, {
        id: project?.id,
        lang: project?.active_project_version?.language.code,
      });
      const finalPath = `${window.location.origin}/${path}`;
      mixpanel.track('ExportQuizzes', { ProjectId: `${project?.id}` });
      window.open(finalPath, '_blank');
      setSystem(null);
    }
    if (system === 'other') {
      window.open('https://855ii67p1l2.typeform.com/to/pS2RK7lZ', '_blank');
      setSystem(null);
    }
  }, [project?.active_project_version?.language.code, project?.id, system]);

  // Init form values
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [defaultValues, form]);

  const handleFormChange = (changedFields: any) => {
    if (changedFields[0].name[0] === 'language') {
      form.setFieldValue('version', null);
    }
  };

  const onSubmit = async () => {
    if (system === 'ccm' || system === 'scorm' || system === 'canvas' || system === 'moodle' || system === 'reezonly') {
      const values = form.getFieldsValue();
      const payload = {
        type: system,
        project_version: Number(values.version),
        note: values.note,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      await postVersionExport(payload).finally(() => {
        publish('export-table');
        setSystem(null);
      });
    }
  };

  const options = [
    {
      value: 'pdf',
      label: (
        <div className="export-params-form-opt__wrap">
          <FilePdfOutlined style={{ fontSize: 16 }} />
          <span className="export-params-form-opt__label">Pdf</span>
        </div>
      ),
    },
    {
      value: 'ccm',
      disabled: !isExecutiveManager && companyType !== CompanyTypeEnum.opm,
      label: (
        <div className="export-params-form-opt__wrap">
          <IconCourseFactory size={16} />
          <span className="export-params-form-opt__label">CourseFactory Workplace</span>
        </div>
      ),
    },
    {
      value: 'scorm',
      label: (
        <div className="export-params-form-opt__wrap">
          <FileZipOutlined style={{ fontSize: 16 }} />
          <span className="export-params-form-opt__label">SCORM</span>
        </div>
      ),
    },
    {
      value: 'quizzes',
      label: (
        <div className="export-params-form-opt__wrap">
          <QuestionOutlined style={{ fontSize: 16 }} />
          <span className="export-params-form-opt__label">{t('exportButton.quizzes')}</span>
        </div>
      ),
    },
    {
      label: 'LMS',
      options: [
        {
          value: 'canvas',
          label: (
            <div className="export-params-form-opt__wrap">
              <IconCanvas size={16} />
              <span className="export-params-form-opt__label">Canvas</span>
            </div>
          ),
        },
        {
          value: 'moodle',
          label: (
            <div className="export-params-form-opt__wrap">
              <IconMoodle size={16} />
              <span className="export-params-form-opt__label">Moodle</span>
            </div>
          ),
        },
        {
          value: 'reezonly',
          disabled: reezonlyData?.state !== 'done',
          label: (
            <div className="export-params-form-opt__wrap">
              <IconIrAcademy size={16} />
              <span className="export-params-form-opt__label">IRacademy</span>
            </div>
          ),
        },
        {
          value: 'edx',
          disabled: true,
          label: (
            <div className="export-params-form-opt__wrap">
              <IconEdx size={16} />
              <span className="export-params-form-opt__label">Open EDX</span>
            </div>
          ),
        },
        {
          value: 'other',
          label: (
            <div className="export-params-form-opt__wrap">
              <FormOutlined style={{ fontSize: 16 }} />
              <span className="export-params-form-opt__label">Add Your LMS</span>
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <div className="export-params-form">
      <span className="modal-export__hint">{t<string>('exportModal.hint1')}</span>
      <span className="modal-export__hint mb-2">{t<string>('exportModal.hint2')}</span>
      <Select
        size="large"
        placeholder={t<string>('exportModal.system.placeholder')}
        listHeight={350}
        options={options}
        value={system}
        onChange={onChange}
      />

      {system ? (
        <ModalContent className="export-params-form__content">
          <Form form={form} layout="vertical" onFieldsChange={handleFormChange}>
            <FormItem name="language" label={t<string>('exportModal.lang.label')}>
              <Select placeholder={t<string>('exportModal.lang.placeholder')} size="large" options={languagesOptions} />
            </FormItem>
            <FormItem name="version" label={t<string>('exportModal.version.label')}>
              <Select
                size="large"
                placeholder={t<string>('exportModal.version.placeholder')}
                loading={loading}
                options={versionOptions}
              />
            </FormItem>
            <FormItem className="mb-2" name="note" label={t<string>('exportModal.note.label')}>
              <TextArea
                placeholder={t<string>('exportModal.note.placeholder')}
                size="large"
                autoSize={{ minRows: 2, maxRows: 10 }}
              />
            </FormItem>
            <span className="modal-export__sub-title">{t('exportModal.subTitle')}</span>
            <div className="modal-export__footer">
              <Button size="large" type="primary" disabled={!version} onClick={onSubmit}>
                {t<string>('exportModal.submit')}
              </Button>
            </div>
          </Form>
        </ModalContent>
      ) : null}
    </div>
  );
};

export default ParamsForm;
