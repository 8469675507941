import React, { ChangeEvent, useCallback, useState } from 'react';

import { useBoolean } from 'usehooks-ts';
import { CaretLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Button from 'shared/ui/button/Button';
import Input from 'shared/ui/input';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { createStructureItemAction } from '../course-structure-collapse/store/actions';

import './styles.scss';
import { useOwner } from '../../shared/hooks/useProjectOwner';

type Props = {
  nextIndex?: number;
};

const AddStructureModule: React.FC<Props> = ({ nextIndex = 0 }) => {
  const { value, toggle } = useBoolean(false);
  const [text, setText] = useState('');
  const { t } = useTranslation();
  const projectGen = useAppSelector((state) => state.project.generation);
  const projectVerId = useAppSelector((state) => state.project.project?.active_project_version?.id);
  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state.structureItem);
  const layout = useAppSelector((s) => s.app.layout);

  const { isOwner } = useOwner();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  }, []);

  const onBlur = () => {
    if (!text) {
      toggle();
    } else {
      const payload = {
        title: text,
      };
      if (projectVerId) {
        dispatch(createStructureItemAction(payload)).finally(() => {
          toggle();
          setText('');
        });
      }
    }
  };

  if (layout === 'print' || layout === 'public') return null;

  return (
    <div className="add-structure-module">
      {value ? (
        <div className="add-structure-module__inner">
          <div className="add-structure-module__info">
            <span className="cs-collapse-head__count">{nextIndex + 1}</span>
          </div>

          <Input size="small" autoFocus bordered={false} value={text} onBlur={onBlur} onChange={handleChange} />
          <div className="add-structure-module__actions">
            <Button
              shape="circle"
              className="cs-collapse-toggle"
              size="small"
              type="text"
              icon={<CaretLeftOutlined />}
              disabled
            />
          </div>
        </div>
      ) : (
        <Button
          size="large"
          exSize="xl"
          type="default"
          disabled={!isOwner || store.loading || projectGen}
          onClick={toggle}
        >
          <PlusOutlined style={{ fontSize: 16 }} />
          {t<string>('structure.addModule')}
        </Button>
      )}
    </div>
  );
};

export default AddStructureModule;
