import {
  postStripeCheckoutRequest,
  postStripeDowngradeRequest,
  postStripePortalSession,
} from 'shared/api/requests/stripe';
import { messageError, messageSave } from 'shared/utils';
import { AppDispatch, store } from 'shared/store/store';
import { message } from 'antd';

import { getMeAction } from '../../../app/store/actions';

export const tokenSubscriptionAction = (subscriptionId?: string) => {
  return async (dispatch: AppDispatch) => {
    const hasSubscription = store.getState().app.me?.has_active_subscription;

    if (subscriptionId) {
      const payload = {
        success_url: window.location.href,
        cancel_url: window.location.href,
        subscription_id: Number(subscriptionId),
      };
      const response = await postStripeCheckoutRequest(payload);

      if (response.payment_url) {
        window.open(response.payment_url, '_self');
      } else {
        messageError();
      }
    } else {
      const payload = {
        return_url: window.location.href,
      };
      const response = await postStripePortalSession(payload);
      if (response.url) {
        window.open(response.url, '_self');
      } else {
        messageError();
      }
    }
  };
};

export const downgradeSubscriptionAction = () => {
  return async (dispatch: AppDispatch) => {
    messageSave();
    await postStripeDowngradeRequest().then(() => {
      dispatch(getMeAction());
      message.destroy();
    });
  };
};
