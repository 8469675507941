import { useMemo } from 'react';

function useWordCount(blocks: any, isReady: boolean) {
  const values = useMemo(() => {
    const temp: string[] = [];
    if (isReady && blocks) {
      for (const block of blocks?.blocks) {
        if (block.type === 'paragraph' || block.type === 'header') {
          temp.push(block.data.text);
        }
        if (block.type === 'table') {
          block.data.content.forEach((el: string[]) => {
            el.forEach((str) => temp.push(str));
          });
        }
        if (block.type === 'list') {
          block.data.items.forEach((el: string) => {
            temp.push(el);
          });
        }
      }
    }

    return temp;
  }, [blocks, isReady]);

  function countWords(str: string) {
    if (!str) return 0;
    return str.trim().split(/\s+/).length;
  }

  const string = values.join(' ').replaceAll(/&nbsp;/g, '');

  return useMemo(() => {
    return {
      words: countWords(string),
      charsWithSpace: string.length,
      charsWithoutSpace: string?.match(/\S/g)?.length,
    };
  }, [string]);
}

export default useWordCount;
