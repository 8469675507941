import React, { ChangeEvent, useCallback, useState } from 'react';

import { cx } from 'shared/utils';
import { ActiveModulesOutcome, ActiveSubModulesOutcome } from 'shared/types/entities';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import mixpanel from 'mixpanel-browser';
import LearningOutcomeItemView from 'shared/components/learning-outcome-item-view';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { toggleSubView } from '../add-learning-outcome/store';
import ButtonSparking from '../../shared/components/button-sparking/ButtonSparking';
import RegeneratePopup from '../regenerate-popup';

import {
  deleteModuleOutcomeAction,
  deleteSubModuleOutcomeAction,
  regenerateModuleOutcomeAction,
  updateModuleOutcomeAction,
  updateSubModuleOutcomeAction,
} from './store/actions';
import MoreActions from './more-actions';

import './styles.scss';
import { useOwner } from '../../shared/hooks/useProjectOwner';

interface LearningOutcomeItemParentProps {
  canShowTour?: boolean;
  isParent?: boolean;
  module: ActiveModulesOutcome;
  color: string;
}

interface LearningOutcomeItemChildProps {
  canShowTour?: boolean;
  isParent?: boolean;
  module: ActiveSubModulesOutcome;
  color: string;
}

type Props = LearningOutcomeItemParentProps | LearningOutcomeItemChildProps;

const LearningOutcomeItem: React.FC<Props> = ({ isParent, canShowTour, module, color }) => {
  const [text, setText] = useState(module.name);
  const isGeneration = useAppSelector((state) => state.project.generation);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const { isOwner } = useOwner();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const classNames = cx('lo-item', {
    'lo-item-parent': isParent,
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  }, []);

  const onBlur = useCallback(() => {
    if (isParent) {
      const payload = {
        name: text,
        color: module?.color || '',
      };
      if (!isEqual(module.name, text)) {
        void dispatch(updateModuleOutcomeAction(module.id, payload));
      }
    } else {
      const payload = {
        name: text,
      };
      if (!isEqual(module.name, text)) {
        void dispatch(updateSubModuleOutcomeAction(module.id, payload));
      }
    }
  }, [dispatch, isParent, module, text]);

  // Re generate outcome
  const onGenerate = useCallback(
    (content: string) => {
      if (id) {
        mixpanel.track(
          isParent ? 'Project First Level Outcome Regenerate' : 'Project Second Level Outcome Regenerate',
          {
            ProjectId: id,
            Page: 'LearningOutcome',
            FirstLevelOutcomeId: module.id,
            FirstLevelOutcomeName: module.name,
          }
        );
        void dispatch(regenerateModuleOutcomeAction(module.id, !!isParent, content));
      }
    },
    [dispatch, id, isParent, module.id, module.name]
  );

  // Delete outcome
  const onRemove = useCallback(() => {
    mixpanel.track(isParent ? 'Project First Level Outcome Delete' : 'Project Second Level Outcome Delete', {
      ProjectId: id,
      Page: 'LearningOutcome',
      FirstLevelOutcomeId: module.id,
      FirstLevelOutcomeName: module.name,
    });
    if (isParent) {
      void dispatch(deleteModuleOutcomeAction(module.id));
    } else {
      void dispatch(deleteSubModuleOutcomeAction(module.id));
    }
  }, [dispatch, id, isParent, module.id, module.name]);

  // Add sub outcome
  const onAddSubOutcome = useCallback(() => {
    mixpanel.track('Add Second Level Outcome Manually from Project First Level Outcome', {
      ProjectId: id,
      Page: 'LearningOutcome',
      FirstLevelOutcomeId: module.id,
      FirstLevelOutcomeName: module.name,
    });
    dispatch(toggleSubView(module.id));
  }, [dispatch, id, module.id, module.name]);

  const disabled = isGeneration || !isOwner || !!selectedVersion;

  return (
    <div className={classNames}>
      <LearningOutcomeItemView
        isParent={isParent}
        letterIndex={module.letter_index}
        color={color}
        text={text}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
      />
      <div className="lo-item__actions">
        <RegeneratePopup disabled={disabled} onSubmit={onGenerate}>
          <ButtonSparking
            className={cx({ [`tour--learning-outcomes-regen`]: canShowTour })}
            disabled={disabled}
            size="small"
          />
        </RegeneratePopup>

        <MoreActions disabled={disabled} isParent={isParent} onRemove={onRemove} onAdd={onAddSubOutcome} />
      </div>
    </div>
  );
};

export default LearningOutcomeItem;
