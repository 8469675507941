import React, { memo, useCallback } from 'react';

import { CheckOutlined } from '@ant-design/icons';
import Badge from 'shared/ui/badge';
import { cx } from 'shared/utils';
import { ActiveSubModulesOutcome } from 'shared/types/entities';

import './styles.scss';

type Props = {
  isSelected?: boolean;
  item: ActiveSubModulesOutcome;
  onClick: (outcomeId: string) => void;
};

const AddOutcomeMenuItem: React.FC<Props> = ({ isSelected, item, onClick }) => {
  const classNames = cx('course-structure-add-outcomes-menu-item', {
    'course-structure-add-outcomes-menu-item--selected': isSelected,
  });

  const handleClick = useCallback(() => {
    onClick(item.id);
  }, [item.id, onClick]);

  return (
    <div className={classNames} onClick={handleClick}>
      <Badge color={item.color} text={item.letter_index} />
      <span className="course-structure-add-outcomes-menu-item__text">{item.name}</span>
      <div className="course-structure-add-outcomes-menu-item__selected">
        {isSelected ? <CheckOutlined style={{ fontSize: 14 }} /> : null}
      </div>
    </div>
  );
};

export default memo(AddOutcomeMenuItem);
