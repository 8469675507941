import React from 'react';

import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { UploadChangeParam } from 'antd/es/upload';
import { cx } from 'shared/utils';

import './styles.scss';

interface UploadAvatarProps extends UploadProps {
  className?: string;
  loading?: boolean;
  onChangeUpload: (info: UploadChangeParam<UploadFile>) => void;
  file?: string | null;
  texts?: {
    modalTitle?: string;
    modalOk?: string;
    modalCancel?: string;
  };
}

const UploadAvatar: React.FC<UploadAvatarProps> = ({ className, texts, loading, onChangeUpload, file, ...props }) => {
  const uploadButton = <div className="upload-avatar__button">{loading ? <LoadingOutlined /> : <PlusOutlined />}</div>;
  const avatar = (
    <div className="upload-avatar__button">{loading ? <LoadingOutlined /> : <img src={file || ''} alt="avatar" />}</div>
  );

  const classNames = cx('upload-avatar', className);

  return (
    <div className={classNames}>
      <ImgCrop modalTitle={texts?.modalTitle} modalOk={texts?.modalOk} modalCancel={texts?.modalCancel}>
        <Upload action={''} showUploadList={false} onChange={onChangeUpload} {...props}>
          {file ? avatar : uploadButton}
        </Upload>
      </ImgCrop>
    </div>
  );
};

export default UploadAvatar;
