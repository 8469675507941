import { FileUnknownOutlined } from '@ant-design/icons';

import SvgInline from '../../modules/svg-inline';
import IconDummy from '../ui/icons/dummy';

export const getIconByUrl = (url?: string) => {
  const extension = url?.split('.').pop();
  switch (extension) {
    case 'png':
      return <img className="img-icon" src={url} alt="icon" />;
    case 'svg':
      return <SvgInline url={url} />;
    default:
      return <IconDummy size={16} />;
  }
};
