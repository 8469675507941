import api, { ApiModules } from 'shared/api/index';
import { ENDPOINTS } from 'shared/api/constants';

interface Payload {
  email: string;
  password: string;
}

export interface LoginResponse {
  access: string;
  refresh: string;
  data?: {
    detail: string;
  };
}

export interface RefreshPayload {
  refresh: string;
}

export const postLogin = (payload: Payload): Promise<LoginResponse> =>
  api(ApiModules.ACCOUNT).post<Payload, LoginResponse>(ENDPOINTS.LOGIN, payload);

export const postRefreshToken = (payload: RefreshPayload): Promise<LoginResponse> =>
  api(ApiModules.ACCOUNT).post(ENDPOINTS.REFRESH_TOKEN, payload);

export const postSignupRequest = (payload: FormData) => api(ApiModules.ACCOUNT).post(ENDPOINTS.SIGNUP, payload);
