import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import mixpanel from 'mixpanel-browser';
import Button from 'shared/ui/button';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useBoolean, useDebounce, useOnClickOutside } from 'usehooks-ts';
import { getSubOutcomes } from 'modules/project/selectors';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import { compareSelectedOutcomes, searchFilterOutcomes } from 'shared/utils/outcomesFilter';

import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { updateStructureItemOutcomeAction } from '../store/actions';

import AddOutcomeMenu from './AddOutcomeMenu';

import './styles.scss';

type Props = {
  disabled: boolean;
  itemId: string;
  selectedItems: string[];
};

const AddOutcomes: React.FC<Props> = ({ disabled, itemId, selectedItems }) => {
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<string[]>(selectedItems);
  const { value: open, setTrue, setFalse } = useBoolean(false);
  const subOutcomes = useAppSelector((state) => getSubOutcomes(state.project));
  const ref = useRef(null);
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const onSaveData = useCallback(() => {
    const payload = {
      sub_module_outcomes_ids: !isEmpty(selected) ? selected.map((el) => Number(el)) : [],
    };
    void dispatch(updateStructureItemOutcomeAction(itemId, payload));
  }, [dispatch, itemId, selected]);

  const selectedDenounce = useDebounce(selected, 1000);

  useEffect(() => {
    if (open) {
      if (!compareSelectedOutcomes(selectedItems, selectedDenounce)) {
        onSaveData();
      }
    }
  }, [onSaveData, open, selected, selectedDenounce, selectedItems]);

  const onClose = () => {
    setFalse();
    setSearch('');
  };

  const handleClickOutside = () => {
    onClose();
  };

  useOnClickOutside(ref, handleClickOutside);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onToggleSelected = useCallback((outcomeItemId: string) => {
    setSelected((prevState) => {
      const hasSelected = prevState.includes(outcomeItemId);
      if (hasSelected) {
        return prevState.filter((el) => el !== outcomeItemId);
      } else {
        return [...prevState, outcomeItemId];
      }
    });
  }, []);

  const onClick = useCallback(() => {
    if (open) {
      onClose();
    } else {
      setTrue();
      mixpanel.track('Learning Outcomes start changing from the course structure', { ProjectId: id });
    }
  }, [id, onClose, open, setTrue]);

  return (
    <div ref={ref} className="course-structure-add-outcomes">
      <Button
        size="small"
        shape="circle"
        type="text"
        icon={<PlusCircleOutlined style={{ fontSize: 14 }} />}
        disabled={disabled}
        onClick={onClick}
      />
      {open ? (
        <AddOutcomeMenu
          selectedItems={selected}
          items={searchFilterOutcomes(search, subOutcomes)}
          onSearch={handleSearch}
          onToggleSelected={onToggleSelected}
        />
      ) : null}
    </div>
  );
};

export default AddOutcomes;
