import React from 'react';

import AboutPageForm from 'modules/about-page-form';

const CourseAboutPage: React.FC = () => {
  return (
    <div className="container-center container-small">
      <AboutPageForm />
    </div>
  );
};

export default CourseAboutPage;
