import React, { useEffect } from 'react';

import { Tabs, TabsProps } from 'antd';
import ExportHistoryWidget from 'widgets/export-history';
import ExportSettingsWidget from 'widgets/export-settings';
import { useAppDispatch } from 'shared/store/hooks';
import { getMeAction } from 'app/store/actions';

import './styles.scss';

const ExportPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const intervalId = setInterval(async () => {
      await dispatch(getMeAction());
    }, 5000);

    return () => clearInterval(intervalId); //This is important
  }, [dispatch]);

  const items: TabsProps['items'] = [
    {
      key: 'history',
      label: 'Export history',
      children: <ExportHistoryWidget />,
    },
    {
      key: 'settings',
      label: 'Export settings',
      children: <ExportSettingsWidget />,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <div className="export-page">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
};

export default ExportPage;
