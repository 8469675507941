import { useCallback, useEffect } from 'react';

import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';
import mixpanel from 'mixpanel-browser';
import { runTutor, setStepIndex } from 'modules/tutorial/store';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { useOwner } from 'shared/hooks/useProjectOwner';

import { generateStructureItemsAction } from '../course-structure/store/actions';

import { generationSubModuleOutcomesAction } from './store/actions';

const useModuleLearningOutcomes = () => {
  const { id, lang } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projectGen = useAppSelector((state) => state.project.generation);
  const projectStore = useAppSelector((state) => state.project);
  const projectVerId = useAppSelector((state) => state.project?.project?.active_project_version?.id);
  const isTourActive = useAppSelector((state) => state.tutorial.tourActive);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);

  //Run Tour
  useEffect(() => {
    if (!projectStore.loading && projectStore?.project?.id && isTourActive) {
      dispatch(runTutor());
      dispatch(setStepIndex(7));
    }
  }, [dispatch, isTourActive, projectStore.loading, projectStore?.project?.id]);

  const onGenerateSubModules = useCallback(async () => {
    if (id && projectVerId) {
      mixpanel.track('Generate 2nd level outcomes', { ProjectId: id, Page: 'LearningOutcome' });
      await dispatch(generationSubModuleOutcomesAction(projectVerId));
    }
  }, [dispatch, id, projectVerId]);

  const onGenerateStructureItems = useCallback(() => {
    if (id && lang && projectVerId) {
      mixpanel.track('Generate structure', { ProjectId: id, Page: 'LearningOutcome' });
      const path = generatePath(PATH_NAME.courseManualWithEntity, {
        id: id,
        lang,
        entity: PATH_NAME.courseEntity.structure,
      });
      navigate(path);
      void dispatch(generateStructureItemsAction(projectVerId));
    }
  }, [id, lang, projectVerId, navigate, dispatch]);

  const { isOwner } = useOwner();

  return {
    allDisabled: !isOwner || Boolean(selectedVersion),
    loading: projectStore.loading,
    hasSubmodules: projectStore.project?.has_submodules,
    moduleOutcomes: projectStore.project?.active_project_version?.active_modules_outcomes || [],
    onGenerateSubModules,
    onGenerateStructureItems,
    projectGen,
    selectedVersion,
  };
};

export default useModuleLearningOutcomes;
