import { postCreateFileRequest } from 'shared/api/requests/file';
import { RcFile } from 'antd/es/upload';
import { onUploadFileInPartRequest } from 'shared/api/fetches/fileUpload';
import { setError, setFile } from 'widgets/create-course/store';
import { AppDispatch } from 'shared/store/store';

import { setFileSize, setProgress, setUploading } from './index';

export const uploadFileAction = (file: RcFile) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setUploading(true));
    dispatch(setFileSize(file.size));
    dispatch(setProgress(0));
    dispatch(setError(null));

    const payload = {
      file_name: file.name || '',
    };
    const response = await postCreateFileRequest('cob_file', payload).then((res) => {
      dispatch(setProgress(25));
      return res;
    });
    await onUploadFileInPartRequest({ file, fileId: response.id }).then(() => {
      dispatch(setUploading(false));
      dispatch(setFile(response));
      dispatch(setProgress(50));
    });
  };
};
