import React, { memo } from 'react';

import './styles.scss';

import SwitchLanguage from '../switch-language';
import { User } from '../../features/user';

const HeaderUser: React.FC = () => {
  return (
    <div className="header-user">
      <SwitchLanguage />
      <User.Menu />
    </div>
  );
};

export default memo(HeaderUser);
