import React from 'react';

import { Select as ASelect, SelectProps } from 'antd';
import { cx } from 'shared/utils';

import './styles.scss';

interface ExSelectProps extends SelectProps {
  exSize?: 'xl' | 'xxl';
}

const Select: React.FC<ExSelectProps> = ({ exSize, ...props }) => {
  const classNames = cx('cb-select', props.className, {
    [`cb-select--${exSize}`]: exSize,
  });

  return <ASelect {...props} className={classNames} />;
};

export default Select;
