import { client } from '../index';
import { OUTLINE_BUILDER_PROJECT_STRUCTURE } from '../schemas/outlineBuilderCourseStructure';

import { ProjectStructureData } from './useOutlineProjectStructure';

export const getStructureRequest = (projectId: string, language: string, projectVerId?: string) => {
  return client.query<ProjectStructureData>({
    query: OUTLINE_BUILDER_PROJECT_STRUCTURE,
    fetchPolicy: 'network-only',
    variables: {
      id: projectId,
      language,
      projectVerId,
    },
  });
};
