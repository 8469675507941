import { gql } from '@apollo/client';

export const LANGUAGES = gql`
  query OutlineBuilderLanguages($language: Boolean) {
    outline_builder_languages(interface_language: $language) {
      id
      code
      order
      name
      interface_language
    }
  }
`;
