import React, { memo } from 'react';

import { Input } from 'antd';
import { InputProps } from 'antd/es/input/Input';
import { cx } from 'shared/utils';

import './styles.scss';

interface ExInputProps extends InputProps {
  exSize?: 'xl' | 'xxl';
}

const InputPassword: React.FC<ExInputProps> = ({ exSize, ...props }) => {
  const classNames = cx('cb-input cb-input-password', props.className, {
    [`cb-input--${exSize}`]: exSize,
  });

  return <Input.Password {...props} className={classNames} />;
};

export default memo(InputPassword);
