import React, { ReactNode } from 'react';

import './styles.scss';

type LearningOutcomeGroupProps = {
  children?: ReactNode;
  color?: string;
};

const LearningOutcomeGroup = ({ children, color }: LearningOutcomeGroupProps) => {
  const styles = {
    borderColor: color || '#1E1E1E',
  };
  return (
    <div className="lo-group" style={styles}>
      {children}
    </div>
  );
};

export default LearningOutcomeGroup;
