import React, { useCallback, useEffect } from 'react';

import AssignmentsHead from 'modules/assignments-head';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { ExternalUrls, VideoGenerateState } from 'shared/types/entities';
import { useTranslation } from 'react-i18next';
import ViewPendingGenerate from 'shared/ui/viewPendingGeneration/viewPendingGenerate';
import { checkStatusGenVideo } from 'features/assignment-video-gen-buttons/model/actions';
import { useSearchParams } from 'react-router-dom';
import { GenerateVideoButton } from 'features/assignment-video-gen-buttons';
import Button from 'shared/ui/button';
import { PlayCircleFilled } from '@ant-design/icons';
import { setExternalVideoUrl } from 'entities/assignment/model';
import { useOwner } from 'shared/hooks/useProjectOwner';
import VideoPlayer from 'shared/components/video-player';
import { selectVideosByProvider } from 'entities/assignment/model/assignments.selectors';

import image from './placeholdercob.png';

import '../styles.scss';

const appCompany = process.env.REACT_APP_CCM_COMPANY;
const AssignmentVideo = () => {
  const [searchParams] = useSearchParams();

  const assignmentId = searchParams.get('str');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const isVideoEnabled = useAppSelector((state) => state.app?.me?.account_data?.is_video_enabled);
  const videos = useAppSelector(selectVideosByProvider);
  const video = videos?.[0];

  const { isOwner } = useOwner();
  const message = useAppSelector((state) =>
    state.assignment.systemMessages.find((el) => el.structure_item_id === Number(assignmentId))
  );

  useEffect(() => {
    if (assignmentId) {
      if ((video?.state === 'in_progress' && assignmentId) || (video?.state === 'new' && assignmentId)) {
        void dispatch(checkStatusGenVideo(assignmentId));
      } else if (message?.state === VideoGenerateState.new || message?.state === VideoGenerateState.inProgress) {
        void dispatch(checkStatusGenVideo(assignmentId));
      }
    }
  }, [assignmentId, dispatch, message?.state, video]);

  const urls: ExternalUrls[] | null = video?.external_urls ? JSON.parse(video?.external_urls) : null;

  const url = urls?.find((el) => el.resolution === 1080);

  const onSwitchToAttach = () => {
    dispatch(setExternalVideoUrl(true));
  };

  const getContent = (videoState?: string) => {
    switch (videoState) {
      case 'succeeded': {
        return <VideoPlayer url={url?.url} />;
      }
      case 'new':
      case 'in_progress': {
        return (
          <ViewPendingGenerate
            title={t<string>('assignments.video.videoPending.text1')}
            text={t<string>('assignments.video.videoPending.text2')}
          />
        );
      }
      case 'failed':
      case 'generation_error':
        return (
          <div className="assignment-select__regen-video">
            <span className="assignment-select__regen-video-text">{t('assignments.video.regenVideoText')}</span>
            <GenerateVideoButton isRegen structureItemId={assignmentId} />
          </div>
        );
      default:
        return (
          <div className="assign-video-settings__footer justify-center">
            <Button disabled={!isOwner} size="large" exSize="xl" type="primary" onClick={onSwitchToAttach}>
              {t('assignments.video.attachUrl')}
              <PlayCircleFilled style={{ fontSize: 16 }} />
            </Button>
            <span className="assign-video-settings__footer--or">{t('assignments.video.or')}</span>
            <GenerateVideoButton structureItemId={assignmentId} />
          </div>
        );
    }
  };

  const onClickContactUs = useCallback(() => {
    window.open('https://meetings.hubspot.com/azat-salikhov/course-videogeneration', '_blank');
  }, []);

  return (
    <div className="assign-video-content__wrap">
      <div className="assignment-select__block">
        <AssignmentsHead />
      </div>
      {isVideoEnabled ? (
        <div className="assignment-select__block">{getContent(video?.state)}</div>
      ) : (
        <>
          <div className="assignment-select__block">
            <div className="assignment-select__image">
              <img src={image} alt="image" />
            </div>
          </div>
          <div className="assign-video-settings__footer justify-center">
            <Button disabled={!isOwner} size="large" exSize="xl" type="primary" onClick={onSwitchToAttach}>
              {t('assignments.video.attachUrl')}
              <PlayCircleFilled style={{ fontSize: 16 }} />
            </Button>

            {!appCompany ? (
              <>
                <span className="assign-video-settings__footer--or">{t('assignments.video.or')}</span>
                <Button
                  disabled={!!selectedVersion}
                  className="contact-us"
                  size="large"
                  exSize="xl"
                  type="primary"
                  onClick={onClickContactUs}
                >
                  {t('assignments.video.contactUs')}
                </Button>
              </>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default AssignmentVideo;
