import React, { useCallback } from 'react';

import Button from 'shared/ui/button';
import { LanguageType } from 'shared/types/entities/app';
import { useTranslation } from 'react-i18next';
import ModalContainer from 'shared/components/modal/container';
import ModalContent from 'shared/components/modal/content';

import './styles.scss';

type Props = {
  lang: LanguageType;
  onSubmit: () => void;
  onCancel: () => void;
};

const TranslationModal: React.FC<Props> = ({ lang, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const onClickSubmit = useCallback(() => {
    onSubmit();
    onCancel();
  }, [onCancel, onSubmit]);

  return (
    <ModalContainer>
      <ModalContent className="translation-modal">
        <h4 className="translation-modal__title">{t('contentLangButton.modal.title')}</h4>
        <span className="translation-modal__text">{t('contentLangButton.modal.text', { lang: lang.name })}</span>
        <span className="translation-modal__text">{t('contentLangButton.modal.text2')}</span>
        <span className="translation-modal__text">{t('contentLangButton.modal.text3')}</span>
        <span className="translation-modal__text">{t('contentLangButton.modal.text4')}</span>
        <div className="translation-modal__actions">
          <Button size="large" type="primary" onClick={onClickSubmit}>
            OK
          </Button>
          <Button size="large" type="primary" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </ModalContent>
    </ModalContainer>
  );
};

export default TranslationModal;
