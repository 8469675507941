import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';

import { Upload } from 'antd/es';
import { useBoolean } from 'usehooks-ts';
import Button from 'shared/ui/button';
import { useTranslation } from 'react-i18next';
import TextArea from 'shared/ui/textArea/TextArea';
import { usePublish } from 'shared/hooks/useSubscribe';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { documentFileId } from 'entities/studio/model';
import { PlusOutlined } from '@ant-design/icons';

import { addParagraphAction } from '../../model/actions';

import './styles.scss';

export const AddParagraph: FC = () => {
  const [text, setText] = useState('');
  const { value: edit, setTrue: onEdit, setFalse: offEdit } = useBoolean();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fileId = useAppSelector(documentFileId);

  const onHandleChangeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const publish = usePublish();

  useEffect(() => {
    if (edit) {
      setTimeout(() => publish('studio-scroll--to-bottom'), 300);
    }
  }, [edit, publish]);

  const onSubmit = () => {
    const payload = {
      text,
      presentation_slide_id: null,
    };
    if (fileId) {
      dispatch(addParagraphAction(fileId, payload)).finally(() => {
        offEdit();
        setText('');
      });
    }
  };

  const uploadButton = (
    <button className="add-paragraph__upload-btn" type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <div className="add-paragraph">
      {edit ? (
        <div className="add-paragraph__wrap">
          <TextArea
            autoFocus={true}
            size="large"
            value={text}
            autoSize={{ minRows: 4, maxRows: 10 }}
            onChange={onHandleChangeText}
          />
        </div>
      ) : null}

      <Button disabled={edit ? !text.length : false} onClick={edit ? onSubmit : onEdit}>
        {t(edit ? 'assignments.video.studio.saveParagraph' : 'assignments.video.studio.addParagraph')}
      </Button>
    </div>
  );
};
