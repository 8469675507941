import api, { ApiModules } from 'shared/api/index';

import { ENDPOINTS } from '../../constants';

interface Payload {
  about_annotation: string;
  goal_annotation: string;
  knowledge_level: string;
  practical_level: string;
  duration: number;
  auto_steps: boolean;
  language: string;
}

interface Response {
  id: string;
  created_at: string;
  updated_at: string;
  about_annotation: string;
  goal_annotation: string;
  knowledge_level: string;
  practical_level: string;
  duration: string;
  created_by: number;
}

export const postOutlineBuilderProjectRequest = (payload: Payload): Promise<Response> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT, payload);

export const putOutlineBuilderProjectRequest = (projectId: string, payload: Payload): Promise<Response> =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.OUTLINE_BUILDER_PROJECT_BY_ID(projectId), payload);

interface VersionResponse {
  request_id: number;
}

type ProjectVersionPayload = {
  language: string;
};

export const postOutlineBuilderProjectVersionRequest = (
  projectId: string,
  payload: ProjectVersionPayload
): Promise<VersionResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_VERSION(projectId), payload);

export const getOutlineBuilderProjectCurriculumRequest = (id: string): Promise<ProjectCurriculumResponse> =>
  api(ApiModules.OUTLINE_BUILDER).get(ENDPOINTS.CURRICULUM(id));

type ProjectCurriculumPayload = {
  file_id: number;
  massive_course: boolean;
  duration: number;
};

export type ProjectCurriculumResponse = {
  file_id: number;
  id: number;
  project_id: number;
  state: 'new' | 'parsing' | 'ready' | 'error';
};

export type ProjectPublicPayload = {
  is_public_available: boolean;
};

export const postOutlineBuilderProjectCurriculumRequest = (
  payload: ProjectCurriculumPayload
): Promise<ProjectCurriculumResponse> => api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.CURRICULUM(), payload);

export const postOutlineBuilderProjectAutoGenStartRequest = (id: string): Promise<VersionResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.AUTO_GENERATION_START(id));

export const postOutlineBuilderProjectAutoGenContinueRequest = (id: string): Promise<VersionResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.AUTO_GENERATION_CONTINUE(id));

export const putOutlineBuilderProjectPublic = (id: string, payload: ProjectPublicPayload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.OUTLINE_BUILDER_PROJECT_PUBLIC(id), payload);

type ProjectFile = {
  file_id: number;
};

export const postOutlineBuilderProjectFileRequest = (id: string, payload: ProjectFile) =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_FILE(id), payload);

export const deleteOutlineBuilderProject = (id: string) =>
  api(ApiModules.OUTLINE_BUILDER).delete(ENDPOINTS.OUTLINE_BUILDER_PROJECT_BY_ID(id));
