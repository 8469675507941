import { FC, useMemo, useRef } from 'react';

import Button from 'shared/ui/button';
import { useTranslation } from 'react-i18next';
import modalService from 'shared/services/modalService';
import { JsonSerialized, VideoScriptParagraphType } from 'shared/types/entities';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { getStudioLinkedSlidesAction, selectLinkedSlide } from 'entities/studio/model';
import { useHover } from 'usehooks-ts';
import { isSelectProjectGenerating } from 'modules/project/project.selectors';
import { useOwner } from 'shared/hooks/useProjectOwner';

import { linkSlideToSectionAction } from '../../../slides/model/actions';

import './styles.scss';

type Props = {
  sectionId: string;
  content: JsonSerialized<VideoScriptParagraphType>;
};

export const Slide: FC<Props> = ({ sectionId, content }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const contentParsed = useMemo(() => JSON.parse(content) as VideoScriptParagraphType, [content]);
  const linkedSlide = useAppSelector(selectLinkedSlide);

  const isProjectGenerating = useAppSelector(isSelectProjectGenerating);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const { isOwner } = useOwner();
  const disabled = !isOwner || isProjectGenerating || !!selectedVersion;

  const currentSlide = useMemo(() => {
    return linkedSlide?.find((el) => el.document_file_section.id === sectionId);
  }, [linkedSlide, sectionId]);

  const onSelect = () => {
    modalService.openStudioSlides({
      type: 'modify',
      text: contentParsed.text,
      sectionId,
    });
  };

  const onClear = async () => {
    await dispatch(linkSlideToSectionAction(sectionId, null, contentParsed.text));
    await dispatch(getStudioLinkedSlidesAction());
  };

  return (
    <div ref={hoverRef} className="paragraph-slide">
      {currentSlide ? (
        <>
          {isHover && !disabled && (
            <div className="paragraph-slide-controls">
              <Button type="primary" onClick={onSelect}>
                {t('assignments.video.studio.replace')}
              </Button>
              <Button type="primary" onClick={onClear}>
                {t('assignments.video.studio.clear')}
              </Button>
            </div>
          )}

          <div className="paragraph-slide__wrap">
            <img src={currentSlide.presentation_file_slide.source_url} alt="slide" />
          </div>
        </>
      ) : (
        <Button disabled={disabled} type="primary" onClick={onSelect}>
          {t('assignments.video.studio.selectSlide')}
        </Button>
      )}
    </div>
  );
};
