import { useEffect } from 'react';

import { runTutor, setStepIndex, setTourAssignmentId } from 'modules/tutorial/store';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

const useCourseStructure = () => {
  const dispatch = useAppDispatch();
  const projectGen = useAppSelector((state) => state.project.generation);
  const projectStore = useAppSelector((state) => state.project);
  const structureStore = useAppSelector((state) => state.structure);
  const isTourActive = useAppSelector((state) => state.tutorial.tourActive);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);

  //Run Tour
  useEffect(() => {
    if (!projectStore.loading && projectStore?.project?.id && isTourActive && !isEmpty(structureStore.structure)) {
      let stop = false;
      for (const l1 of structureStore.structure) {
        if (stop) {
          break;
        }
        if (!isEmpty(l1.children)) {
          for (const l2 of l1.children) {
            if (stop) {
              break;
            }
            if (!isEmpty(l2.children)) {
              for (const l3 of l2.children) {
                if (stop) {
                  break;
                }
                if (l3?.type?.name === 'quiz') {
                  dispatch(setTourAssignmentId(Number(l3.id)));
                  stop = true;
                  break;
                }
              }
            }
          }
        }
      }

      dispatch(runTutor());
      dispatch(setStepIndex(11));
    }
  }, [dispatch, isTourActive, projectStore.loading, projectStore?.project?.id, structureStore.structure]);

  return {
    loading: projectStore.loading || structureStore.loading,
    structure: structureStore.structure,
    selectedVersion,
    structureStore,
    projectGen,
  };
};

export default useCourseStructure;
