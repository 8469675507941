import React, { useEffect, useState } from 'react';

import Select from 'shared/ui/select';
import useProjectVersions from 'shared/graphql/queries/useProjectVersions';
import { Skeleton } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import { dateFormat } from 'shared/utils';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { setSelectedVersion } from 'modules/project';
import { setAssignment } from 'entities/assignment/model';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { getProjectVersionAction } from '../model/actions';

import './styles.scss';

const VersionSelect = () => {
  const [selected, setSelected] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id, lang } = useParams();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const version = searchParams.get('version');
  const generation = useAppSelector((state) => state.project.generation);
  const projectActiveVerId = useAppSelector((state) => state.project?.project?.active_project_version?.id);

  const { data, loading, refetch } = useProjectVersions({ id, lang, skip: generation || id === 'new' });

  useEffect(() => {
    void refetch();
  }, [projectActiveVerId]);

  useEffect(() => {
    if (projectActiveVerId && !version) {
      setSelected(projectActiveVerId);
    } else {
      if (version) {
        setSelected(version);
      }
    }
  }, [projectActiveVerId, version]);

  useEffect(() => {
    if (version === projectActiveVerId) {
      dispatch(setSelectedVersion(null));
      searchParams.delete('version');
      setSearchParams(searchParams);
    }
    if (version && version !== projectActiveVerId) {
      void dispatch(getProjectVersionAction(version));
    }
  }, [dispatch, projectActiveVerId, searchParams, setSearchParams, version]);

  if (loading) {
    return <Skeleton.Input active />;
  }

  const options = data
    ?.sort((a, b) => dayjs(b.created_at).unix() - dayjs(a.created_at).unix())
    .map((v) => {
      const current = `${dateFormat(v.created_at, 'YYYY-MM-DD HH:mm', lang)} ${v.pk === version ? '(current)' : ''}`;
      const active = `${dateFormat(v.created_at, 'YYYY-MM-DD HH:mm', lang)} ${
        v.pk === projectActiveVerId ? '(active)' : ''
      }`;

      return {
        id: v.pk,
        label: v.pk === projectActiveVerId ? active : current,
        value: v.pk,
      };
    });

  const onChange = (value: string) => {
    setSelected(value);
    setSearchParams({ version: value });
    dispatch(setAssignment(null));
  };

  if (!id || id === 'new') {
    return null;
  }

  return (
    <div className="project-version-select__wrap">
      {/*<span className="project-version-select__label">{t('version.label')}</span>*/}
      <Select
        className="project-version-select"
        placeholder={'version'}
        options={options}
        value={selected}
        onChange={onChange}
        disabled={generation}
      />
    </div>
  );
};

export default VersionSelect;
