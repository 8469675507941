import React from 'react';

import CourseList from 'widgets/course-list';

import './styles.scss';

const CoursesPage: React.FC = () => {
  return (
    <div className="container course-page">
      <CourseList />
    </div>
  );
};

export default CoursesPage;
