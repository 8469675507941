import React, { ReactNode, useEffect } from 'react';

import { Modal, ModalProps } from 'antd';
import { cx } from 'shared/utils';

import './styles.scss';

interface IModalProps extends ModalProps {
  show: boolean;
  className: string;
  onCancel: () => void;
  component: ReactNode;
}

const maskStyle = {
  backgroundColor: 'rgb(30, 30, 30, 0.8)',
};

const BaseModal: React.FC<IModalProps> = ({ width, maskClosable, show, className, onCancel, component, ...props }) => {
  const classNames = cx('cb-modal', className);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        return;
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    <Modal
      width={width}
      title={null}
      closable={false}
      maskClosable={maskClosable || false}
      centered
      className={classNames}
      open={show}
      onCancel={onCancel}
      footer={null}
      styles={{ mask: maskStyle }}
      {...props}
      keyboard={false}
    >
      {component}
    </Modal>
  );
};

export default BaseModal;
