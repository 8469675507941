import React from 'react';

import { ScriptEditor } from 'features/assignment-editor';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import BlockTitle from 'shared/ui/block-title/BlockTitle';
import { GenerateVideoButton, generateVideoScriptAction } from 'features/assignment-video-gen-buttons';
import AssignmentsHead from 'modules/assignments-head';
import ViewPendingGenerate from 'shared/ui/viewPendingGeneration';
import { useSearchParams } from 'react-router-dom';
import WordCounterView from 'shared/ui/wordCounterView';
import useWordCount from 'shared/hooks/useWordCount';
import { cleanScript, setExternalVideoUrl, useCurrentAssignment } from 'entities/assignment/model';
import { PlayCircleFilled } from '@ant-design/icons';
import Button from 'shared/ui/button';
import RegeneratePopup from 'modules/regenerate-popup';
import IconMagic from 'shared/ui/icons/magic/IconMagic';

import { useOwner } from '../../../../shared/hooks/useProjectOwner';

type Props = {
  onChangeTab: (key: string) => void;
};

const AssignmentVideoScript: React.FC<Props> = ({ onChangeTab }) => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const assignmentId = searchParams.get('str');
  const { t } = useTranslation();
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const { loading, assignment } = useCurrentAssignment();
  const projectGenerate = useAppSelector((state) => state.project.generation);
  const file = useAppSelector((state) => state?.assignment?.assignment?.assignment?.video?.script?.file);
  const { isOwner } = useOwner();

  const disabled = !isOwner || projectGenerate || loading || !!selectedVersion;

  const originalDataStr = file?.related_files[0]?.original_data;
  const data = originalDataStr ? JSON.parse(originalDataStr) : undefined;

  const { words, charsWithSpace, charsWithoutSpace } = useWordCount(data, !projectGenerate || !!file);

  const onClickRegen = (text: string) => {
    if (assignment?.id) {
      void dispatch(generateVideoScriptAction(assignment?.id, text, true));
      void dispatch(cleanScript());
    }
  };

  const onSwitchToAttach = () => {
    dispatch(setExternalVideoUrl(true));
    onChangeTab('video');
  };

  return (
    <div className="assign-video-content__wrap">
      <div className="assignment-select__block">
        <AssignmentsHead />
      </div>
      <div className="assignment-select__block">
        <div className="d-inline-flex justify-space-between">
          <BlockTitle className="mb-0" text={t('assignments.video.scriptTitle')} />
          {projectGenerate || !file ? null : (
            <RegeneratePopup onSubmit={onClickRegen}>
              <Button disabled={!isOwner} size="large" exSize="xl" type="primary">
                {t('assignments.video.regen')}
                <IconMagic size={16} />
              </Button>
            </RegeneratePopup>
          )}
        </div>

        {projectGenerate || !file ? (
          <ViewPendingGenerate
            title={t<string>('assignments.video.scriptPending.text1')}
            text={t<string>('assignments.video.scriptPending.text2')}
          />
        ) : (
          <ScriptEditor file={file} />
        )}
      </div>
      <div className="assign-video-settings__footer">
        <Button size="large" exSize="xl" type="primary" disabled={disabled} onClick={onSwitchToAttach}>
          {t('assignments.video.attachUrl')}
          <PlayCircleFilled style={{ fontSize: 16 }} />
        </Button>
        <span className="assign-video-settings__footer--or">{t('assignments.video.or')}</span>
        <GenerateVideoButton disable={disabled} structureItemId={assignmentId} onChangeTab={onChangeTab} />
      </div>
      {!projectGenerate && !!file ? (
        <WordCounterView
          className="assign-video-words"
          words={words}
          charsWithSpace={charsWithSpace}
          charsWithoutSpace={charsWithoutSpace}
        />
      ) : null}
    </div>
  );
};

export default AssignmentVideoScript;
