import React, { memo } from 'react';

import { CBIconProps } from '../types';
const NoData: React.FC<CBIconProps> = ({ size }) => {
  const styles = {
    width: size || 106,
    height: size || 100,
  };
  return (
    <span style={styles} className="cb-icon">
      <svg style={styles} width="106" height="100" viewBox="0 0 106 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M45.1033 99.9926C69.737 99.9926 89.7066 96.2613 89.7066 91.6584C89.7066 87.0556 69.737 83.3242 45.1033 83.3242C20.4696 83.3242 0.5 87.0556 0.5 91.6584C0.5 96.2613 20.4696 99.9926 45.1033 99.9926Z"
          fill="#F5F5F7"
          fillOpacity="0.8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.7854 67.1734L65.0452 47.8017C64.29 46.8899 63.186 46.3379 62.0235 46.3379H28.1814C27.0196 46.3379 25.9156 46.8899 25.1604 47.8017L9.4209 67.1734V77.2938H80.786V67.1734H80.7854Z"
          fill="#AEB8C2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7566 21.3359H67.4493C68.1473 21.3359 68.8166 21.6132 69.3102 22.1067C69.8037 22.6002 70.0809 23.2696 70.0809 23.9675V85.378C70.0809 86.076 69.8037 86.7453 69.3102 87.2388C68.8166 87.7324 68.1473 88.0096 67.4493 88.0096H22.7566C22.0586 88.0096 21.3893 87.7324 20.8958 87.2388C20.4023 86.7453 20.125 86.076 20.125 85.378V23.9675C20.125 23.2696 20.4023 22.6002 20.8958 22.1067C21.3893 21.6132 22.0586 21.3359 22.7566 21.3359V21.3359Z"
          fill="#F5F5F7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.5777 27.8848H61.6283C61.9773 27.8848 62.312 28.0234 62.5587 28.2702C62.8055 28.5169 62.9441 28.8516 62.9441 29.2006V45.6196C62.9441 45.9686 62.8055 46.3033 62.5587 46.55C62.312 46.7968 61.9773 46.9354 61.6283 46.9354H28.5777C28.2287 46.9354 27.894 46.7968 27.6472 46.55C27.4005 46.3033 27.2619 45.9686 27.2619 45.6196V29.2006C27.2619 28.8516 27.4005 28.5169 27.6472 28.2702C27.894 28.0234 28.2287 27.8848 28.5777 27.8848ZM28.75 54.0782H61.4559C61.8506 54.0782 62.2291 54.235 62.5082 54.5141C62.7873 54.7931 62.9441 55.1717 62.9441 55.5663C62.9441 55.961 62.7873 56.3395 62.5082 56.6186C62.2291 56.8977 61.8506 57.0545 61.4559 57.0545H28.75C28.3553 57.0545 27.9768 56.8977 27.6977 56.6186C27.4186 56.3395 27.2619 55.961 27.2619 55.5663C27.2619 55.1717 27.4186 54.7931 27.6977 54.5141C27.9768 54.235 28.3553 54.0782 28.75 54.0782ZM28.75 61.817H61.4559C61.8507 61.817 62.2293 61.9738 62.5085 62.253C62.7876 62.5321 62.9444 62.9107 62.9444 63.3055C62.9444 63.7003 62.7876 64.0789 62.5085 64.358C62.2293 64.6372 61.8507 64.794 61.4559 64.794H28.75C28.3552 64.794 27.9766 64.6372 27.6975 64.358C27.4184 64.0789 27.2615 63.7003 27.2615 63.3055C27.2615 62.9107 27.4184 62.5321 27.6975 62.253C27.9766 61.9738 28.3552 61.817 28.75 61.817ZM80.6402 90.4367C80.1303 92.4571 78.3395 93.9631 76.2092 93.9631H13.9967C11.8665 93.9631 10.0757 92.4565 9.56646 90.4367C9.46934 90.0517 9.42029 89.6562 9.42041 89.2591V67.1756H26.7349C28.6474 67.1756 30.1888 68.7861 30.1888 70.7413V70.7677C30.1888 72.7223 31.748 74.3006 33.6605 74.3006H56.5454C58.4579 74.3006 60.0171 72.7078 60.0171 70.7525V70.7446C60.0171 68.7894 61.5586 67.1749 63.4711 67.1749H80.7855V89.2598C80.7855 89.6657 80.7349 90.0598 80.6402 90.4367Z"
          fill="#DCE0E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.8166 22.4026L78.3232 24.1461C78.2078 24.1909 78.082 24.2023 77.9604 24.1789C77.8388 24.1555 77.7263 24.0982 77.6357 24.0137C77.5452 23.9292 77.4804 23.8208 77.4487 23.7011C77.4171 23.5813 77.4199 23.4551 77.4568 23.3368L78.7311 19.2533C77.0278 17.3164 76.0278 14.9546 76.0278 12.4059C76.0278 5.83026 82.6844 0.5 90.8962 0.5C99.1061 0.5 105.763 5.83026 105.763 12.4059C105.763 18.9816 99.1068 24.3118 90.8956 24.3118C87.9166 24.3118 85.143 23.6105 82.8166 22.4026Z"
          fill="#DCE0E6"
        />
        <path
          d="M96.7528 14.5555C97.7879 14.5555 98.6271 13.7264 98.6271 12.7035C98.6271 11.6807 97.7879 10.8516 96.7528 10.8516C95.7176 10.8516 94.8784 11.6807 94.8784 12.7035C94.8784 13.7264 95.7176 14.5555 96.7528 14.5555Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.9132 14.3248H83.1646L85.0711 11.084L86.9132 14.3248ZM89.256 11.084H92.5356V14.3248H89.256V11.084Z"
          fill="white"
        />
      </svg>
    </span>
  );
};

export default memo(NoData);
