import { gql } from '@apollo/client';

import { OBJECT_TYPE } from './fragments';

export const OBJECT_TYPES = gql`
  query ObjectTypes($language: String!) {
    outline_builder_course_object_types(language: $language) {
      ...ObjectType
    }
  }
  ${OBJECT_TYPE}
`;
