import { gql } from '@apollo/client';

import {
  ACTIVE_MODULE_OUTCOMES,
  ACTIVE_PROJECT_VERSION,
  ACTIVE_SUB_MODULE_OUTCOMES,
  OUTLINE_PROJECT,
} from './fragments';

export const OUTLINE_BUILDER_PROJECT = gql`
  query OutlineBuilderProject($id: Int, $public_slug: String, $language: String) {
    outline_builder_project(id: $id, public_slug: $public_slug, language: $language) {
      ...OutlineProject
      active_project_version {
        ...ActiveProjectVersion
        active_modules_outcomes {
          ...ActiveModuleOutcomes
          active_sub_modules_outcomes {
            ...ActiveSubModuleOutcomes
          }
        }
      }
    }
  }
  ${OUTLINE_PROJECT}
  ${ACTIVE_PROJECT_VERSION}
  ${ACTIVE_MODULE_OUTCOMES}
  ${ACTIVE_SUB_MODULE_OUTCOMES}
`;
