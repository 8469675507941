import { selectedVideoProvider } from 'app/store/app.selectors';
import { useQuery } from '@apollo/client';

import { useAppSelector } from '../../store/hooks';
import { VIDEO_PROVIDER_AVATAR_LANGUAGES } from '../schemas/videoProvider';

type VideoProviderAvatarLangData = {
  cob_video_provider_avatar_languages: {
    id: string;
    name: string;
    locale_bcp47_id: string;
  }[];
};

const useVideoProviderAvatarLang = () => {
  const provider = useAppSelector(selectedVideoProvider);
  const { data, loading } = useQuery<VideoProviderAvatarLangData>(VIDEO_PROVIDER_AVATAR_LANGUAGES, {
    variables: {
      videoProvider: provider,
    },
    fetchPolicy: 'cache-first',
  });

  return {
    data: data?.cob_video_provider_avatar_languages,
    loading,
  };
};

export default useVideoProviderAvatarLang;
