import React, { memo, useCallback } from 'react';

import Button from 'shared/ui/button';

import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { CreateCourseError, setError } from '../store';
import { startAutogenAction } from '../store/actions';

import './styles.scss';

const ErrorBlock = () => {
  const error = useAppSelector((state) => state.createCourse.error);
  const project = useAppSelector((state) => state.project.project);
  const dispatch = useAppDispatch();

  const onRegen = useCallback(() => {
    if (project?.id) {
      void dispatch(startAutogenAction(project.id));
      dispatch(setError(null));
    }
  }, [dispatch, project?.id]);

  if (!error || error === CreateCourseError.file) {
    return null;
  }

  return (
    <div id={error} className="create-course-error">
      <span className="create-course-error__text">
        {error === CreateCourseError.recognise
          ? 'Failed to recognise the data. Try uploading another file'
          : 'Some error'}
      </span>
      {error === CreateCourseError.generation ? (
        <Button type="text" onClick={onRegen}>
          regenerate
        </Button>
      ) : null}
    </div>
  );
};

export default memo(ErrorBlock);
