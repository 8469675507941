import { AppDispatch, store } from 'shared/store/store';
import {
  postCreateFileRequest,
  putSetFileStateRequest,
  putUploadFileRequest,
  updateFileByIdRequest,
} from 'shared/api/requests/file';
import { UploadFile } from 'antd/es/upload/interface';
import { messageError } from 'shared/utils';
import { setStudioSlideLoading } from 'entities/studio/model';
import { RcFile } from 'antd/es/upload';
import { chunkSize, createChunks } from 'shared/api/fetches/fileUpload';
import { UploadStateEnum } from 'shared/types/entities';

import { assignmentVideoPresentationRequest, onUploadSlideRequest } from '../api';
import { changeDocumentSectionRequest } from '../../editor/api';

export const uploadPresentationAction = (file: UploadFile) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setStudioSlideLoading(true));
    let fileId: string | number | null = null;
    const chunks = createChunks(file.originFileObj as RcFile, chunkSize);

    const assignmentId = store.getState().assignment.assignment?.id || '';
    const presentationFile = store.getState().studio.presentationFile;

    if (presentationFile?.id) {
      fileId = presentationFile?.id;
    }

    if (!fileId) {
      const fileResponse = await postCreateFileRequest('presentation');
      fileId = fileResponse.id;
      //File binding to the script
      await assignmentVideoPresentationRequest(assignmentId, { presentation_file_id: fileId });
    }

    if (fileId && presentationFile?.state === UploadStateEnum.UPLOADED) {
      await updateFileByIdRequest('presentation', fileId.toString());
    }

    let iterator = 1;
    let loaded = 0;
    let stopLoop = false;

    if (!stopLoop) {
      if (fileId) {
        for await (const chunk of chunks) {
          const formData = new FormData();
          formData.append('part', iterator.toString());
          formData.append('file', chunk);

          await putUploadFileRequest(Number(fileId), formData)
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            .then(() => {
              loaded = loaded + 1;
              iterator = iterator + 1;

              if (loaded === chunks.length) {
                console.log('Process is complete, counter', loaded);
                putSetFileStateRequest(Number(fileId), { state: UploadStateEnum.UPLOADED }).finally(() => {
                  stopLoop = true;
                  dispatch(setStudioSlideLoading(false));
                });
              }
            })
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            .catch((err) => {
              messageError(err);
              putSetFileStateRequest(Number(fileId), { state: UploadStateEnum.CANCELLED }).finally(() => {
                stopLoop = true;
                dispatch(setStudioSlideLoading(false));
              });
            });
        }
      }
    }
  };
};

export const uploadSlidesAction = (fileList: UploadFile[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setStudioSlideLoading(true));
    let fileId = null;

    const assignmentId = store.getState().assignment.assignment?.id || '';
    const presentationFile = store.getState().studio.presentationFile;

    if (presentationFile?.id) {
      fileId = presentationFile?.id;
    }

    if (!fileId) {
      const fileResponse = await postCreateFileRequest('presentation');
      fileId = fileResponse.id;
      //File binding to the script
      await assignmentVideoPresentationRequest(assignmentId, { presentation_file_id: fileId });
      await putSetFileStateRequest(fileId, { state: UploadStateEnum.UPLOADED });
    }

    let iterator = 1;
    let loaded = 0;
    let stopLoop = false;

    if (fileId) {
      for await (const f of fileList) {
        if (!stopLoop) {
          const formData = new FormData();

          formData.append('file', (f?.originFileObj || f) as Blob);

          await onUploadSlideRequest(fileId.toString(), formData)
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            .then(() => {
              loaded = loaded + 1;
              iterator = iterator + 1;
            })
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            .catch((err) => {
              messageError(err);
              stopLoop = true;
            });

          if (loaded === fileList.length) {
            console.log('Process is complete, counter', loaded);
            stopLoop = true;
            dispatch(setStudioSlideLoading(false));
          }
        }
      }
    }
  };
};

export const linkSlideToSectionAction = (sectionId: string, slideId: string | null, text: string) => {
  return async () => {
    const fileId = store.getState().studio.file?.id;

    if (fileId && sectionId) {
      const payload = {
        text,
        presentation_slide_id: slideId ? Number(slideId) : null,
      };

      await changeDocumentSectionRequest(fileId, sectionId, payload).catch((err) => messageError(err));
    }
  };
};
