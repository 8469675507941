import React, { useState } from 'react';
import type { ChangeEvent, FC } from 'react';

import AssignmentsHead from 'modules/assignments-head';
import { useTranslation } from 'react-i18next';
import BlockTitle from 'shared/ui/block-title';
import Input from 'shared/ui/input';
import { useCurrentAssignment } from 'entities/assignment/model';
import { useAppSelector } from 'shared/store/hooks';
import { useOwner } from 'shared/hooks/useProjectOwner';
import GenerateKeyPoints from 'features/assignment-reading/ui/GenerateKeyPoints';
import TextArea from 'shared/ui/textArea';

type Props = {
  onChangeTab: (key: string) => void;
};

export const ReadingSettings: FC<Props> = ({ onChangeTab }) => {
  const [duration, setDuration] = useState<number | string>(5);
  const [text, setText] = useState('');
  const { t } = useTranslation();

  const { loading, assignment } = useCurrentAssignment();
  const projectGenerate = useAppSelector((state) => state.project.generation);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);

  const { isOwner } = useOwner();

  const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/, '');
    const validated = Number(value) > 10 ? 10 : value;
    setDuration(validated);
  };

  const handleChangeText = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const disabled = !isOwner || projectGenerate || loading || !!selectedVersion;

  return (
    <div className="assign-reading-content__wrap">
      <div className="assignment-select__block">
        <AssignmentsHead />
      </div>
      <div className="assignment-select__block w-auto">
        <BlockTitle className="mb-0" text={t('assignments.reading.readingTime')} />
        <div>
          <Input disabled={disabled} style={{ width: 100 }} size="large" value={duration} onChange={onHandleChange} />
          <BlockTitle className="ml-2" text={t('assignments.reading.minutes')} />
        </div>
      </div>
      <div className="assignment-select__block w-auto">
        <BlockTitle className="mb-0" text={t('assignments.reading.addInstructions')} />
        <TextArea
          placeholder={t<string>('assignments.reading.addInstructionsPlaceholder')}
          size="large"
          autoSize={{ minRows: 2, maxRows: 10 }}
          disabled={disabled}
          value={text}
          onChange={handleChangeText}
        />
      </div>
      <div className="assign-reading__footer">
        <GenerateKeyPoints
          text={text}
          structureItemId={assignment?.id}
          duration={Number(duration)}
          disabled={disabled || !duration || Number(duration) < 1}
          onChangeTab={onChangeTab}
        />
      </div>
    </div>
  );
};
