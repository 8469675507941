import { client } from '../index';
import { ASSIGNMENT_CONTEXT_FILES } from '../schemas/assignmentContectFileState';
import { AssignmentItemContextFileStateData } from '../../types/entities';

export const getAssignContextFiles = (assignId: string) => {
  return client.query<AssignmentItemContextFileStateData>({
    query: ASSIGNMENT_CONTEXT_FILES,
    fetchPolicy: 'network-only',
    variables: {
      id: assignId,
    },
  });
};
