import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TutorialState {
  run: boolean;
  stepIndex: number;
  tourActive: boolean;
  tourFinish: boolean;
  tourId: number | null;
  assignmentTourId: number | null;
}

const initialState: TutorialState = {
  run: false,
  stepIndex: 0,
  tourActive: false,
  tourFinish: false,
  tourId: null,
  assignmentTourId: null,
};

export const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    startTutor: (state) => {
      state.tourActive = true;
    },
    finishTutor: (state) => {
      state.tourActive = false;
      state.tourFinish = true;
      state.run = false;
    },
    runTutor: (state) => {
      state.run = true;
    },
    stopTutor: (state) => {
      state.run = false;
    },
    setStepIndex: (state, action: PayloadAction<number>) => {
      state.stepIndex = action.payload;
    },
    setTourId: (state, action: PayloadAction<number | null>) => {
      state.tourId = action.payload;
    },
    setTourAssignmentId: (state, action: PayloadAction<number | null>) => {
      state.assignmentTourId = action.payload;
    },
  },
});

export const { setStepIndex, startTutor, finishTutor, runTutor, stopTutor, setTourId, setTourAssignmentId } =
  tutorialSlice.actions;
export default tutorialSlice.reducer;
