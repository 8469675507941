import React from 'react';

import { Table as ATable, TableProps } from 'antd';

import { cx } from '../../utils';

type Props = TableProps<any>;

const Table = ({ ...props }: Props) => {
  const classNames = cx('cob-table', props.className);
  return <ATable {...props} rowKey={(record) => record.id} className={classNames} />;
};

export default Table;
