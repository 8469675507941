import { useCallback, useEffect, useRef } from 'react';

import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useParams, useSearchParams } from 'react-router-dom';
import { message } from 'antd';
import { DEV_TEST, SOCKET_COB, SOCKET_TEST } from 'shared/config/urls';
import { localStorageService } from 'shared/services/localStorageService';
import { useAppDispatch } from 'shared/store/hooks';
import { messageGeneration } from 'shared/utils';
import { getStructureAction } from 'widgets/course-structure/store/actions';
import { getAssignmentItemAction, setSystemMessages } from 'entities/assignment/model';

import { getProjectAction } from './actions';

import { setProjectGeneration } from './index';

const useProjectWebSockets = () => {
  const { id, lang } = useParams();
  const [searchParams] = useSearchParams();
  const didUnmount = useRef(false);
  const progress = useRef(false);
  const token = localStorageService.getAuthData()?.access;
  const dispatch = useAppDispatch();
  const isConnect = Boolean(id && id !== 'new' && token);
  const selectedId = searchParams.get('str');

  const socketUrl =
    process.env.NODE_ENV === 'development'
      ? `${SOCKET_COB}/outline-builder/ws/project/${id}/?token=${token}`
      : `wss://${window.location.host}/outline-builder/ws/project/${id}/?token=${token}`;

  const onActions = useCallback(
    (event: WebSocketEventMap['message']) => {
      const data = JSON.parse(event.data);
      const type = data?.message?.type;

      if (data?.message?.message?.generation_in_progress && !progress.current) {
        progress.current = true;
        dispatch(setProjectGeneration(true));
        messageGeneration();
      }
      if (!data?.message?.message?.generation_in_progress && progress.current) {
        progress.current = false;
        dispatch(setProjectGeneration(false));
        message.destroy();
      }
      if (
        type === 'project_version_generated' ||
        type === 'project_version_copied' ||
        type === 'primary_outcome_generated' ||
        type === 'secondary_outcome_generated'
      ) {
        if (id && lang) {
          void dispatch(getProjectAction(id, true, undefined, lang));
        }
      }
      if (type === 'structure_item_created') {
        if (id && lang) {
          void dispatch(getStructureAction(id, lang, true));
        }
      }
      if (
        type === 'question_created' ||
        type === 'answer_created' ||
        type === 'video_point_created' ||
        type === 'video_script_created'
      ) {
        if (selectedId) {
          void dispatch(getAssignmentItemAction(selectedId, true));
        }
      }
      if (type === 'video_generation_updated') {
        void dispatch(setSystemMessages(data.message.message));
      }
    },
    [dispatch, id, lang, selectedId]
  );

  const { readyState } = useWebSocket(
    socketUrl,
    {
      shouldReconnect: () => {
        return !didUnmount.current;
      },
      onMessage: (event: WebSocketEventMap['message']) => onActions(event),
      reconnectAttempts: 10,
      reconnectInterval: 2000,
    },
    isConnect
  );

  // For debug
  // const connectionStatus = {
  //   [ReadyState.CONNECTING]: 'Connecting',
  //   [ReadyState.OPEN]: 'Open',
  //   [ReadyState.CLOSING]: 'Closing',
  //   [ReadyState.CLOSED]: 'Closed',
  //   [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  // }[readyState];
  // console.log(connectionStatus);

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);
};

export default useProjectWebSockets;
