import React, { ChangeEvent, memo } from 'react';

import BlockTitle from 'shared/ui/block-title';
import TextArea from 'shared/ui/textArea';

import './styles.scss';

type Props = {
  name: string;
  title: string;
  label: string;
  sampleText: string;
  disabled: boolean;
  value: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: () => void;
};

const CurriculumFormBlock: React.FC<Props> = ({
  name,
  title,
  label,
  sampleText,
  disabled,
  value,
  onChange,
  onBlur,
}) => {
  return (
    <div className="curriculum-form-block">
      <BlockTitle text={title} type="h5" />
      <div className="curriculum-form-block__row">
        <div className="curriculum-form-block__col curriculum-form-block__col-full">
          <span className="curriculum-form-block__label">{label}</span>
          <TextArea
            name={name}
            autoSize={{ minRows: 2, maxRows: 50 }}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        </div>
        <div className="curriculum-form-block__col curriculum-form-block__col-short">
          <span className="curriculum-form-block__label">Sample</span>
          <span className="curriculum-form-block__sample">{sampleText}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(CurriculumFormBlock);
