import { OutputData } from '@editorjs/editorjs/types/data-formats/output-data';

import api, { ApiModules } from '../../api';
import { ENDPOINTS } from '../../constants';
import { UploadStateEnum } from '../../../types/entities';

export type CreateFileResponse = {
  file_list: any;
  file_type: string;
  id: number;
  name: string | null;
  state: UploadStateEnum;
  uploaded_by: {
    id: number;
    first_name: string;
    last_name: string;
  };
};

type CreateFilePayload = {
  file_name: string;
};

export const postCreateFileRequest = (fileType: string, payload?: CreateFilePayload): Promise<CreateFileResponse> =>
  api(ApiModules.ARTEFACT_MANAGEMENT).post(ENDPOINTS.CREATE_FILE(fileType), payload);

export const putUploadFileRequest = (fileId: number, payload: any, config?: any): Promise<any> =>
  api(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.UPLOAD_FILE(fileId), payload, config);

export const putSetFileStateRequest = (fileId: number, params: any): Promise<any> =>
  api(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.FILE_STATE(fileId), params);

export const deleteFileByIdRequest = (fileType: string, fileId: string) =>
  api(ApiModules.ARTEFACT_MANAGEMENT).delete(ENDPOINTS.DELETE_FILE(fileType, fileId));

export const updateFileByIdRequest = (fileType: string, fileId: string) =>
  api(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.DELETE_FILE(fileType, fileId));

export const deleteOutlineBuilderContextFile = (id: string) =>
  api(ApiModules.OUTLINE_BUILDER).delete(ENDPOINTS.OUTLINE_BUILDER_CONTEXT_FILE(id));

export const deleteOutlineBuilderAssignmentContextFile = (id: string) =>
  api(ApiModules.OUTLINE_BUILDER).delete(ENDPOINTS.OUTLINE_BUILDER_ASSIGNMENT_CONTEXT_FILE(id));

// EDITOR

export type UpdateDocumentPayload = OutputData;

export const onUpdateDocument = (fileId: string, payload: UpdateDocumentPayload) =>
  api(ApiModules.ARTEFACT_MANAGEMENT).put(ENDPOINTS.UPDATE_DOCUMENT(fileId), payload);
