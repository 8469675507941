import React, { useEffect, useCallback } from 'react';

import Button from 'shared/ui/button';
import modalService from 'shared/services/modalService';
import { useTranslation } from 'react-i18next';
import ContentLanguageButton from 'modules/content-language-button';
import SwitchLanguage from 'modules/switch-language';
import { PATH_NAME } from 'pages/constants';
import HeaderSmall from 'shared/components/header-small';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';
import { useLocation, useParams } from 'react-router-dom';

import { getPublicProjectAction } from '../../modules/project/actions';
import { getStructureAction } from '../course-structure/store/actions';

const CourseHeaderPublic: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useCustomNavigator();
  const { id, lang } = useParams();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const { pathname } = location;
  const isPublic = pathname.includes('public');

  const project = useAppSelector((state) => state.project.project);

  useEffect(() => {
    if (id && id !== 'new' && lang) {
      void dispatch(getPublicProjectAction(id, lang));
      if (project?.id) {
        void dispatch(getStructureAction(project?.id, lang));
      }
    }
  }, [dispatch, id, isPublic, lang, project?.id]);

  const onClickJoin = useCallback(() => {
    navigate(PATH_NAME.signup);
  }, [navigate]);

  const title = project?.active_project_version?.generated_title || 'New course';

  return (
    <div className="course-header">
      <HeaderSmall
        title={title}
        actions={
          <>
            {project?.languages?.length && project?.languages?.length > 1 ? <ContentLanguageButton /> : null}
            <Button type="primary" onClick={onClickJoin}>
              {t('common.joinToCreate')}
            </Button>
            <SwitchLanguage />
          </>
        }
      />
    </div>
  );
};

export default CourseHeaderPublic;
