import React from 'react';

import Button from 'shared/ui/button/Button';
import { useAppDispatch } from 'shared/store/hooks';
import { useTranslation } from 'react-i18next';

import { tokenSubscriptionAction } from '../actions/actions';

const PortalButton: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClick = () => {
    void dispatch(tokenSubscriptionAction());
  };

  return (
    <Button size="large" exSize="xl" type="primary" onClick={onClick}>
      {t('subscribe.manage')}
    </Button>
  );
};

export default PortalButton;
