import React from 'react';

import { useAppSelector } from 'shared/store/hooks';
import { selectReading } from 'entities/assignment/model/assignment.selectors';
import { useTranslation } from 'react-i18next';
import BlockTitle from 'shared/ui/block-title';
import ViewPendingGenerate from 'shared/ui/viewPendingGeneration';
import { ScriptEditor } from 'features/assignment-editor';
import useWordCount from 'shared/hooks/useWordCount';
import WordCounterView from 'shared/ui/wordCounterView';
import AssignmentsHead from 'modules/assignments-head';

// import RegeneratePopup from '../../../../modules/regenerate-popup';
// import Button from '../../../../shared/ui/button/Button';
// import IconMagic from '../../../../shared/ui/icons/magic/IconMagic';
// import { generateVideoScriptAction } from '../../../../features/assignment-video-gen-buttons';
// import { cleanScript, useCurrentAssignment } from '../../../../entities/assignment/model';
// import { generateReadingAction } from '../../../../features/assignment-reading';
// import { useOwner } from '../../../../shared/hooks/useProjectOwner';

export const ReadingContent = () => {
  const { t } = useTranslation();
  //const dispatch = useAppDispatch();
  const readingData = useAppSelector(selectReading);
  const projectGenerate = useAppSelector((state) => state.project.generation);
  //const { assignment } = useCurrentAssignment();
  const originalDataStr = readingData?.file?.related_files[0]?.original_data;
  const data = originalDataStr ? JSON.parse(originalDataStr) : undefined;
  //const { isOwner } = useOwner();

  // const onClickRegen = () => {
  //   if (assignment?.id) {
  //     void dispatch(generateReadingAction(assignment?.id));
  //     void dispatch(cleanScript());
  //   }
  // };

  const { words, charsWithSpace, charsWithoutSpace } = useWordCount(data, !projectGenerate || !!readingData?.file);

  return (
    <div className="assign-reading-content__wrap">
      <div className="assignment-select__block">
        <AssignmentsHead />
      </div>
      {!!readingData?.file || projectGenerate ? (
        <div className="assignment-select__block">
          <div className="d-inline-flex justify-space-between">
            {readingData?.file && !projectGenerate ? (
              <BlockTitle className="mb-0" text={t('assignments.reading.titleEditor')} />
            ) : null}
            {/*{projectGenerate || !readingData?.file ? null : (*/}
            {/*  <RegeneratePopup onSubmit={onClickRegen}>*/}
            {/*    <Button disabled={!isOwner} size="large" exSize="xl" type="primary">*/}
            {/*      {t('assignments.video.regen')}*/}
            {/*      <IconMagic size={16} />*/}
            {/*    </Button>*/}
            {/*  </RegeneratePopup>*/}
            {/*)}*/}
          </div>

          {projectGenerate ? (
            <ViewPendingGenerate
              title={t<string>('assignments.reading.readingPending.text1')}
              text={t<string>('assignments.reading.readingPending.text2')}
            />
          ) : (
            <ScriptEditor file={readingData?.file} />
          )}
        </div>
      ) : null}
      {!projectGenerate && !!readingData?.file ? (
        <WordCounterView
          className="assign-video-words"
          words={words}
          charsWithSpace={charsWithSpace}
          charsWithoutSpace={charsWithoutSpace}
        />
      ) : null}
    </div>
  );
};
