import React, { memo } from 'react';

import { Slider as ASlider } from 'antd';
import { SliderSingleProps } from 'antd/es/slider';
import { cx } from 'shared/utils';

import './styles.scss';

const Slider: React.FC<SliderSingleProps> = (props) => {
  const classNames = cx('cb-slider', props.className);
  return <ASlider {...props} className={classNames} />;
};

export default memo(Slider);
