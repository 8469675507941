import { client } from '../index';
import { PROJECT_VERSION } from '../schemas/project-version';
import { ActiveProjectVersion } from '../../types/entities';

type ProjectVerData = {
  outline_builder_project_version: ActiveProjectVersion;
};

export const getProjectVersion = (projectVerId: string) => {
  return client.query<ProjectVerData>({
    query: PROJECT_VERSION,
    variables: {
      projectVerId,
    },
    fetchPolicy: 'network-only',
  });
};
