import React, { memo, ReactNode } from 'react';

import './styles.scss';

type Props = {
  children: ReactNode;
};

const TourTitle: React.FC<Props> = ({ children }) => {
  return <span className="tour__title">{children}</span>;
};

export default memo(TourTitle);
