import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StructureItemState {
  loading: boolean;
  isTypeMenuOpen: boolean;
  addLesson: string | null;
  addElementType: string | null;
  showAddElement: string | null;
}

const initialState: StructureItemState = {
  loading: false,
  isTypeMenuOpen: false,
  addLesson: null,
  addElementType: null,
  showAddElement: null,
};

export const structureItemSlice = createSlice({
  name: 'structureItem',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    finishLoading: (state) => {
      state.loading = false;
    },
    setTypeMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isTypeMenuOpen = action.payload;
    },
    setAddLesson: (state, action: PayloadAction<string | null>) => {
      state.addLesson = action.payload;
    },
    setAddElement: (state, action: PayloadAction<string | null>) => {
      state.showAddElement = action.payload;
    },
    setAddElementType: (state, action: PayloadAction<string | null>) => {
      state.addElementType = action.payload;
    },
  },
});

export const { startLoading, finishLoading, setTypeMenuOpen, setAddLesson, setAddElement, setAddElementType } =
  structureItemSlice.actions;
export default structureItemSlice.reducer;
