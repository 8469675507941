import { client } from 'shared/graphql';
import {
  STRUCTURE_ITEM_STUDIO_FILE,
  STRUCTURE_ITEM_STUDIO_PRESENTATION_FILE,
} from 'shared/graphql/schemas/structureItemStudioFile';
import { SCRIPT_SLIDES_LINKS } from 'shared/graphql/schemas/scriptPresentationSlides';

import type {
  StructureItemStudioFileData,
  StructureItemStudioPresentationFileData,
  StructureItemStudioSlidesData,
} from '../model/studio';

export const getStructureItemStudioFileQuery = (structureId: string) => {
  return client.query<StructureItemStudioFileData>({
    query: STRUCTURE_ITEM_STUDIO_FILE,
    fetchPolicy: 'network-only',
    variables: {
      id: structureId,
    },
  });
};

export const getStructureItemStudioPresentationFileQuery = (structureId: string) => {
  return client.query<StructureItemStudioPresentationFileData>({
    query: STRUCTURE_ITEM_STUDIO_PRESENTATION_FILE,
    fetchPolicy: 'network-only',
    variables: {
      id: structureId,
    },
  });
};

export const getScriptSlidesLinksQuery = (scriptId: number) => {
  return client.query<StructureItemStudioSlidesData>({
    query: SCRIPT_SLIDES_LINKS,
    fetchPolicy: 'network-only',
    variables: {
      scriptId,
    },
  });
};
