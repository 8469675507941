import React, { memo } from 'react';

import { CBIconProps } from '../types';

import '../styles.scss';

const IconMoodle: React.FC<CBIconProps> = ({ size }: CBIconProps) => {
  const styles = {
    width: size || 24,
    height: size || 24,
  };
  return (
    <span style={styles} className="cb-icon">
      <svg style={styles} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.98817 2.80933L7.08242 4.26334C7.08242 4.26334 7.58822 4.67537 7.84845 4.97956C7.96768 5.06222 8.07472 5.15729 8.16957 5.26477C8.52346 4.79924 9.16872 4.56651 10.1054 4.56651C10.9618 4.56651 11.6412 4.76355 12.1435 5.15771C12.7144 5.59863 12.9999 6.24897 13 7.10869V11.2794H11.0814V7.3411C11.0814 6.51776 10.756 6.10605 10.1052 6.10599C9.45439 6.10589 9.12882 6.51763 9.12849 7.3411V11.2794H7.21007V7.3411C7.21007 6.75503 7.04807 6.37753 6.72407 6.2086C6.30736 6.49491 5.83968 6.68527 5.34406 6.7701C5.29779 6.93114 5.2747 7.12147 5.27477 7.3411V11.2794H3.35636V7.10869C3.35636 6.92043 3.37007 6.74221 3.39747 6.57402C3.39722 6.57392 3.39697 6.57383 3.39672 6.57374C3.32562 5.99081 3.33858 5.37075 3.33858 5.37075L1.37518 5.35113C1.35411 5.58508 1.31555 6.18664 1.36222 7.10091C1.5867 7.9333 1.59164 8.76947 1.54417 9.53419C1.52816 9.4951 1.51209 9.45617 1.49607 9.41736C1.21119 8.72723 0.940667 8.07188 1.25186 7.13086C1.20361 6.21019 1.24109 5.598 1.26294 5.34998L1.02739 5.34772L1 5.26608C3.96156 3.47205 5.52594 3.15987 8.96366 2.72046L8.98817 2.80933Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default memo(IconMoodle);
