import { gql } from '@apollo/client';

export const ASSIGNMENT_CONTEXT_FILES = gql`
  query AssignmentContextFileState($id: Int) {
    outline_builder_course_structure_item(id: $id) {
      id
      pk
      context_files {
        id
        order
        context_file {
          id
          state
          file {
            id
            name
            file_type
          }
        }
      }
    }
  }
`;
