import React from 'react';

import UserDetailsWidget from 'widgets/user-details';

const ProfilePage = () => {
  return (
    <div className="container profile-page">
      <UserDetailsWidget />
    </div>
  );
};

export default ProfilePage;
