import { gql } from '@apollo/client';

import { LEARNING_STRUCTURE, OBJECT_TYPE, STRUCTURE_ITEM_FULL } from './fragments';

export const OUTLINE_BUILDER_PROJECT_STRUCTURE_ITEM = gql`
  query OutlineBuilderProjectStructureItem($id: Int) {
    outline_builder_course_structure_item(id: $id) {
      ...StructureItemFull
      learning_structure {
        ...LearningStructure
      }
      type {
        ...ObjectType
      }
    }
  }
  ${STRUCTURE_ITEM_FULL}
  ${LEARNING_STRUCTURE}
  ${OBJECT_TYPE}
`;
