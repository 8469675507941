import React, { useCallback, useRef } from 'react';

import BlockTitle from 'shared/ui/block-title/BlockTitle';
import CourseStructureCollapse from 'modules/course-structure-collapse';
import AddStructureModule from 'modules/add-structure-module';
import { useTranslation } from 'react-i18next';
import SpinnerOverlay from 'shared/ui/spinner-overlay';
import StructureCollapseButtons from 'modules/structure-collapse-buttons';

import useCourseStructure from './useCourseStructure';

import './styles.scss';

const CourseStructure: React.FC = () => {
  const { structure, loading, projectGen, selectedVersion } = useCourseStructure();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const onScrollTop = useCallback(() => {
    if (contentRef.current) {
      contentRef.current?.scroll({ behavior: 'smooth', top: 0 });
    }
  }, []);

  return (
    <div className="course-structure">
      <>
        <div className="course-structure__head">
          <BlockTitle text={t<string>('structure.courseStructure')} />
          <StructureCollapseButtons callback={onScrollTop} />
        </div>

        <div ref={contentRef} className="course-structure__content">
          {structure?.map((item, index) => {
            return <CourseStructureCollapse canShowTour={index === 0} key={item.id} level={1} item={item} />;
          })}
          {loading || projectGen || selectedVersion ? null : <AddStructureModule nextIndex={structure?.length} />}
        </div>
      </>
      <SpinnerOverlay loading={loading} />
    </div>
  );
};

export default CourseStructure;
