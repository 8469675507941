import React, { FC, useCallback, useEffect, useRef } from 'react';

import ModalContainer from 'shared/components/modal/container';
import ModalHeader from 'shared/components/modal/header/ModalHeader';
import { useTranslation } from 'react-i18next';
import ModalContent from 'shared/components/modal/content';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { Slides } from 'features/slides';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Spinner from 'shared/ui/spinner/Spinner';
import Button from 'shared/ui/button';
import { Spin } from 'antd';
import { UploadStateEnum } from 'shared/types/entities';

import {
  deleteStudioAllSlideAction,
  getStudioFileAction,
  getStudioPresentationFileAction,
  selectPresentationFile,
  selectPresentationFileId,
  selectPresentationStatus,
  selectSlideLoading,
} from '../../model';

import './styles.scss';

type Props = {
  type?: string;
  text: string;
  sectionId?: string;
  onCancel?: () => void;
};

type Timer = ReturnType<typeof setTimeout>;

export const StudioModalSlides: FC<Props> = ({ type, text, sectionId, onCancel }) => {
  const [searchParams] = useSearchParams();
  const selectedId = searchParams.get('str');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectSlideLoading);
  const presentationFile = useAppSelector(selectPresentationFile);
  const presentationFileId = useAppSelector(selectPresentationFileId);
  const status = useAppSelector(selectPresentationStatus);
  const clearTimerRef = useRef<Timer | null>(null);

  useEffect(() => {
    if (selectedId) {
      void dispatch(getStudioPresentationFileAction(selectedId));
    }
  }, [dispatch, selectedId]);

  useEffect(() => {
    return () => {
      if (clearTimerRef.current) {
        clearTimeout(clearTimerRef.current);
      }
    };
  }, []);

  const checkStatus = useCallback(async () => {
    if (selectedId) {
      await dispatch(getStudioPresentationFileAction(selectedId, true));
    }

    if (status === UploadStateEnum.UPLOADING) {
      clearTimerRef.current = setTimeout(() => checkStatus(), 5000);
    }
  }, [dispatch, selectedId, status]);

  useEffect(() => {
    if (status === UploadStateEnum.UPLOADING) {
      void checkStatus();
    }
  }, [checkStatus, status]);

  const onDeleteAll = async () => {
    if (presentationFileId && selectedId) {
      await dispatch(deleteStudioAllSlideAction(presentationFileId));
      await dispatch(getStudioPresentationFileAction(selectedId));
    }
  };

  const onClose = async () => {
    if (selectedId) {
      await dispatch(getStudioPresentationFileAction(selectedId, true));
      await dispatch(getStudioFileAction(selectedId, true));
    }
    onCancel?.();
  };

  return (
    <ModalContainer>
      <ModalHeader
        type="h4"
        title={t<string>('assignments.video.studio.modalSlides.title')}
        extraContent={
          <Button type="primary" onClick={onDeleteAll}>
            Delete all
          </Button>
        }
        onClose={onClose}
      />
      <ModalContent className="modal-slides">
        {loading ? <Spinner variant="mask" /> : null}
        {!loading && isEmpty(presentationFile?.related_files) && status !== UploadStateEnum.UPLOADING ? (
          <Slides.DraggerUpload />
        ) : null}

        {status === UploadStateEnum.UPLOADING && !loading ? (
          <div className="modal-slides__loading">
            <Spin size="large" />
            <span className="modal-slides__loading-tip">
              {t<string>('assignments.video.studio.modalSlides.waitingDivided')}
            </span>
          </div>
        ) : null}

        {status !== UploadStateEnum.UPLOADING ? (
          <div className="modal-slides__list">
            {!isEmpty(presentationFile?.related_files) &&
              presentationFile?.related_files.map((item) => (
                <Slides.Card type={type} sectionId={sectionId} text={text} key={item.id} file={item} />
              ))}
            {!isEmpty(presentationFile?.related_files) && <Slides.DraggerUpload variant="small" />}
          </div>
        ) : null}
      </ModalContent>
    </ModalContainer>
  );
};
