import React, { useMemo } from 'react';

import { Avatar, Dropdown, MenuProps } from 'antd';
import { EuroOutlined, ExportOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';
import { PATH_NAME } from 'pages/constants';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { cx } from 'shared/utils';
import { logoutAction } from 'app/store/actions';
import IconCourseFactory from 'shared/ui/icons/cf/IconCourseFactory';
import { CompanyTypeEnum } from 'shared/types/entities';
import { useUserDetails } from 'app/hooks/useUserDetails';
import { DEV_AMAZON_API_URL } from 'shared/config/urls';

import './styles.scss';

type Props = {
  className?: string;
};

const appCompany = process.env.REACT_APP_CCM_COMPANY;
const workplaceUrl = process.env.REACT_APP_WORKPLACE_URL;

export const UserMenu = ({ className }: Props) => {
  const { t } = useTranslation();
  const { navigate } = useCustomNavigator();
  const dispatch = useAppDispatch();
  const me = useAppSelector((state) => state.app.me);
  const { companyType } = useUserDetails();
  const name = me?.name ? me?.name : `${me?.first_name || ''} ${me?.last_name || ''}`;

  const items: MenuProps['items'] = [
    {
      key: 'account',
      label: t('userMenu.account'),
      icon: <UserOutlined style={{ fontSize: 14 }} />,
    },
    {
      key: 'workplace',
      label: t('userMenu.workplace'),
      icon: (
        <span>
          <IconCourseFactory size={14} />
        </span>
      ),
      disabled: companyType !== CompanyTypeEnum.opm,
    },
    {
      key: 'billing',
      label: t('userMenu.billing'),
      icon: <EuroOutlined style={{ fontSize: 14 }} />,
    },
    {
      key: 'affiliate',
      label: t('userMenu.affiliate'),
      icon: <TeamOutlined style={{ fontSize: 14 }} />,
    },
    {
      key: 'logout',
      label: t('userMenu.logout'),
      icon: <ExportOutlined style={{ fontSize: 14 }} />,
    },
  ];

  const filteredItems = useMemo(() => {
    if (appCompany === 'wiley') {
      return items.filter((el) => el?.key !== 'workplace');
    }
    return items;
  }, [items]);

  const onClick = ({ key }: MenuInfo) => {
    if (key === 'account') {
      navigate(PATH_NAME.profile);
    }
    if (key === 'workplace') {
      const path =
        process.env.NODE_ENV === 'development' ? `${DEV_AMAZON_API_URL}/courses/` : `https://${workplaceUrl}/courses`;
      window.open(path, '_black');
    }
    if (key === 'billing') {
      navigate(PATH_NAME.billing);
    }
    if (key === 'affiliate') {
      navigate(PATH_NAME.affiliate);
    }
    if (key === 'logout') {
      void dispatch(logoutAction());
    }
  };

  const classNames = cx('user-menu__title', className);

  return (
    <Dropdown
      overlayClassName="user-menu"
      placement="bottom"
      menu={{ items: filteredItems, onClick }}
      trigger={['click']}
    >
      <div className="user-menu__wrap">
        <Avatar src={me?.icon_image || <UserOutlined />} />
        <span className={classNames}>{name}</span>
      </div>
    </Dropdown>
  );
};
