import React, { useCallback } from 'react';

import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import ModalContainer from 'shared/components/modal/container';
import ModalContent from 'shared/components/modal/content';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { removeTourProjectAction } from '../project/actions';

import { runTutor, startTutor } from './store';
import TourTitle from './ui/TourTitle';
import TourText from './ui/TourText';

import './styles.scss';

type Props = {
  onCancel: () => void;
};

const WelcomeTutorialModal: React.FC<Props> = ({ onCancel }) => {
  const dispatch = useAppDispatch();
  const projectTourId = useAppSelector((state) => state.tutorial.tourId);
  const { t } = useTranslation();

  const onClickSubmit = useCallback(() => {
    dispatch(startTutor());
    dispatch(runTutor());
    onCancel();
  }, [dispatch, onCancel]);

  const onCancelTour = useCallback(() => {
    if (projectTourId) {
      void dispatch(removeTourProjectAction(projectTourId.toString()));
    }
    onCancel();
  }, [dispatch, onCancel, projectTourId]);

  return (
    <ModalContainer className="welcome-tutor">
      <ModalContent>
        <TourTitle>{t('tour.welcome.title')}</TourTitle>
        <TourText>{t('tour.welcome.text1')}</TourText>
        <div className="welcome-tutor__actions">
          <Button size="large" type="primary" onClick={onCancelTour}>
            {t('tour.welcome.cancel')}
          </Button>
          <Button size="large" type="primary" onClick={onClickSubmit}>
            {t('tour.welcome.submit')}
          </Button>
        </div>
      </ModalContent>
    </ModalContainer>
  );
};

export default WelcomeTutorialModal;
