import React from 'react';

import { cx } from 'shared/utils';

import './styles.scss';

type Props = {
  className?: string;
  text?: string;
  color?: string;
  shape?: 'rectangle';
};

const Badge: React.FC<Props> = ({ text, color, className, shape }) => {
  const classNames = cx('cb-badge', className, {
    [`cb-badge--${shape}`]: shape,
  });
  return (
    <div style={{ backgroundColor: color }} className={classNames}>
      <span className="cb-badge__text">{text}</span>
    </div>
  );
};

export default Badge;
