import { RcFile, UploadFile } from 'antd/lib/upload/interface';
export const chunkSize = 1048576 * 5;
const presentationMimeType: string[] = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/pdf',
];

const imagesMimeType: string[] = ['image/png', 'image/jpeg', 'image/jpg'];

const allowedMimeType: string[] = [...imagesMimeType, ...presentationMimeType];

type CheckMimeTypePresentationResult = {
  isAllowed?: boolean;
  isPresentation?: boolean;
  isImages?: boolean;
  isAllImages?: boolean;
  isAllPresentations?: boolean;
};

export const checkMimeTypePresentation = (data: RcFile | UploadFile[]): CheckMimeTypePresentationResult => {
  if (Array.isArray(data)) {
    const isImages = data.every((el) => imagesMimeType.includes(el?.originFileObj?.type || el?.type || ''));
    const isPresentation = data.every((el) => presentationMimeType.includes(el?.originFileObj?.type || el?.type || ''));
    const isAllowed = data.every((el) => allowedMimeType.includes(el?.originFileObj?.type || el?.type || ''));
    return {
      isAllImages: isImages,
      isAllPresentations: isPresentation,
      isAllowed: isAllowed,
    };
  } else {
    return {
      isAllowed: allowedMimeType.includes(data.type),
      isPresentation: presentationMimeType.includes(data.type),
      isImages: imagesMimeType.includes(data.type),
    };
  }
};
