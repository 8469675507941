import React, { ReactNode } from 'react';

import { Link } from 'react-router-dom';
import HeaderUser from 'modules/header-user';
import CreditsView from 'modules/credits-view';
import PromoAlert from 'modules/promo-alert';
import TokenSubscriptionButton from 'modules/token-subscription-button';
import { Logo } from 'shared/components/logo/Logo';

import './styles.scss';
import { cx } from 'shared/utils';

const appCompany = process.env.REACT_APP_CCM_COMPANY;

type HeaderProps = {
  onBack?: ReactNode;
};

const Header: React.FC<HeaderProps> = ({ onBack }) => {
  const classNames = cx('header__logo', {
    [`header__logo--${appCompany}`]: appCompany,
  });

  return (
    <header className="header">
      <PromoAlert />
      <div className="header__inner">
        <div className="header-app">
          {onBack}
          <Link className="header__link" to="/">
            <div className={classNames}>
              <Logo />
            </div>
            {appCompany !== 'wiley' ? <h4 className="header__title">CoPilot</h4> : null}
          </Link>
        </div>
        <div className="header__mid">
          <CreditsView />
          <TokenSubscriptionButton />
        </div>

        <HeaderUser />
      </div>
    </header>
  );
};

export default Header;
