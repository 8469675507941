import React, { memo } from 'react';

import Button from 'shared/ui/button';
import IconSparking from 'shared/ui/icons/sparking';
import { cx } from 'shared/utils';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

type ButtonSparkingProps = {
  size?: 'small' | 'middle' | 'large' | undefined;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

const ButtonSparking: React.FC<ButtonSparkingProps> = ({
  size = 'large',
  className,
  disabled,
  onClick,
}: ButtonSparkingProps) => {
  const { t } = useTranslation();
  const classNames = cx('button-sparking', className);
  return (
    <Tooltip title={t('common.regenerate')}>
      <Button
        type="text"
        size={size}
        shape="circle"
        exSize={size !== 'large' ? undefined : 'xl'}
        className={classNames}
        icon={<IconSparking size={size !== 'large' ? 12 : 24} />}
        disabled={disabled}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default memo(ButtonSparking);
