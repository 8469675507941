import api, { ApiModules } from 'shared/api/index';
import { ENDPOINTS } from 'shared/api/constants';

import { GenerateResponse } from '../../types';

export interface ModuleOutcomePayload {
  name: string;
  color?: string;
}

export const putModuleOutcomeRequest = (moduleId: string, payload: ModuleOutcomePayload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.OUTLINE_BUILDER_PROJECT_MODULE_OUTCOME(moduleId), payload);

export const postModuleOutcomeSubRequest = (moduleId: string, payload: ModuleOutcomePayload) =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_MODULE_OUTCOME_SUB(moduleId), payload);

export const putSubModuleOutcomeRequest = (moduleId: string, payload: ModuleOutcomePayload) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.OUTLINE_BUILDER_PROJECT_SUB_MODULE_OUTCOME(moduleId), payload);

export const deleteModuleOutcomeRequest = (moduleId: string) =>
  api(ApiModules.OUTLINE_BUILDER).delete(ENDPOINTS.OUTLINE_BUILDER_PROJECT_MODULE_OUTCOME(moduleId));

export const deleteSubModuleOutcomeRequest = (moduleId: string) =>
  api(ApiModules.OUTLINE_BUILDER).delete(ENDPOINTS.OUTLINE_BUILDER_PROJECT_SUB_MODULE_OUTCOME(moduleId));

export type ModuleOutcomeRegeneratePayload = {
  for_regeneration_primary_outcome?: string;
};

export const postModuleOutcomeRegenerateRequest = (
  moduleId: string,
  payload: ModuleOutcomeRegeneratePayload
): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_PROJECT_MODULE_OUTCOME_REGENERATE(moduleId), payload);

export type SubModuleOutcomeRegeneratePayload = {
  for_regeneration_secondary_outcome?: string;
};

export const postSubModuleOutcomeRegenerateRequest = (
  moduleId: string,
  payload: SubModuleOutcomeRegeneratePayload
): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(
    ENDPOINTS.OUTLINE_BUILDER_PROJECT_SUB_MODULE_OUTCOME_REGENERATE(moduleId),
    payload
  );
