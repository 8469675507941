import React, { useCallback } from 'react';

import { DislikeFilled, LikeFilled } from '@ant-design/icons';
import Button from 'shared/ui/button';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { sendProjectFeedBackAction } from '../project/actions';

import './styles.scss';

const Feedback: React.FC = () => {
  const dispatch = useAppDispatch();
  const appStore = useAppSelector((state) => state.app);

  const projectStore = useAppSelector((state) => state.project);
  const structureStore = useAppSelector((state) => state.structure);
  const project = useAppSelector((state) => state.project.project);
  const { t } = useTranslation();

  const onSendLike = useCallback(() => {
    void dispatch(sendProjectFeedBackAction(1));
    mixpanel.track('ProjectRate', { ProjectId: project?.id, Rate: 'Good' });
  }, [dispatch, project?.id]);

  const onSendDislike = useCallback(() => {
    void dispatch(sendProjectFeedBackAction(-1));
    mixpanel.track('ProjectRate', { ProjectId: project?.id, Rate: 'Bad' });
  }, [dispatch, project?.id]);

  if (projectStore.loading || structureStore.loading || projectStore.generation) {
    return null;
  }

  if (appStore.layout === 'public') {
    return null;
  }

  if (project?.active_project_version?.feedback !== null) {
    return null;
  }

  return (
    <div className="feedback">
      <div className="feedback__inner">
        <span className="feedback__text">{t('feedback.text')}</span>
        <Button type="primary" className="feedback__like" icon={<LikeFilled />} onClick={onSendLike} />
        <Button type="primary" className="feedback__dislike" icon={<DislikeFilled />} onClick={onSendDislike} />
      </div>
    </div>
  );
};

export default Feedback;
