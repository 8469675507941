import React, { memo } from 'react';

import BlockTitle from 'shared/ui/block-title/BlockTitle';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import RegenerateEntityBlock from 'entities/regenerate-entity/ui/regenerate-block';
import { regenerationModuleOutcomesAction } from 'widgets/module-learning-outcomes/store/actions';

import './styles.scss';
import { useOwner } from '../../shared/hooks/useProjectOwner';

const LearningOutcomesDescriptionView: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.project);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const generation = useAppSelector((state) => state.project.generation);
  const projectVerId = useAppSelector((state) => state.project?.project?.active_project_version?.id);

  const { isOwner } = useOwner();

  const onRegenAllOutcomes = (text: string) => {
    if (projectVerId) {
      void dispatch(regenerationModuleOutcomesAction(text));
    }
  };

  return (
    <div className="lo-description-view">
      <RegenerateEntityBlock
        title={t<string>('regenEntityBlock.outcomes.title')}
        buttonText={t<string>('regenEntityBlock.outcomes.button')}
        disabled={!isOwner || generation || !!selectedVersion}
        action={onRegenAllOutcomes}
      />
      <BlockTitle text={t<string>('learningOutcomes.description')} />
      <span className="lo-description-view__text">
        {selectedVersion
          ? selectedVersion.generated_description
          : project?.active_project_version?.generated_description}
      </span>
    </div>
  );
};

export default memo(LearningOutcomesDescriptionView);
