import React, { memo, useCallback } from 'react';

import { message, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import Input from 'shared/ui/input';
import { LinkOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button';
import { useBoolean } from 'usehooks-ts';
import { generatePath, useParams } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';
import { updateProjectPublicAction } from 'modules/project/actions';
import mixpanel from 'mixpanel-browser';

import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import '../styles.scss';

type Props = {
  onClose: () => void;
};

const LinkButtonContent: React.FC<Props> = ({ onClose }) => {
  const { lang } = useParams();
  const project = useAppSelector((state) => state.project.project);
  const { value, setValue } = useBoolean(project?.is_public_available);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const path = generatePath(PATH_NAME.coursePublic, {
    id: project?.public_slug || '',
    lang: lang,
  });

  const fullPath = `${location.origin}${path}`;

  const onClickCopy = useCallback(() => {
    navigator.clipboard.writeText(fullPath).then(() => {
      mixpanel.track('ProjectPublicCopyLink', { ProjectId: `${project?.id}` });
      void message.info(t<string>('linkButton.linkCopied'), 5);
      onClose();
    });
  }, [fullPath, onClose, project?.id, t]);

  const onToggle = useCallback(
    (checked: boolean) => {
      setValue(checked);
      if (project?.id && lang) {
        void dispatch(updateProjectPublicAction(project?.id, checked, lang));
      }
      mixpanel.track('ProjectPublishPublic', { ProjectId: `${project?.id}` });
    },
    [dispatch, lang, project?.id, setValue]
  );

  return (
    <div className="link-button-content">
      <div className="link-button-content__top">
        <Switch checked={value} size="small" onChange={onToggle} />
        <span className="link-button-content__title">{t<string>('linkButton.title')}</span>
      </div>
      {project?.is_public_available ? (
        <div className="link-button-content__bottom">
          <Input size="large" disabled value={fullPath} />
          <Button size="large" icon={<LinkOutlined />} onClick={onClickCopy}>
            {t('linkButton.link')}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default memo(LinkButtonContent);
