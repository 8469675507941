import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { localStorageService } from 'shared/services/localStorageService';
import { ENDPOINTS } from 'shared/api/constants';
import { message } from 'antd';
import i18n from 'i18next';

const API_URL =
  process.env.NODE_ENV === 'development' ? 'https://dev.cftry.me/account/api' : `${window.location.origin}/account/api`;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const authData = localStorageService.getAuthData();
  config.headers = config.headers ?? {};
  if (authData) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authData.access}`,
    };
  }
  return config;
};

const onRequestError = (error: AxiosError) => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response?.data;
};

type CustomDataError = {
  error: string | null;
  error_code: string | null;
};

const onResponseError = async (error: AxiosError): Promise<AxiosResponse<any, any>> => {
  const originalConfig = error.config;
  if (error.response) {
    if (error.response?.data) {
      const dataError = error.response?.data as CustomDataError;

      const text = dataError.error ? dataError.error : 'An error occurred, try again later.';

      message.destroy();
      message.error(text, 7);
    }

    if (error?.response?.status === 401 && error.response?.data === 'token_not_valid') {
      const authData = localStorageService.getAuthData();
      await axios
        .post(`${API_URL}/${ENDPOINTS.REFRESH_TOKEN}`, {
          refresh: authData?.refresh,
        })
        .then(async (response: AxiosResponse) => {
          const { access, refresh } = response?.data;
          if (access && refresh) {
            const newAuthData = {
              access,
              refresh,
            };
            localStorageService.setAuthData(newAuthData);
            originalConfig!.headers!.Authorization = 'Bearer ' + access;
            // return Promise.resolve();
            return Promise.resolve(axios(originalConfig || {}));
          } else {
            localStorageService.removeAuthData();
          }
        })
        .catch((_error) => {
          localStorageService.removeAuthData();
          return Promise.reject(_error.response);
        });
    }
  }

  //console.log(error?.response);

  if (error?.response?.status === 401) {
    return Promise.resolve(error.response);
  } else {
    return Promise.reject(error.response);
  }
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
