import { getProjectRequest } from 'shared/graphql/queries/getProject';
import {
  deleteOutlineBuilderProject,
  putOutlineBuilderProjectPublic,
} from 'shared/api/requests/outline-builder-project';
import { postOutlineBuilderProjectVersionFeedbackRequest } from 'shared/api/requests/outline-builder-project-version';
import { redirect } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';
import { AppDispatch, store } from 'shared/store/store';

import { getProjectsAction } from '../../widgets/course-list/store/actions';
import { getProjectGenerationStatusRequest } from '../../shared/graphql/queries/getProjectGenerationStatus';
import { getStructureAction } from '../../widgets/course-structure/store/actions';

import { setProject, setProjectGeneration, setProjectLoading } from './index';

export const getProjectAction = (id: string, isSilent?: boolean, publicSlug?: string, language?: string) => {
  return async (dispatch: AppDispatch) => {
    if (!isSilent) {
      dispatch(setProjectLoading(true));
    }
    await getProjectRequest(id, publicSlug, language)
      .then((response) => {
        dispatch(setProject(response?.data?.outline_builder_project));
        if (!isSilent) {
          dispatch(setProjectLoading(false));
        }
      })
      .catch((err) => {
        redirect(PATH_NAME.error.main);
        if (!isSilent) {
          dispatch(setProjectLoading(false));
        }
      });
  };
};

export const intermediateProjectAction = (id: string, language: string) => {
  return async (dispatch: AppDispatch) => {
    const projectStore = store.getState().project;
    await getProjectRequest(id, undefined, language)
      .then((response) => {
        if (response.data.outline_builder_project.id === projectStore?.project?.id) {
          dispatch(setProject(response?.data?.outline_builder_project));
        }
      })
      .catch((err) => {
        const origin = window.location.origin;
        window.open(`${origin}/error`, '_self');
        console.log(err);
      });
  };
};

export const getPublicProjectAction = (slug: string, language: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setProjectLoading(true));
    await getProjectRequest('', slug, language)
      .then((response) => {
        if (response.data.outline_builder_project.is_public_available) {
          dispatch(setProject(response?.data?.outline_builder_project));
          dispatch(setProjectLoading(false));
        } else {
          dispatch(setProjectLoading(false));
          const origin = window.location.origin;
          window.open(`${origin}/error`, '_self');
        }
      })
      .catch((err) => {
        const origin = window.location.origin;
        window.open(`${origin}/error`, '_self');
        console.log(err);
      });
  };
};

export const updateProjectPublicAction = (id: string, isPublic: boolean, lang: string) => {
  return async (dispatch: AppDispatch) => {
    const payload = {
      is_public_available: isPublic,
    };
    await putOutlineBuilderProjectPublic(id, payload).then(() => {
      dispatch(getProjectAction(id, true, undefined, lang));
    });
  };
};

export const sendProjectFeedBackAction = (val: number) => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const payload = {
      feedback_rate: val,
    };
    if (project?.active_project_version?.id) {
      await postOutlineBuilderProjectVersionFeedbackRequest(project?.active_project_version?.id, payload).then(() => {
        dispatch(getProjectAction(project?.id, true, undefined, project.active_project_version?.language.code));
      });
    }
  };
};

export const removeTourProjectAction = (projectId: string) => {
  return async (dispatch: AppDispatch) => {
    await deleteOutlineBuilderProject(projectId).then(() => {
      dispatch(getProjectsAction());
    });
  };
};

export const checkGenInProgressAction = () => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const language = project?.active_project_version?.language.code;
    const response = await getProjectGenerationStatusRequest(project?.id || '');

    if (
      response.data.outline_builder_project.generation_in_progress ||
      (project?.id && project?.generation_in_progress)
    ) {
      dispatch(setProjectGeneration(true));

      if (response.data.outline_builder_project.generation_in_progress) {
        setTimeout(() => dispatch(checkGenInProgressAction()), 1000);
      } else {
        void dispatch(getProjectAction(project?.id || '', true, undefined, language));
        void dispatch(getStructureAction(project?.id || '', language || '', true));
        dispatch(setProjectGeneration(false));
      }
    } else {
      dispatch(setProjectGeneration(false));
    }
  };
};
