import { useQuery } from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { ObjectType } from 'shared/types/entities';
import { localStorageService } from 'shared/services/localStorageService';

import { OBJECT_TYPES } from '../schemas/objectTypes';

type ObjectTypesData = {
  outline_builder_course_object_types: ObjectType[];
};

const useObjectTypes = (fetchPolicy?: WatchQueryFetchPolicy) => {
  const language = localStorageService.getLangData();
  const { data } = useQuery<ObjectTypesData>(OBJECT_TYPES, {
    variables: {
      language,
    },
    fetchPolicy: fetchPolicy,
  });

  return {
    objectTypes: data?.outline_builder_course_object_types?.map((el) => {
      return {
        ...el,
      };
    }),
  };
};

export default useObjectTypes;
