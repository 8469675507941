import React, { memo } from 'react';
import type { FC } from 'react';

import IconMagic from 'shared/ui/icons/magic/IconMagic';
import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'shared/store/hooks';

import { generateReadingKeyPointsAction } from '../model/actions';

type Props = {
  disabled?: boolean;
  structureItemId?: string;
  duration: number;
  text: string;
  onChangeTab: (key: string) => void;
};

const GenerateKeyPoints: FC<Props> = ({ structureItemId, disabled, duration, text, onChangeTab }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClick = () => {
    const payload = {
      user_target_content_length: duration,
      regeneration_text: text,
    };
    if (structureItemId) {
      void dispatch(generateReadingKeyPointsAction(structureItemId, payload));
      onChangeTab('points');
    }
  };

  return (
    <Button size="large" exSize="xl" type="primary" disabled={disabled} onClick={onClick}>
      {t('assignments.reading.genKeyPoints')}
      <IconMagic size={16} />
    </Button>
  );
};

export default memo(GenerateKeyPoints);
