import { ActiveModulesOutcome, ActiveSubModulesOutcome, OutlineProject, VideoPoint } from 'shared/types/entities';
import dayjs from 'dayjs';

import { LanguageType } from '../types/entities/app';

export const sortModuleOutcomesByOrder = (modules?: ActiveModulesOutcome[] | null) => {
  if (!modules) {
    return [];
  }
  const items = [...modules];
  return items?.sort((a, b) => a?.order - b?.order);
};

export const sortSubModuleOutcomesByOrder = (modules?: ActiveSubModulesOutcome[] | null) => {
  if (!modules) {
    return [];
  }
  const items = [...modules];
  return items?.sort((a, b) => a?.order - b?.order);
};

export const sortProjectsByDate = (projects: OutlineProject[] | null) => {
  if (!projects) {
    return [];
  }
  const items = [...projects];
  return items?.sort((a, b) => {
    return dayjs(b.created_at).isAfter(dayjs(a.created_at)) ? 1 : -1;
  });
};

export const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k'];

export const sortProjectsByTour = (projects: OutlineProject[]) => {
  return projects.sort((a, b) => Number(a.is_tutorial) - Number(b.is_tutorial));
};

export const sortVideoPoints = (points?: VideoPoint[]) => {
  if (!points) {
    return [];
  }
  const items = [...points];
  return items.sort((a, b) => a.order - b.order);
};

export const sortLanguages = (languages: LanguageType[] | null) => {
  if (!languages) {
    return [];
  }
  const items = [...languages];
  return items.sort((a, b) => a.order - b.order);
};
