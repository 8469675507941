import {
  postOutlineBuilderProjectAutoGenContinueRequest,
  postOutlineBuilderProjectAutoGenStartRequest,
  postOutlineBuilderProjectCurriculumRequest,
} from 'shared/api/requests/outline-builder-project';
import { checkAsyncState, checkParsingFile, messageGeneration, messageRejectedGeneration } from 'shared/utils';
import { getProjectAction } from 'modules/project/actions';
import { setProgress } from 'modules/create-course-upload/store';
import { message } from 'antd';
import { AppDispatch, store } from 'shared/store/store';

import { getProjectsAction } from '../../course-list/store/actions';

import { CreateCourseError, setError, setGenerating } from './index';

export const startAutogenAction = (projectId: string) => {
  return async (dispatch: AppDispatch) => {
    messageGeneration();
    dispatch(setGenerating(true));

    const requests = () => {
      dispatch(getProjectsAction(true));
      dispatch(getProjectAction(projectId));
    };

    const iterationCallback = () => {
      requests();
    };

    const rejectCallback = () => {
      dispatch(setError(CreateCourseError.generation));
      message.destroy();
      messageRejectedGeneration();
      dispatch(setGenerating(false));
      requests();
    };

    const finishRequest = () => {
      dispatch(setGenerating(false));
      requests();
      message.destroy();
    };

    const response = await postOutlineBuilderProjectAutoGenStartRequest(projectId);
    await checkAsyncState(response.request_id, finishRequest, rejectCallback, iterationCallback);
  };
};

export const continueAutogenAction = (projectId: string) => {
  return async (dispatch: AppDispatch) => {
    await postOutlineBuilderProjectAutoGenContinueRequest(projectId);
  };
};

export const createCurriculumAction = () => {
  return async (dispatch: AppDispatch) => {
    const createStore = store.getState().createCourse;
    dispatch(setProgress(75));
    dispatch(setGenerating(true));

    const payload = {
      file_id: createStore?.file!.id,
      massive_course: createStore!.massive_course,
      duration: createStore!.duration,
    };

    const res = await postOutlineBuilderProjectCurriculumRequest(payload);
    const callback = () => {
      dispatch(getProjectAction(res.project_id.toString())).then(() => {
        dispatch(setProgress(100));
        dispatch(setProgress(null));
      });
      dispatch(startAutogenAction(res.project_id.toString()));
    };

    const rejectCallback = () => {
      dispatch(setError(CreateCourseError.recognise));
      dispatch(setProgress(null));
    };

    await checkParsingFile(res.id, callback, rejectCallback);
  };
};
