import React, { useCallback } from 'react';

import SwitchLanguage from 'modules/switch-language';
import Button from 'shared/ui/button';
import LoginForm from 'modules/login-form/LoginForm';
import { useTranslation } from 'react-i18next';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';

import { PATH_NAME } from '../constants';

import './styles.scss';

const appCompany = process.env.REACT_APP_CCM_COMPANY;

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useCustomNavigator();

  const goSignUpPage = useCallback(() => {
    navigate(PATH_NAME.signup);
  }, [navigate]);

  return (
    <div className="no-auth-page">
      <div className="no-auth-page__actions">
        <SwitchLanguage />
          {appCompany !== 'wiley' ? <Button onClick={goSignUpPage}>{t('signup.button')}</Button> : null}
      </div>
      <LoginForm />
    </div>
  );
};

export default LoginPage;
