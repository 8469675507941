import axios, { AxiosRequestConfig } from 'axios';
import { DEV_AMAZON_API_URL, DEV_API_URL, DEV_DEV, DEV_TEST } from 'shared/config/urls';

import { setupInterceptorsTo } from './interceptors';

export enum ApiModules {
  ACCOUNT = 'account',
  PM = 'pm',
  ARTEFACT_MANAGEMENT = 'artefact-management',
  ARTEFACT_AUDIT = 'artefact-audit',
  OUTLINE_BUILDER = 'outline-builder',
  DATA_CONVERTER = 'data-converter',
}

const getBaseUrl = (type: ApiModules) => {
  return process.env.NODE_ENV === 'development'
    ? `${DEV_AMAZON_API_URL}/${type}/api/`
    : `${window.location.origin}/${type}/api/`;
};

const getInstance = (type: ApiModules) => {
  return setupInterceptorsTo(
    axios.create({
      baseURL: getBaseUrl(type),
      timeout: 250000,
      headers: {
        //'Content-Type': 'application/json',
      },
    })
  );
};

declare module 'axios' {
  export interface AxiosResponse<T = any> extends Promise<T> {}
}

const api = (type: ApiModules) => {
  const instance = getInstance(type);
  return {
    get: <P, R>(url: string, config: AxiosRequestConfig = {}, params?: P): Promise<R> =>
      instance.get(url, { params, responseType: 'json', ...config }).then((data) => data),
    post: <D, R>(url: string, payload?: D, config?: AxiosRequestConfig): Promise<R> =>
      instance.post<R>(url, payload, config).then((data) => data),
    patch: <D, R>(url: string, payload: D, config?: AxiosRequestConfig): Promise<R> =>
      instance.patch(url, payload, config).then((data) => data),
    put: <D, R>(url: string, payload?: D, config?: AxiosRequestConfig): Promise<R> =>
      instance.put(url, payload, config).then((data) => data),
    delete: <D, R>(url: string, payload?: D, config?: AxiosRequestConfig): Promise<R> =>
      instance.delete(url, { ...config, data: payload }).then((data) => data),
  };
};

export default api;
