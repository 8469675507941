import api, { ApiModules } from '../../api';
import { ENDPOINTS } from '../../constants';

type ConnectAccountPayload = {
  email: string;
  type: string;
};
export const postDCConnectAccountRequest = (payload: ConnectAccountPayload) =>
  api(ApiModules.DATA_CONVERTER).post(ENDPOINTS.DCCONNECTOR_ACCOUNT, payload);

type ExternalConnectAccountPayload = {
  id: number;
  state: 'done' | 'rejected';
  external_account_id: string;
};

export const postExternalDCConnectAccountRequest = (payload: ExternalConnectAccountPayload) =>
  api(ApiModules.DATA_CONVERTER).post(ENDPOINTS.EXTERNAL_DCCONNECTOR_ACCOUNT, payload);

export const postDCConnectUnlinkRequest = (payload: ConnectAccountPayload) =>
  api(ApiModules.DATA_CONVERTER).post(ENDPOINTS.DCCONNECTOR_ACCOUNT_UNLINK, payload);
