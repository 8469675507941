import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveProjectVersion, OutlineProject } from 'shared/types/entities';

export interface ProjectState {
  project: OutlineProject | null;
  selectedVersion: ActiveProjectVersion | null;
  loading: boolean;
  projectFilesTemp: number[] | [];
  activeUploads: number;
  totalFilesSize: number;
  progress: number;
  generation: boolean;
}

const initialState: ProjectState = {
  loading: false,
  project: null,
  projectFilesTemp: [],
  selectedVersion: null,
  activeUploads: 0,
  totalFilesSize: 0,
  progress: 0,
  generation: false,
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setProject: (state, action: PayloadAction<OutlineProject | null>) => {
      state.project = action.payload;
    },
    setProjectFilesTemp: (state, action: PayloadAction<number | null>) => {
      if (action.payload === null) {
        state.projectFilesTemp = [];
      } else {
        state.projectFilesTemp = [...state.projectFilesTemp, action.payload];
      }
    },
    removeProjectFileTemp: (state, action: PayloadAction<number>) => {
      state.projectFilesTemp = state.projectFilesTemp.filter((item) => item !== action.payload);
    },
    setFileSize: (state, action: PayloadAction<number>) => {
      state.totalFilesSize = state.totalFilesSize + action.payload;
    },
    resetFileSize: (state) => {
      state.totalFilesSize = 0;
    },
    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = state.progress + action.payload;
    },
    resetProgress: (state) => {
      state.progress = 0;
    },
    startUpload: (state) => {
      state.activeUploads = state.activeUploads + 1;
    },
    finishUpload: (state) => {
      state.activeUploads = state.activeUploads - 1;
    },
    setProjectGeneration: (state, action: PayloadAction<boolean>) => {
      state.generation = action.payload;
    },
    setSelectedVersion: (state, action: PayloadAction<ActiveProjectVersion | null>) => {
      state.selectedVersion = action.payload;
    },
  },
});

export const {
  startUpload,
  finishUpload,
  setFileSize,
  resetFileSize,
  setProgress,
  resetProgress,
  setProjectLoading,
  setProject,
  setProjectFilesTemp,
  removeProjectFileTemp,
  setProjectGeneration,
  setSelectedVersion,
} = projectSlice.actions;
export default projectSlice.reducer;
