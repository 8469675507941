import React, { memo, ReactNode } from 'react';

import { cx } from 'shared/utils';

import './styles.scss';

type Props = {
  className?: string;
  children: ReactNode;
};

const ModalContent: React.FC<Props> = ({ className, children }) => {
  return <div className={cx('cb-modal-content', className)}>{children}</div>;
};

export default memo(ModalContent);
