import React, { FC } from 'react';

import Button from 'shared/ui/button/Button';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import modalService from 'shared/services/modalService';
import { cx } from 'shared/utils';

import './styles.scss';

type Props = {
  isFirstPosition?: boolean;
};

const GetExpertButton: FC<Props> = ({ isFirstPosition }) => {
  const { t } = useTranslation();

  const handleClick = () => modalService.openExpertModal({});

  const classNames = cx('get-expert-bnt', {
    'get-expert-bnt--first-position': isFirstPosition,
  });

  return (
    <Button size="large" type="primary" className={classNames} onClick={handleClick}>
      <UserOutlined style={{ fontSize: 14 }} />
      {t('expert.getExpert')}
    </Button>
  );
};

export default GetExpertButton;
