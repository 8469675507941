import { useQuery } from '@apollo/client';

import { COURSE_TYPES } from '../schemas/courseTypes';
import { CourseType } from '../../types/entities';
import { localStorageService } from '../../services/localStorageService';

type Data = {
  outline_builder_course_types: CourseType[];
};

const useCourseTypes = () => {
  const lang = localStorageService.getLangData();
  const { data } = useQuery<Data>(COURSE_TYPES, {
    variables: {
      language: lang,
    },
    fetchPolicy: 'network-only',
  });

  return {
    courseTypes: data?.outline_builder_course_types,
    courseTypesForSelect: data?.outline_builder_course_types.map((item) => {
      return {
        label: item.name,
        value: item.id,
        disabled: !item.is_active,
      };
    }),
  };
};

export default useCourseTypes;
