import { localStorageService } from 'shared/services/localStorageService';
import { postRefreshToken } from 'shared/api/requests/auth';
import { setAutoUpdate } from 'widgets/course-list/store';
import { getMeRequest } from 'shared/graphql/queries/getMe';
import { getLanguagesRequest } from 'shared/graphql/queries/getLanguages';
import { putUserPromoMessage } from 'shared/api/requests/app';
import { AppDispatch, store } from 'shared/store/store';

import { finishLoading, logout, setAuth, setLang, setMe, startLoading } from './index';

export const getMeAction = () => {
  return async (dispatch: AppDispatch) => {
    await getMeRequest().then((response) => {
      dispatch(setMe(response?.data?.me));
    });
  };
};

export const closePromoAction = (promoId: string) => {
  return async (dispatch: AppDispatch) => {
    const payload = {
      ids: [Number(promoId)],
      is_active: false,
    };
    await putUserPromoMessage(payload);
    void dispatch(getMeAction());
  };
};

export const getLanguagesAction = () => {
  return async (dispatch: AppDispatch) => {
    await getLanguagesRequest().then((response) => {
      dispatch(setLang(response.data.outline_builder_languages));
    });
  };
};

export const logoutAction = () => {
  return async (dispatch: AppDispatch) => {
    const intervalId = store.getState().projects.autoUpdate;
    localStorageService.removeAuthData();
    dispatch(setMe(null));
    dispatch(logout());
    if (intervalId) {
      window.clearInterval(intervalId);
    }
    dispatch(setAutoUpdate(null));
  };
};

export const initAuth = () => {
  return async (dispatch: AppDispatch) => {
    const token = localStorageService.getAuthData();

    dispatch(startLoading());

    if (!token) {
      void dispatch(logoutAction());
    }

    try {
      if (token?.refresh) {
        const payload = {
          refresh: token?.refresh,
        };
        await postRefreshToken(payload)
          .then((res) => {
            localStorageService.setAuthData(res);
            dispatch(setAuth());
            dispatch(getMeAction());
            dispatch(getLanguagesAction());
            dispatch(finishLoading());
          })
          .catch((err) => console.log(err));
      }
    } catch (err) {
      void dispatch(logoutAction());
    }
  };
};
