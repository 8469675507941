import { gql } from '@apollo/client';

import { PAGE_INFO } from './fragments';

export const AFFILIATE_HISTORY = gql`
  query AffiliateHistory($after: String, $first: Int, $offset: Int) {
    outline_builder_affiliation_history(after: $after, first: $first, offset: $offset) {
      edges {
        node {
          date
          num_users
          sum_tokens
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PAGE_INFO}
`;
