import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VideoScript, VideoScriptPresentationFile } from 'shared/types/entities';

import { StructureItemStudioLinkedSlide, StudioState } from './studio';

const initialState: StudioState = {
  loading: false,
  slideLoading: false,
  file: null,
  scriptId: null,
  presentationFile: null,
  linkedSlides: null,
};

export const studioSlice = createSlice({
  name: 'studio',
  initialState,
  reducers: {
    clearStudio: () => initialState,
    setStudioLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setStudioSlideLoading: (state, action: PayloadAction<boolean>) => {
      state.slideLoading = action.payload;
    },
    setStudioScript: (state, action: PayloadAction<VideoScript | null>) => {
      state.file = action.payload && action.payload.file;
      state.scriptId = action.payload && Number(action.payload.id);
    },
    setStudioPresentationFile: (state, action: PayloadAction<VideoScriptPresentationFile | null>) => {
      state.presentationFile = action.payload ? action.payload : null;
    },
    setLinkedSlides: (state, action: PayloadAction<StructureItemStudioLinkedSlide[] | null>) => {
      state.linkedSlides = action.payload;
    },
  },
});

export const {
  setStudioLoading,
  setStudioSlideLoading,
  setStudioScript,
  setStudioPresentationFile,
  setLinkedSlides,
  clearStudio,
} = studioSlice.actions;

export default studioSlice.reducer;
