import React, { memo, useCallback, useMemo } from 'react';

import { PlusOutlined, WalletOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Button from 'shared/ui/button';

import { cx } from '../../utils';

import './styles.scss';
import { useCustomNavigator } from '../../hooks/useCustomNavigator';
import { PATH_NAME } from '../../../pages/constants';
import { useAppSelector } from '../../store/hooks';

type Props = {
  onClick?: () => void;
  type: 'create' | 'buy';
};

const CourseCardAction: React.FC<Props> = ({ type, onClick }) => {
  const { t } = useTranslation();
  const { navigate } = useCustomNavigator();
  const hasSubscription = useAppSelector((s) => s.app.me?.has_active_subscription);

  const onGoBilling = useCallback(() => navigate(PATH_NAME.billing), [navigate]);

  const content = useMemo(() => {
    if (type === 'create') {
      return (
        <div className="course-card-action__inner">
          <PlusOutlined style={{ fontSize: 48, color: '#1E1E1E' }} />
          <span className="course-card-action__text">{t<string>('card.createNew')}</span>
        </div>
      );
    }
    if (hasSubscription) {
      return (
        <div className="course-card-action__inner">
          <WalletOutlined style={{ fontSize: 48, color: '#1E1E1E' }} />
          <span className="course-card-action__text">{t<string>('subscribe.outTokens.title')}</span>
          <span className="course-card-action__text">{t<string>('subscribe.outTokens.hasSubscription.content')}</span>
        </div>
      );
    }
    return (
      <div className="course-card-action__inner">
        <WalletOutlined style={{ fontSize: 48, color: '#1E1E1E' }} />
        <span className="course-card-action__text">{t<string>('card.toSupportTeam')}</span>
        <Button className="mt-2" size="large" type="primary" onClick={onGoBilling}>
          {t<string>('subscribe.upgrade')}
        </Button>
      </div>
    );
  }, [onGoBilling, t, type]);

  const classNames = cx('course-card-action', {
    'course-card-action--no': type === 'buy',
  });

  return (
    <div className={classNames} onClick={onClick}>
      {content}
    </div>
  );
};

export default memo(CourseCardAction);
