import { getProjectAssignmentsRequest } from 'shared/graphql/queries/getProjectAssignments';
import { AppDispatch, store } from 'shared/store/store';

import { getProjectAssignmentsFullRequest } from '../../../shared/graphql/queries/getProjectAssignmentsFull';

import { setAssignments, setAssignmentsLoading } from './index';

export const getAssignmentsAction = (
  projectId: string,
  language: string,
  isSilent?: boolean,
  projectVersionId?: string
) => {
  return async (dispatch: AppDispatch) => {
    if (!isSilent) {
      dispatch(setAssignmentsLoading(true));
    }

    await getProjectAssignmentsRequest(projectId, language, projectVersionId).then((response) => {
      dispatch(setAssignments(response.data.outline_builder_assignments));
      if (!isSilent) {
        dispatch(setAssignmentsLoading(false));
      }
    });
  };
};

export const getAssignmentsFullAction = (projectId: string, language: string, isSilent?: boolean) => {
  return async (dispatch: AppDispatch) => {
    if (!isSilent) {
      dispatch(setAssignmentsLoading(true));
    }

    await getProjectAssignmentsFullRequest(projectId, language).then((response) => {
      dispatch(setAssignments(response.data.outline_builder_assignments));
      if (!isSilent) {
        dispatch(setAssignmentsLoading(false));
      }
    });
  };
};
