import React, { memo } from 'react';

import { CBIconProps } from '../types';

const IconDummy: React.FC<CBIconProps> = ({ size }: CBIconProps) => {
  const styles = {
    width: size || 24,
    height: size || 24,
  };
  return (
    <svg style={styles} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.7506 17.9964H31.5006C31.3238 17.9964 31.1792 18.141 31.1792 18.3178V31.1749H4.82204V4.81778H17.6792C17.856 4.81778 18.0006 4.67314 18.0006 4.49636V2.24636C18.0006 2.06957 17.856 1.92493 17.6792 1.92493H3.2149C2.50374 1.92493 1.92918 2.49948 1.92918 3.21064V32.7821C1.92918 33.4932 2.50374 34.0678 3.2149 34.0678H32.7863C33.4975 34.0678 34.072 33.4932 34.072 32.7821V18.3178C34.072 18.141 33.9274 17.9964 33.7506 17.9964Z"
        fill="#1E1E1E"
      />
      <path
        d="M11.7285 18.9164L11.6521 23.6937C11.6481 24.0513 11.9374 24.3446 12.295 24.3446H12.3111L17.0521 24.2281C17.1325 24.224 17.2128 24.1919 17.2691 24.1356L33.9794 7.46153C34.1039 7.33698 34.1039 7.13207 33.9794 7.00752L28.9852 2.01734C28.9209 1.95305 28.8405 1.92493 28.7561 1.92493C28.6718 1.92493 28.5914 1.95707 28.5271 2.01734L11.8209 18.6914C11.7629 18.7521 11.7298 18.8325 11.7285 18.9164ZM14.2798 19.8647L28.7561 5.42046L30.5722 7.23252L16.0878 21.6847L14.2517 21.7289L14.2798 19.8647Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export default memo(IconDummy);
