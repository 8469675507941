import React from 'react';

import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import ModalContent from 'shared/components/modal/content/ModalContent';
import ModalContainer from 'shared/components/modal/container/ModalContainer';

import TourTitle from './ui/TourTitle';
import TourText from './ui/TourText';

type Props = {
  onCancel: () => void;
};

const EndTutorialModal: React.FC<Props> = ({ onCancel }) => {
  const { t } = useTranslation();

  return (
    <ModalContainer className="end-tutor">
      <ModalContent>
        <TourTitle>{t('tour.end.title')}</TourTitle>
        <TourText>{t('tour.end.text1')}</TourText>
        <TourText>{t('tour.end.text2')}</TourText>
        <TourText>{t('tour.end.text3')}</TourText>
        <div className="end-tutor__actions">
          <Button size="large" type="primary" onClick={onCancel}>
            {t('tour.end.submit')}
          </Button>
        </div>
      </ModalContent>
    </ModalContainer>
  );
};

export default EndTutorialModal;
