import { message } from 'antd';
import {
  postOutlineBuilderProjectVersionOutcomesRequest,
  postOutlineBuilderProjectVersionSubOutcomesRequest,
  postProjectModuleOutcomesRegen,
} from 'shared/api/requests/outline-builder-project-version';
import { messageError } from 'shared/utils';
import { AppDispatch, store } from 'shared/store/store';
import { setProjectGeneration } from 'modules/project';

export const regenerationModuleOutcomesAction = (text: string) => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const verId = project?.active_project_version?.id;
    dispatch(setProjectGeneration(true));

    if (verId) {
      const payload = {
        regeneration_text: text,
      };

      await postProjectModuleOutcomesRegen(verId, payload).catch((err) => {
        dispatch(setProjectGeneration(false));
        message.destroy();
        messageError(err);
      });
    }
  };
};

export const generationModuleOutcomesAction = (verId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setProjectGeneration(true));

    postOutlineBuilderProjectVersionOutcomesRequest(verId).catch((err) => {
      dispatch(setProjectGeneration(false));
      message.destroy();
      messageError(err);
    });
  };
};

export const generationSubModuleOutcomesAction = (verId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setProjectGeneration(true));

    await postOutlineBuilderProjectVersionSubOutcomesRequest(verId).catch((err) => {
      dispatch(setProjectGeneration(false));
      message.destroy();
      messageError(err);
    });
  };
};
