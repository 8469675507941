import React, { memo } from 'react';

import { PaperClipOutlined } from '@ant-design/icons';

import { useAppSelector } from 'shared/store/hooks';

import './styles.scss';

const FileView: React.FC = () => {
  const project = useAppSelector((state) => state.project.project);
  return (
    <div className="create-course-file-view">
      <PaperClipOutlined />
      <span className="create-course-file-view__name">{project?.active_project_version?.curriculum?.file.name}</span>
    </div>
  );
};

export default memo(FileView);
