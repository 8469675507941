import React, { useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import Header from 'widgets/header';

import { setLayout } from '../../app/store';
import { useAppDispatch } from '../../shared/store/hooks';

import './styles.scss';

const MainLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setLayout('main'));
  }, [dispatch]);
  return (
    <div className="main-layout">
      <Header />
      <Outlet />
    </div>
  );
};

export default MainLayout;
