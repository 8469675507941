import React, { memo } from 'react';

import { cx } from 'shared/utils';
import Badge from 'shared/ui/badge';

import './styles.scss';

type LearningOutcomesStructureViewItemProps = {
  isParent?: boolean;
  tag: string;
  text: string;
  color?: string;
};

const LearningOutcomesStructureViewItem: React.FC<LearningOutcomesStructureViewItemProps> = ({
  isParent,
  tag,
  text,
  color = '#1E1E1E',
}) => {
  return (
    <div className={cx('lo-structure-view-item', { 'lo-structure-view-item--parent': isParent })}>
      {isParent ? (
        <span style={{ color: color }} className="lo-structure-view-item__tag">
          {tag}
        </span>
      ) : (
        <Badge color={color} text={tag} />
      )}

      <span className="lo-structure-view-item__text">{text}</span>
    </div>
  );
};

export default memo(LearningOutcomesStructureViewItem);
