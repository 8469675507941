import { gql } from '@apollo/client';

export const PROJECT_GENERATION_STATUS = gql`
  query ProjectGenerationStatus($id: Int) {
    outline_builder_project(id: $id) {
      id
      generation_in_progress
    }
  }
`;
