import {
  deleteOutlineBuilderCourseStructureItemRequest,
  OutlineBuilderProjectStructureItemGeneratePayload,
  postOutlineBuilderProjectStructureItemGenerateRequest,
  postOutlineBuilderProjectVersionStructureItemRequest,
  putOutlineBuilderCourseStructureItemRequest,
  putOutlineBuilderProjectStructureItemOutcomesRequest,
  putOutlineBuilderProjectStructureItemPositionRequest,
  putOutlineBuilderProjectStructureItemTypeRequest,
  StructureItemPayload,
  UpdateItemOutcomePayload,
  UpdateItemPositionPayload,
  UpdateItemTypePayload,
} from 'shared/api/requests/outline-builder-project-structure';
import { message } from 'antd';
import { messageError, messageSave } from 'shared/utils';
import { getStructureAction } from 'widgets/course-structure/store/actions';
import { localStorageService } from 'shared/services/localStorageService';
import { AppDispatch, store } from 'shared/store/store';

import { setProjectGeneration } from '../../project';

import { finishLoading, startLoading } from './index';

export const deleteStructureItemAction = (itemId: string) => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const lang = project?.active_project_version?.language.code || localStorageService.getLangData();
    messageSave();
    dispatch(startLoading());
    await deleteOutlineBuilderCourseStructureItemRequest(itemId).then(() => {
      if (project?.id) {
        dispatch(getStructureAction(project?.id, lang, true)).then(() => {
          message.destroy();
          dispatch(finishLoading());
        });
      }
    });
  };
};

export const updateStructureItem = (payload: StructureItemPayload, itemId: string) => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const lang = project?.active_project_version?.language.code || localStorageService.getLangData();

    messageSave();
    dispatch(startLoading());
    await putOutlineBuilderCourseStructureItemRequest(itemId, payload).then(() => {
      if (project?.id) {
        dispatch(getStructureAction(project?.id, lang, true)).then(() => {
          message.destroy();
          dispatch(finishLoading());
        });
      }
    });
  };
};

export const createStructureItemAction = (payload: StructureItemPayload) => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const lang = project?.active_project_version?.language.code || localStorageService.getLangData();
    const projectId = project?.id;
    const projectVerId = project?.active_project_version?.id;

    messageSave();
    dispatch(startLoading());
    if (projectVerId) {
      await postOutlineBuilderProjectVersionStructureItemRequest(projectVerId, payload)
        .then(() => {
          if (projectId) {
            dispatch(getStructureAction(projectId, lang, true))
              .then(() => {
                message.destroy();
                dispatch(finishLoading());
              })
              .catch(() => {
                message.destroy();
                messageError();
                dispatch(finishLoading());
              });
          }
        })
        .catch(() => {
          message.destroy();
          messageError();
          dispatch(finishLoading());
        });
    }
  };
};

export const generateStructureItemAction = (
  structureItemId: string,
  payload: OutlineBuilderProjectStructureItemGeneratePayload
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setProjectGeneration(true));

    await postOutlineBuilderProjectStructureItemGenerateRequest(structureItemId, payload).catch((err) => {
      dispatch(setProjectGeneration(false));
      message.destroy();
      messageError(err);
    });
  };
};

export const updateStructureItemTypeAction = (itemId: string, payload: UpdateItemTypePayload) => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const lang = project?.active_project_version?.language.code || localStorageService.getLangData();

    messageSave();
    dispatch(startLoading());
    await putOutlineBuilderProjectStructureItemTypeRequest(itemId, payload)
      .then(() => {
        if (project?.id) {
          dispatch(getStructureAction(project?.id, lang, true)).then(() => {
            message.destroy();
            dispatch(finishLoading());
          });
        }
      })
      .catch((err) => {
        console.log(err);
        message.destroy();
        dispatch(finishLoading());
      });
  };
};

export const updateStructureItemOutcomeAction = (itemId: string, payload: UpdateItemOutcomePayload) => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const lang = project?.active_project_version?.language.code || localStorageService.getLangData();

    messageSave();
    await putOutlineBuilderProjectStructureItemOutcomesRequest(itemId, payload)
      .then(() => {
        if (project?.id) {
          dispatch(getStructureAction(project?.id, lang, true)).then(() => {
            message.destroy();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        message.destroy();
      });
  };
};

export const updateItemPositionAction = (itemId: string, payload: UpdateItemPositionPayload) => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const lang = project?.active_project_version?.language.code || localStorageService.getLangData();

    messageSave();
    await putOutlineBuilderProjectStructureItemPositionRequest(itemId, payload)
      .then(() => {
        if (project?.id) {
          dispatch(getStructureAction(project?.id, lang, true)).then(() => {
            message.destroy();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        message.destroy();
      });
  };
};
