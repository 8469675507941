import { AppDispatch } from 'shared/store/store';
import { getProjectVersion } from 'shared/graphql/queries/getProjectVersion';
import { setSelectedVersion } from 'modules/project';

export const getProjectVersionAction = (projectVerId: string) => {
  return async (dispatch: AppDispatch) => {
    await getProjectVersion(projectVerId).then((response) => {
      dispatch(setSelectedVersion(response.data?.outline_builder_project_version));
    });
  };
};
