import { FC, useRef } from 'react';

import { VideoScriptPresentationRelatedFile } from 'shared/types/entities';
import { useHover } from 'usehooks-ts';
import Button from 'shared/ui/button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import {
  deleteStudioSlideAction,
  getStudioLinkedSlidesAction,
  getStudioPresentationFileAction,
  selectPresentationFileId,
  selectSlideLoading,
} from 'entities/studio/model';
import modalService from 'shared/services/modalService';
import { useSearchParams } from 'react-router-dom';

import { linkSlideToSectionAction } from '../../model/actions';

import './styles.scss';

type Props = {
  file: VideoScriptPresentationRelatedFile;
  text: string;
  sectionId?: string;
  type?: string;
};

export const Card: FC<Props> = ({ type, file, sectionId, text }) => {
  const [searchParams] = useSearchParams();
  const selectedId = searchParams.get('str');
  const { t } = useTranslation();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const dispatch = useAppDispatch();
  const presentationFileId = useAppSelector(selectPresentationFileId);
  const loadingSlides = useAppSelector(selectSlideLoading);

  const onSelect = async () => {
    if (sectionId) {
      await dispatch(linkSlideToSectionAction(sectionId, file.id, text));
      await dispatch(getStudioLinkedSlidesAction());
      modalService.closeAll();
    }
  };

  const onDelete = async () => {
    if (presentationFileId && selectedId) {
      await dispatch(deleteStudioSlideAction(presentationFileId, file.id));
      await dispatch(getStudioPresentationFileAction(selectedId));
      await dispatch(getStudioLinkedSlidesAction());
    }
  };

  return (
    <div ref={hoverRef} className="slide-card">
      {isHover && !loadingSlides && (
        <div className="slide-card-controls">
          <div className="slide-card-controls__wrap">
            {sectionId && text ? (
              <Button type="primary" onClick={onSelect}>
                {t('assignments.video.studio.select')}
              </Button>
            ) : null}

            {type === 'modify' ? null : (
              <Button type="primary" onClick={onDelete}>
                {t('assignments.video.studio.delete')}
              </Button>
            )}
          </div>
        </div>
      )}

      <img src={file.source_url} alt="slide" />
    </div>
  );
};
