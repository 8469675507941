import React, { memo } from 'react';

import Spinner from 'shared/ui/spinner/Spinner';

import './styles.scss';

type Props = {
  title?: string;
  text?: string;
};

const ViewPendingGenerate: React.FC<Props> = ({ title, text }) => {
  return (
    <div className="gen-pending">
      <span className="gen-pending__title">{title}</span>
      <span className="gen-pending__text">{text}</span>
      <Spinner />
    </div>
  );
};

export default memo(ViewPendingGenerate);
