import React, { FC, useEffect } from 'react';

import VideoPlayer from 'shared/components/video-player';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { checkStatusGenVideo } from 'features/assignment-video-gen-buttons/model/actions';
import { ExternalUrls, VideoGenerateState } from 'shared/types/entities';
import { useSearchParams } from 'react-router-dom';
import ViewPendingGenerate from 'shared/ui/viewPendingGeneration/viewPendingGenerate';
import { useTranslation } from 'react-i18next';
import { GenerateVideoButton } from 'features/assignment-video-gen-buttons';
import { Studio } from 'features/studio';

import { selectVideosByProvider } from '../../../assignment/model/assignments.selectors';

import './styles.scss';

export const StudioPlayer: FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const assignmentId = searchParams.get('str');
  const message = useAppSelector((state) =>
    state.assignment.systemMessages.find((el) => el.structure_item_id === Number(assignmentId))
  );

  const videos = useAppSelector(selectVideosByProvider);
  const video = videos?.[0];

  useEffect(() => {
    if (assignmentId) {
      if (
        (video?.state === VideoGenerateState.inProgress && assignmentId) ||
        (video?.state === VideoGenerateState.new && assignmentId)
      ) {
        void dispatch(checkStatusGenVideo(assignmentId));
      } else if (message?.state === VideoGenerateState.new || message?.state === VideoGenerateState.inProgress) {
        void dispatch(checkStatusGenVideo(assignmentId));
      }
    }
  }, [assignmentId, dispatch, message?.state, video]);

  const urls: ExternalUrls[] | null = video?.external_urls ? JSON.parse(video?.external_urls) : null;

  const url = urls?.find((el) => el.resolution === 1080);

  return (
    <div className="studio-player">
      {video?.state === VideoGenerateState.succeeded ? <VideoPlayer url={url?.url} /> : null}

      {video?.state === VideoGenerateState.new || video?.state === VideoGenerateState.inProgress ? (
        <div className="studio-player__wrapper">
          <ViewPendingGenerate
            title={t<string>('assignments.video.videoPending.text1')}
            text={t<string>('assignments.video.videoPending.text2')}
          />
        </div>
      ) : null}

      {video?.state === VideoGenerateState.failed || video?.state === VideoGenerateState.generationError ? (
        <div className="studio-player__wrapper">
          <span className="assignment-select__regen-video-text">{t('assignments.video.regenVideoText')}</span>
          <GenerateVideoButton isRegen structureItemId={assignmentId} />
        </div>
      ) : null}

      {!video?.state ? (
        <div className="studio-player__wrapper">
          <span className="assignment-select__regen-video-text">{t('assignments.video.studio.notVideo')}</span>
        </div>
      ) : null}

      <div className="studio-player__settings">
        <Studio.AvatarPosition />
        <Studio.AvatarSettings />
      </div>
    </div>
  );
};
