import React, { memo, SyntheticEvent, useCallback } from 'react';

import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { cx } from 'shared/utils';

import './styles.scss';

type NavItem = {
  path: string;
  translateKey: string;
  disabled?: boolean;
  entity?: string;
};

type HeaderNavProps = {
  navList: NavItem[];
};

type NavItemProps = {
  item: NavItem;
};

const NaviItem: React.FC<NavItemProps> = ({ item }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const version = searchParams.get('version') || undefined;

  const handleNavigate = useCallback(
    (e: SyntheticEvent) => {
      if (item.disabled) {
        e.preventDefault();
      } else {
        mixpanel.track('Header Menu Navigate', { ProjectId: id, Page: item.path });
      }
    },
    [id, item.disabled, item.path]
  );

  return (
    <li className="header-nav__item">
      <NavLink
        className={cx('header-nav__link', {
          'header-nav__link--disabled': item.disabled,
          [`tour--nav-${item.entity}`]: item.entity,
        })}
        to={version ? { pathname: item.path, search: `?version=${version}` } : { pathname: item.path }}
        onClick={handleNavigate}
      >
        {t(item.translateKey)}
      </NavLink>
    </li>
  );
};

const HeaderNav: React.FC<HeaderNavProps> = ({ navList }: HeaderNavProps) => {
  return (
    <nav className="header-nav">
      <ul className="header-nav__list">
        {navList.map((el, index) => {
          return <NaviItem key={index} item={el} />;
        })}
      </ul>
    </nav>
  );
};

export default memo(HeaderNav);
