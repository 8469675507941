import { ENDPOINTS } from 'shared/api/constants';
import api, { ApiModules } from 'shared/api';
import type { AxiosProgressEvent } from 'axios';

type AssignmentVideoPresentationPayload = {
  presentation_file_id: number;
};

export const assignmentVideoPresentationRequest = (
  structureItemId: string,
  payload: AssignmentVideoPresentationPayload
) => api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.ASSIGNMENT_VIDEO_PRESENTATION(structureItemId), payload);

export const onUploadSlideRequest = (
  fileId: string,
  payload: FormData,
  updateProgress?: (progressEvent: AxiosProgressEvent) => void
): Promise<any> =>
  api(ApiModules.ARTEFACT_MANAGEMENT).post(ENDPOINTS.UPLOAD_PRESENTATION_SLIDE(fileId), payload, {
    onUploadProgress: updateProgress,
  });

export const onDeleteSlideRequest = (fileId: string, slideId: string) =>
  api(ApiModules.ARTEFACT_MANAGEMENT).delete(ENDPOINTS.DELETE_PRESENTATION_SLIDE(fileId, slideId));
