import { VideoAvatarPosition } from 'entities/studio/model/types';

import { User } from './user';
import { ObjectType } from './objectType';
import { ProjectGenerateState, UploadStateEnum, VideoGenerateState } from './status';
import { QuizAssignment, QuizContentItem } from './structureItem';
import { LanguageType } from './app';

export interface ActiveSubModulesOutcome {
  id: string;
  letter_index: string;
  name: string;
  order: number;
  created_at: string;
  updated_at: string;
  color?: string;
  is_edited: boolean;
}

export interface ActiveModulesOutcome {
  id: string;
  color: string;
  letter_index: string;
  name: string;
  order: number;
  created_at: string;
  updated_at: string;
  is_edited: boolean;
  active_sub_modules_outcomes: ActiveSubModulesOutcome[] | null;
}

export interface Curriculum {
  id: string;
  file: {
    id: string;
    name: string;
  };
}

export interface ActiveProjectVersion {
  id: string;
  generated_description: string;
  generated_title: string;
  generated_course_participants: string;
  generated_prerequisites: string;
  active_modules_outcomes: ActiveModulesOutcome[] | null;
  curriculum: Curriculum | null;
  feedback: boolean | null;
  language: {
    id: string;
    code: string;
    name: string;
  };
}

export enum OutlineProjectGenerationStatusType {
  projectGeneration = 'PROJECT_GENERATION',
  projectAutogeneration = 'PROJECT_AUTOGENERATION',
  translation = 'TRANSLATION',
}

export interface OutlineProjectGenerationStatus {
  id: string;
  current_step: number;
  total_steps: number;
  state: ProjectGenerateState;
  is_ended: boolean;
  generation_type: OutlineProjectGenerationStatusType;
}

export type ContextFileState = 'new' | 'parsing' | 'ready' | 'error' | 'error_file_parsing' | 'done';

export type ContextFile = {
  context_file: {
    id: string;
    state: ContextFileState | null;
    file: {
      id: string;
      name: string;
      file_type: string;
    };
  };
};

export interface ProjectContextFile extends ContextFile {}

export interface CourseType {
  id: string;
  code: string;
  name: string;
  is_active: boolean;
}

export interface ProjectTypeFK {
  id: string;
  default_name: string;
  course_key: string;
}

export interface OutlineProject {
  id: string;
  pk: string;
  is_tutorial?: boolean;
  about_annotation: string;
  goal_annotation: string;
  knowledge_level: string;
  tone_of_voice: string;
  prerequisites: string;
  massive_course: boolean;
  course_study_load: number;
  duration: number;
  practical_level: string;
  created_at: string;
  updated_at: string;
  webinar_duration?: number;
  created_by: User;
  course_type: CourseType;
  project_type_fk: ProjectTypeFK;
  context_files: ProjectContextFile[];
  active_project_version: ActiveProjectVersion | null;
  has_submodules: boolean;
  project_type: 'autogenerated' | 'manual';
  generation_status: OutlineProjectGenerationStatus | null;
  is_public_available: boolean;
  generation_in_progress: boolean;
  public_slug: string;
  auto_steps: boolean;
  languages: LanguageType[];
}

export type LevelType = 'module' | 'lesson' | 'production_item';

export interface LearningStructure {
  id: string;
  level_type: LevelType;
  name: string;
}

export interface StructureItemSubModule {
  id: string;
  letter_index: string;
  name: string;
  outline_builder_project_module_outcome: {
    color: string;
  };
}

type StructureItemOpen = {
  open: boolean;
};

export type VideoPoint = {
  id: string;
  pk: string;
  name: string;
  order: number;
  color: string;
};

export interface ReadingPoint extends VideoPoint {}

export type ReadingFile = {
  id: string;
  related_files: {
    id: string;
    original_data: string;
  }[];
};

export type JsonSerialized<T> = string & {
  __json_seralized: T;
};

interface JSON {
  parse<T>(text: JsonSerialized<T>): T;
}

type VideoScriptSectionType = 'paragraph';
export type VideoScriptParagraphType = {
  text: string;
};

export type VideoScriptSection = {
  id: string;
  level: number | null;
  order: number;
  params: null;
  parent_section: null;
  section_content: JsonSerialized<VideoScriptParagraphType>;
  section_type: VideoScriptSectionType;
};

export type VideoScriptPresentationRelatedFile = {
  id: string;
  is_active: boolean;
  source_url: string;
};

export type VideoScriptPresentationFile = {
  id: string;
  state: UploadStateEnum;
  related_files: VideoScriptPresentationRelatedFile[];
};

export type VideoScriptFile = {
  id: string;
  related_files: {
    id: string;
    original_data: string;
    sections: VideoScriptSection[];
  }[];
};

export type VideoScriptGeneratedVideo = {
  id: string;
  external_id: string;
  state: string;
  external_urls: string;
  system: string;
};

export type VideoScript = {
  id: string;
  created_at: string;
  file: VideoScriptFile;
  presentation_file?: VideoScriptPresentationFile;
  generated_videos: VideoScriptGeneratedVideo[];
};

export type StructureItemAssignment = {
  id: string;
  quiz?: {
    id: string;
    generate_param_number_of_questions: string;
    generate_param_type_of_answer: string;
  };
  video?: {
    id: string;
    external_video_href?: string;
    video_length_s: number;
    avatar_position_type: VideoAvatarPosition;
    avatar_language: {
      id: string;
    };
    avatar_voice: {
      id: string;
    };
    points?: VideoPoint[];
    script?: VideoScript | null;
  };
  points?: ReadingPoint[];
  reading?: {
    id: string;
    user_target_content_length: number;
    file: ReadingFile;
  };
  content_items?: QuizContentItem[] | null;
};

export interface StructureItem extends StructureItemOpen {
  id: string;
  title: string;
  description: string;
  index: string;
  is_active: boolean;
  is_edited: boolean;
  assignment_filled: boolean;
  order: number;
  ordering_index: string;
  learning_structure: LearningStructure;
  course_structure_item_parent: {
    id: string;
  } | null;
  assignment: StructureItemAssignment | null;
  type: ObjectType | null;
  children: StructureItem[] | [];
  sub_module_outcomes: StructureItemSubModule[];
}

export interface AssignmentItem {
  id: string;
  title: string;
  description: string;
  index: string;
  assignment_filled: boolean;
  is_active: boolean;
  is_edited: boolean;
  order: number;
  ordering_index: string;
  context_files: ContextFile[];
  assignment: StructureItemAssignment | null;
  learning_structure: LearningStructure;
  course_structure_item_parent: {
    id: string;
  };
  type: ObjectType | null;
  sub_module_outcomes: StructureItemSubModule[];
}

export type VideoGenMessage = {
  id: number;
  script_id: number;
  structure_item_id: number;
  generation_in_progress: boolean;
  state: VideoGenerateState;
};

export interface AssignmentItemData {
  outline_builder_assignment: AssignmentItem;
}

export interface AssignmentItemContextFileStateData {
  outline_builder_course_structure_item: {
    id: string;
    pk: string;
    context_files: ContextFile[];
  };
}

export interface AssignmentsItemData {
  outline_builder_assignments: AssignmentItem[];
}

export interface AssignmentItemFull extends QuizAssignment {
  content_items: QuizContentItem[];
}

export interface AssignmentItemFullData {
  outline_builder_assignments: AssignmentItemFull[];
}

export interface ProjectGenerationStatusData {
  outline_builder_project: {
    id: string;
    generation_in_progress: boolean;
  };
}
