import React, { memo } from 'react';

import NoData from 'shared/ui/icons/no-data/NoData';
import { useTranslation } from 'react-i18next';

type Props = {
  isContent?: boolean;
};

const AssignmentNoData = ({ isContent }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="assignment-select-empty">
      <NoData />
      <span className="assignment-select-empty__text">
        {isContent ? t('assignments.plsSelectVideo') : t('assignments.plsSelectQuiz')}
      </span>
    </div>
  );
};

export default memo(AssignmentNoData);
