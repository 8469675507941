import { RcFile } from 'antd/es/upload';

import { putUploadFileRequest, putSetFileStateRequest } from '../requests/file';
import { UploadStateEnum } from '../../types/entities';

export const chunkSize = 1048576 * 5;

export const createChunks = (file: RcFile, cSize: number) => {
  let startPointer = 0;
  const endPointer = file.size;
  const chunks = [];
  while (startPointer < endPointer) {
    const newStartPointer = startPointer + cSize;
    chunks.push(file.slice(startPointer, newStartPointer));
    startPointer = newStartPointer;
  }
  return chunks;
};

type UploadFileInPartProps = {
  file: RcFile;
  fileId: number;
  updateUpload?: (loaded: number) => void;
  callback?: () => void;
  callbackComplete?: () => void;
  canceled?: any;
};

export const onUploadFileInPartRequest = async ({
  file,
  fileId,
  updateUpload,
  callback,
  callbackComplete,
  canceled,
}: UploadFileInPartProps) => {
  const chunks = createChunks(file, chunkSize);
  let iterator = 1;
  let loaded = 0;
  let stopLoop = false;

  const onUploadProgress = (progressEvent: any) => {
    // console.log(progressEvent, 'progressEvent');
    if (chunks.length) {
      updateUpload?.(progressEvent.bytes);
    }
  };

  for await (const chunk of chunks) {
    const formData = new FormData();
    formData.append('part', iterator.toString());
    formData.append('file', chunk);
    const config = {
      timeout: 75000,
      onUploadProgress: (progressEvent: any) => onUploadProgress(progressEvent),
    };

    if (!stopLoop) {
      if (!canceled?.current) {
        await putUploadFileRequest(fileId, formData, config)
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          .then(async () => {
            loaded = loaded + 1;
            iterator = iterator + 1;

            if (canceled?.current) {
              putSetFileStateRequest(fileId, { state: UploadStateEnum.CANCELLED })
                .then(() => callback?.())
                .catch(() => {
                  stopLoop = true;
                  callback?.();
                });
            }

            if (loaded === chunks.length) {
              console.log('Process is complete, counter', loaded);
              await putSetFileStateRequest(fileId, { state: UploadStateEnum.UPLOADED })
                .then(() => {
                  callback?.();
                  callbackComplete?.();
                })
                .catch(() => {
                  stopLoop = true;
                  callback?.();
                });
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          .catch(async (error) => {
            stopLoop = true;
            console.log('Error Occurred:', error);
            await putSetFileStateRequest(fileId, { state: UploadStateEnum.CANCELLED })
              .then(() => callback?.())
              .catch(() => callback?.());
          });
      }
    } else {
      break;
    }
  }
};
