import {
  getOutlineBuilderProjectVersionOutcomeNextRequest,
  postOutlineBuilderProjectVersionOutcomeRequest,
} from 'shared/api/requests/outline-builder-project-version';
import { message } from 'antd';
import { messageSave } from 'shared/utils';
import { postModuleOutcomeSubRequest } from 'shared/api/requests/outline-builder-project-module-outcome';
import { AppDispatch, store } from 'shared/store/store';

import { getProjectAction } from '../../project/actions';

import { setNext, toggleSubView, toggleView } from './index';

export const loadOutcomeNextItemDataAction = (verId: string) => {
  return async (dispatch: AppDispatch) => {
    const response = await getOutlineBuilderProjectVersionOutcomeNextRequest(verId);

    if (response) {
      dispatch(setNext(response));
    }
  };
};

export const saveNewOutcomeAction = (verId: string, name: string) => {
  return async (dispatch: AppDispatch) => {
    const state = store.getState().addOutcome;
    const project = store.getState().project.project;
    const lang = project?.active_project_version?.language?.code;

    messageSave();
    const finishRequest = () => {
      dispatch(toggleView());
      message.destroy();
    };

    const payload = {
      name,
      color: state.color,
    };
    await postOutlineBuilderProjectVersionOutcomeRequest(verId, payload)
      .then(() => {
        if (project?.id && lang) {
          dispatch(getProjectAction(project?.id, true, undefined, lang)).then(() => {
            finishRequest();
          });
          dispatch(loadOutcomeNextItemDataAction(verId)).then(() => {});
        }
      })
      .catch(() => finishRequest());
  };
};

export const saveNewSubOutcomeAction = (moduleId: string, name: string) => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const lang = project?.active_project_version?.language?.code;
    messageSave();

    const finishRequest = () => {
      dispatch(toggleSubView(null));
      message.destroy();
    };

    const payload = {
      name,
    };

    await postModuleOutcomeSubRequest(moduleId, payload)
      .then(() => {
        if (project?.id && lang) {
          dispatch(getProjectAction(project?.id, true, undefined, lang))
            .then(() => {
              finishRequest();
            })
            .catch(() => {
              finishRequest();
            });
        }
      })
      .catch(() => finishRequest());
  };
};
