import React, { memo, useCallback, useEffect, useState } from 'react';

import { Divider, Form, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'shared/ui/form-item/FormItem';
import Input from 'shared/ui/input/Input';
import Button from 'shared/ui/button/Button';
import { LoginResponse, postLogin, postRefreshToken } from 'shared/api/requests/auth';
import { localStorageService } from 'shared/services/localStorageService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';
import { setAuth } from 'app/store';
import InputPassword from 'shared/ui/input/InputPassword';
import { getMeAction } from 'app/store/actions';
import { useBoolean } from 'usehooks-ts';
import Spinner from 'shared/ui/spinner';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'shared/store/hooks';

import GoogleAuthButton from '../google-auth-button';

import './styles.scss';

const workplaceUrl = process.env.REACT_APP_WORKPLACE_URL;

const LoginForm: React.FC = () => {
  const { value: loader, setTrue, setFalse } = useBoolean(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const refresh = searchParams.get('log');

  const onStartFetching = () => setFetching(true);
  const onFinishFetching = () => setFetching(false);

  const onAutoLogin = useCallback(async () => {
    if (refresh) {
      const payload = {
        refresh,
      };
      await postRefreshToken(payload)
        .then((res: LoginResponse) => {
          if (res.access) {
            localStorageService.setAuthData(res);
            dispatch(setAuth());
            dispatch(getMeAction()).then(() => {
              navigate(PATH_NAME.main);
              setFalse();
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setFalse();
        });
    }
  }, [dispatch, navigate, refresh, setFalse]);

  useEffect(() => {
    if (refresh) {
      setTrue();
      void onAutoLogin();
    }
  }, [refresh, onAutoLogin, setTrue]);

  const onSubmit = async () => {
    onStartFetching();
    const values = form.getFieldsValue();
    const payload = {
      email: values.email,
      password: values.password,
    };

    await postLogin(payload)
      .then((res: LoginResponse) => {
        onFinishFetching();
        if (res?.access) {
          localStorageService.setAuthData(res);
          dispatch(setAuth());
          dispatch(getMeAction()).then(() => {
            navigate(PATH_NAME.main);
          });
        }
        if (res.data?.detail) {
          message.error(t<string>('loginForm.error.wrongCredentials'), 5);
          //message.error(`${res.data.detail}`, 5);
        }
      })
      .catch((err) => {
        onFinishFetching();
        console.log(err);
      });
  };

  const onClickResetPass = useCallback(() => {
    window.open(`https://${workplaceUrl}/reset-password?company_type=outline_builder`, '_self');
  }, []);

  return (
    <div className="auth-page container">
      {loader ? (
        <Spinner />
      ) : (
        <Form requiredMark={false} className="login-form" layout="vertical" form={form} onFinish={onSubmit}>
          <FormItem
            name="email"
            label={t<string>('loginForm.email.label')}
            rules={[{ required: true, type: 'email', message: t<string>('loginForm.error.plsEmail') }]}
          >
            <Input size="large" exSize="xl" placeholder={t<string>('loginForm.email.placeholder')} />
          </FormItem>
          <FormItem
            name="password"
            label={t<string>('loginForm.password.label')}
            rules={[{ required: true, message: t<string>('loginForm.error.plsPassword') }]}
          >
            <InputPassword size="large" exSize="xl" placeholder={t<string>('loginForm.password.placeholder')} />
          </FormItem>
          <Button size="large" exSize="xl" htmlType="submit" loading={fetching}>
            {t<string>('loginForm.login')}
          </Button>
          <Divider>{t('signup.or')}</Divider>
          <GoogleAuthButton className="mb-8" fetching={fetching} onStartFetching={onStartFetching} />
          <Button type="link" onClick={onClickResetPass}>
            {t<string>('loginForm.reset')}
          </Button>
        </Form>
      )}
    </div>
  );
};

export default memo(LoginForm);
