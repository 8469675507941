import { CourseStructureItemData } from 'shared/types/entities/structureItem';

import { client } from '../index';
import { OUTLINE_BUILDER_PROJECT_STRUCTURE_ITEM } from '../schemas/outlineBuilderCourseStructureItem';

export const getStructureItem = (itemId: string) => {
  return client.query<CourseStructureItemData>({
    query: OUTLINE_BUILDER_PROJECT_STRUCTURE_ITEM,
    fetchPolicy: 'network-only',
    variables: {
      id: itemId,
    },
  });
};
