import React from 'react';

import { Account } from 'features/account';

const ChangePasswordPage = () => {
  return (
    <div className="change-pass-page container">
      <Account.UserInfo.ChangePassForm />
    </div>
  );
};

export default ChangePasswordPage;
