import React, { memo, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import Button from 'shared/ui/button/Button';
import { OutlineProject } from 'shared/types/entities';
import mixpanel from 'mixpanel-browser';
import { createProjectVersionAction } from 'modules/about-page-form/store/actions';
import { getProjectsAction } from 'widgets/course-list/store/actions';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'shared/store/hooks';

type Props = {
  project: OutlineProject;
};

const RegenerateButton: React.FC<Props> = ({ project }) => {
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const onClick = useCallback(async () => {
    mixpanel.track('Project Regenerate', { ProjectId: project.pk });
    await dispatch(createProjectVersionAction(project.pk, lang || i18n.language));
    await dispatch(getProjectsAction(true));
  }, [dispatch, i18n.language, lang, project]);

  return (
    <Button className="card-regen-button" type="primary" onClick={onClick}>
      {t('common.regenerate')}
    </Button>
  );
};

export default memo(RegenerateButton);
