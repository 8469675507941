import React, { memo, useCallback } from 'react';

import Button from 'shared/ui/button/Button';
import { StructureItemAssignment } from 'shared/types/entities';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';

import './styles.scss';

type Props = {
  disabled: boolean;
  structureItemId: string;
  assignment: StructureItemAssignment | null;
  assignment_filled: boolean;
};

const QuizButton: React.FC<Props> = ({ disabled, structureItemId, assignment_filled, assignment }) => {
  const { t } = useTranslation();
  const { id, lang } = useParams();

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    const path = generatePath(PATH_NAME.courseManualWithEntity, {
      id,
      lang,
      entity: PATH_NAME.courseEntity.assignments,
    });
    if (assignment_filled) {
      const pathWithSearch = `${path}?str=${structureItemId}`;
      navigate(pathWithSearch);
    } else {
      const pathWithSearch = `${path}?str=${structureItemId}&auto=true`;
      navigate(pathWithSearch);
    }
  }, [assignment_filled, id, lang, navigate, structureItemId]);

  return (
    <Button className="cs-element-main-quiz-button" size="small" type="text" disabled={disabled} onClick={onClick}>
      {assignment_filled ? t<string>('structure.openQuiz') : t<string>('structure.genQuiz')}
    </Button>
  );
};

export default memo(QuizButton);
