import React, { FC, useRef } from 'react';

import { RcFile } from 'antd/es/upload';
import { message, UploadProps } from 'antd';
import FilesUpload from 'shared/components/files-upload/FilesUpload';
import { checkMimeTypePresentation } from 'shared/utils/images';
import { messageDurability } from 'shared/utils';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { useSearchParams } from 'react-router-dom';
import { getStudioPresentationFileAction, selectPresentationFile } from 'entities/studio/model';

import { uploadPresentationAction, uploadSlidesAction } from '../../model/actions';

type Props = {
  variant?: string;
};

export const DraggerUpload: FC<Props> = ({ variant }) => {
  const [searchParams] = useSearchParams();
  const selectedId = searchParams.get('str');
  const trigger = useRef<boolean | null>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const customRequest = () => null;
  const presentationFile = useAppSelector(selectPresentationFile);

  const onBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    const { isAllowed, isAllPresentations } = checkMimeTypePresentation(fileList);
    if (!isAllowed) {
      void message.error(t<string>('messages.upload.allowedImagesFormats'), messageDurability);
      return;
    }
    if (isAllowed && isAllPresentations && presentationFile?.related_files.length) {
      void message.error(t<string>('messages.upload.allowedSlideFormats'), messageDurability);
    }
  };

  const onChange = async (info: UploadChangeParam<UploadFile>) => {
    const { isAllPresentations, isAllImages } = checkMimeTypePresentation(info.fileList);

    if (isAllImages) {
      if (!trigger.current && selectedId) {
        trigger.current = true;
        await dispatch(uploadSlidesAction(info.fileList));
        await dispatch(getStudioPresentationFileAction(selectedId)).finally(() => {
          trigger.current = false;
        });
      }
    }
    if (isAllPresentations && info.fileList.length > 1) {
      void message.error(t<string>('messages.upload.onePresentation'), messageDurability);
    }
    if (isAllPresentations && info.fileList.length === 1 && !trigger.current && selectedId) {
      await dispatch(uploadPresentationAction(info.file));
      await dispatch(getStudioPresentationFileAction(selectedId)).finally(() => {
        trigger.current = false;
      });
    }
  };

  const uploadProps: UploadProps = {
    customRequest,
    onChange,
    showUploadList: false,
    beforeUpload: onBeforeUpload,
    multiple: true,
  };

  return (
    <FilesUpload
      text={t<string>('assignments.video.studio.modalSlides.uploadText')}
      hint={t<string>(
        presentationFile?.related_files.length
          ? 'assignments.video.studio.modalSlides.uploadHintOnlySlides'
          : 'assignments.video.studio.modalSlides.uploadHint'
      )}
      variant={variant}
      {...uploadProps}
    />
  );
};
