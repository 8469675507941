import { gql } from '@apollo/client';

export const PAGE_INFO = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

export const USER_INFO = gql`
  fragment UserInfo on UserType {
    email
    first_name
    icon_image
    id
    last_name
    name
    phone_number
    state
    timezone
  }
`;

export const ACTIVE_SUB_MODULE_OUTCOMES = gql`
  fragment ActiveSubModuleOutcomes on OutlineBuilderProjectSubModuleOutcomeType {
    id
    letter_index
    name
    order
    created_at
    updated_at
    is_edited
  }
`;

export const ACTIVE_MODULE_OUTCOMES = gql`
  fragment ActiveModuleOutcomes on OutlineBuilderProjectModuleOutcomeType {
    id
    color
    letter_index
    name
    order
    created_at
    updated_at
    is_edited
  }
`;

export const ACTIVE_PROJECT_VERSION = gql`
  fragment ActiveProjectVersion on OutlineBuilderProjectVersionType {
    id
    generated_description
    generated_title
    generated_course_participants
    generated_prerequisites
    feedback
    curriculum {
      id
      file {
        id
        name
      }
    }
    language {
      id
      code
      name
    }
  }
`;

export const OUTLINE_PROJECT_NODE = gql`
  fragment OutlineProjectNode on OutlineBuilderProjectNodeType {
    id
    pk
    is_tutorial
    about_annotation
    goal_annotation
    knowledge_level
    duration
    practical_level
    created_at
    updated_at
    created_by {
      id
      name
    }
    has_submodules
    project_type
    project_type_fk {
      course_key
    }
    course_type {
      name
    }
    generation_status {
      id
      current_step
      total_steps
      state
      is_ended
      generation_type
    }
    is_public_available
    generation_in_progress
    public_slug
    auto_steps
    languages {
      id
      code
      name
    }
  }
`;

export const OUTLINE_PROJECT = gql`
  fragment OutlineProject on OutlineBuilderProjectType {
    id
    pk
    tone_of_voice
    about_annotation
    goal_annotation
    prerequisites
    knowledge_level
    course_study_load
    duration
    practical_level
    created_at
    updated_at
    created_by {
      id
    }
    has_submodules
    massive_course
    project_type
    generation_in_progress
    webinar_duration
    project_type_fk {
      id
      default_name
      course_key
    }
    context_files {
      id
      order
      context_file {
        id
        file {
          id
          name
          file_type
        }
      }
    }
    generation_status {
      id
      current_step
      total_steps
      state
      is_ended
      generation_type
    }
    is_public_available
    public_slug
    auto_steps
    languages {
      id
      code
      name
    }
  }
`;

export const OBJECT_TYPE = gql`
  fragment ObjectType on OutlineBuilderCourseObjectTypeType {
    id
    title
    name
    icon
  }
`;

export const LEARNING_STRUCTURE = gql`
  fragment LearningStructure on OutlineBuilderLearningStructureType {
    id
    level_type
    name
  }
`;

export const STRUCTURE_ITEM = gql`
  fragment StructureItem on OutlineBuilderCourseStructureItemType {
    id
    pk
    title
    assignment_filled
    description
    index
    is_active
    order
    is_edited
    ordering_index
    assignment {
      id
      quiz {
        id
        generate_param_number_of_questions
        generate_param_type_of_answer
      }
      video {
        id
        video_length_s
        points {
          id
          pk
          name
          order
        }
      }
    }
    course_structure_item_parent {
      id
    }
    sub_module_outcomes {
      id
      letter_index
      name
      outline_builder_project_module_outcome {
        color
      }
    }
  }
`;

export const DOCUMENT_FILE_TYPE = gql`
  fragment DocumentFileType on FileType {
    id
    related_files {
      ... on VideoFileType {
        id
        resolution
        source_url
      }

      ... on DocumentFileVersionType {
        id
        original_data
        sections {
          id
          parent_section {
            id
          }
          section_type
          section_content
          style
          level
          params
          order
        }
      }
    }
  }
`;

export const STRUCTURE_ITEM_VIDEO_SETTINGS_FRAGMENT = gql`
  fragment StructureItemVideoSettings on OutlineBuilderCourseStructureItemType {
    id
    assignment {
      id
      video {
        id
        avatar_position_type
        avatar_voice {
          id
        }
        avatar_language {
          id
        }
      }
    }
  }
`;

export const STRUCTURE_ITEM_FULL = gql`
  fragment StructureItemFull on OutlineBuilderCourseStructureItemType {
    id
    pk
    title
    assignment_filled
    description
    index
    is_active
    order
    is_edited
    ordering_index
    context_files {
      id
      order
      context_file {
        id
        state
        file {
          id
          name
          file_type
        }
      }
    }
    assignment {
      id
      content_items {
        id
        content_type
        content
        description
        order
        children {
          id
          is_answer
          content
          description
          order
        }
      }
      quiz {
        id
        generate_param_number_of_questions
        generate_param_type_of_answer
      }
      video {
        id
        avatar_position_type
        avatar_language {
          id
        }
        avatar_voice {
          id
        }
        external_video_href
        video_length_s
        script {
          created_at
          file {
            ...DocumentFileType
          }
          generated_videos {
            id
            external_id
            state
            external_urls
            system
          }
          id
          pk
        }
        points {
          id
          pk
          name
          order
          color
        }
      }
      points {
        id
        pk
        name
        order
        color
      }
      reading {
        id
        pk
        user_target_content_length
        file {
          id
          related_files {
            ... on DocumentFileVersionType {
              id
              original_data
              sections {
                id
                parent_section {
                  id
                }
                section_type
                section_content
                style
                level
                params
                order
              }
            }
          }
        }
      }
    }
    course_structure_item_parent {
      id
    }
    sub_module_outcomes {
      id
      letter_index
      name
      outline_builder_project_module_outcome {
        color
      }
    }
  }
  ${DOCUMENT_FILE_TYPE}
`;
