import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, App as AntApp } from 'antd';
import { createRoot } from 'react-dom/client';
import i18next from 'i18next';
import ruRu from 'antd/es/locale/ru_RU';
import enUS from 'antd/es/locale/en_US';
import CreateCourse from 'widgets/create-course';
import WeContactModal from 'modules/we-contact-modal';
import TranslationModal from 'modules/content-language-button/translationModal';
import WelcomeTutorialModal from 'modules/tutorial/WelcomeTutorialModal';
import EndTutorialModal from 'modules/tutorial/EndTutorialModal';
import UserDetailModal from 'features/account/update-user-data/ui/modal';
import { Export } from 'features/export';
import { ParamsModalProps } from 'features/export/ui/params-modal';
import { Subscription } from 'features/subscription';
import { Expert } from 'features/expert';
import { StudioModalSlides } from 'entities/studio/ui/modalSlides/StudioModalSlides';

import BaseModal from '../components/base-modal';
import { store } from '../store/store';
import { client } from '../graphql';
import { theme } from '../theme/theme';
const locale = i18next.language === 'ru' ? ruRu : enUS;

const modalService = {
  id: 0,
  modals: {} as any,
  root: {} as any,
  open(options: any) {
    const target = document.createElement('div');
    document.body.appendChild(target);
    const {
      component: Component,
      width,
      props,
      onCancel,
      maskClosable,
      hideClose,
      keyboard,
      maskStyle,
      ...rest
    } = options;
    const { id } = this;
    this.modals[this.id] = target;
    const close = () => {
      if (onCancel) {
        onCancel();
      }
      this.close(id);
    };
    this.root = createRoot(target);
    this.root.render(
      <Provider store={store}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <ConfigProvider locale={locale} theme={theme}>
              <AntApp>
                <BaseModal
                  id={id}
                  show
                  {...rest}
                  width={width}
                  onCancel={close}
                  hideClose={hideClose}
                  keyboard={keyboard}
                  styles={{ mask: maskStyle }}
                  maskClosable={maskClosable}
                  component={<Component {...props} modalId={id} onCancel={close} />}
                />
              </AntApp>
            </ConfigProvider>
          </BrowserRouter>
        </ApolloProvider>
      </Provider>
    );
    // eslint-disable-next-line no-plusplus
    this.id++;
    return id;
  },
  close(id: number) {
    const container: any = this.modals[id];
    if (container) {
      this.root.unmount();
      document.body.removeChild(container);
    }
    delete this.modals[id];
  },
  closeAll() {
    const keys = Object.keys(this.modals).map((key) => parseInt(key, 10));
    keys?.forEach((key) => {
      this.close(key);
    });
  },
  openCreateCourse: (props: any) => {
    return modalService.open({
      width: 1085,
      component: CreateCourse,
      className: 'cb-modal-create-course',
      props,
    });
  },
  openWeContact: (props: any) => {
    return modalService.open({
      width: 800,
      component: WeContactModal,
      props,
    });
  },
  openCourseTranslation: (props: any) => {
    return modalService.open({
      width: 800,
      component: TranslationModal,
      props,
    });
  },
  openWelcomeTutorial: (props: any) => {
    return modalService.open({
      width: 800,
      component: WelcomeTutorialModal,
      props,
    });
  },
  openEndTutorial: (props: any) => {
    return modalService.open({
      width: 800,
      component: EndTutorialModal,
      props,
    });
  },
  openUserDetails: (props: any) => {
    return modalService.open({
      width: 650,
      component: UserDetailModal,
      props,
    });
  },
  openExportModal: (props: ParamsModalProps) => {
    return modalService.open({
      width: 650,
      component: Export.ParamsModal,
      props,
    });
  },
  openTokensModal: (props: any) => {
    return modalService.open({
      width: 650,
      mask: false,
      component: Subscription.OutOfTokensModal,
      props,
    });
  },
  openExpertModal: (props: any) => {
    return modalService.open({
      width: 650,
      maskClosable: true,
      component: Expert.Form,
      props,
    });
  },
  openStudioSlides: (props: any) => {
    return modalService.open({
      width: 1000,
      maskClosable: false,
      component: StudioModalSlides,
      props,
    });
  },
};

export default modalService;
