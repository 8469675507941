import React, { useEffect, useState } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { cx } from 'shared/utils';
import './styles.scss';

type SvgInlineProps = {
  url?: string;
  className?: string;
  size?: number;
};

const request = async (url: string) => {
  return fetch(url, {
    headers: { 'Access-Control-Allow-Origin': '*' },
  });
};

function SvgInline({ url, className, size }: SvgInlineProps) {
  const [svg, setSvg] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (url) {
      request(url)
        .then((response) => {
          if (!response.ok) {
            setError(true);
          } else {
            return response.text();
          }
        })
        .then((json) => {
          setSvg(json);
        })
        .catch((e) => {
          setError(true);
        });
    }
  }, [url]);
  const classNames = cx('ccm-svg-inline', className);
  return error ? (
    <div className={classNames}>
      <QuestionCircleOutlined />
    </div>
  ) : (
    <div className={classNames} dangerouslySetInnerHTML={{ __html: svg }} />
  );
}

export default SvgInline;
