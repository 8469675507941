import React, { useCallback, useEffect } from 'react';

import { App } from 'antd';
import { useAppSelector } from 'shared/store/hooks';
import { useTranslation } from 'react-i18next';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';
import { PATH_NAME } from 'pages/constants';

type Props = {
  onCancel: () => void;
};

const OutOfTokensModal = ({ onCancel }: Props) => {
  const { modal } = App.useApp();
  const { t } = useTranslation();
  const { navigate } = useCustomNavigator();
  const hasSubscription = useAppSelector((state) => state.app.me?.has_active_subscription);

  const goBilling = useCallback(() => {
    onCancel();
    navigate(PATH_NAME.billing);
    navigate(0); //bad decision
  }, [navigate, onCancel]);

  useEffect(() => {
    if (hasSubscription) {
      modal.error({
        width: 500,
        title: t<string>('subscribe.outTokens.title'),
        content: t<string>('subscribe.outTokens.hasSubscription.content'),
        onOk: goBilling,
        okText: t<string>('subscribe.outTokens.hasSubscription.ok'),
      });
    } else {
      modal.error({
        width: 500,
        title: t<string>('subscribe.outTokens.title'),
        content: t<string>('subscribe.outTokens.notSubscription.content'),
        onOk: goBilling,
        okText: t<string>('subscribe.outTokens.notSubscription.ok'),
      });
    }
  }, [goBilling, hasSubscription, modal, onCancel, t]);

  return <></>;
};

export default OutOfTokensModal;
