import { useQuery } from '@apollo/client';
import { BaseListOption } from 'shared/types/entities';

import { POSITIONS } from '../schemas/positions';
import { localStorageService } from '../../services/localStorageService';
import { baseListToSelect } from '../../utils';

type Node = {
  node: BaseListOption;
};

type Data = {
  positions: {
    edges: Node[];
  };
};

const usePositions = () => {
  const language = localStorageService.getLangData();
  const { data } = useQuery<Data>(POSITIONS, {
    variables: {
      language,
    },
    fetchPolicy: 'network-only',
  });

  const positions = data?.positions.edges.map((item) => item.node);
  const sorted = positions?.sort((a, b) => a?.order - b?.order);

  return {
    positions,
    positionsForSelect: baseListToSelect(sorted),
  };
};

export default usePositions;
