import React from 'react';

import { Subscription } from 'features/subscription';
import { AffiliateTokensTable } from 'entities/price';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const AffiliatePage = () => {
  const { t } = useTranslation();
  const alertContent = (
    <div>
      <p>{t('affiliate.alert.text1')}</p>
      <p>{t('affiliate.alert.text2')}</p>
      <p>{t('affiliate.alert.text3')}</p>
      <p>{t('affiliate.alert.text4')}</p>
    </div>
  );

  return (
    <div className="container affiliate-page">
      <Subscription.InviteButton />
      <Alert className="affiliate-page-alert" type="info" message={alertContent} />
      <AffiliateTokensTable />
    </div>
  );
};

export default AffiliatePage;
