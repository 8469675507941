import React, { useEffect } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { getAssignmentItemAction } from 'entities/assignment/model';
import SpinnerOverlay from 'shared/ui/spinner-overlay/SpinnerOverlay';
import { selectLoading } from 'entities/assignment/model/assignment.selectors';

import AssignmentQuizWidget from '../assignment-quiz';
import AssignmentVideoWidget from '../assignment-video';
import AssignmentReadingWidget from '../assignment-reading';

import AssignmentNoData from './ui/no-data';

import './styles.scss';

const getEntity = (type?: string, isContentPage?: boolean) => {
  switch (type) {
    case 'quiz':
      return <AssignmentQuizWidget />;
    case 'video':
      return <AssignmentVideoWidget />;
    case 'reading':
      return <AssignmentReadingWidget />;
    default:
      return <AssignmentNoData isContent={isContentPage} />;
  }
};

const AssignmentSelectWidget: React.FC = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const isContentPage = location.pathname.indexOf('content') !== -1;

  const dispatch = useAppDispatch();
  const selectedId = searchParams.get('str');
  const assignment = useAppSelector((state) => state.assignment.assignment);
  const loading = useAppSelector(selectLoading);
  const projectGenerate = useAppSelector((state) => state.project.generation);

  useEffect(() => {
    if (selectedId) {
      void dispatch(getAssignmentItemAction(selectedId));
    }
  }, [selectedId, dispatch]);

  return (
    <div className="assignment-select">
      <SpinnerOverlay loading={loading && !projectGenerate} />
      <div className="assignment-select__wrapper">{getEntity(assignment?.type?.name, isContentPage)}</div>
    </div>
  );
};

export default AssignmentSelectWidget;
