import { message } from 'antd';
import { checkAsyncState, messageError, messageGeneration, messageRejectedGeneration } from 'shared/utils';
import {
  postOutlineBuilderProjectVersionStructureItemsRequest,
  ProjectVersionStructureItemsPayload,
} from 'shared/api/requests/outline-builder-project-structure';
import { getStructureRequest } from 'shared/graphql/queries/getStructure';
import { getProjectAction } from 'modules/project/actions';
import { generatePath, redirect } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';
import { extendStructure, setCollapseStructureItem, toggleCollapseStructureItem } from 'shared/utils/structure';
import { AppDispatch, store } from 'shared/store/store';

import { setProjectGeneration } from '../../../modules/project';
import { postStructureItemsRegen } from '../../../shared/api/requests/outline-builder-project-version';

import { setStructure, setStructureLoading } from './index';

export const getStructureAction = (projectId: string, language: string, isSilent?: boolean, projectVerId?: string) => {
  return async (dispatch: AppDispatch) => {
    if (!isSilent) {
      dispatch(setStructureLoading(true));
    }
    await getStructureRequest(projectId, language, projectVerId).then((response) => {
      dispatch(setStructure(extendStructure(response.data.outline_builder_course_structure)));
      if (!isSilent) {
        dispatch(setStructureLoading(false));
      }
    });
  };
};

const intermediateStructureAction = (id: string, language: string) => {
  return async (dispatch: AppDispatch) => {
    const projectStore = store.getState().project;

    if (id === projectStore?.project?.id) {
      void dispatch(getStructureAction(id, language, true));
    }
  };
};

export const regenerationStructureItemsAction = (text: string) => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const verId = project?.active_project_version?.id;

    dispatch(setProjectGeneration(true));

    if (verId) {
      const payload = {
        regeneration_text: text,
      };

      await postStructureItemsRegen(verId, payload).catch((err) => {
        dispatch(setProjectGeneration(false));
        message.destroy();
        messageError(err);
      });
    }
  };
};

export const generateStructureItemsAction = (verId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setProjectGeneration(true));

    postOutlineBuilderProjectVersionStructureItemsRequest(verId).catch((err) => {
      dispatch(setProjectGeneration(false));
      message.destroy();
      messageError(err);
    });
  };
};

export const onToggleCollapseItemAction = (id: string, value: boolean) => {
  return async (dispatch: AppDispatch) => {
    const structure = store.getState().structure.structure;
    const updatedStructure = toggleCollapseStructureItem(id, value, structure);
    dispatch(setStructure(updatedStructure));
  };
};

export const onSetAllCollapseItemAction = (value: boolean) => {
  return async (dispatch: AppDispatch) => {
    const structure = store.getState().structure.structure;
    const updatedStructure = setCollapseStructureItem(value, structure);
    dispatch(setStructure(updatedStructure));
  };
};
