import React, { ChangeEvent } from 'react';

import { CaretLeftOutlined, PlusOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import TextArea from 'shared/ui/textArea/TextArea';
import { MenuInfo, QuizContentItem } from 'shared/types/entities';
import { cx } from 'shared/utils';
import { useCurrentAssignment } from 'entities/assignment/model';

import QuizCardMore from '../quiz-card-more';

import './styles.scss';
import { useAppSelector } from '../../../../shared/store/hooks';
import { useOwner } from '../../../../shared/hooks/useProjectOwner';

type QuizCardHeadProps = {
  canShowTour?: boolean;
  isPrint?: boolean;
  isShowEmpty?: boolean;
  isNew?: boolean;
  open: boolean;
  item: QuizContentItem;
  onMoreActions: (info: MenuInfo) => void;
  onChangeText: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onToggle: () => void;
  onBlur: () => void;
  onClickAdd?: () => void;
};

const QuizCardHead: React.FC<QuizCardHeadProps> = ({
  canShowTour,
  isPrint,
  isShowEmpty,
  isNew,
  open,
  item,
  onChangeText,
  onMoreActions,
  onToggle,
  onBlur,
  onClickAdd,
}) => {
  const { t } = useTranslation();
  const { loading } = useCurrentAssignment();
  const isGeneration = useAppSelector((state) => state.project.generation);
  const selectedVersion = useAppSelector((s) => s.project.selectedVersion);
  const { isOwner } = useOwner();

  const classNames = cx('quiz-card-head__controls quiz-card-head__title', {
    'tour--assignments-quiz-edit': canShowTour,
  });

  const disable = !isOwner || loading || isGeneration || !!selectedVersion;

  return (
    <div className="quiz-card-head">
      <div className="quiz-card-head__count">
        <span>{item.order}</span>
      </div>
      <div className="quiz-card-head__content">
        <TextArea
          autoFocus={isNew}
          name="content"
          bordered={false}
          placeholder={t<string>('structure.addTitle')}
          autoSize={{ minRows: 1, maxRows: 20 }}
          className={classNames}
          value={item.content}
          disabled={disable}
          onBlur={onBlur}
          onChange={onChangeText}
        />
      </div>
      <div className="quiz-card-head__actions">
        {!isNew && !isPrint ? (
          <Button
            shape="circle"
            type="text"
            size="small"
            icon={<PlusOutlined style={{ fontSize: 16 }} />}
            disabled={disable || isShowEmpty}
            onClick={onClickAdd}
          />
        ) : null}

        {!isNew && !isPrint ? <QuizCardMore disabled={disable} onClick={onMoreActions} /> : null}
        <Button
          shape="circle"
          className="cs-collapse-toggle"
          type="text"
          size="small"
          icon={<CaretLeftOutlined style={{ fontSize: 16 }} rotate={open ? -90 : 0} />}
          disabled={disable || isNew}
          onClick={onToggle}
        />
      </div>
    </div>
  );
};

export default QuizCardHead;
