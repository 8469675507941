import React, { ChangeEvent, memo, useCallback } from 'react';

import { CaretLeftOutlined, PlusOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button';
import { StructureItem } from 'shared/types/entities';
import Badge from 'shared/ui/badge';
import TextArea from 'shared/ui/textArea';
import { cx } from 'shared/utils';
import CourseStructureIndexWithState from 'shared/components/course-structure-index-with-state';
import ButtonSparking from 'shared/components/button-sparking/ButtonSparking';
import { useAppSelector } from 'shared/store/hooks';

import MoreActions from '../more-actions';
import AddElementDropdown from '../add-element-drop-btn';
import RegeneratePopup from '../../regenerate-popup';

import './styles.scss';

type CourseStructureCollapseHeadProps = {
  canShowTour?: boolean;
  item: StructureItem;
  title: string;
  open: boolean;
  disabled: boolean;
  onToggle: () => void;
  onBlur: () => void;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onAdd?: () => void;
  onRemove?: () => void;
  onGenerate?: (text: string) => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
};

const CourseStructureCollapseHead: React.FC<CourseStructureCollapseHeadProps> = ({
  canShowTour,
  item,
  title,
  open,
  disabled,
  onToggle,
  onBlur,
  onChange,
  onAdd,
  onRemove,
  onGenerate,
  onMoveUp,
  onMoveDown,
}) => {
  const layout = useAppSelector((s) => s.app.layout);
  const hidden = layout === 'print' || layout === 'public';

  const onDropCallback = useCallback(() => {
    if (!open) {
      onToggle();
    }
  }, [onToggle, open]);

  const classNamesActions = cx('cs-collapse-head__actions', {
    'cs-collapse-head__actions--hidden': layout === 'print' || layout === 'public',
  });

  const classNamesMain = cx('cs-collapse-head', {
    'cs-collapse-head--disabled': disabled,
  });

  const hideActions = layout === 'print' || layout === 'public';

  return (
    <div className={classNamesMain}>
      <div className="cs-collapse-head__info">
        <CourseStructureIndexWithState isEdited={hidden ? false : item.is_edited} index={item.index} />
      </div>
      <div className="cs-collapse-head__content">
        <TextArea
          bordered={false}
          className="cs-collapse-head__title"
          autoSize={{ minRows: 1, maxRows: 20 }}
          disabled={hideActions || disabled}
          value={title}
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>
      <div className="cs-collapse-head__goals">
        {item.sub_module_outcomes.map((el, idx) => {
          return (
            <Badge
              key={idx}
              shape="rectangle"
              text={el.letter_index}
              color={el.outline_builder_project_module_outcome.color}
            />
          );
        })}
      </div>
      <div className={classNamesActions}>
        {!hideActions ? (
          <>
            <RegeneratePopup disabled={disabled} onSubmit={onGenerate}>
              <ButtonSparking
                className={cx('', {
                  'tour--structure-regen': canShowTour,
                })}
                disabled={disabled}
                size="small"
              />
            </RegeneratePopup>
            {item.learning_structure.level_type === 'module' ? (
              <Button
                className={cx('', {
                  'tour--structure-add-item': canShowTour,
                })}
                disabled={disabled}
                size="small"
                type="text"
                shape="circle"
                icon={<PlusOutlined style={{ fontSize: 14 }} />}
                onClick={onAdd}
              />
            ) : (
              <AddElementDropdown disabled={disabled} itemId={item.id} />
            )}

            <MoreActions
              className={cx('', {
                'tour--structure-more': canShowTour,
              })}
              disabled={disabled}
              itemId={item.id}
              levelType={item.learning_structure.level_type}
              onRemove={onRemove}
              onDropCallback={onDropCallback}
              onMoveUp={onMoveUp}
              onMoveDown={onMoveDown}
            />
          </>
        ) : null}

        <Button
          shape="circle"
          className="cs-collapse-toggle"
          type="text"
          size="small"
          icon={<CaretLeftOutlined style={{ fontSize: 16 }} rotate={open ? -90 : 0} />}
          disabled={disabled}
          onClick={onToggle}
        />
      </div>
    </div>
  );
};

export default memo(CourseStructureCollapseHead);
