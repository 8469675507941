import React from 'react';

import LearningOutcomeGroup from 'modules/learning-outcome-group';
import LearningOutcomeItem from 'modules/learning-outcome-item';
import IconMagic from 'shared/ui/icons/magic/IconMagic';
import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import BlockTitle from 'shared/ui/block-title/BlockTitle';
import AddLearningOutcome from 'modules/add-learning-outcome/add-learning-outcome-item';
import AddLearningSubOutcome from 'modules/add-learning-outcome/add-learning-sub-outcome-item/';
import SpinnerOverlay from 'shared/ui/spinner-overlay';
import { isEmpty } from 'lodash';
import { sortModuleOutcomesByOrder, sortSubModuleOutcomesByOrder } from 'shared/utils/sort';

import useModuleLearningOutcomes from './useModuleLearningOutcomes';

import './styles.scss';

const ModuleLearningOutcomes: React.FC = () => {
  const { t } = useTranslation();

  const {
    allDisabled,
    loading,
    projectGen,
    hasSubmodules,
    moduleOutcomes,
    selectedVersion,
    onGenerateSubModules,
    onGenerateStructureItems,
  } = useModuleLearningOutcomes();

  return (
    <div className="module-lo">
      <BlockTitle className="ml-10" text={t<string>('learningOutcomes.learningOutcomes')} />

      {!isEmpty(selectedVersion?.active_modules_outcomes || moduleOutcomes) ? (
        <div className="module-lo__wrapper">
          <>
            {sortModuleOutcomesByOrder(selectedVersion?.active_modules_outcomes || moduleOutcomes)?.map((m, index) => {
              return (
                <LearningOutcomeGroup key={m.id} color={m.color}>
                  <LearningOutcomeItem isParent canShowTour={index === 1} module={m} color={m.color} />
                  {sortSubModuleOutcomesByOrder(m?.active_sub_modules_outcomes)?.map((s) => {
                    return <LearningOutcomeItem key={s.id} module={s} color={m.color} />;
                  })}
                  <AddLearningSubOutcome
                    parentId={m.id}
                    letterIndex={m.letter_index}
                    color={m.color}
                    order={m?.active_sub_modules_outcomes?.length}
                  />
                </LearningOutcomeGroup>
              );
            })}
            {loading || projectGen ? null : <AddLearningOutcome isParent disabled={allDisabled} />}
          </>
        </div>
      ) : null}

      {!isEmpty(moduleOutcomes) ? (
        <>
          {loading || projectGen || hasSubmodules ? null : (
            <Button
              className="module-lo__submit"
              size="large"
              exSize="xl"
              type="primary"
              disabled={projectGen || allDisabled}
              onClick={onGenerateSubModules}
            >
              {t<string>('learningOutcomes.gen2n')}
              <IconMagic size={16} />
            </Button>
          )}
          {hasSubmodules && (!loading || !projectGen) ? (
            <Button
              className="module-lo__submit tour--learning-outcomes-gen-structure"
              size="large"
              exSize="xl"
              type="primary"
              disabled={projectGen || allDisabled}
              onClick={onGenerateStructureItems}
            >
              {t<string>('learningOutcomes.genStructure')}
              <IconMagic size={16} />
            </Button>
          ) : null}
        </>
      ) : null}

      <SpinnerOverlay loading={loading} />
    </div>
  );
};

export default ModuleLearningOutcomes;
