import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { dateFormat } from 'shared/utils';
import { OutlineProject } from 'shared/types/entities';

import '../styles.scss';
import useCourseTypes from '../../../graphql/queries/useCourseTypes';

type CourseCardProps = {
  project: OutlineProject;
};

const BaseContent: React.FC<CourseCardProps> = ({ project }) => {
  const { t, i18n } = useTranslation();
  const { courseTypes } = useCourseTypes();

  const currentCourseType = courseTypes?.find((el) => el.code === project.project_type_fk.course_key);

  return (
    <>
      <h4 className="course-card__title">{project.active_project_version?.generated_title || 'New course'}</h4>
      <div className="course-card__lang-list">
        {project.languages.map((el) => (
          <span key={el.id} className="course-card__lang-item">
            {el.name}
          </span>
        ))}
      </div>
      <div className="course-card-footer">
        <div className="course-card-footer__wrap">
          <span className="course-card-footer__text">{project.created_by.name}</span>
          <span className="course-card-footer__text">
            {t<string>('card.lastUpdate')} {dateFormat(project.updated_at, 'MMM Do', i18n.language)}
          </span>
        </div>
        <div className="course-card-footer__wrap course-card-footer__wrap-r">
          <span className="course-card-footer__text">{project?.course_type?.name || currentCourseType?.name}</span>
        </div>
      </div>
    </>
  );
};

export default memo(BaseContent);
