import React from 'react';

import AssignmentsHead from 'modules/assignments-head';
import AssignmentsSettings from 'modules/assignments-settings';
import QuizCard from 'modules/quiz-card';
import { isEmpty } from 'lodash';
import AddQuizCard from 'modules/add-quiz-card';
import { useCurrentAssignment } from 'entities/assignment/model';

const AssignmentQuizWidget = () => {
  const { loading, assignment } = useCurrentAssignment();
  return (
    <div className="assign-quiz-content__wrap">
      <div className="assignment-select__block">
        <AssignmentsHead />
        <AssignmentsSettings />
      </div>
      <div className="assignment-select__results">
        {assignment?.assignment?.content_items?.map((item, index) => {
          return <QuizCard canShowTour={index === 0} key={item.id} item={item} />;
        })}
        {!isEmpty(assignment?.assignment?.content_items) && !loading ? <AddQuizCard /> : null}
      </div>
    </div>
  );
};

export default AssignmentQuizWidget;
