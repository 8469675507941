import React, { memo } from 'react';

import IconMagic from 'shared/ui/icons/magic/IconMagic';
import Button, { ExButtonProps } from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { useOwner } from 'shared/hooks/useProjectOwner';

import { createVideoAction } from '../model/actions';

type Props = {
  disable?: boolean;
  isRegen?: boolean;
  structureItemId?: string | null;
  onChangeTab?: (key: string) => void;
  buttonProps?: ExButtonProps;
};

const GenerateVideoButton: React.FC<Props> = ({ buttonProps, isRegen, disable, structureItemId, onChangeTab }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const isVideoEnabled = useAppSelector((state) => state.app?.me?.account_data?.is_video_enabled);
  const video = useAppSelector(
    (state) => state.assignment?.assignment?.assignment?.video?.script?.generated_videos?.[0]
  );

  const { isOwner } = useOwner();
  const isVideoGenerating = video?.state === 'new' || video?.state === 'in_progress';

  const onClick = () => {
    if (structureItemId) {
      onChangeTab?.('video');
      if (isRegen) {
        void dispatch(createVideoAction(structureItemId));
      } else {
        if (isVideoEnabled && !video) {
          void dispatch(createVideoAction(structureItemId));
        }
      }
    }
  };

  return (
    <Button
      size="large"
      exSize="xl"
      type="primary"
      {...buttonProps}
      disabled={!isOwner || isVideoGenerating || disable || !!selectedVersion}
      onClick={onClick}
    >
      {isRegen ? t('assignments.video.regenVideo') : t('assignments.video.genVideo')}
      <IconMagic size={16} />
    </Button>
  );
};

export default memo(GenerateVideoButton);
