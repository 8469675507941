import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Tabs, TabsProps } from 'antd';
import { isEmpty } from 'lodash';
import { selectReading, selectReadingPoints } from 'entities/assignment/model/assignment.selectors';
import { AssignmentKeyPoint } from 'shared/types/entities';
import { useAppSelector } from 'shared/store/hooks';

import AssignmentVideoPoints from '../assignment-video/content/points';

import { ReadingSettings } from './content/settings/ReadingSettings';
import { ReadingContent } from './content/reading/ReadingContent';

import './styles.scss';

const AssignmentReadingWidget = () => {
  const [tabKey, setTabKey] = useState<string>('settings');
  const { t } = useTranslation();
  const readingPoints = useAppSelector(selectReadingPoints);
  const readingFile = useAppSelector(selectReading);

  const onChangeTab = (activeKey: string) => {
    setTabKey(activeKey);
  };

  const items: TabsProps['items'] = useMemo(() => {
    return [
      {
        key: 'settings',
        label: t('assignments.reading.tabs.settings'),
        children: <ReadingSettings onChangeTab={onChangeTab} />,
      },
      {
        key: 'points',
        label: t('assignments.reading.tabs.points'),
        children: <AssignmentVideoPoints type={AssignmentKeyPoint.reading} onChangeTab={onChangeTab} />,
        disabled: isEmpty(readingPoints),
      },
      {
        key: 'reading',
        label: t('assignments.reading.tabs.reading'),
        children: <ReadingContent />,
        disabled: isEmpty(readingFile?.file),
      },
    ];
  }, [readingFile?.file, readingPoints, t]);

  return <Tabs destroyInactiveTabPane activeKey={tabKey} items={items} onChange={onChangeTab} />;
};

export default AssignmentReadingWidget;
