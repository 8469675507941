import { useQuery } from '@apollo/client';
import { VideoAvatarPosition } from 'entities/studio/model/types';

import { STRUCTURE_ITEM_VIDEO_SETTINGS } from '../schemas/videoSettings';

type VideoSettingsData = {
  outline_builder_course_structure_item: {
    id: string;
    assignment: {
      id: string;
      video: {
        id: string;
        avatar_position_type: VideoAvatarPosition;
        avatar_voice: {
          id: string;
        };
        avatar_language: {
          id: string;
        };
      };
    };
  };
};

const useStructureItemVideoSettings = (structureItemId: string | null) => {
  const { data, loading, refetch } = useQuery<VideoSettingsData>(STRUCTURE_ITEM_VIDEO_SETTINGS, {
    variables: {
      id: structureItemId,
    },
    fetchPolicy: 'network-only',
  });

  return {
    data: data?.outline_builder_course_structure_item.assignment.video,
    loading,
    refetch,
  };
};

export default useStructureItemVideoSettings;
