import React from 'react';

import Button from 'shared/ui/button';
import { Dropdown, MenuProps } from 'antd';
import { DeleteOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import IconMagic from 'shared/ui/icons/magic';
import { cx } from 'shared/utils';
import { useTranslation } from 'react-i18next';

import './styles.scss';

type DropdownProps = {
  text: string;
  type: 'gen' | 'add' | 'del';
  hidden?: boolean;
  action?: () => void;
};

const getIcon = (type: 'gen' | 'add' | 'del') => {
  switch (type) {
    case 'gen':
      return <IconMagic size={12} />;
    case 'add':
      return <PlusOutlined style={{ fontSize: 12 }} />;
    case 'del':
      return <DeleteOutlined style={{ fontSize: 12 }} />;
    default:
      return <DeleteOutlined style={{ fontSize: 12 }} />;
  }
};

const DropdownLabel = ({ text, type, hidden, action }: DropdownProps) => {
  if (hidden) {
    return null;
  }
  const classNames = cx('more-actions-label', {
    [`more-actions-label--${type}`]: type,
  });
  return (
    <div className={classNames} onClick={action}>
      <div className="more-actions-label__icon">{getIcon(type)}</div>
      <span className="more-actions-label__text">{text}</span>
    </div>
  );
};

type Props = {
  disabled?: boolean;
  isParent?: boolean;
  onAdd?: () => void;
  onRemove?: () => void;
};

const MoreActions: React.FC<Props> = ({ disabled, isParent, onAdd, onRemove }) => {
  const { t } = useTranslation();
  const items: MenuProps['items'] = [
    // {
    //   key: 'generation',
    //   label: (
    //     <DropdownLabel text={t<string>('learningOutcomes.moreActions.regenerate')} type="gen" action={onGenerate} />
    //   ),
    // },
    {
      key: 'add',
      label: (
        <DropdownLabel
          hidden={!isParent}
          text={t<string>('learningOutcomes.moreActions.addOutcome')}
          type="add"
          action={onAdd}
        />
      ),
    },
    {
      key: 'del',
      label: <DropdownLabel text={t<string>('learningOutcomes.moreActions.delete')} type="del" action={onRemove} />,
    },
  ];

  return (
    <Dropdown
      disabled={disabled}
      overlayClassName="more-action-drop"
      placement="bottomRight"
      trigger={['click']}
      menu={{ items }}
    >
      <Button
        disabled={disabled}
        size="small"
        shape="circle"
        type="text"
        icon={<EllipsisOutlined style={{ fontSize: 16 }} />}
      />
    </Dropdown>
  );
};

export default MoreActions;
