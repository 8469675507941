import React, { ChangeEvent, memo, useCallback } from 'react';

import { Checkbox } from 'antd';
import Input from 'shared/ui/input';
import { QuizAnswer } from 'shared/types/entities';
import { alphabet } from 'shared/utils';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import './styles.scss';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';

type Props = {
  isPrint?: boolean;
  disabled: boolean;
  isShowEmpty?: boolean;
  item: QuizAnswer;
  onChangeText: (id: string, text: string) => void;
  onChangeCheckbox: (id: string, value: boolean) => void;
  onBlur: () => void;
};

const QuizCardItem: React.FC<Props> = ({
  isPrint,
  disabled,
  isShowEmpty,
  item,
  onChangeText,
  onChangeCheckbox,
  onBlur,
}) => {
  const { t } = useTranslation();
  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeText(item?.id || '', event.target.value);
    },
    [item.id, onChangeText]
  );

  const handleChangeCheckbox = useCallback(
    (event: CheckboxChangeEvent) => {
      onChangeCheckbox(item?.id || '', event.target.checked);
    },
    [item.id, onChangeCheckbox]
  );

  return (
    <div className="quiz-card-item">
      <span className="quiz-card-item__num">{alphabet[item.order - 1]}</span>
      <Input
        autoFocus={isShowEmpty}
        className="quiz-card-item__controls"
        size="small"
        bordered={false}
        placeholder={t<string>('quizCard.item.placeholder')}
        value={item.content}
        disabled={disabled}
        onChange={handleChangeInput}
        onBlur={onBlur}
      />
      {isPrint ? (
        <div>{item.is_answer ? <CheckOutlined style={{ fontSize: 14 }} /> : null}</div>
      ) : (
        <Checkbox
          className="quiz-card-item__checkbox"
          disabled={disabled}
          checked={item.is_answer}
          onChange={handleChangeCheckbox}
        />
      )}
    </div>
  );
};

export default memo(QuizCardItem);
