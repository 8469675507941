import React, { ChangeEvent, useCallback, useState } from 'react';

import { useBoolean } from 'usehooks-ts';
import Button from 'shared/ui/button';
import TextArea from 'shared/ui/textArea';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import { useAppSelector } from '../../../../shared/store/hooks';

type RegenerateEntityProps = {
  title?: string;
  buttonText?: string;
  disabled?: boolean;
  action?: (text: string) => void;
};

const RegenerateEntityBlock = ({ title, buttonText, disabled, action }: RegenerateEntityProps) => {
  const [text, setText] = useState('');
  const { value: open, setTrue, setFalse } = useBoolean();
  const { t } = useTranslation();
  const layout = useAppSelector((s) => s.app.layout);
  const hide = layout === 'print' || !layout;

  const onClick = () => {
    action?.(text);
    setFalse();
    setText('');
  };

  const handleChangeText = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  }, []);

  if (hide) {
    return null;
  }

  return (
    <div className="regen-entity">
      <span className="regen-entity__title">{title}</span>

      {!open ? (
        <Button className="regen-entity__add-custom" type="link" disabled={disabled} onClick={setTrue}>
          {t('regenEntityBlock.addButton')}
        </Button>
      ) : null}

      {open ? (
        <div className="regen-entity__content">
          <span className="regen-entity__content-label">{t('regenEntityBlock.label')}</span>
          <TextArea autoSize={{ minRows: 3, maxRows: 10 }} value={text} onChange={handleChangeText} />
        </div>
      ) : null}

      <Button size="large" type="primary" disabled={disabled || !text} onClick={onClick}>
        {buttonText}
      </Button>
    </div>
  );
};

export default RegenerateEntityBlock;
