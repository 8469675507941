import { OutputData } from '@editorjs/editorjs/types/data-formats/output-data';
import { AppDispatch, store } from 'shared/store/store';
import { onUpdateDocument } from 'shared/api/requests/file';
import { messageError, messageSave } from 'shared/utils';
import { message } from 'antd';
import { getAssignmentItemAction } from 'entities/assignment/model';

export const updateFileDocument = (fileId: string, payload: OutputData) => {
  return async (dispatch: AppDispatch) => {
    messageSave();
    const assign = store.getState().assignment.assignment;
    await onUpdateDocument(fileId, payload)
      .then(() => {
        if (assign?.id) {
          dispatch(getAssignmentItemAction(assign.id, true));
          message.destroy();
        }
      })
      .catch(() => {
        messageError();
      });
  };
};
