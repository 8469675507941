import api, { ApiModules } from 'shared/api/index';
import { QuizContentItem } from 'shared/types/entities';

import { ENDPOINTS } from '../../constants';
import { GenerateResponse } from '../../types';

export const putOutlineBuilderAssignmentQuizRequest = (id: string, payload: QuizContentItem) =>
  api(ApiModules.OUTLINE_BUILDER).put(ENDPOINTS.ASSIGNMENT_QUIZ(id), payload);

export const deleteOutlineBuilderAssignmentQuizRequest = (id: string) =>
  api(ApiModules.OUTLINE_BUILDER).delete(ENDPOINTS.ASSIGNMENT_QUIZ(id));

export const postOutlineBuilderAssignmentGenAnswersRequest = (id: string): Promise<GenerateResponse> =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.ASSIGNMENT_QUIZ_GEN_ANSWERS(id));
