import React, { memo } from 'react';

import CourseCreateExample from 'shared/components/course-create-example';
import { useAppSelector } from 'shared/store/hooks';

import UploadView from './upload/Upload';
import FileView from './file';

import './styles.scss';

const FILE_SIZE_MAX = 10;

const CreateCourseUpload: React.FC = () => {
  const project = useAppSelector((state) => state.project.project);
  const error = useAppSelector((state) => state.createCourse.error);
  return (
    <div className="create-course-upload">
      <div className="create-course-upload__row">
        <div className="create-course-upload__col">
          <span className="create-course-upload__text mb-2">
            Please provide one course syllabus (not the whole online degree program)
          </span>
          {project?.active_project_version?.curriculum ? <FileView /> : <UploadView />}
        </div>
        <CourseCreateExample />
      </div>
    </div>
  );
};

export default memo(CreateCourseUpload);
