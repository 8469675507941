import React, { memo } from 'react';

import './styles.scss';

type Props = {
  isEdited?: boolean;
  index: string;
};

const CourseStructureIndexWithState: React.FC<Props> = ({ isEdited, index }) => {
  return (
    <div className="cs-collapse-index">
      <div className="cs-collapse-index__state-wrap">
        {isEdited ? <div className="cs-collapse-index__state"></div> : null}
      </div>
      <div className="cs-collapse-index__num-wrap">
        <span className="cs-collapse-index__num">{index}</span>
      </div>
    </div>
  );
};

export default memo(CourseStructureIndexWithState);
