import React, { ChangeEvent, useEffect, useState } from 'react';

import CurriculumFormBlock from 'shared/components/curriculum-form-block';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import './styles.scss';
import { updateProjectAboutContentAction } from '../about-page-form/store/actions';

const sample = {
  generated_title:
    'Course on International Business for undergraduate and graduate students to become more competitive in the international business arena.',
  generated_description:
    'The course is designed for undergraduate and graduate students who are interested in pursuing a career in the global business world. This course is also suitable for professionals who are currently working in international business and wish to expand their knowledge and skills.',
  generated_course_participants:
    'Undergraduate and graduate students to become more competitive in the international business arena',
  generated_prerequisites:
    'The common goal among the participants is to gain a deeper understanding of the challenges and opportunities of conducting business across different countries and cultures. They aim to develop their knowledge and skills in areas such as global marketing strategies, foreign investment, cross-cultural communication, and legal and ethical issues in international business.',
};

const init = {
  generated_title: sample.generated_title,
  generated_description: sample.generated_description,
  generated_course_participants: sample.generated_course_participants,
  generated_prerequisites: sample.generated_course_participants,
};

const CurriculumForm: React.FC = () => {
  const [fields, setFields] = useState(init);
  const dispatch = useAppDispatch();
  const createStore = useAppSelector((state) => state.createCourse);
  const project = useAppSelector((state) => state.project.project);
  const projectVer = useAppSelector((state) => state.project.project?.active_project_version);

  useEffect(() => {
    if (projectVer?.curriculum) {
      setFields({
        generated_title: projectVer.generated_title,
        generated_description: projectVer.generated_description,
        generated_course_participants: projectVer.generated_course_participants,
        generated_prerequisites: projectVer.generated_prerequisites,
      });
    }
  }, [projectVer]);

  if (!projectVer) return null;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    mixpanel.track('ProjectRecognizedField', { ProjectId: `${project?.id}`, FileId: `${name}` });
    setFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onBlur = () => {
    const payload = {
      ...fields,
    };
    if (projectVer.id) {
      void dispatch(updateProjectAboutContentAction(projectVer.id, payload));
    }
  };

  return (
    <div className="curriculum-form">
      <CurriculumFormBlock
        sampleText={sample.generated_title}
        name="generated_title"
        title="Course title"
        label="Instruction"
        value={fields.generated_title}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={createStore.generating}
      />
      <CurriculumFormBlock
        sampleText={sample.generated_description}
        name="generated_description"
        title="Description"
        label="Instruction"
        value={fields.generated_description}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={createStore.generating}
      />
      <CurriculumFormBlock
        sampleText={sample.generated_course_participants}
        name="generated_course_participants"
        title="Participants and their goals"
        label="Instruction"
        value={fields.generated_course_participants}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={createStore.generating}
      />
      <CurriculumFormBlock
        sampleText={sample.generated_prerequisites}
        name="generated_prerequisites"
        title="Prerequisites"
        label="What students know and don't know"
        value={fields.generated_prerequisites}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={createStore.generating}
      />
    </div>
  );
};

export default CurriculumForm;
