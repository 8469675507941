import React, { memo, SyntheticEvent, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import IconSearch from 'shared/ui/icons/search';

import pdf from './curriculum-example.pdf';
import example from './curriculum-example.png';

import './styles.scss';

const CourseCreateExample: React.FC = () => {
  const { id } = useParams();
  const onLinkClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      mixpanel.track('ProjectViewSample', { ProjectId: id ? `${id}` : 'new' });
      window.open(pdf, '_blank');
    },
    [id]
  );

  return (
    <div className="course-create-example">
      <span className="course-create-example__label">Sample</span>
      <div className="course-create-example__wrap">
        <img src={example} alt="example" />
        <a href={pdf} rel="noreferrer" target="_blank" className="course-create-example__link" onClick={onLinkClick}>
          <IconSearch size={17} />
        </a>
      </div>
    </div>
  );
};

export default memo(CourseCreateExample);
