import React, { SyntheticEvent } from 'react';

import { useAppSelector } from 'shared/store/hooks';
import Button from 'shared/ui/button/Button';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const InviteButton = () => {
  const { t } = useTranslation();
  const affiliateName = useAppSelector((state) => state.app?.me?.affiliate_username);

  const path = `${window.location.origin}/signup?promo=${affiliateName}`;

  const onClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (affiliateName) {
      navigator.clipboard.writeText(path).then(() => {
        message.info(t('affiliate.inviteButton.message'));
      });
    }
  };

  return (
    <div className="user-invite">
      <span className="user-invite__title">{t('affiliate.inviteButton.label')}</span>
      <div className="user-invite__wrap">
        <div className="user-invite__str-wrap">
          <span className="user-invite__str">{path}</span>
        </div>

        <Button size="large" type="primary" onClick={onClick}>
          {t('affiliate.inviteButton.button')}
        </Button>
      </div>
    </div>
  );
};

export default InviteButton;
