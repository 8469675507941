import React, { ChangeEvent, ReactNode, useCallback, useState } from 'react';

import { Popover } from 'antd';

import RegeneratePopupContent from './RegeneratePopupContent';

import './styles.scss';

type Props = {
  disabled?: boolean;
  children: ReactNode;
  onSubmit?: (text: string) => void;
};

const RegeneratePopup: React.FC<Props> = ({ disabled, children, onSubmit }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState<string>('');

  const handleOpenChange = useCallback((newOpen: boolean) => {
    setOpen(newOpen);
    if (!newOpen) {
      setText('');
    }
  }, []);

  const handleChangeText = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  }, []);

  const onRegenerate = () => {
    onSubmit?.(text);
    setOpen(false);
  };

  return (
    <Popover
      overlayClassName="regen-popup"
      trigger={!disabled ? 'click' : undefined}
      placement="left"
      destroyTooltipOnHide={true}
      getPopupContainer={() => {
        // TODO need refactoring
        const target = document.querySelector('.edit-card__actions') as HTMLElement;
        if (target) {
          return target;
        } else {
          return document.body;
        }
      }}
      open={open}
      onOpenChange={handleOpenChange}
      content={<RegeneratePopupContent text={text} onChange={handleChangeText} onSubmit={onRegenerate} />}
    >
      {children}
    </Popover>
  );
};

export default RegeneratePopup;
