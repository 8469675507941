import { message } from 'antd';
import { AppDispatch, store } from 'shared/store/store';
import { checkAsyncState, messageError, messageGeneration, messageRejectedGeneration } from 'shared/utils';
import { setProjectGeneration } from 'modules/project';
import {
  GenReadingKeyPointsPayload,
  postStructureItemGenerateReading,
  postStructureItemGenerateReadingKeyPoints,
} from 'shared/api/requests/outline-builder-project-structure';
import { getAssignmentItemAction } from 'entities/assignment/model';

const intermediateRequest = () => {
  const assign = store.getState().assignment.assignment;

  if (assign?.id) {
    void store.dispatch(getAssignmentItemAction(assign.id, true));
  }
};

const finishRequest = () => {
  const assign = store.getState().assignment.assignment;
  if (assign?.id) {
    void store.dispatch(getAssignmentItemAction(assign.id, true));
  }
  void store.dispatch(setProjectGeneration(false));
  message.destroy();
};

const rejectedRequest = () => {
  void store.dispatch(setProjectGeneration(false));
  message.destroy();
  messageRejectedGeneration();
};

export const generateReadingKeyPointsAction = (structureItemId: string, payload: GenReadingKeyPointsPayload) => {
  return async (dispatch: AppDispatch) => {
    messageGeneration();
    dispatch(setProjectGeneration(true));

    await postStructureItemGenerateReadingKeyPoints(structureItemId, payload)
      .then((response) => {
        checkAsyncState(response.request_id, finishRequest, rejectedRequest, intermediateRequest);
      })
      .catch((err) => {
        dispatch(setProjectGeneration(false));
        message.destroy();
        messageError(err);
      });
  };
};

export const generateReadingAction = (structureItemId: string) => {
  return async (dispatch: AppDispatch) => {
    messageGeneration();
    dispatch(setProjectGeneration(true));

    await postStructureItemGenerateReading(structureItemId)
      .then((response) => {
        checkAsyncState(response.request_id, finishRequest, rejectedRequest, intermediateRequest);
      })
      .catch((err) => {
        dispatch(setProjectGeneration(false));
        message.destroy();
        messageError(err);
      });
  };
};
