import { AssignmentsItemData } from 'shared/types/entities';

import { client } from '../index';
import { OUTLINE_BUILDER_PROJECT_ASSIGNMENTS } from '../schemas/outlineBuilderAssignments';

export const getProjectAssignmentsRequest = (projectId: string, language: string, projectVersionId?: string) => {
  return client.query<AssignmentsItemData>({
    query: OUTLINE_BUILDER_PROJECT_ASSIGNMENTS,
    fetchPolicy: 'network-only',
    variables: {
      id: projectId,
      language,
      projectVersionId,
    },
  });
};
