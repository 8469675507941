import React, { memo, useCallback } from 'react';

import { setDuration, setMassiveCourse } from 'widgets/create-course/store';
import CourseSettingView from 'shared/components/course-settings-view';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import './styles.scss';

const CreateCourseSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const duration = useAppSelector((state) => state.createCourse.duration);
  const mmoc = useAppSelector((state) => state.createCourse.massive_course);

  const handleSwitch = useCallback(
    (checked: boolean) => {
      dispatch(setMassiveCourse(checked));
    },
    [dispatch]
  );

  const handleDuration = useCallback(
    (value: number) => {
      dispatch(setDuration(value));
    },
    [dispatch]
  );

  return (
    <CourseSettingView
      massive_course={mmoc}
      duration={duration}
      onChangeMassive={handleSwitch}
      onChangeDuration={handleDuration}
    />
  );
};

export default memo(CreateCourseSettings);
