import React, { memo, ReactNode } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button';

import './styles.scss';

type HeaderSmallProps = {
  title?: string;
  goBack?: () => void;
  actions?: ReactNode;
};

const HeaderSmall: React.FC<HeaderSmallProps> = ({ title, actions, goBack }: HeaderSmallProps) => {
  return (
    <header className="header-small">
      <div className="header-small__inner">
        {goBack ? (
          <Button
            className="header-small__back"
            shape="circle"
            type="primary"
            icon={<ArrowLeftOutlined style={{ fontSize: 16 }} />}
            onClick={goBack}
          />
        ) : null}
        <h4 className="header-small__title">{title}</h4>
        {actions ? <div className="header-small__actions">{actions}</div> : null}
      </div>
    </header>
  );
};

export default memo(HeaderSmall);
