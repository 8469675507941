import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';
import { generationModuleOutcomesAction } from 'widgets/module-learning-outcomes/store/actions';
import mixpanel from 'mixpanel-browser';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { useOwner } from 'shared/hooks/useProjectOwner';

import { getProjectAction } from '../project/actions';
import { runTutor, setStepIndex } from '../tutorial/store';

import { regenerateProjectAboutContentAction, updateProjectAboutContentAction } from './store/actions';

const initState = {
  generated_title: '',
  generated_description: '',
};

const useAboutForm = () => {
  const [form, setForm] = useState(initState);
  const { id, lang } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const storeProject = useAppSelector((state) => state.project);
  const projectVerId = useAppSelector((state) => state.project?.project?.active_project_version?.id);
  const isTourActive = useAppSelector((state) => state.tutorial.tourActive);

  //Run Tour
  useEffect(() => {
    if (!storeProject.loading && storeProject?.project?.id && isTourActive) {
      dispatch(runTutor());
      dispatch(setStepIndex(4));
    }
  }, [dispatch, isTourActive, storeProject.loading, storeProject?.project?.id]);

  //Init form values
  useEffect(() => {
    if (storeProject.selectedVersion) {
      setForm({
        generated_title: storeProject?.selectedVersion?.generated_title || '',
        generated_description: storeProject?.selectedVersion?.generated_description || '',
      });
    } else {
      setForm({
        generated_title: storeProject.project?.active_project_version?.generated_title || '',
        generated_description: storeProject.project?.active_project_version?.generated_description || '',
      });
    }
  }, [storeProject.project, storeProject.selectedVersion]);

  const handleChangeTextField = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      mixpanel.track(`Project Fill Form (${name})`, { ProjectId: id, Page: 'About' });
      setForm((prevState) => ({ ...prevState, [name]: value }));
    },
    [id]
  );

  const onBlur = useCallback(async () => {
    if (projectVerId) {
      await dispatch(updateProjectAboutContentAction(projectVerId, form));
      if (id) {
        await dispatch(getProjectAction(id, true, undefined, lang));
      }
    }
  }, [dispatch, form, id, lang, projectVerId]);

  const onSubmit = useCallback(() => {
    if (id && projectVerId && lang) {
      mixpanel.track('Project Generate Learning Outcome', { ProjectId: id, Page: 'About' });
      const path = generatePath(PATH_NAME.courseManualWithEntity, {
        id: id,
        lang,
        entity: PATH_NAME.courseEntity.learning,
      });
      navigate(path);
      void dispatch(generationModuleOutcomesAction(projectVerId));
    }
  }, [dispatch, id, lang, navigate, projectVerId]);

  const onRegenerateTitle = useCallback(
    (text: string) => {
      if (id && projectVerId) {
        const payload = {
          for_regeneration_title: text,
        };
        mixpanel.track('Project Regenerate Title Field', { ProjectId: id, Page: 'About' });
        void dispatch(regenerateProjectAboutContentAction('title', payload));
      }
    },
    [dispatch, id, projectVerId]
  );

  const onRegenerateDescription = useCallback(
    (text: string) => {
      if (id && projectVerId) {
        const payload = {
          for_regeneration_description: text,
        };
        mixpanel.track('Project Regenerate Description Field', { ProjectId: id, Page: 'About' });
        void dispatch(regenerateProjectAboutContentAction('description', payload));
      }
    },
    [dispatch, id, projectVerId]
  );

  const onGoToSettings = useCallback(() => {
    const pathToSettings = generatePath(PATH_NAME.courseManualWithEntity, {
      id,
      lang,
      entity: PATH_NAME.courseEntity.settings,
    });
    mixpanel.track('Project Back To Settings', { ProjectId: id, Page: 'About' });
    navigate(pathToSettings);
  }, [id, lang, navigate]);

  const { isOwner } = useOwner();

  return {
    form,
    disabled: !isOwner || storeProject.loading || storeProject.generation || Boolean(storeProject.selectedVersion),
    loading: storeProject.loading,
    handleChangeTextField,
    onBlur,
    onSubmit,
    onRegenerateTitle,
    onRegenerateDescription,
    onGoToSettings,
  };
};

export default useAboutForm;
