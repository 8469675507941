import React, { useEffect } from 'react';

import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';
import mixpanel from 'mixpanel-browser';
import { usePostHog } from 'posthog-js/react';
import { useAppSelector } from 'shared/store/hooks';

import modalService from '../../shared/services/modalService';
import { localStorageService } from '../../shared/services/localStorageService';
import { useCustomNavigator } from '../../shared/hooks/useCustomNavigator';

const ProtectedLayout: React.FC = () => {
  const [searchParams] = useSearchParams();
  const app = useAppSelector((state) => state.app);
  const refreshToken = searchParams.get('refresh_token');
  const path = refreshToken ? `${PATH_NAME.login}?log=${refreshToken}` : PATH_NAME.login;
  const posthog = usePostHog();
  const { navigate } = useCustomNavigator();

  const location = useLocation();
  const navPath = localStorageService.getNavigate();

  if (location.pathname === '/billing' && !app.auth) {
    localStorageService.setNavigate('billing');
  }

  useEffect(() => {
    if (app.me && !app.me?.user_details) {
      modalService.openUserDetails({});
    }
  }, [app.me, app.me?.user_details]);

  useEffect(() => {
    if (app.me) {
      mixpanel.identify(`${app.me.id}`);
      mixpanel.people.set_once({
        name: app.me.name,
        email: app.me.email,
        signupPromoCode: app.me.signup_promo_code,
      });
      posthog?.identify(app.me.id, {
        email: app.me.email,
      });
    }
  }, [app.me, posthog]);

  useEffect(() => {
    if (app.auth && navPath === 'billing') {
      navigate(PATH_NAME.billing);
    }
  }, [app.auth, navPath, navigate]);

  return <>{app.auth ? <Outlet /> : <Navigate to={path} />}</>;
};

export default ProtectedLayout;
