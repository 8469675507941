import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssignmentItem } from 'shared/types/entities';

export interface AssignmentsState {
  loading: boolean;
  assignments: AssignmentItem[] | null;
}

const initialState: AssignmentsState = {
  loading: false,
  assignments: null,
};

export const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState,
  reducers: {
    setAssignmentsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAssignments: (state, action: PayloadAction<AssignmentItem[] | null>) => {
      state.assignments = action.payload;
    },
  },
});

export const { setAssignmentsLoading, setAssignments } = assignmentsSlice.actions;
export default assignmentsSlice.reducer;
