import React, { memo, useCallback } from 'react';

import { CloseCircleFilled } from '@ant-design/icons';
import Button from 'shared/ui/button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PATH_NAME } from '../constants';

import './styles.scss';

const ErrorPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickReturn = useCallback(() => {
    navigate(PATH_NAME.main);
    window.location.reload();
  }, [navigate]);

  return (
    <div className="error-page">
      <div className="error-page__icon">
        <CloseCircleFilled style={{ color: '#D73C1D', fontSize: '70px' }} />
      </div>
      <span className="error-page__title">{t('noAccess.title')}</span>
      {/*<span className="error-page__text">{t('noAccess.description')}</span>*/}
      <Button size="large" onClick={onClickReturn} className="mr_8">
        {t<string>(`common.goMainPage`)}
      </Button>
    </div>
  );
};

export default memo(ErrorPage);
