import React, { memo } from 'react';

import { CBIconProps } from '../types';

import '../styles.scss';

const IconCourseFactory: React.FC<CBIconProps> = ({ size }: CBIconProps) => {
  const styles = {
    width: size || 24,
    height: size || 24,
  };
  return (
    <span style={styles} className="cb-icon">
      <svg style={styles} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_627_12411)">
          <path d="M13.5 1H7V13H0.5V7H7H13.5" stroke="currentColor" />
        </g>
        <defs>
          <clipPath id="clip0_627_12411">
            <rect width="14" height="14" fill="currentColor" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default memo(IconCourseFactory);
