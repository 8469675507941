import React from 'react';

import { message, Progress } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import { checkMimeType, cx } from 'shared/utils';
import { createCurriculumAction } from 'widgets/create-course/store/actions';
import { CreateCourseError, setError } from 'widgets/create-course/store';

import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { uploadFileAction } from '../store/actions';

const UploadView: React.FC = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.createCourse.error);
  const upload = useAppSelector((state) => state.createCourseUpload);
  const customRequest = () => null;

  const onBeforeUpload = async (file: RcFile) => {
    const { isPdf } = checkMimeType(file);
    const fSize = file?.size || 0;
    dispatch(setError(null));

    if (!isPdf) {
      void message.error('Allowed file format: PDF', 5);
    } else {
      await dispatch(uploadFileAction(file));
      await dispatch(createCurriculumAction());
    }
  };

  const percent = upload.progress || undefined;

  const className = cx('cb-drag', {
    [`cb-drag--error`]: error === CreateCourseError.file,
  });

  return (
    <Dragger className={className} showUploadList={false} customRequest={customRequest} beforeUpload={onBeforeUpload}>
      <div className="create-course-upload__drag-content">
        {upload.uploading ? (
          <>
            <div className="create-course-upload__progress">
              <Progress percent={percent} showInfo={false} strokeColor="#1E1E1E" trailColor="#E9E9E9" />
            </div>
            <span className="create-course-upload__drag-content-label">Uploading</span>
          </>
        ) : (
          <>
            <PlusOutlined style={{ fontSize: 24 }} />
            <span className="create-course-upload__drag-content-label">Upload one course curriculum</span>
          </>
        )}
      </div>
    </Dragger>
  );
};

export default UploadView;
