import { FC, useCallback, useEffect, useState } from 'react';

import { cx } from 'shared/utils';
import { useAppSelector } from 'shared/store/hooks';
import { VideoAvatarPosition } from 'entities/studio/model/types';
import { useSearchParams } from 'react-router-dom';
import { selectProjectDisabled } from 'modules/project/project.selectors';
import { useOwner } from 'shared/hooks/useProjectOwner';
import { useTranslation } from 'react-i18next';
import useStructureItemVideoSettings from 'shared/graphql/queries/useStructureItemVideoSettings';

import { putVideoAvatarPositionRequest } from '../../api';

import { buttonsMap } from './constants';

import './styles.scss';

type Props = {
  item: {
    key: VideoAvatarPosition;
    icon: string;
  };
  disabled: boolean;
  isSelected: boolean;
  onChange: (val: VideoAvatarPosition) => void;
};

const AvatarPositionItem: FC<Props> = ({ item, isSelected, disabled, onChange }) => {
  const classNames = cx('avatar-position__button', {
    'avatar-position__button--active': isSelected,
    'avatar-position__button--disabled': disabled,
  });

  const onClick = () => onChange(item.key);

  return (
    <div role="button" className={classNames} onClick={onClick}>
      <img src={item.icon} alt="avatarOnly" />
    </div>
  );
};

export const AvatarPosition: FC = () => {
  const [searchParams] = useSearchParams();
  const [position, setPosition] = useState(VideoAvatarPosition.AVATAR_LEFT_W_SLIDE);

  const { t } = useTranslation();
  const projectDisabled = useAppSelector(selectProjectDisabled);
  const { isOwner } = useOwner();
  const disabled = !isOwner || projectDisabled;
  const strItemId = searchParams.get('str');

  const { data: settings, refetch } = useStructureItemVideoSettings(strItemId);

  const onChangePosition = useCallback(async () => {
    if (disabled) {
      return;
    }
    if (strItemId) {
      const payload = {
        avatar_position_type: position,
      };
      await putVideoAvatarPositionRequest(strItemId, payload);
      await refetch();
    }
  }, [position]);

  useEffect(() => {
    if (settings?.avatar_position_type) {
      setPosition(settings?.avatar_position_type);
    }
  }, [settings?.avatar_position_type]);

  useEffect(() => {
    if (settings?.avatar_position_type && settings?.avatar_position_type !== position) {
      void onChangePosition();
    }
  }, [onChangePosition, position, settings?.avatar_position_type]);

  return (
    <div className="avatar-position">
      <span className="avatar-position__title">{t('assignments.video.studio.positionTitle')}</span>
      <div className="avatar-position__actions">
        {buttonsMap.map((el) => (
          <AvatarPositionItem
            isSelected={position === el.key}
            disabled={disabled}
            onChange={setPosition}
            key={el.key}
            item={el}
          />
        ))}
      </div>
    </div>
  );
};
