import { useEffect } from 'react';
import type { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Empty } from 'antd/es';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { Editor } from 'features/editor';
import { useCurrentAssignment } from 'entities/assignment/model';

import {
  clearStudio,
  documentFileSections,
  getStudioFileAction,
  getStudioLinkedSlidesAction,
  selectScriptId,
} from '../../model';

import './styles.scss';

export const StudioContent: FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { assignment } = useCurrentAssignment();
  const scriptId = useAppSelector(selectScriptId);
  const selectedId = searchParams.get('str');

  const sections = useAppSelector(documentFileSections);

  useEffect(() => {
    if (selectedId) {
      void dispatch(clearStudio());
      void dispatch(getStudioFileAction(selectedId));
    }
  }, [assignment?.id, dispatch, selectedId]);

  useEffect(() => {
    if (scriptId) {
      void dispatch(getStudioLinkedSlidesAction());
    }
  }, [dispatch, scriptId]);

  if (!sections) {
    return (
      <div className="studio-content studio-content__empty-block">
        <Empty
          description={
            <span className="studio-content__empty-block-text">{t('assignments.video.studio.emptyScript')}</span>
          }
        />
      </div>
    );
  }

  return (
    <div className="studio-content">
      {sections?.map((section) => {
        const last = sections.at(-1);
        return (
          <div key={section.id} className="studio-content__row">
            <Editor.Paragraph item={section} isLast={last?.id === section.id} />
            <Editor.Slide sectionId={section.id} content={section.section_content} />
          </div>
        );
      })}
      <Editor.AddParagraph />
    </div>
  );
};
