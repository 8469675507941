import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StructureItem } from 'shared/types/entities';

interface StructureState {
  structure: StructureItem[] | [];
  loading: boolean;
}

const initialState: StructureState = {
  structure: [],
  loading: false,
};

export const structureSlice = createSlice({
  name: 'structure',
  initialState,
  reducers: {
    setStructureLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setStructure: (state, action: PayloadAction<StructureItem[]>) => {
      state.structure = action.payload;
    },
  },
});

export const { setStructureLoading, setStructure } = structureSlice.actions;
export default structureSlice.reducer;
