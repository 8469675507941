import React, { memo } from 'react';

import IconMagic from 'shared/ui/icons/magic/IconMagic';
import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'shared/store/hooks';

import { generateVideoPointsAction } from '../model/actions';

type Props = {
  disable?: boolean;
  structureItemId?: string;
  duration: number;
  onChangeTab: (key: string) => void;
};

const GeneratePointsButton: React.FC<Props> = ({ disable, structureItemId, duration, onChangeTab }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClick = () => {
    const payload = {
      video_length_s: duration,
    };
    if (structureItemId) {
      void dispatch(generateVideoPointsAction(structureItemId, payload));
      onChangeTab('points');
    }
  };

  return (
    <Button size="large" exSize="xl" type="primary" disabled={disable} onClick={onClick}>
      {t('assignments.video.genPoints')}
      <IconMagic size={16} />
    </Button>
  );
};

export default memo(GeneratePointsButton);
