import { gql } from '@apollo/client';

import { DOCUMENT_FILE_TYPE } from './fragments';

export const STRUCTURE_ITEM_STUDIO_FILE = gql`
  query StructureItemStudioFile($id: Int) {
    outline_builder_course_structure_item(id: $id) {
      id
      assignment {
        id
        video {
          id
          script {
            id
            file {
              ...DocumentFileType
            }
          }
        }
      }
    }
  }
  ${DOCUMENT_FILE_TYPE}
`;

export const STRUCTURE_ITEM_STUDIO_PRESENTATION_FILE = gql`
  query StructureItemStudioFile($id: Int) {
    outline_builder_course_structure_item(id: $id) {
      id
      assignment {
        id
        video {
          id
          script {
            id
            presentation_file {
              id
              state
              related_files {
                ... on PresentationFileSlideType {
                  id
                  is_active
                  source_url
                  file {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
