import { gql } from '@apollo/client';

export const PROJECT_VERSIONS = gql`
  query ProjectVersions($projectId: Int!, $language: String) {
    outline_builder_project_versions(project_id: $projectId, language: $language) {
      edges {
        node {
          id
          pk
          created_at
          is_active
        }
      }
    }
  }
`;
