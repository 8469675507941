import React, { useEffect } from 'react';

import StructureItemsListWidget from 'widgets/assignments-list';
import AssignmentSelectWidget from 'widgets/assignment-select';
import { useAppDispatch } from 'shared/store/hooks';
import { setAssignment } from 'entities/assignment/model';
import { clearStudio } from 'entities/studio/model';

import './styles.scss';

const CourseAssignmentsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(setAssignment(null));
      dispatch(clearStudio());
    };
    // Don't update deps
  }, []);
  return (
    <div className="course-assignment-page">
      <StructureItemsListWidget />
      <AssignmentSelectWidget />
    </div>
  );
};

export default CourseAssignmentsPage;
