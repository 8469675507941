import React, { memo } from 'react';

import { CBIconProps } from '../types';

const IconSparking: React.FC<CBIconProps> = ({ size }: CBIconProps) => {
  const styles = {
    width: size || 24,
    height: size || 24,
  };
  return (
    <svg style={styles} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 4.66675L11.8668 10.8L18 12.6667L11.8668 14.5335L10 20.6667L8.13324 14.5335L2 12.6667L8.13324 10.8L10 4.66675Z"
        fill="currentColor"
      />
      <path
        d="M18.3333 1.33325L19.3445 4.65542L22.6667 5.66659L19.3445 6.67775L18.3333 9.99992L17.3222 6.67775L14 5.66659L17.3222 4.65542L18.3333 1.33325Z"
        fill="currentColor"
      />
      <path
        d="M17 16.6667L17.7 18.9667L20 19.6667L17.7 20.3668L17 22.6667L16.3 20.3668L14 19.6667L16.3 18.9667L17 16.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(IconSparking);
