import { gql } from '@apollo/client';

export const SCRIPT_SLIDES_LINKS = gql`
  query ScripsSlides($scriptId: Int!) {
    cob_script_presentation_slides(script_id: $scriptId) {
      id
      document_file_section {
        id
        order
      }
      presentation_file_slide {
        id
        file {
          id
          name
        }
        source_url
        is_active
      }
    }
  }
`;
