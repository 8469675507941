import { ChangeEvent, useCallback, useState } from 'react';

import mixpanel from 'mixpanel-browser';
import { useParams } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import { StructureItem } from 'shared/types/entities';
import { onToggleCollapseItemAction } from 'widgets/course-structure/store/actions';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { OutlineBuilderProjectStructureItemGeneratePayload } from '../../shared/api/requests/outline-builder-project-structure';

import {
  deleteStructureItemAction,
  generateStructureItemAction,
  updateItemPositionAction,
  updateStructureItem,
} from './store/actions';
import { setAddElement, setAddElementType, setAddLesson } from './store';

const useStructureItem = (item: StructureItem) => {
  const [text, setText] = useState(item.title);

  const { id } = useParams();

  const itemState = useAppSelector((state) => state.structureItem);
  const structure = useAppSelector((state) => state.structure.structure);
  const dispatch = useAppDispatch();

  const onToggle = useCallback(() => {
    void dispatch(onToggleCollapseItemAction(item.id, !item.open));
    if (item.open) {
      dispatch(setAddElement(null));
      dispatch(setAddElementType(null));
    }
  }, [dispatch, item.id, item.open]);

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  }, []);

  const onBlur = () => {
    if (!isEqual(item.title, text)) {
      if (item.id) {
        const payload = {
          title: text,
          course_structure_item_parent_id: item?.course_structure_item_parent?.id
            ? Number(item?.course_structure_item_parent?.id)
            : undefined,
        };
        void dispatch(updateStructureItem(payload, item.id));
      }
    }
  };

  const onShowAddLesson = useCallback(() => {
    dispatch(setAddLesson(item.id));
  }, [dispatch, item.id]);

  const onGenerate = useCallback(
    (content: string) => {
      if (id) {
        const payload: OutlineBuilderProjectStructureItemGeneratePayload = {};
        if (item.learning_structure.level_type === 'module') {
          payload.for_regeneration_structure_module = content;
        }
        if (item.learning_structure.level_type === 'lesson') {
          payload.for_regeneration_structure_lesson = content;
        }
        if (item.learning_structure.level_type === 'production_item') {
          payload.for_regeneration_structure_item = content;
        }
        void dispatch(generateStructureItemAction(item.id, payload));
      }
    },
    [dispatch, id, item.id, item.learning_structure.level_type]
  );

  const onClickAdd = useCallback(() => {
    if (item.learning_structure.level_type === 'module') {
      if (!open) {
        dispatch(onToggleCollapseItemAction(item.id, true)).then(() => {
          onShowAddLesson();
        });
      } else {
        onShowAddLesson();
      }
    }
  }, [dispatch, item.id, item.learning_structure.level_type, onShowAddLesson]);

  const onRemoveItem = useCallback(() => {
    if (item.id) {
      void dispatch(deleteStructureItemAction(item.id));
    }
  }, [dispatch, item.id]);

  const currentModuleIndex = Number(item.index[0]) - 1;
  const currentModulePosition = Number(item.index[0]);
  const currentLessonIndex = Number(item.index[2]) - 1;
  const currentLessonPosition = Number(item.index[2]);
  const currentPosition = Number(item.index[4]);
  const parent = structure[currentModuleIndex]?.children[currentLessonIndex];

  const onMoveUp = useCallback(() => {
    console.log('onMoveUp');
    mixpanel.track('Course Structure Element Move Up', { ProjectId: id });
    if (item.learning_structure.level_type === 'module') {
      if (item.order > 1) {
        const payload = {
          order: item.order - 1,
        };
        void dispatch(updateItemPositionAction(item.id, payload));
      }
    }

    if (item.learning_structure.level_type === 'lesson') {
      if (item.order > 1) {
        const payload = {
          course_structure_item_parent_id: Number(item.course_structure_item_parent?.id),
          order: item.order - 1,
        };
        void dispatch(updateItemPositionAction(item.id, payload));
      }
      if (item.order === 1) {
        if (currentModuleIndex > 0) {
          const payload = {
            course_structure_item_parent_id: Number(structure[currentModuleIndex - 1].id),
            order: structure[currentModuleIndex - 1].children.length + 1,
          };
          void dispatch(updateItemPositionAction(item.id, payload));
        }
      }
    }

    if (item.learning_structure.level_type === 'production_item') {
      if (item.order > 1) {
        const payload = {
          course_structure_item_parent_id: Number(item.course_structure_item_parent?.id),
          order: item.order - 1,
        };
        void dispatch(updateItemPositionAction(item.id, payload));
      }
      if (item.order === 1) {
        if (currentModuleIndex > 0) {
          if (currentLessonIndex > 0) {
            const nextParent = structure[currentModuleIndex].children[currentLessonIndex - 1];
            const payload = {
              course_structure_item_parent_id: Number(nextParent.id),
              order: structure[currentModuleIndex].children[currentLessonIndex - 1].children.length + 1,
            };
            void dispatch(updateItemPositionAction(item.id, payload));
          } else {
            //Moving between modules
            const nextStructureItem = structure[currentModuleIndex - 1];
            const nextParent = nextStructureItem.children[nextStructureItem.children.length - 1];
            const payload = {
              course_structure_item_parent_id: Number(nextParent.id),
              order: nextStructureItem.children[nextStructureItem.children.length - 1].children.length + 1,
            };
            void dispatch(updateItemPositionAction(item.id, payload));
          }
        } else {
          if (currentLessonIndex > 0) {
            const nextParent = structure[currentModuleIndex].children[currentLessonIndex - 1];
            const payload = {
              course_structure_item_parent_id: Number(nextParent.id),
              order: structure[currentModuleIndex].children[currentLessonIndex - 1].children.length + 1,
            };
            void dispatch(updateItemPositionAction(item.id, payload));
          }
        }
      }
    }
  }, [currentLessonIndex, currentModuleIndex, dispatch, item, structure]);

  const onMoveDown = useCallback(() => {
    console.log('onMoveDown');
    mixpanel.track('Course Structure Element Move Down', { ProjectId: id });
    if (item.learning_structure.level_type === 'module') {
      const payload = {
        order: item.order + 1,
      };
      void dispatch(updateItemPositionAction(item.id, payload));
    }

    if (item.learning_structure.level_type === 'lesson') {
      if (item.order < structure[currentModuleIndex].children.length) {
        const payload = {
          course_structure_item_parent_id: Number(structure[currentModuleIndex].id),
          order: item.order + 1,
        };
        void dispatch(updateItemPositionAction(item.id, payload));
      }
      if (item.order === structure[currentModuleIndex].children.length) {
        if (currentModuleIndex + 1 < structure.length) {
          const payload = {
            course_structure_item_parent_id: Number(structure[currentModuleIndex + 1].id),
            order: 1,
          };
          void dispatch(updateItemPositionAction(item.id, payload));
        }
      }
    }

    if (item.learning_structure.level_type === 'production_item') {
      if (parent.children.length > currentPosition) {
        const payload = {
          course_structure_item_parent_id: Number(item.course_structure_item_parent?.id),
          order: item.order + 1,
        };
        void dispatch(updateItemPositionAction(item.id, payload));
        return;
      }
      if (structure[currentModuleIndex].children.length > currentLessonPosition) {
        const nextParent = structure[currentModuleIndex].children[currentLessonIndex + 1];
        const payload = {
          course_structure_item_parent_id: Number(nextParent?.id),
          order: 1,
        };
        void dispatch(updateItemPositionAction(item.id, payload));
        return;
      }
      if (structure.length > currentModulePosition && !isEmpty(structure[currentModuleIndex + 1].children)) {
        const nextParent = structure[currentModuleIndex + 1].children[0];
        const payload = {
          course_structure_item_parent_id: Number(nextParent?.id),
          order: 1,
        };
        void dispatch(updateItemPositionAction(item.id, payload));
        return;
      }
    }
  }, [
    currentLessonIndex,
    currentLessonPosition,
    currentModuleIndex,
    currentModulePosition,
    currentPosition,
    dispatch,
    item.course_structure_item_parent?.id,
    item.id,
    item.learning_structure.level_type,
    item.order,
    parent?.children?.length,
    structure,
  ]);

  return {
    open: item.open,
    onToggle,
    onClickAdd,
    text,
    itemState,
    handleChange,
    onBlur,
    onRemoveItem,
    onGenerate,
    onMoveUp,
    onMoveDown,
  };
};

export default useStructureItem;
