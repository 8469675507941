import React from 'react';

import { Export } from 'features/export';
import HistoryTable from 'entities/export/ui/history-table';

import './styles.scss';

const ExportHistoryWidget = () => {
  return (
    <div className="export-history-widget">
      <Export.ParamsForm />
      <HistoryTable />
    </div>
  );
};

export default ExportHistoryWidget;
