import React, { memo } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import useLockBodyScroll from 'shared/hooks/useLockBodyScroll';

import './styles.scss';

type Props = {
  loading?: boolean;
};

const SpinnerOverlay: React.FC<Props> = ({ loading }) => {
  useLockBodyScroll(loading);
  if (!loading) return null;
  return (
    <div className="spinner-overlay">
      <LoadingOutlined style={{ fontSize: 64, color: '#1E1E1E' }} spin />
    </div>
  );
};

export default memo(SpinnerOverlay);
