import React, { memo } from 'react';

import Button from 'shared/ui/button/Button';
import { PromoMessageData } from 'shared/types/entities';
import mixpanel from 'mixpanel-browser';
import { CloseOutlined } from '@ant-design/icons';
import { closePromoAction } from 'app/store/actions';

import { useAppDispatch } from 'shared/store/hooks';

import './styles.scss';

type Props = {
  promoId: string;
  item: PromoMessageData;
};

const PromoCardItem: React.FC<Props> = ({ promoId, item }) => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    mixpanel.track('Promo banner click button', { BannerId: promoId, BannerType: 'card' });
    window.open(item.button_href, '_blank');
  };

  const onClosePromo = () => {
    mixpanel.track('Promo banner click button close', { BannerId: promoId, BannerType: 'card' });
    void dispatch(closePromoAction(promoId));
  };

  return (
    <div className="promo-card">
      <Button className="promo-card__close" type="text" icon={<CloseOutlined />} onClick={onClosePromo} />
      <div className="promo-card__info">
        <span className="promo-card__title">{item.title}</span>
        <span className="promo-card__description">{item.description}</span>
      </div>
      <Button type="primary" size="large" onClick={onClick}>
        {item.button_name}
      </Button>
    </div>
  );
};

export default memo(PromoCardItem);
