import React from 'react';

import LearningOutcomesDescriptionView from 'modules/learning-outcomes-description-view';
import ModuleLearningOutcomes from 'widgets/module-learning-outcomes';

import './styles.scss';

const CourseLearningOutcomesPage: React.FC = () => {
  return (
    <div className="learning-outcomes-page">
      <LearningOutcomesDescriptionView />
      <ModuleLearningOutcomes />
    </div>
  );
};

export default CourseLearningOutcomesPage;
