import React from 'react';

import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import InputPassword from 'shared/ui/input/InputPassword';
import FormItem from 'shared/ui/form-item/FormItem';
import Button from 'shared/ui/button/Button';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';
import { PATH_NAME } from 'pages/constants';
import { useBoolean } from 'usehooks-ts';
import { putChangePassRequest } from 'shared/api/requests/app';
import { messageSave } from 'shared/utils';

const ChangePassForm = () => {
  const { value: loading, setValue: setLoading } = useBoolean(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { navigate } = useCustomNavigator();

  const onCancel = () => navigate(PATH_NAME.profile);

  const onSubmit = async () => {
    setLoading(true);
    messageSave();
    const values = form.getFieldsValue();

    const payload = {
      password: values.password,
      password2: values.password2,
      old_password: values.old_password,
    };

    await putChangePassRequest(payload)
      .then(() => {
        setLoading(false);
        onCancel();
        message.destroy();
      })
      .catch((error) => {
        console.log(error, 'error');
        setLoading(false);
      });
  };

  return (
    <Form
      name="change-pass-form"
      form={form}
      requiredMark={false}
      className="user-form"
      layout="vertical"
      onFinish={onSubmit}
    >
      <FormItem
        name="old_password"
        label={t<string>('loginForm.passwordOld.label')}
        rules={[{ required: true, message: t<string>('loginForm.error.plsPassword') }]}
      >
        <InputPassword size="large" exSize="xl" placeholder={t<string>('loginForm.passwordOld.placeholder')} />
      </FormItem>
      <FormItem
        name="password"
        label={t<string>('loginForm.passwordNew.label')}
        rules={[{ required: true, message: t<string>('loginForm.error.plsPassword') }]}
      >
        <InputPassword size="large" exSize="xl" placeholder={t<string>('loginForm.passwordNew.placeholder')} />
      </FormItem>
      <FormItem
        name="password2"
        label={t<string>('loginForm.passwordConfirm.label')}
        rules={[
          {
            required: true,
            message: t<string>('loginForm.error.plsPassword'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t<string>('loginForm.error.passNotMatch')));
            },
          }),
        ]}
      >
        <InputPassword size="large" exSize="xl" placeholder={t<string>('loginForm.passwordConfirm.placeholder')} />
      </FormItem>
      <div className="mt-6">
        <Button className="mr-4" size="large" exSize="xl" htmlType="submit" disabled={loading} type="primary">
          {t<string>('common.save')}
        </Button>
        <Button size="large" exSize="xl" onClick={onCancel} disabled={loading}>
          {t<string>('common.cancel')}
        </Button>
      </div>
    </Form>
  );
};

export default ChangePassForm;
