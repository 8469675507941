import React from 'react';

import { Alert } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useAppSelector } from 'shared/store/hooks';
import { dateFormat } from 'shared/utils';
import { priceModel } from 'entities/price';

const DowngradeAlert = () => {
  const { i18n } = useTranslation();
  const data = useAppSelector((state) => state.app?.me?.account_data);
  const { free } = priceModel.useSubscriptions();

  return (
    <Alert
      className="downgrade-alert"
      type="warning"
      message={
        <Trans
          i18nKey={'billingUser.planWillBeChanged'}
          defaults="Your plan will be changed to <bold>{{plan}}</bold> on <bold>{{date}}</bold>"
          values={{ plan: free?.name, date: dateFormat(data?.subscription_cancel_dt || '', 'DD MMM', i18n.language) }}
          components={{ bold: <strong /> }}
        />
      }
    />
  );
};

export default DowngradeAlert;
