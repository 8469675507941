import React, { memo } from 'react';
import type { ReactNode } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button/Button';
import { cx } from 'shared/utils';

import './styles.scss';

type Props = {
  title?: string;
  type?: 'h3' | 'h4';
  onClose?: () => void;
  extraContent?: ReactNode;
};

const ModalHeader: React.FC<Props> = ({ title, type, extraContent, onClose }) => {
  const classNames = cx('cb-modal-header__title', {
    [`cb-modal-header__title--${type}`]: type,
  });
  return (
    <div className="cb-modal-header">
      <span className={classNames}>{title}</span>
      {extraContent ? <div className="cb-modal-header__extra">{extraContent}</div> : null}
      {onClose ? (
        <Button
          size="large"
          exSize="xl"
          className="cb-modal-header__close"
          type="primary"
          icon={<CloseOutlined style={{ fontSize: 24 }} />}
          onClick={onClose}
        />
      ) : null}
    </div>
  );
};

export default memo(ModalHeader);
