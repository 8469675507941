import { createSelector } from '@reduxjs/toolkit';
import { ActiveSubModulesOutcome } from 'shared/types/entities';

import { ProjectState } from './index';

const projectOutcomes = (state: ProjectState) => state.project?.active_project_version?.active_modules_outcomes;

export const getSubOutcomes = createSelector(projectOutcomes, (items) => {
  const newArr: ActiveSubModulesOutcome[] = [];
  items?.forEach((outcome) => {
    outcome?.active_sub_modules_outcomes?.forEach((sub) => {
      const newItem = {
        ...sub,
        color: outcome.color,
      };
      newArr.push(newItem);
    });
  });
  return newArr as ActiveSubModulesOutcome[];
});
