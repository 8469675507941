import React, { useCallback } from 'react';

import { generatePath } from 'react-router-dom';
import ReactJoyride, { CallBackProps } from 'react-joyride';
import { PATH_NAME } from 'pages/constants';
import modalService from 'shared/services/modalService';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';

import { removeTourProjectAction } from '../project/actions';

import { finishTutor, setStepIndex, stopTutor } from './store';
import TourTitle from './ui/TourTitle';
import TourText from './ui/TourText';

const Tutorial: React.FC = () => {
  const projectTourId = useAppSelector((state) => state.tutorial.tourId);
  const projectAssnTourId = useAppSelector((state) => state.tutorial.assignmentTourId);
  const run = useAppSelector((state) => state.tutorial.run);
  const stepIndex = useAppSelector((state) => state.tutorial.stepIndex);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { navigate } = useCustomNavigator();

  const steps = [
    {
      target: '.tour--card-list',
      disableBeacon: true,
      content: (
        <>
          <TourTitle>{t('tour.steps.cardList.title')}</TourTitle>
        </>
      ),
    },
    {
      target: '.tour--nav-settings',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.navSettings.title')}</TourTitle>
          <TourText>{t('tour.steps.navSettings.text1')}</TourText>
          <TourText>{t('tour.steps.navSettings.text2')}</TourText>
          <TourText>{t('tour.steps.navSettings.text3')}</TourText>
        </div>
      ),
    },
    {
      target: '.tour--slider',
      disableBeacon: true,
      content: (
        <>
          <TourTitle>{t('tour.steps.slider.title')}</TourTitle>
        </>
      ),
    },
    {
      target: '.tour--settings-gen',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.settingsGen.title')}</TourTitle>
          <TourText>{t('tour.steps.settingsGen.text1')}</TourText>
        </div>
      ),
    },
    {
      target: '.tour--nav-about',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.navAbout.title')}</TourTitle>
          <TourText>{t('tour.steps.navAbout.text1')}</TourText>
          <TourText> {t('tour.steps.navAbout.text2')}</TourText>
        </div>
      ),
    },
    {
      target: '.tour--about-input',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.aboutInput.title')}</TourTitle>
          <TourText>{t('tour.steps.aboutInput.text1')}</TourText>
        </div>
      ),
    },
    {
      target: '.tour--about-gen',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.aboutGen.title')}</TourTitle>
        </div>
      ),
    },
    {
      target: '.tour--nav-learning-outcomes',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.navLO.title')}</TourTitle>
          <TourText>{t('tour.steps.navLO.text1')}</TourText>
          <TourText>{t('tour.steps.navLO.text2')}</TourText>
          <TourText>{t('tour.steps.navLO.text3')}</TourText>
        </div>
      ),
    },
    {
      target: '.tour--learning-outcomes-regen',
      placement: 'top' as const,
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.loRegen.title')}</TourTitle>
          <TourText>{t('tour.steps.loRegen.text1')}</TourText>
        </div>
      ),
    },
    {
      target: '.tour--learning-outcomes-add',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.loAdd.title')}</TourTitle>
        </div>
      ),
    },
    {
      target: '.tour--learning-outcomes-gen-structure',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.loGenStructure.title')}</TourTitle>
        </div>
      ),
    },
    {
      target: '.tour--nav-structure',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.navStructure.title')}</TourTitle>
          <TourText>{t('tour.steps.navStructure.text1')}</TourText>
          <TourText>{t('tour.steps.navStructure.text2')}</TourText>
        </div>
      ),
    },
    {
      target: '.tour--structure-regen',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.structureRegen.title')}</TourTitle>
        </div>
      ),
    },
    {
      target: '.tour--structure-add-item',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.structureAddItem.title')}</TourTitle>
        </div>
      ),
    },
    {
      target: '.tour--structure-more',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.structureMore.title')}</TourTitle>
        </div>
      ),
    },
    {
      target: '.tour--nav-assignments',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.navAssignments.title')}</TourTitle>
          <TourText>{t('tour.steps.navAssignments.text1')}</TourText>
          <TourText>{t('tour.steps.navAssignments.text2')}</TourText>
        </div>
      ),
    },
    {
      target: '.tour--assignments-quiz',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.assignmentsQuiz.title')}</TourTitle>
        </div>
      ),
    },
    {
      target: '.tour--assignments-quiz-number',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.assignmentsQuizNumber.title')}</TourTitle>
        </div>
      ),
    },
    {
      target: '.tour--assignments-quiz-type',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.assignmentsQuizType.title')}</TourTitle>
        </div>
      ),
    },
    {
      target: '.tour--assignments-quiz-edit',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.assignmentsQuizEdit.title')}</TourTitle>
          <TourText>{t('tour.steps.assignmentsQuizEdit.text1')}</TourText>
        </div>
      ),
    },
    {
      target: '.tour--export',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.export.title')}</TourTitle>
        </div>
      ),
    },
    {
      target: '.tour--share',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.share.title')}</TourTitle>
        </div>
      ),
    },
    {
      target: '.tour--content-lang',
      disableBeacon: true,
      content: (
        <div className="d-flex flex-column">
          <TourTitle>{t('tour.steps.contentLang.title')}</TourTitle>
        </div>
      ),
    },
  ];

  const handleCallback = useCallback(
    (data: CallBackProps) => {
      const { action, index, lifecycle, type } = data;

      if (action === 'skip') {
        if (projectTourId) {
          void dispatch(removeTourProjectAction(projectTourId.toString()));
        }
        dispatch(finishTutor());
        navigate(PATH_NAME.main);
      }

      // Settings page start
      if (type === 'step:after' && index === 0) {
        dispatch(stopTutor());
        dispatch(setStepIndex(1));
        const path = generatePath(PATH_NAME.courseManualWithEntity, {
          id: projectTourId,
          lang: 'en',
          entity: PATH_NAME.courseEntity.settings,
        });
        navigate(path);
      }
      // Finish settings page

      // About page start
      if (type === 'step:after' && index === 3 && action === 'next') {
        dispatch(stopTutor());
        const path = generatePath(PATH_NAME.courseManualWithEntity, {
          id: projectTourId,
          lang: 'en',
          entity: PATH_NAME.courseEntity.about,
        });
        navigate(path);
      }
      // About page end

      // LO page start
      if (type === 'step:after' && index === 6 && action === 'next') {
        dispatch(stopTutor());
        const path = generatePath(PATH_NAME.courseManualWithEntity, {
          id: projectTourId,
          lang: 'en',
          entity: PATH_NAME.courseEntity.learning,
        });
        navigate(path);
      }
      // LO page end

      // Structure page start
      if (type === 'step:after' && index === 10 && action === 'next') {
        dispatch(stopTutor());
        const path = generatePath(PATH_NAME.courseManualWithEntity, {
          id: projectTourId,
          lang: 'en',
          entity: PATH_NAME.courseEntity.structure,
        });
        navigate(path);
      }
      // Structure page end

      // Assignments page start
      if (type === 'step:after' && index === 14 && action === 'next') {
        dispatch(stopTutor());
        const path = generatePath(PATH_NAME.courseManualWithEntity, {
          id: projectTourId,
          lang: 'en',
          entity: PATH_NAME.courseEntity.assignments,
        });
        navigate(`${path}?assignment=${projectAssnTourId}`);
      }
      // Assignments page end

      if (type === 'tour:end' && action === 'next') {
        if (projectTourId) {
          void dispatch(removeTourProjectAction(projectTourId.toString()));
        }
        dispatch(finishTutor());
        navigate(PATH_NAME.main);
        modalService.openEndTutorial({});
      }

      if (type === 'step:after' && lifecycle === 'complete' && action === 'next') {
        dispatch(setStepIndex(index + 1));
      }
      if (type === 'step:after' && lifecycle === 'complete' && action === 'prev') {
        dispatch(setStepIndex(index - 1));
      }
    },
    [dispatch, navigate, projectAssnTourId, projectTourId]
  );

  return (
    <ReactJoyride
      steps={steps}
      stepIndex={stepIndex}
      continuous
      run={run}
      callback={handleCallback}
      showSkipButton={true}
      hideCloseButton={true}
      hideBackButton={true}
      disableOverlayClose={true}
      locale={{ last: 'Finish' }}
      styles={{
        options: {
          arrowColor: '#ffffff',
          backgroundColor: '#ffffff',
          overlayColor: 'rgba(0, 0, 0, 0.4)',
          primaryColor: '#1e1e1e',
          textColor: '#1e1e1e',
          width: undefined,
          zIndex: 1000,
        },
      }}
    />
  );
};

export default Tutorial;
