import React from 'react';

import { cx } from 'shared/utils';

import './styles.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  isGen: boolean;
};

const GenStatusView = ({ isGen }: Props) => {
  const { t } = useTranslation();
  const classNames = cx('generation-status-view', {
    'generation-status-view--busy': isGen,
  });

  return (
    <div className={classNames}>
      <div className="generation-status-view__status" />
      <div className="generation-status-view__wrap">
        <span className="generation-status-view__label">Copilot:</span>
        <span className="generation-status-view__text">
          {isGen ? t('genStatusView.progress') : t('genStatusView.available')}
        </span>
      </div>
    </div>
  );
};

export default GenStatusView;
