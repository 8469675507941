import { useParams } from 'react-router-dom';

import { useAppSelector } from '../store/hooks';

export const useOwner = () => {
  const { id } = useParams();
  const projectCreateById = useAppSelector((state) => state?.project?.project?.created_by.id);
  const userId = useAppSelector((state) => state.app.me?.id);

  return {
    isOwner: id === 'new' ? 'true' : projectCreateById === userId,
  };
};
