import React from 'react';

import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import FormItem from 'shared/ui/form-item';
import TextArea from 'shared/ui/textArea';
import Input from 'shared/ui/input';
import SpinnerOverlay from 'shared/ui/spinner-overlay/SpinnerOverlay';
import { cx } from 'shared/utils';
import FormFooter from 'shared/components/form-footer/FormFooter';
import ButtonSparking from 'shared/components/button-sparking';

import RegeneratePopup from '../regenerate-popup';

import useAboutForm from './useAboutForm';

import './styles.scss';

const AboutPageForm: React.FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const dir = i18n.dir(i18n.language);

  const {
    form,
    loading,
    disabled,
    handleChangeTextField,
    onBlur,
    onSubmit,
    onRegenerateTitle,
    onRegenerateDescription,
    onGoToSettings,
  } = useAboutForm();

  const classNames = cx('about-form', {
    [`about-form--${dir}`]: dir,
  });

  return (
    <>
      <Form name="about-form" className={classNames} layout="vertical">
        <div className="about-form__field-wrap">
          <FormItem label={t<string>('aboutForm.title.label')}>
            <Input
              className="tour--about-input"
              name="generated_title"
              size="large"
              exSize="xl"
              placeholder={t<string>('aboutForm.title.placeholder')}
              disabled={disabled}
              value={form.generated_title}
              onBlur={onBlur}
              onChange={handleChangeTextField}
            />
          </FormItem>
          {!disabled ? (
            <RegeneratePopup onSubmit={onRegenerateTitle}>
              <ButtonSparking />
            </RegeneratePopup>
          ) : null}
        </div>
        <div className="about-form__field-wrap">
          <FormItem label={t<string>('aboutForm.description.label')}>
            <TextArea
              name="generated_description"
              placeholder={t<string>('aboutForm.description.placeholder')}
              size="large"
              autoSize={{ minRows: 2, maxRows: 10 }}
              disabled={disabled}
              value={form.generated_description}
              onBlur={onBlur}
              onChange={handleChangeTextField}
            />
          </FormItem>
          {!disabled ? (
            <RegeneratePopup onSubmit={onRegenerateDescription}>
              <ButtonSparking />
            </RegeneratePopup>
          ) : null}
        </div>
        <FormFooter
          classNameSubmit="tour--about-gen"
          labelSubmit={t<string>('aboutForm.generateLearningOutcomes')}
          labelBack={t<string>('courseNav.settings')}
          onBack={onGoToSettings}
          onSubmit={onSubmit}
          disabledSubmit={(!form.generated_description && !form.generated_title) || disabled}
        />
      </Form>
      <SpinnerOverlay loading={loading} />
    </>
  );
};

export default AboutPageForm;
