import { combineReducers } from '@reduxjs/toolkit';
import appReducer from 'app/store';
import structureReducer from 'widgets/course-structure/store';
import addOutcomeReducer from 'modules/add-learning-outcome/store';
import structureItemReducer from 'modules/course-structure-collapse/store';
import projectReducer from 'modules/project';
import createCourseUploadReducer from 'modules/create-course-upload/store';
import createCourseReducer from 'widgets/create-course/store';
import projectsReducer from 'widgets/course-list/store';
import assignmentsReducer from 'widgets/assignments-list/store';
import tutorialReducer from 'modules/tutorial/store';
import assignment from 'entities/assignment/model/assignment';
import studio from 'entities/studio/model/studio.slice';

export default combineReducers({
  app: appReducer,
  structure: structureReducer,
  structureItem: structureItemReducer,
  addOutcome: addOutcomeReducer,
  project: projectReducer,
  createCourseUpload: createCourseUploadReducer,
  createCourse: createCourseReducer,
  projects: projectsReducer,
  assignments: assignmentsReducer,
  tutorial: tutorialReducer,
  assignment,
  studio,
});
