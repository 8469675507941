import React, { ChangeEvent } from 'react';

import TextArea from 'shared/ui/textArea';
import Button from 'shared/ui/button';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@ant-design/icons';

type Props = {
  text: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
};

const RegeneratePopupContent: React.FC<Props> = ({ text, onChange, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <div className="regen-popup-content">
      <span className="regen-popup-content__title">{t('regenPopup.title')}</span>
      <TextArea autoSize={{ minRows: 3, maxRows: 10 }} value={text} onChange={onChange} />
      <div className="regen-popup-content__footer">
        <div className="regen-popup-content__extra">
          <span className="regen-popup-content__extra-title">
            <WarningOutlined style={{ fontSize: 16, color: '#D73C1D' }} />
            {t('regenPopup.text')}
          </span>
          <span className="regen-popup-content__extra-text">{t('regenPopup.text2')}</span>
        </div>
        <Button disabled={!text?.trim()} type="primary" onClick={onSubmit}>
          {t('common.regenerate')}
        </Button>
      </div>
    </div>
  );
};

export default RegeneratePopupContent;
