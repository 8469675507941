import React, { memo } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { OutlineProject, OutlineProjectGenerationStatusType, ProjectGenerateState } from 'shared/types/entities';
import { useTranslation } from 'react-i18next';
import RegenerateButton from 'modules/regenerate-button';
import ReTranslateButton from 'modules/re-translate-button';

import '../styles.scss';
import { cx } from '../../../utils';

type Props = {
  project: OutlineProject;
};

const StepsContent: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();

  const hasReject = project.generation_status?.state === ProjectGenerateState.rejected;
  const type = project.generation_status?.generation_type;

  const classNames = cx('course-card-steps', {
    'course-card-steps--translate': type === OutlineProjectGenerationStatusType.translation,
  });

  return (
    <div className={classNames}>
      {type === OutlineProjectGenerationStatusType.translation ? (
        <h4 className="course-card__title">{project.active_project_version?.generated_title}</h4>
      ) : null}

      <div className="course-card-steps__loading">
        <div className="course-card-steps__current">
          <span className="course-card-steps__current-text">{`${project.generation_status?.current_step} / ${project.generation_status?.total_steps}`}</span>
        </div>
        {!hasReject ? <LoadingOutlined style={{ fontSize: 52, color: '#1E1E1E' }} spin /> : null}
      </div>
      {hasReject ? (
        <>
          {type === OutlineProjectGenerationStatusType.translation ? (
            <>
              <span className="course-card-steps__text mb-5">{t<string>('card.rejected')}</span>
              <ReTranslateButton project={project} />
            </>
          ) : (
            <>
              <span className="course-card-steps__text mb-5">{t<string>('card.rejected')}</span>
              <RegenerateButton project={project} />
            </>
          )}
        </>
      ) : (
        <>
          <span className="course-card-steps__text">
            {type === OutlineProjectGenerationStatusType.translation
              ? t<string>('card.translation')
              : t<string>('card.generation')}
          </span>
        </>
      )}
    </div>
  );
};

export default memo(StepsContent);
