import React, { useCallback, useRef } from 'react';

import Button from 'shared/ui/button';
import { ObjectType } from 'shared/types/entities';
import { useBoolean, useOnClickOutside } from 'usehooks-ts';
import { CheckOutlined } from '@ant-design/icons';
import useObjectTypes from 'shared/graphql/queries/useObjectTypes';
import { getIconByUrl } from 'shared/utils/getIcon';
import { useAppDispatch } from 'shared/store/hooks';

import { updateStructureItemTypeAction } from '../store/actions';

import './styles.scss';
import { cx } from '../../../shared/utils';

type Props = {
  disabled: boolean;
  itemId: string;
  itemType: ObjectType | null;
};

type ItemProps = {
  selected: boolean;
  onClick: (itemTypeId: string) => void;
  item: ObjectType;
};

const ItemTypeSelectionMenuItem = React.memo<ItemProps>(({ selected, item, onClick }) => {
  const onClickItem = useCallback(() => {
    onClick(item.id);
  }, [item.id, onClick]);

  return (
    <div className="item-type-select-item" onClick={onClickItem}>
      <div className="item-type-select-item__icon">{getIconByUrl(item.icon)}</div>
      <span className="item-type-select-item__text">{item.title}</span>
      <div className="item-type-select-item__selected">
        {selected ? <CheckOutlined style={{ fontSize: 14 }} /> : null}
      </div>
    </div>
  );
});

const ItemTypeSelection: React.FC<Props> = ({ itemId, itemType, disabled }) => {
  const { value: open, toggle, setFalse } = useBoolean(false);
  const ref = useRef(null);
  const dispatch = useAppDispatch();

  const { objectTypes } = useObjectTypes();

  const handleClickOutside = () => {
    setFalse();
  };

  useOnClickOutside(ref, handleClickOutside);

  const onSelectItem = useCallback(
    (itemTypeId: string) => {
      const payload = {
        type_id: Number(itemTypeId),
      };
      void dispatch(updateStructureItemTypeAction(itemId, payload));
      setFalse();
    },
    [dispatch, itemId, setFalse]
  );

  const classNames = cx('item-type-select__button', {
    'item-type-select__button--disabled': disabled,
  });

  return (
    <div ref={ref} className="item-type-select">
      <Button className={classNames} disabled={disabled} onClick={toggle}>
        {getIconByUrl(itemType?.icon)}
      </Button>
      {open ? (
        <div className="item-type-select__menu">
          {objectTypes?.map((el: ObjectType) => {
            return (
              <ItemTypeSelectionMenuItem
                key={el.id}
                selected={itemType?.id === el.id}
                item={el}
                onClick={onSelectItem}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ItemTypeSelection;
