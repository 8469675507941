import React from 'react';

import SignupWidget from 'widgets/signup';
import GoLoginFormModule from 'modules/login-form/GoLoginForm';

import './styles.scss';

const SignupPage: React.FC = () => {
  return (
    <div className="signup-page">
      <SignupWidget />
      <GoLoginFormModule />
    </div>
  );
};

export default SignupPage;
