import { gql } from '@apollo/client';

import { LEARNING_STRUCTURE, OBJECT_TYPE, STRUCTURE_ITEM } from './fragments';

export const OUTLINE_BUILDER_PROJECT_ASSIGNMENTS = gql`
  query OutlineBuilderProjectAssignments($id: Int, $language: String!, $projectVersionId: Int) {
    outline_builder_assignments(project_id: $id, language: $language, project_version_id: $projectVersionId) {
      ...StructureItem
      learning_structure {
        ...LearningStructure
      }
      type {
        ...ObjectType
      }
    }
  }
  ${STRUCTURE_ITEM}
  ${LEARNING_STRUCTURE}
  ${OBJECT_TYPE}
`;
