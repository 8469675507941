import { gql } from '@apollo/client';

import { LEARNING_STRUCTURE, OBJECT_TYPE, STRUCTURE_ITEM_FULL } from './fragments';

export const OUTLINE_BUILDER_PROJECT_ASSIGNMENTS_FULL = gql`
  query OutlineBuilderProjectAssignmentsFull($id: Int, $language: String!) {
    outline_builder_assignments(project_id: $id, language: $language) {
      ...StructureItemFull
      learning_structure {
        ...LearningStructure
      }
      type {
        ...ObjectType
      }
    }
  }
  ${STRUCTURE_ITEM_FULL}
  ${LEARNING_STRUCTURE}
  ${OBJECT_TYPE}
`;
