import React, { memo, useCallback, useEffect } from 'react';

import LearningOutcomesStructureView from 'modules/learning-outcomes-structure-view/Learning-outcomes-structure-view';
import BlockTitle from 'shared/ui/block-title/BlockTitle';
import CourseStructureCollapse from 'modules/course-structure-collapse';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getProjectAction } from 'modules/project/actions';
import { getStructureAction } from 'widgets/course-structure/store/actions';
import { setLayout } from 'app/store';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import './styles.scss';
import { localStorageService } from '../../../shared/services/localStorageService';

const CoursePrint: React.FC = () => {
  const { id, lang } = useParams();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.project);
  const structure = useAppSelector((state) => state.structure.structure);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setLayout('print'));
    return () => {
      dispatch(setLayout(null));
    };
  }, [dispatch]);
  const initPage = useCallback(() => {
    if (id) {
      void dispatch(getProjectAction(id, false, undefined, lang));
      void dispatch(getStructureAction(id, lang || localStorageService.getLangData())).then(() => {
        setTimeout(() => window.print(), 1000);
      });
    }
  }, [dispatch, id, lang]);

  useEffect(() => {
    initPage();
  }, [initPage]);

  return (
    <div className="course-print">
      <h4 className="course-print__title">{project?.active_project_version?.generated_title}</h4>
      <BlockTitle text="Course Description" />
      <span className="lo-description-view__text mb-8">{project?.active_project_version?.generated_description}</span>
      <LearningOutcomesStructureView />
      <>
        <BlockTitle text={t<string>('structure.courseStructure')} />
        <div className="course-structure__content">
          {structure?.map((item) => {
            return <CourseStructureCollapse key={item.id} level={1} item={item} />;
          })}
        </div>
      </>
    </div>
  );
};

export default memo(CoursePrint);
