import React, { ChangeEvent, useEffect, useState } from 'react';

import AssignmentsHead from 'modules/assignments-head';
import { useTranslation } from 'react-i18next';
import Input from 'shared/ui/input';
import Button from 'shared/ui/button';
import BlockTitle from 'shared/ui/block-title/BlockTitle';

import './styles.scss';
import { useAppDispatch, useAppSelector } from '../../../../shared/store/hooks';
import { postStructureItemVideoExternalUrl } from '../../../../shared/api/requests/outline-builder-project-structure';
import { convertedUrl, isValidHttpUrl } from '../../../../shared/utils';
import { getAssignmentItemAction } from '../../../../entities/assignment/model';
import { useOwner } from '../../../../shared/hooks/useProjectOwner';

const ExternalVideoUrl = () => {
  const [fetch, setFetch] = useState(false);
  const [err, setErr] = useState(false);
  const [url, setUrl] = useState('');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const assignmentId = useAppSelector((state) => state.assignment?.assignment?.id);
  const videoUrl = useAppSelector((state) => state.assignment?.assignment?.assignment?.video?.external_video_href);
  const { isOwner } = useOwner();

  useEffect(() => {
    if (videoUrl) {
      setUrl(videoUrl);
    }
  }, [videoUrl]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (err) {
      setErr(false);
    }
    setUrl(event.target.value);
  };

  const onSaveVideoUrl = async () => {
    if (!isValidHttpUrl(url) && convertedUrl(url)) {
      setErr(true);
    } else {
      setErr(false);
      if (assignmentId) {
        setFetch(true);
        const payload = {
          external_video_href: convertedUrl(url) || '',
        };
        await postStructureItemVideoExternalUrl(assignmentId, payload);
        await dispatch(getAssignmentItemAction(assignmentId)).finally(() => setFetch(false));
      }
    }
  };

  const disable = !isOwner || fetch;

  return (
    <div className="assign-video-content__wrap">
      <div className="assignment-select__block">
        <AssignmentsHead />
      </div>
      <div className="assignment-select__block">
        <BlockTitle className="mb-0" text={t('assignments.video.attachUrl')} />
        <div className="assign-video-ex-url__wrap">
          <Input value={url} disabled={disable} onChange={handleChange} />
          <Button type="primary" disabled={err || disable} loading={fetch} onClick={onSaveVideoUrl}>
            {t('assignments.video.attachVideo')}
          </Button>
        </div>
        {err ? (
          <div className="assign-video-ex-url__error">
            <span className="assign-video-ex-url__error-text">The string is not a valid URL</span>
          </div>
        ) : null}
      </div>
      {videoUrl ? (
        <div className="assignment-select__block">
          <div className="assign-video-ex-url__container">
            <iframe src={videoUrl} allowFullScreen></iframe>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ExternalVideoUrl;
