import React from 'react';

import { useTranslation } from 'react-i18next';
import { dateFormat } from 'shared/utils';
import { AccountData } from 'shared/types/entities';

import './styles.scss';

type BlockProps = {
  label: string;
  text?: string;
};

const Block = React.memo(({ label, text }: BlockProps) => {
  return (
    <div className="user-billing-block">
      <span className="user-billing-block__label">{label}</span>
      <span className="user-billing-block__text">{text}</span>
    </div>
  );
});

type Props = {
  data?: AccountData;
};

const UserBilling = ({ data }: Props) => {
  const { t, i18n } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <div className="user-billing">
      <span className="user-billing__title">{t('billingUser.title')}</span>
      <div className="user-billing__row">
        <Block label={t('billingUser.cycle')} text={data?.subscription.billing_cycle} />
        <Block
          label={t('billingUser.next')}
          text={dateFormat(data?.subscription_expire_at, 'DD MMMM YYYY', i18n.language)}
        />
        <Block label={t('billingUser.total')} text={`$${data?.subscription.price}`} />
      </div>
    </div>
  );
};

export default UserBilling;
